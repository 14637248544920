import React, {Component, Fragment} from "react";
import "./account.css";
import {isMobile} from "../../utils/MobileMgr";
import Tran from "../../config/IntTranslation";
import {
    DownOutlined,
    ExclamationCircleOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone,
    GoogleOutlined,
    KeyOutlined,
    MailOutlined,
    MobileOutlined, RightOutlined,
    SearchOutlined,
    SecurityScanOutlined, UpOutlined
} from "@ant-design/icons";
import USERNAME from "../../assets/icon/icon_username.svg";
import UID from "../../assets/icon/icon_uid.svg";
import PWD from "../../assets/icon/icon_pwd.svg";
import VP from "../../assets/icon/icon_vphone.svg";
import VE from "../../assets/icon/icon_vemail.svg";
import VG from "../../assets/icon/icon_vgo.svg";
import VER from "../../assets/icon/icon_ver.svg";
import BACK from "../../assets/icon/icon_back.svg";
import APP from "../../assets/icon/icon_app.svg";
import GOOGLE from "../../assets/icon/icon_google.svg";
import APPM from "../../assets/icon/icon_app_m.svg";
import GOOGLEM from "../../assets/icon/icon_google_m.svg";
import SEKEY_COPY from "../../assets/icon/icon_sekey_copy.svg";
import {
    checkSecCode,
    data,
    emailCodeType,
    error,
    genPojo,
    getCountryCodeUrl,
    goValid,
    phoneCodeType,
    sendGetRequest,
    sendPostRequest,
    userSettingConfirmUrl,
    userSettingUrl
} from "../../utils/RequestUrlMgr";
import {sendEmailValid, sendGoInitValid, sendGoValid, sendPhoneValid} from "../../utils/Verfiy";
import {Dropdown, Input, Spin, Switch} from "antd";
import intl from "react-intl-universal";
import {connectionError, fail, findMsgByError, findMsgBySuccess, success} from "../../utils/ErrorMgr";
import {
    changeCountryStatus,
    convertToUrl,
    copyFun,
    copyFunMobile,
    countryId,
    handleCountDown,
    toMaskLong
} from "../../utils/Request";
import ValidWin from "../component/ValidWin";
import {
    userEmailChecker,
    userEmailErrorMsg,
    userPasswordChecker,
    userPasswordErrorMsg,
    userPhoneChecker,
    userPhoneErrorMsg,
    userPwdModifyErrorMsg
} from "../../utils/UserInputChecker";
import PageLayout from "../PageLayout";
import {listCountry, listCommonCountry} from "../../config/countryCode";

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            oldPhoneChecker: false,
            submitStatus: 0,
            isUserLoad: false,
            userInfo: "",
            userPojo: "",
            type: "home",
            status: '',
            googleSecure: {
                seKey: "",
                imgUrl: "",
            },
            vPwd: "",
            goPwd: "",
            bindCode: "",
            secCodeOld: "",
            secondOld: 120,
            resendOld: 120,
            secCode: "",
            second: 120,
            resend: 120,
            secCodeL: "",
            secondL: 120,
            resendL: 120,
            showModal: false,
            isConfirm: false,
            countryStatus: false,
            countryCode: '',
            curList: [],
        }
    }

    setterInfo = {
        setterPojo: {
            userName: "",
            userPhone: "",
            userEmail: "",
            userPwd: "",
            uId: "",
            country: "",
            param: "", // 修改的参数
            type: "", // 修改的类型
        }
    }

    checkPost = (secCode, status, userPojo, setterPojo) => {
        if (status === "vPhone" || status === "vEmail") {
            let type = status === "vPhone" ? phoneCodeType : emailCodeType;
            sendGetRequest(checkSecCode, {
                secCode: secCode,
                type: type,
            }).then(result => {
                if (result === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (result) {
                        success(findMsgBySuccess("ACCOUNT_VERIFY_SUCCESS"));
                        this.setState({
                            isConfirm: true,
                            showModal: false,
                        })
                    } else {
                        fail(findMsgByError("VER_CODE_ERROR"))
                    }
                }
            })
        }
        if (status === "mPwd") {
            let goPwd = this.state.goPwd;
            let secCode = this.state.secCode;
            let secCodeL = this.state.secCodeL;
            if (userPojo.vGo) {
                if (goPwd) {
                    sendGoValid(userPojo.goKey, goPwd).then(res => {
                        if (res) {
                            return res;
                        } else {
                            fail(findMsgByError("VER_CODE_ERROR"))
                        }
                    })
                    if (userPojo.vEmail || userPojo.vPhone) {
                        let type = userPojo.vEmail ? emailCodeType : phoneCodeType;
                        sendGetRequest(checkSecCode, {
                            secCode: secCode,
                            type: type,
                        }).then(result => {
                            if (result === connectionError) {
                                fail(findMsgByError(connectionError));
                            } else {
                                if (result) {
                                    let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                    if (finalPojo) {
                                        sendPostRequest(finalPojo).then(res => {
                                            console.log("用户设置API2的finial:", res)
                                            if (res && res.hasOwnProperty("success") && res.success) {
                                                success(findMsgBySuccess("PWD_MODIFY_SUCCESS"), "/login")
                                            } else {
                                                if (res === connectionError) {
                                                    fail(findMsgByError(connectionError));
                                                } else {
                                                    fail(findMsgByError(res[error]))
                                                }
                                            }
                                        })
                                    }
                                } else {
                                    fail(findMsgByError("VER_CODE_ERROR"))
                                }
                            }
                        })
                    }
                }
            } else {
                if (userPojo.vPhone) {
                    sendGetRequest(checkSecCode, {
                        secCode: secCode,
                        type: phoneCodeType,
                    }).then(result => {
                        if (result === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            if (result) {
                                console.log("修改密码手机验证：", result)
                                if (userPojo.vEmail) {
                                    sendGetRequest(checkSecCode, {
                                        secCode: secCodeL,
                                        type: emailCodeType,
                                    }).then(result => {
                                        if (result === connectionError) {
                                            fail(findMsgByError(connectionError));
                                        } else {
                                            if (result) {
                                                let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                                if (finalPojo) {
                                                    sendPostRequest(finalPojo).then(res => {
                                                        console.log("用户设置API2的finial:", res)
                                                        if (res && res.hasOwnProperty("success") && res.success) {
                                                            success(findMsgBySuccess("PWD_MODIFY_SUCCESS"), "/")
                                                        } else {
                                                            if (res === connectionError) {
                                                                fail(findMsgByError(connectionError));
                                                            } else {
                                                                fail(findMsgByError(res[error]))
                                                            }
                                                        }
                                                    })
                                                }
                                            } else {
                                                fail(findMsgByError("VER_CODE_ERROR"))
                                            }
                                        }
                                    })
                                } else {
                                    let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                    if (finalPojo) {
                                        sendPostRequest(finalPojo).then(res => {
                                            console.log("用户设置API2的finial:", res)
                                            if (res && res.hasOwnProperty("success") && res.success) {
                                                success(findMsgBySuccess("PWD_MODIFY_SUCCESS"), "/")
                                            } else {
                                                if (res === connectionError) {
                                                    fail(findMsgByError(connectionError));
                                                } else {
                                                    fail(findMsgByError(res[error]))
                                                }
                                            }
                                        })
                                    }
                                }
                            } else {
                                fail(findMsgByError("VER_CODE_ERROR"))
                            }
                        }
                    })
                } else {
                    console.log("邮箱验证", secCodeL)
                    sendGetRequest(checkSecCode, {
                        secCode: secCodeL,
                        type: emailCodeType,
                    }).then(result => {
                        if (result === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            if (result) {
                                let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                if (finalPojo) {
                                    sendPostRequest(finalPojo).then(res => {
                                        console.log("用户设置API2的finial:", res)
                                        if (res && res.hasOwnProperty("success") && res.success) {
                                            success(findMsgBySuccess("PWD_MODIFY_SUCCESS"), "/")
                                        } else {
                                            if (res === connectionError) {
                                                fail(findMsgByError(connectionError));
                                            } else {
                                                fail(findMsgByError(res[error]))
                                            }
                                        }
                                    })
                                }
                            } else {
                                fail(findMsgByError("VER_CODE_ERROR"))
                            }
                        }
                    })
                }
            }
        }
    }

    /* user setting */
    setCode = (e, props) => {
        let inputValue = e.target.value;
        console.log("input：", inputValue)
        switch (props) {
            case "secCode": {
                if (inputValue) {
                    this.setState({
                        secCode: inputValue,
                    })
                } else {
                    this.setState({
                        secCode: "",
                    })
                }
                return;
            }
            case "secCodeL": {
                if (inputValue) {
                    this.setState({
                        secCodeL: inputValue,
                    })
                } else {
                    this.setState({
                        secCodeL: "",
                    })
                }
                return;
            }
            case "secCodeOld": {
                if (inputValue) {
                    this.setState({
                        secCodeOld: inputValue,
                    })
                } else {
                    this.setState({
                        secCodeOld: "",
                    })
                }
                return;
            }
            case "goPwd": {
                if (inputValue) {
                    this.setState({
                        goPwd: inputValue,
                    })
                } else {
                    this.setState({
                        goPwd: "",
                    })
                }
                return;
            }
            case "vPwd": {
                if (inputValue && userPasswordChecker.test(inputValue)) {
                    this.setState({
                        vPwd: inputValue,
                    })
                } else {
                    this.setState({
                        vPwd: "",
                    })
                }
                return;
            }
            case "bPhone": {
                if (inputValue && userPhoneChecker.test(inputValue)) {
                    this.setState({
                        bindCode: inputValue,
                    })
                } else {
                    this.setState({
                        bindCode: 0,
                    })
                }
                return;
            }
            case "bEmail": {
                if (inputValue && userEmailChecker.test(inputValue)) {
                    this.setState({
                        bindCode: inputValue,
                    })
                } else {
                    this.setState({
                        bindCode: "",
                    })
                }
                return;
            }
            case "mPwd": {
                if (inputValue && userPasswordChecker.test(inputValue)) {
                    this.setState({
                        bindCode: inputValue,
                    })
                } else {
                    this.setState({
                        bindCode: "",
                    })
                }
                return;
            }
            default: {
                return;
            }
        }
    }

    genUserSetting = (setterPojo, type, param, label) => {
        let userInfo = this.state.userInfo;
        let userPojo = this.state.userPojo;
        let isSetting = userPojo.vPhone && userPojo.vEmail;
        console.log("校验是否可行：", isSetting)
        if (label === "vPhone") {
            if (userPojo.userPhone === "0") {
                fail(findMsgByError("VER_OPEN_ERROR"))
            } else {
                if (!userPojo.vEmail) {
                    fail(findMsgByError("VER_CLOSE_ERROR"))
                } else {
                    setterPojo.userName = userInfo.userName;
                    setterPojo.userPwd = userInfo.userPwd;
                    setterPojo.uId = userPojo.uId;
                    setterPojo.type = type;
                    setterPojo.param = param;
                    this.setState({
                            setterPojo,
                        },
                        () => {
                            this.sendPost(setterPojo, type, param, label)
                        }
                    )
                }
            }
        }
        if (label === "vEmail") {
            if (userPojo.userEmail === null) {
                fail(findMsgByError("VER_OPEN_ERROR"))
            } else {
                if (!userPojo.vPhone) {
                    fail(findMsgByError("VER_CLOSE_ERROR"))
                } else {
                    setterPojo.userName = userInfo.userName;
                    setterPojo.userPwd = userInfo.userPwd;
                    setterPojo.uId = userPojo.uId;
                    setterPojo.type = type;
                    setterPojo.param = param;
                    this.setState({
                            setterPojo,
                        },
                        () => {
                            this.sendPost(setterPojo, type, param, label)
                        }
                    )
                }
            }
        }
        if (label === "vGo") {
            setterPojo.userName = userInfo.userName;
            setterPojo.userPwd = userInfo.userPwd;
            setterPojo.uId = userPojo.uId;
            setterPojo.type = type;
            setterPojo.param = param;
            this.setState({
                    setterPojo,
                },
                () => {
                    this.sendPost(setterPojo, type, param, label)
                }
            )
        }
    }

    scrollTop = () => {
        let navTop = document.getElementById("pageHeader");
        navTop.scrollIntoView();
        window.scrollTo(0, 0);
    }

    sendPost = (setterPojo, type, param, label) => {
        let userPojo = this.state.userPojo;
        let pojo = genPojo(userSettingUrl, this.setterInfo.setterPojo);
        console.log("用户设置修改：", pojo)
        if (pojo && label === "vGo" && !userPojo.goKey) {
            sendPostRequest(pojo).then(res => {
                console.log(res)
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                            type: label,
                        },
                        () => this.scrollTop())
                    sendGoInitValid(setterPojo).then(result => {
                        console.log("sendGoInitValid：", result)
                        let googleSecure = this.state.googleSecure;
                        googleSecure.seKey = result[data].seKey;
                        googleSecure.imgUrl = result[data].imgUrl;
                        this.setState({
                            googleSecure,
                        })
                    });
                } else {
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
        } else {
            sendPostRequest(pojo).then(res => {
                console.log(res)
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        type: label,
                    })
                } else {
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
        }
    }

    confirmPostMPN = (bindCode, secCode, secCodeL, setterPojo, userInfo, userPojo, type) => {
        let olderChecker = this.state.oldPhoneChecker;
        if (!olderChecker) {
            fail(findMsgByError("VER_CODE_OLD_ERROR"))
            return;
        }

        if (secCode) {
            sendGetRequest(checkSecCode, {
                secCode: secCode,
                type: phoneCodeType,
            }).then(res => {
                console.log("用户设置API1-原手机验证码校验：", res);
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    res ? this.opNextValidation(bindCode, secCodeL, setterPojo, userInfo, userPojo) : fail(findMsgByError("VER_CODE_ERROR"));
                }
            })
        }
    }

    opNextValidation = (bindCode, secCodeL, setterPojo, userInfo, userPojo, type) => {
        console.log("success change");
        if (secCodeL) {
            if (userPojo.vGo) {
                sendGetRequest(goValid, {
                    key: userPojo.goKey,
                    goPwd: secCodeL,
                }).then(res => {
                    console.log("用户修改手机API1-谷歌校验：", res);
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                    } else {
                        if (res) {
                            this.opPwdValidation(bindCode, secCodeL, setterPojo, userInfo, userPojo)
                        }
                    }
                })
            } else {
                sendGetRequest(checkSecCode, {
                    secCode: secCodeL,
                    type: emailCodeType,
                }).then(res => {
                    console.log("用户修改手机API1-邮箱校验：", res);
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                    } else {
                        if (res) {
                            this.opPwdValidation(bindCode, secCodeL, setterPojo, userInfo, userPojo)
                        }
                    }
                })
            }
        } else {
            this.opPwdValidation(bindCode, secCodeL, setterPojo, userInfo, userPojo)
        }
    }

    opPwdValidation = (bindCode, secCodeL, setterPojo, userInfo, userPojo) => {
        let vPwd = this.state.vPwd + "";
        let pwd = userInfo.userPwd + "";
        if (vPwd && pwd && vPwd === pwd) {
            this.opFinalValidation(bindCode, secCodeL, setterPojo, userInfo, userPojo)
        } else {
            fail(findMsgByError("WRONG_PASS_ERROR"));
        }
    }

    opFinalValidation = (bindCode, secCodeL, setterPojo, userInfo, userPojo) => {
        let param = setterPojo.country + "-" + bindCode;
        console.log(param)
        let userPhone = bindCode;
        let typeR = 1;
        setterPojo.userName = userPojo.userName;
        setterPojo.userPwd = userInfo.userPwd;
        setterPojo.userPhone = userPhone;
        setterPojo.uId = userPojo.uId;
        setterPojo.type = typeR;
        setterPojo.param = param;
        this.setState({
                setterPojo,
            },
            () => {
                let pojo = genPojo(userSettingUrl, setterPojo);
                console.log("用户修改接口1：", pojo)
                if (pojo) {
                    sendPostRequest(pojo).then(res => {
                        console.log("用户修改接口API1：", res)
                        if (res && res.hasOwnProperty("success") && res.success) {
                            let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                            if (finalPojo) {
                                sendPostRequest(finalPojo).then(res => {
                                    console.log("用户设置API2的finial:", res)
                                    if (res && res.hasOwnProperty("success") && res.success) {
                                        success(findMsgBySuccess("PE_MODIFY_SUCCESS"), "/account")
                                    } else {
                                        fail(findMsgByError(res[error]))
                                    }
                                })
                            }
                        } else {
                            fail(findMsgByError(res[error]))
                        }
                    })
                }
            })
    }

    confirmPostM = (bindCode, secCode, secCodeL, vPwd, setterPojo, userInfo, userPojo, type) => {
        if (bindCode) {
            let param = type === "mPhone" ? (setterPojo.country + "-" + bindCode) : bindCode;
            console.log(param)
            let userPhone = bindCode;
            let typeR = 1;
            setterPojo.userName = userInfo.userName;
            setterPojo.userPwd = userInfo.userPwd;
            setterPojo.userPhone = userPhone;
            setterPojo.uId = userPojo.uId;
            setterPojo.type = typeR;
            setterPojo.param = param;
            this.setState({
                    setterPojo,
                },
                () => {
                    let pojo = genPojo(userSettingUrl, setterPojo);
                    console.log("用户设置接口123：", pojo)
                    if (pojo && secCode) {
                        sendPostRequest(pojo).then(res => {
                            console.log("用户设置API1：", res)
                            if (res && res.hasOwnProperty("success") && res.success) {
                                console.log("用户设置API1-验证码校验内容：", secCode)
                                sendGetRequest(checkSecCode, {
                                    secCode: secCode,
                                    type: phoneCodeType,
                                }).then(res => {
                                    console.log("用户设置API1-验证码校验：", res);
                                    if (res === connectionError) {
                                        fail(findMsgByError(connectionError));
                                    } else {
                                        if (res) {
                                            if (secCodeL) {
                                                if (userPojo.vGo) {
                                                    sendGetRequest(goValid, {
                                                        key: userPojo.goKey,
                                                        goPwd: secCodeL,
                                                    }).then(res => {
                                                        console.log("用户设置API1-谷歌校验：", res);
                                                        if (res === connectionError) {
                                                            fail(findMsgByError(connectionError));
                                                        } else {
                                                            if (res) {
                                                                let pwd = userInfo.userPwd;
                                                                if (vPwd === pwd) {
                                                                    let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                                                    if (finalPojo) {
                                                                        sendPostRequest(finalPojo).then(res => {
                                                                            console.log("用户设置API2的finial:", res)
                                                                            if (res && res.hasOwnProperty("success") && res.success) {
                                                                                success(findMsgBySuccess("PE_MODIFY_SUCCESS"), "/account")
                                                                            } else {
                                                                                if (res === connectionError) {
                                                                                    fail(findMsgByError(connectionError));
                                                                                } else {
                                                                                    fail(findMsgByError(res[error]))
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                } else {
                                                                    fail(findMsgByError("WRONG_PASS_ERROR"))
                                                                }
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    sendGetRequest(checkSecCode, {
                                                        secCode: secCodeL,
                                                        type: emailCodeType,
                                                    }).then(res => {
                                                        console.log("用户设置API1-验证码第二次校验：", res);
                                                        if (res === connectionError) {
                                                            fail(findMsgByError(connectionError));
                                                        } else {
                                                            if (res) {
                                                                let pwd = userInfo.userPwd;
                                                                if (vPwd === pwd) {
                                                                    let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                                                    if (finalPojo) {
                                                                        sendPostRequest(finalPojo).then(res => {
                                                                            console.log("用户设置API2的finial:", res)
                                                                            if (res && res.hasOwnProperty("success") && res.success) {
                                                                                success(findMsgBySuccess("PE_MODIFY_SUCCESS"), "/account")
                                                                            } else {
                                                                                if (res === connectionError) {
                                                                                    fail(findMsgByError(connectionError));
                                                                                } else {
                                                                                    fail(findMsgByError(res[error]))
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                } else {
                                                                    fail(findMsgByError("WRONG_PASS_ERROR"))
                                                                }
                                                            }
                                                        }
                                                    })
                                                }
                                            } else {
                                                let pwd = userInfo.userPwd;
                                                if (vPwd === pwd) {
                                                    let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                                    if (finalPojo) {
                                                        sendPostRequest(finalPojo).then(res => {
                                                            console.log("用户设置API2的finial:", res)
                                                            if (res && res.hasOwnProperty("success") && res.success) {
                                                                success(findMsgBySuccess("PE_MODIFY_SUCCESS"), "/account")
                                                            } else {
                                                                if (res === connectionError) {
                                                                    fail(findMsgByError(connectionError));
                                                                } else {
                                                                    fail(findMsgByError(res[error]))
                                                                }
                                                            }
                                                        })
                                                    }
                                                } else {
                                                    fail(findMsgByError("WRONG_PASS_ERROR"))
                                                }
                                            }
                                        } else {
                                            fail(findMsgByError("VER_CODE_ERROR"))
                                        }
                                    }
                                })
                            } else {
                                if (res === connectionError) {
                                    fail(findMsgByError(connectionError));
                                } else {
                                    fail(findMsgByError(res[error]))
                                }
                            }
                        })
                    }
                })
        }
        this.setState({
            submitStatus: 1,
        })
    }

    confirmPostMP = (bindCode, vPwd, setterPojo, userInfo, userPojo, type) => {
        if (bindCode && vPwd && bindCode === vPwd) {
            setterPojo.userName = userInfo.userName;
            setterPojo.userPwd = userInfo.userPwd;
            setterPojo.uId = userPojo.uId;
            setterPojo.type = 3;
            setterPojo.param = bindCode;
            this.setState({
                    setterPojo,
                },
                () => {
                    let pojo = genPojo(userSettingUrl, setterPojo);
                    console.log("修改密码用户设置AP1存入信息：", pojo)
                    if (pojo) {
                        sendPostRequest(pojo).then(res => {
                            console.log("修改密码用户设置API1：", res)
                            if (res && res.hasOwnProperty("success") && res.success) {
                                if (type === "mPwd-v") {
                                    this.setState({
                                        type: type,
                                    })
                                } else {
                                    this.setState({
                                        showModal: true,
                                    })
                                }
                            }
                        })
                    }
                })
        }
        this.setState({
            submitStatus: 1,
        })
    }

    confirmPostB = (setterPojo, type) => {
        let bindCode = this.state.bindCode;
        let secCode = this.state.secCode;
        let secCodeL = this.state.secCodeL;
        let userInfo = this.state.userInfo;
        let userPojo = this.state.userPojo;
        if (bindCode) {
            let param = type === "bPhone" ? (setterPojo.country + "-" + bindCode) : bindCode;
            console.log("param：", param)
            let userPhone = type === "bPhone" && bindCode;
            let userEmail = type === "bEmail" && bindCode;
            let typeR = type === "bPhone" ? 1 : 2;
            setterPojo.userName = userInfo.userName;
            setterPojo.userPwd = userInfo.userPwd;
            setterPojo.userPhone = userPhone;
            setterPojo.userEmail = userEmail;
            setterPojo.uId = userPojo.uId;
            setterPojo.type = typeR;
            setterPojo.param = param;
            this.setState({
                    setterPojo,
                },
                () => {
                    let pojo = genPojo(userSettingUrl, setterPojo);
                    console.log("用户设置接口123：", pojo)
                    if (pojo && secCode) {
                        sendPostRequest(pojo).then(res => {
                            console.log("用户设置API1：", res)
                            if (res && res.hasOwnProperty("success") && res.success) {
                                let checkType = type === "bPhone" ? phoneCodeType : emailCodeType;
                                console.log("用户设置API1-验证码校验内容：", checkType, secCode)
                                sendGetRequest(checkSecCode, {
                                    secCode: secCode,
                                    type: checkType,
                                }).then(result => {
                                    console.log("用户设置API1-验证码校验：", result);
                                    if (result === connectionError) {
                                        fail(findMsgByError(connectionError));
                                    } else {
                                        if (result) {
                                            if (secCodeL) {
                                                if (userPojo.vGo) {
                                                    sendGetRequest(goValid, {
                                                        key: userPojo.goKey,
                                                        goPwd: secCodeL,
                                                    }).then(result => {
                                                        console.log("用户设置API1-谷歌校验：", result);
                                                        if (result === connectionError) {
                                                            fail(findMsgByError(connectionError));
                                                        } else {
                                                            if (result) {
                                                                let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                                                if (finalPojo) {
                                                                    sendPostRequest(finalPojo).then(res => {
                                                                        console.log("用户设置API2的finial:", res)
                                                                        if (res && res.hasOwnProperty("success") && res.success) {
                                                                            success(findMsgBySuccess("PE_MODIFY_SUCCESS"), "/account")
                                                                        } else {
                                                                            if (res === connectionError) {
                                                                                fail(findMsgByError(connectionError));
                                                                            } else {
                                                                                fail(findMsgByError(res[error]))
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            } else {
                                                                fail(findMsgByError("VER_GO_CODE_ERROR"))
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    let checkTypeT = type === "bPhone" ? emailCodeType : phoneCodeType;
                                                    console.log("用户设置API1-二次验证码校验内容：", checkTypeT, secCodeL)
                                                    sendGetRequest(checkSecCode, {
                                                        secCode: secCodeL,
                                                        type: checkTypeT,
                                                    }).then(result => {
                                                        console.log("用户设置API1-验证码第二次校验：", result);
                                                        if (result === connectionError) {
                                                            fail(findMsgByError(connectionError));
                                                        } else {
                                                            if (result) {
                                                                let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                                                if (finalPojo) {
                                                                    sendPostRequest(finalPojo).then(res => {
                                                                        console.log("用户设置API2的finial:", res)
                                                                        if (res && res.hasOwnProperty("success") && res.success) {
                                                                            success(findMsgBySuccess("PE_MODIFY_SUCCESS"), "/account")
                                                                        } else {
                                                                            if (res === connectionError) {
                                                                                fail(findMsgByError(connectionError));
                                                                            } else {
                                                                                fail(findMsgByError(res[error]))
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            } else {
                                                                fail(findMsgByError(type === "bPhone" ? "VER_EMAIL_CODE_ERROR" : "VER_PHONE_CODE_ERROR"))
                                                            }
                                                        }
                                                    })
                                                }
                                            } else {
                                                let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                                if (finalPojo) {
                                                    sendPostRequest(finalPojo).then(res => {
                                                        console.log("用户设置API2的finial:", res)
                                                        if (res && res.hasOwnProperty("success") && res.success) {
                                                            success(findMsgBySuccess("PE_MODIFY_SUCCESS"), "/account")
                                                        } else {
                                                            if (res === connectionError) {
                                                                fail(findMsgByError(connectionError));
                                                            } else {
                                                                fail(findMsgByError(res[error]))
                                                            }
                                                        }
                                                    })
                                                }
                                            }
                                        } else {
                                            fail(findMsgByError("VER_CODE_ERROR"))
                                        }
                                    }
                                })
                            } else {
                                if (res === connectionError) {
                                    fail(findMsgByError(connectionError));
                                } else {
                                    fail(findMsgByError(res[error]))
                                }
                            }
                        })
                    }
                })
        }
        this.setState({
            submitStatus: 1,
        })
    }

    confirmPostV = (isConfirm, setterPojo) => {
        if (isConfirm) {
            console.log("手机邮箱验证接口：", setterPojo)
            let pojo = genPojo(userSettingConfirmUrl, setterPojo);
            sendPostRequest(pojo).then(res => {
                console.log(res)
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                            isConfirm: false,
                        },
                        () => {
                            window.location.href = "/account"
                        }
                    )
                } else {
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
        }
    }

    confirmPostVGO = (googleSecure, secCode, goPwd, userPojo, setterPojo) => {
        let type = userPojo.vEmail ? emailCodeType : phoneCodeType;
        if (secCode && type) {
            sendGetRequest(checkSecCode, {
                secCode: secCode,
                type: type,
            }).then(result => {
                console.log("vGo验证：", result)
                if (result === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (result) {
                        if (userPojo.goKey) {
                            if (goPwd) {
                                sendGoValid(userPojo.goKey, goPwd).then(res => {
                                    console.log("有goKey：", res)
                                    if (res) {
                                        let pojo = genPojo(userSettingConfirmUrl, setterPojo);
                                        if (pojo) {
                                            sendPostRequest(pojo).then(res => {
                                                console.log("有goKey的finial:", res)
                                                if (res && res.hasOwnProperty("success") && res.success) {
                                                    success(findMsgBySuccess("ACCOUNT_VG_SUCCESS"), "/account")
                                                } else {
                                                    if (res === connectionError) {
                                                        fail(findMsgByError(connectionError));
                                                    } else {
                                                        fail(findMsgByError(res[error]))
                                                    }
                                                }
                                            })
                                        }
                                    } else {
                                        fail(findMsgByError("VER_GO_CODE_ERROR"));
                                    }
                                })
                            } else {
                                fail(findMsgByError("VER_CODE_EMPTY"));
                            }
                        } else {
                            if (goPwd) {
                                sendGoValid(googleSecure.seKey, goPwd).then(res => {
                                    console.log("sendGoValid：", res)
                                    if (res) {
                                        let pojo = genPojo(userSettingConfirmUrl, setterPojo);
                                        console.log("用户pojo：", pojo);
                                        if (pojo) {
                                            sendPostRequest(pojo).then(res => {
                                                console.log("用户设置结果：", res)
                                                if (res && res.hasOwnProperty("success") && res.success) {
                                                    console.log(res);
                                                    setterPojo.type = 5;
                                                    setterPojo.param = googleSecure.seKey;
                                                    this.setState({
                                                            setterPojo,
                                                        },
                                                        () => {
                                                            let pojo = genPojo(userSettingUrl, setterPojo);
                                                            if (pojo) {
                                                                sendPostRequest(pojo).then(res => {
                                                                    if (res && res.hasOwnProperty("success") && res.success) {
                                                                        let pojo = genPojo(userSettingConfirmUrl, setterPojo);
                                                                        if (pojo) {
                                                                            sendPostRequest(pojo).then(res => {
                                                                                console.log("finial:", res)
                                                                                if (res && res.hasOwnProperty("success") && res.success) {
                                                                                    success(findMsgBySuccess("ACCOUNT_VG_SUCCESS"), "/account")
                                                                                } else {
                                                                                    if (res === connectionError) {
                                                                                        fail(findMsgByError(connectionError));
                                                                                    } else {
                                                                                        fail(findMsgByError(res[error]))
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        })
                                                } else {
                                                    if (res === connectionError) {
                                                        fail(findMsgByError(connectionError));
                                                    } else {
                                                        fail(findMsgByError(res[error]))
                                                    }
                                                }
                                            })
                                        }
                                    } else {
                                        fail(findMsgByError("VER_GO_CODE_ERROR"));
                                    }
                                });
                            } else {
                                fail(findMsgByError("VER_CODE_EMPTY"));
                            }
                        }
                    } else {
                        fail(findMsgByError("VER_CODE_ERROR"));
                    }
                }
            })
        } else {
            fail(findMsgByError("VER_CODE_EMPTY"));
        }
    }

    loadingMainWin = (type, userInfo, userPojo, setterPojo, isMobile) => {
        let time = this.state.second;
        let resend = this.state.resend;
        let canSend = time === resend;

        let timeL = this.state.secondL;
        let resendL = this.state.resendL;
        let canSendL = timeL === resendL;

        let timeOld = this.state.secondOld;
        let resendOld = this.state.resendOld;
        let canSendOld = timeOld === resendOld;

        let bindCode = this.state.bindCode;
        let secCode = this.state.secCode;
        let secCodeL = this.state.secCodeL;
        let secCodeOld = this.state.secCodeOld;
        let vPwd = this.state.vPwd;
        let goPwd = this.state.goPwd;

        let countryStatus = this.state.countryStatus;
        let countryCode = this.state.countryCode;
        let curList = this.state.curList;

        let googleSecure = this.state.googleSecure;
        let isConfirm = this.state.isConfirm;

        let appStoreUrl = "https://apps.apple.com/cn/app/google-authenticator/id388497605";
        let googlePlayUrl = "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2";

        let lang = intl.get("LANG").toString() === "0";

        if (!isMobile) {
            if (userPojo) {
                if (type === "home") {
                    let widthEN = intl.get("LANG").toString() === "0" ? "" : "22%";
                    return (
                        <Fragment>
                            <div className={"account-layout-base"}>
                                <div className={"account-layout-main-title"}>
                                    <Tran intlKey={"ACCOUNT_BASE"}/>
                                </div>
                                <div className={"account-layout-main-ele"}>
                                    <div className={"account-layout-main-ele-content"}>
                                        <div className={"account-layout-main-ele-wrap"}>
                                            <div className={"account-layout-main-ele-icon"}>
                                                <img className={"icon-img"} src={USERNAME} alt={"user"}/>
                                            </div>
                                            <div className={"account-layout-main-ele-title"} style={{width: widthEN}}>
                                                <Tran intlKey={"ACCOUNT_USERNAME"}/>
                                                {":"}
                                            </div>
                                            <div className={"account-layout-main-ele-text"}>
                                                {userPojo.userName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"account-layout-main-ele-content"}>
                                        <div className={"account-layout-main-ele-wrap"}>
                                            <div className={"account-layout-main-ele-icon"}>
                                                <img className={"icon-img"} src={UID} alt={"id"}/>
                                            </div>
                                            <div className={"account-layout-main-ele-title"} style={{width: widthEN}}>
                                                <Tran intlKey={"ACCOUNT_UID"}/>
                                                {":"}
                                            </div>
                                            <div className={"account-layout-main-ele-text"}>
                                                {userPojo.uId}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"account-layout-safety"}>
                                <div className={"account-layout-main-title"}>
                                    <Tran intlKey={"ACCOUNT_SAFETY"}/>
                                </div>
                                <div className={"account-layout-main-ele"}>
                                    <div className={"account-layout-main-ele-content"}>
                                        <div className={"account-layout-main-ele-wrap"}>
                                            <div className={"account-layout-main-ele-icon"}>
                                                <img className={"icon-img"} src={PWD} alt={"pwd"}/>
                                            </div>
                                            <div className={"account-layout-main-ele-title"} style={{width: widthEN}}>
                                                <Tran intlKey={"ACCOUNT_PWD"}/>
                                            </div>
                                        </div>
                                        <div className={"account-layout-main-ele-title-m"} onClick={() => {
                                            this.setType("mPwd")
                                        }}>
                                            <Tran intlKey={"ACCOUNT_PWD_M"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"account-layout-verify"}>
                                <div className={"account-layout-main-title"}>
                                    <Tran intlKey={"ACCOUNT_VERIFY"}/>
                                </div>
                                <div className={"account-layout-main-ele"}>
                                    <div className={"account-layout-main-ele-content"}>
                                        <div className={"account-layout-main-ele-wrap"}>
                                            <div className={"account-layout-main-ele-icon"}>
                                                <img className={"icon-img"} src={VP} alt={"user"}/>
                                            </div>
                                            <div className={"account-layout-main-ele-title"} style={{width: widthEN}}>
                                                <Tran intlKey={"ACCOUNT_VP"}/>
                                                {":"}
                                            </div>
                                            <div className={"account-layout-main-ele-text"}>
                                                {userPojo.userPhone ? userPojo.userPhone :
                                                    <Tran intlKey={"ACCOUNT_VP_UN"}/>}
                                            </div>
                                            {
                                                userPojo.userPhone ?
                                                    <div className={"account-layout-main-ele-title-m"}
                                                         onClick={() => {
                                                             this.setType("mPhone")
                                                         }}
                                                    >
                                                        <Tran intlKey={"MODIFY"}/>
                                                    </div> :
                                                    <div className={"account-layout-main-ele-title-m"}
                                                         onClick={() => {
                                                             this.setType("bPhone")
                                                         }}
                                                    >
                                                        <Tran intlKey={"ACCOUNT_BIND"}/>
                                                    </div>
                                            }
                                        </div>
                                        <div className={"account-layout-main-ele-title-m"}
                                             onClick={() => {
                                                 this.genUserSetting(setterPojo, 4, userPojo.vPhone ? "phone-false" : "phone-true", "vPhone")
                                             }
                                             }
                                        >
                                            {userPojo.vPhone ? <Tran intlKey={"ACCOUNT_VERIFY_C"}/> :
                                                <Tran intlKey={"ACCOUNT_VERIFY_O"}/>}
                                        </div>
                                    </div>
                                    <div className={"account-layout-main-ele-content"}>
                                        <div className={"account-layout-main-ele-wrap"}>
                                            <div className={"account-layout-main-ele-icon"}>
                                                <img className={"icon-img"} src={VE} alt={"id"}/>
                                            </div>
                                            <div className={"account-layout-main-ele-title"} style={{width: widthEN}}>
                                                <Tran intlKey={"ACCOUNT_VE"}/>
                                                {":"}
                                            </div>
                                            <div className={"account-layout-main-ele-text"}>
                                                {userPojo.userEmail ? userPojo.userEmail :
                                                    <Tran intlKey={"ACCOUNT_VE_UN"}/>}
                                            </div>
                                            <div className={"account-layout-main-ele-title-m"}
                                                 onClick={() => {
                                                     this.setType("bEmail")
                                                 }}
                                            >
                                                {userPojo.userEmail ? "" : <Tran intlKey={"ACCOUNT_BIND"}/>}
                                            </div>
                                        </div>
                                        <div className={"account-layout-main-ele-title-m"}
                                             onClick={() => {
                                                 this.genUserSetting(setterPojo, 4, userPojo.vEmail ? "email-false" : "email-true", "vEmail")
                                             }
                                             }
                                        >
                                            {userPojo.vEmail ? <Tran intlKey={"ACCOUNT_VERIFY_C"}/> :
                                                <Tran intlKey={"ACCOUNT_VERIFY_O"}/>}
                                        </div>
                                    </div>
                                    <div className={"account-layout-main-ele-content"}>
                                        <div className={"account-layout-main-ele-wrap"}>
                                            <div className={"account-layout-main-ele-icon"}>
                                                <img className={"icon-img"} src={VG} alt={"id"}/>
                                            </div>
                                            <div className={"account-layout-main-ele-title"} style={{width: widthEN}}>
                                                <Tran intlKey={"ACCOUNT_VG"}/>
                                                {":"}
                                            </div>
                                            <div className={"account-layout-main-ele-text"}>
                                                {userPojo.vGo ? <Tran intlKey={"ACCOUNT_VG_BIND"}/> :
                                                    <Tran intlKey={"ACCOUNT_VG_UN"}/>}
                                            </div>
                                            <div className={"account-layout-main-ele-title-m"}
                                                 onClick={() => {
                                                     this.genUserSetting(setterPojo, 4, userPojo.vGo ? "go-false" : "go-true", "vGo")
                                                 }}
                                            >
                                                {userPojo.vGo ? "" : <Tran intlKey={"ACCOUNT_BIND"}/>}
                                            </div>
                                        </div>
                                        <div className={"account-layout-main-ele-title-m"}
                                             onClick={() => {
                                                 this.genUserSetting(setterPojo, 4, userPojo.vGo ? "go-false" : "go-true", "vGo")
                                             }}
                                        >
                                            {userPojo.vGo ? <Tran intlKey={"ACCOUNT_VERIFY_C"}/> :
                                                <Tran intlKey={"ACCOUNT_VERIFY_O"}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"account-layout-logout"}>
                                <button className={"account-layout-logout-button"}
                                        onClick={() => {
                                            sessionStorage.removeItem("userInfo");
                                            window.location.href = '/login';
                                        }}
                                >
                                    <Tran intlKey={"ACCOUNT_LOGOUT"}/>
                                </button>
                            </div>
                        </Fragment>
                    )
                }
                if (type === "vPhone" || type === "vEmail") {
                    return (
                        <Fragment>
                            <div className={"account-layout-ver"}>
                                <div className={"account-layout-ver-title"}>
                                    <div style={{cursor: 'pointer'}} onClick={() => {
                                        this.setType("home")
                                    }}>
                                        <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                    </div>
                                    {type === "vPhone" ? <Tran intlKey={"ACCOUNT_VP"}/> :
                                        <Tran intlKey={"ACCOUNT_VE"}/>}
                                </div>
                                <div className={"account-layout-ver-container"}>
                                    <div className={"account-layout-ver-ele"}>
                                        <div className={"account-layout-ver-ele-icon"}>
                                            <img src={type === "vPhone" ? VP : VE} alt={"type"}
                                                 style={{paddingRight: '10px'}}/>
                                        </div>
                                        <div className={"account-layout-ver-ele-title"}>
                                            {type === "vPhone" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                        </div>
                                        <div className={"account-layout-ver-ele-title-m"}>
                                            {type === "vPhone" ? userPojo.userPhone : userPojo.userEmail}
                                        </div>
                                    </div>
                                    <div className={"account-layout-ver-ele"}>
                                        <div className={"account-layout-ver-ele-icon"}>
                                            <img src={VER} alt={"ver"} style={{paddingRight: '10px'}}/>
                                        </div>
                                        <div className={"account-layout-ver-ele-title"}>
                                            {type === "vPhone" ? <Tran intlKey={"ACCOUNT_VP"}/> :
                                                <Tran intlKey={"ACCOUNT_VE"}/>}
                                        </div>
                                        <div className={"account-layout-ver-ele-title-m"}>
                                            <Switch checked={type === "vPhone" ? userPojo.vPhone : userPojo.vEmail}
                                                    onChange={() => {
                                                        this.onChangeVer(type, userPojo)
                                                    }}/>
                                        </div>
                                    </div>
                                </div>
                                <button className={"account-layout-ver-button"}
                                        style={{backgroundColor: isConfirm ? "" : "#666666"}}
                                        disabled={!isConfirm}
                                        onClick={() => {
                                            this.confirmPostV(isConfirm, setterPojo)
                                        }}
                                >
                                    <Tran intlKey={"CONFIRM_MODIFY"}/>
                                </button>
                            </div>
                        </Fragment>
                    )
                }
                if (type === "vGo") {
                    return (
                        <Fragment>
                            <div className={"account-layout-vgo"}>
                                <div className={"account-layout-vgo-title"}>
                                    <div style={{cursor: 'pointer'}} onClick={() => {
                                        this.setType("home")
                                    }}>
                                        <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                    </div>
                                    <Tran intlKey={"ACCOUNT_VG"}/>
                                </div>
                                <div className={"account-layout-vgo-abstract"}>
                                    <ExclamationCircleOutlined style={{paddingRight: '5px'}}/>
                                    <Tran intlKey={"VGO_ABSTRACT"}/>
                                </div>
                                {/*{*/}
                                {/*    userPojo.goKey ?*/}
                                {/*        "" :*/}
                                {/*        <Fragment>*/}
                                            <div className={"account-layout-vgo-ele"}>
                                                <div className={"account-layout-vgo-ele-label"}>
                                                    1
                                                </div>
                                                <div className={"account-layout-vgo-ele-content"}>
                                                    <div className={"account-layout-vgo-ele-title"}>
                                                        <Tran intlKey={"VGO_DOWN_TITLE"}/>
                                                    </div>
                                                    <div className={"account-layout-vgo-ele-des"}>
                                                        <Tran intlKey={"VGO_DOWN_DES"}/>
                                                    </div>
                                                    <div className={"account-layout-vgo-ele-button"}>
                                                        <button className={"vgo-button"}>
                                                            <div className={"vgo-button-modify"}
                                                                 onClick={() => {
                                                                     const w = window.open('about:blank');
                                                                     w.location.href = appStoreUrl}
                                                                 }
                                                            >
                                                                <img src={APP} alt={"app"}/>
                                                                <Tran intlKey={"APP_STORE"}/>
                                                            </div>
                                                        </button>
                                                        <button className={"vgo-button"}>
                                                            <div className={"vgo-button-modify"}
                                                                 onClick={() => {
                                                                     const w = window.open('about:blank');
                                                                     w.location.href = googlePlayUrl}
                                                                 }
                                                            >
                                                                <img src={GOOGLE} alt={"google"}/>
                                                                <Tran intlKey={"GOOGLE_PLAY"}/>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"account-layout-vgo-ele"}>
                                                <div className={"account-layout-vgo-ele-label"}>
                                                    2
                                                </div>
                                                <div className={"account-layout-vgo-ele-content"}>
                                                    <div className={"account-layout-vgo-ele-title"}>
                                                        <Tran intlKey={"VGO_INSTALL_TITLE"}/>
                                                    </div>
                                                    <div className={"account-layout-vgo-ele-des"}
                                                         style={{color: '#DF5060'}}>
                                                        <Tran intlKey={"VGO_INSTALL_DES"}/>
                                                    </div>
                                                    <div className={"account-layout-vgo-ele-img"}>
                                                        {
                                                            userPojo.goKey ?
                                                                null :
                                                                googleSecure.imgUrl ?
                                                                    <img className={"qr-img"}
                                                                         src={convertToUrl(googleSecure.imgUrl)}
                                                                         alt={"qr"}/>
                                                                    :
                                                                    <div className={"qr-img"} style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItem: 'center',
                                                                        paddingTop: '50px'
                                                                    }}>
                                                                        <Spin size={"middle"}/>
                                                                    </div>
                                                        }
                                                        <div className={"account-layout-vgo-ele-img-text"}>
                                                            <div className={"account-layout-vgo-ele-seKey"}>
                                                                <Tran intlKey={"VGO_SEKEY"}/>{"："}
                                                                <div className={"input-empty"}>
                                                                    {userPojo.goKey ? userPojo.goKey : googleSecure.seKey}
                                                                </div>
                                                            </div>
                                                            <input className={"rewards-layout-my-code-ele-content-input"}
                                                                   id={"sekey"}
                                                                   value={userPojo.goKey ? userPojo.goKey : googleSecure.seKey}
                                                                   readOnly={true}
                                                            />
                                                            <div className={"account-layout-vgo-ele-seKey-copy"}>
                                                                <img src={SEKEY_COPY} alt={"copy"}
                                                                     style={{paddingRight: '5px'}}/>
                                                                <div onClick={() => {
                                                                    copyFun("sekey")
                                                                }}>
                                                                    <Tran intlKey={"COPY"}/>
                                                                    <Tran intlKey={"VGO_SEKEY"}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                {/*        </Fragment>*/}
                                {/*}*/}
                                <div className={"account-layout-vgo-ele"}>
                                    <div className={"account-layout-vgo-ele-label"}>
                                        {/*{userPojo.goKey ? 1 : 3}*/}
                                        {3}
                                    </div>
                                    <div className={"account-layout-vgo-ele-content"}>
                                        <div className={"account-layout-vgo-ele-title"}>
                                            <Tran intlKey={"VGO_VER_TITLE"}/>
                                        </div>
                                        {
                                            userPojo.vEmail ?
                                                <div className={"account-layout-vgo-ele-input"}>
                                                    <input
                                                        style={{width: '70%', paddingLeft: '20px', border: '0'}}
                                                        size="middle"
                                                        maxLength={6}
                                                        placeholder={intl.get("VEMAIL_VER_PH")}
                                                        onChange={(e) => this.setCode(e, "secCode")}
                                                    />
                                                    <div className={"modal-win-send-button-mod"}
                                                         style={{color: !canSend ? "grey" : "#236BF3"}}
                                                         onClick={() => {
                                                             canSend && this.handleSendButton(resend, "email", userPojo.userEmail)
                                                         }}
                                                    >
                                                        {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                                    </div>
                                                </div>
                                                :
                                                <div className={"account-layout-vgo-ele-input"}>
                                                    <input
                                                        style={{width: '70%', paddingLeft: '20px', border: '0'}}
                                                        size="middle"
                                                        maxLength={6}
                                                        placeholder={intl.get("VPHONE_VER_PH")}
                                                        onChange={(e) => this.setCode(e, "secCode")}
                                                    />
                                                    <div className={"modal-win-send-button-mod"}
                                                         style={{color: !canSend ? "grey" : "#236BF3"}}
                                                         onClick={() => {
                                                             canSend && this.handleSendButton(resend, "phone", userPojo.userPhone, userPojo)
                                                         }}
                                                    >
                                                        {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                                    </div>
                                                </div>
                                        }
                                        <div className={"account-layout-vgo-ele-input"}>
                                            <input
                                                style={{
                                                    width: '100%',
                                                    paddingLeft: '20px',
                                                    border: '0',
                                                    color: '#666666'
                                                }}
                                                size="middle"
                                                maxLength={10}
                                                placeholder={intl.get("VGO_VER_PH")}
                                                onChange={(e) => this.setCode(e, "goPwd")}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className={"account-layout-vgo-ele-button"} style={{marginTop: '10px'}}>
                                    <button className={"account-layout-bind-ele-vgo-button"}
                                            onClick={() => {
                                                this.confirmPostVGO(googleSecure, secCode, goPwd, userPojo, setterPojo)
                                            }}
                                    >
                                        <Tran intlKey={"CONFIRM"}/>
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
                if (type === "bPhone" || type === "bEmail") {
                    return (
                        <Fragment>
                            <div className={"account-layout-bind"}>
                                <div className={"account-layout-bind-title"}>
                                    <div style={{cursor: 'pointer'}} onClick={() => {
                                        this.setType("home")
                                    }}>
                                        <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                    </div>
                                    <Tran intlKey={"ACCOUNT_BIND"}/>
                                    {lang ? null : <div style={{width: '5px'}}/>}
                                    {type === "bPhone" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                </div>
                                {type === "bPhone" ? "" :
                                    <div className={"account-layout-bind-title"}
                                         style={{marginTop: '10px', fontSize: '12px', color: '#DF5060'}}>
                                        <ExclamationCircleOutlined style={{paddingRight: '10px'}}/>
                                        <Tran intlKey={"VEMAIL_BIND_TIP"}/>
                                    </div>
                                }
                                <div className={"account-layout-bind-ele"}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        {type === "bPhone" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '280px',
                                        height: '40px',
                                        border: '0'
                                    }}>
                                        {type === "bPhone" ?
                                            <Dropdown
                                                overlay={countryId(listCommonCountry, listCountry, curList, this, isMobile, "bPhone")}
                                                trigger={'click'}
                                                visible={countryStatus}
                                                onVisibleChange={visible => {
                                                    this.setState({
                                                        countryStatus: visible,
                                                    })
                                                }}
                                            >
                                                <div style={{
                                                    zIndex: '10',
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '60px',
                                                    height: '20px',
                                                    borderRight: '1px solid #D9E0E9',
                                                    color: 'black'
                                                }}
                                                     onClick={() => {
                                                         changeCountryStatus(this, countryStatus)
                                                     }}>
                                                    {"+"}{setterPojo.country}
                                                    <div className={"register-layout-win-country-icon"}>
                                                        {
                                                            !countryStatus ? <DownOutlined/> : <UpOutlined/>
                                                        }
                                                    </div>
                                                </div>
                                            </Dropdown> : ""
                                        }
                                        <Input
                                            className={"account-layout-bind-ele-input"}
                                            style={type === "bPhone" ? {
                                                height: '100%',
                                                paddingLeft: '70px'
                                            } : {height: '100%'}}
                                            size="middle"
                                            placeholder={type === "bPhone" ? intl.get("PHONE_PH") : intl.get("EMAIL_PH")}
                                            // prefix={type === "bPhone" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => {
                                                this.setCode(e, type)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={"account-layout-bind-ele-input-notice"}>
                                    {this.getErrorMsg(type === "bPhone" ? "userPhone" : "userEmail")}
                                </div>
                                <div className={"account-layout-bind-ele"} style={{margin: 0}}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        {type === "bPhone" ? <Tran intlKey={"ACCOUNT_VP"}/> :
                                            <Tran intlKey={"ACCOUNT_VE"}/>}
                                    </div>
                                    <div className={"input-style-common"}>
                                        <Input
                                            className={"account-layout-bind-ele-input"}
                                            style={{height: '100%'}}
                                            size="middle"
                                            placeholder={type === "bPhone" ? intl.get("VPHONE_VER_PH") : intl.get("VEMAIL_VER_PH")}
                                            // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => this.setCode(e, "secCode")}
                                        />
                                        <div className={"modal-win-send-button"}
                                             style={{left: '220px', color: !canSend ? "grey" : "#236BF3"}}
                                             onClick={() => {
                                                 canSend && this.handleSendButton(resend, type === "bPhone" ? "phone" : "email", bindCode, setterPojo)
                                             }}
                                        >
                                            {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                        </div>
                                    </div>
                                </div>
                                <div className={"account-layout-bind-ele"}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        {userPojo.vGo ? <Tran intlKey={"ACCOUNT_VG"}/> : type === "bPhone" ?
                                            <Tran intlKey={"ACCOUNT_VE"}/> : <Tran intlKey={"ACCOUNT_VP"}/>}
                                    </div>
                                    <div className={"input-style-common"}>
                                        <Input
                                            className={"account-layout-bind-ele-input"}
                                            style={{height: '100%'}}
                                            size="middle"
                                            placeholder={userPojo.vGo ? intl.get("VGO_VER_PH") : type === "bPhone" ? intl.get("VEMAIL_VER_PH") : intl.get("VPHONE_VER_PH")}
                                            // prefix={userPojo.vGo ? <GoogleOutlined style={{color: "grey"}}/> : type === "bPhone" ? <MailOutlined style={{color: "grey"}}/> : <MobileOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => {
                                                this.setCode(e, "secCodeL")
                                            }}
                                        />
                                        {!userPojo.vGo &&
                                            <div className={"modal-win-send-button"}
                                                 style={{left: '220px', color: !canSendL ? "grey" : "#236BF3"}}
                                                 onClick={() => {
                                                     canSendL && this.handleSendButton(resendL, type === "bPhone" ? "email" : "phone", type === "bPhone" ? userPojo.userEmail : userPojo.userPhone, userPojo, "double")
                                                 }}
                                            >
                                                {!canSendL ? "( " + timeL + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <button className={"account-layout-bind-ele-button"}
                                        style={{marginTop: '30px'}}
                                        onClick={() => {
                                            this.confirmPostB(setterPojo, type)
                                        }}
                                >
                                    <Tran intlKey={"CONFIRM"}/>
                                </button>
                            </div>
                        </Fragment>
                    )
                }
                if (type === "mPhone") {
                    return (
                        <Fragment>
                            <div className={"account-layout-modify"} style={{height: 'auto'}}>
                                <div className={"account-layout-modify-title"}>
                                    <div style={{cursor: 'pointer'}}
                                         onClick={() => {this.setType("home")}}
                                    >
                                        <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                    </div>
                                    <Tran intlKey={"MODIFY"}/>
                                    {lang ? null : <div style={{width: '5px'}}/>}
                                    {<Tran intlKey={"PHONE"}/>}
                                </div>
                                <div className={"account-layout-modify-ele"}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        {<Tran intlKey={"PHONE"}/>}
                                    </div>
                                    <div className={"input-style-common"}>
                                        <Dropdown
                                            overlay={countryId(listCommonCountry, listCountry, curList, this, isMobile, 'mPhone')}
                                            trigger={"click"}
                                            visible={countryStatus}
                                        >
                                            <div
                                                className={"register-layout-win-country"}
                                                style={{width: '80px', height: '30px'}}
                                                onClick={() => {
                                                    changeCountryStatus(this, countryStatus)
                                                }}
                                            >
                                                {"+"}{setterPojo.country}
                                                <div className={"register-layout-win-country-icon"}>
                                                    {
                                                        !countryStatus ? <DownOutlined/> : <UpOutlined/>
                                                    }
                                                </div>
                                            </div>
                                        </Dropdown>
                                        <Input
                                            className={"account-layout-modify-ele-input"}
                                            style={type === "mPhone" ? {
                                                height: '100%',
                                                paddingLeft: '90px'
                                            } : {height: '100%'}}
                                            size="middle"
                                            placeholder={intl.get("PHONE_PH")}
                                            // prefix={<MobileOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => {
                                                this.setCode(e, "bPhone")
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={"account-layout-modify-ele-input-notice"}>
                                    {this.getErrorMsg("userPhone")}
                                </div>

                                <div className={"account-layout-modify-ele"}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        {<Tran intlKey={"ACCOUNT_VP_OLD"}/>}
                                    </div>
                                    <div className={"input-style-common"}>
                                        <Input
                                            className={"account-layout-modify-ele-input"}
                                            style={{height: '100%'}}
                                            size="middle"
                                            placeholder={intl.get("VPHONE_VER_PH")}
                                            // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => this.setCode(e, "secCodeOld")}
                                        />
                                        <div className={"modal-win-send-button"}
                                             style={{left: '220px', color: !canSendOld ? "grey" : "#236BF3"}}
                                             onClick={() => {
                                                 canSendOld && this.handleSendButton(resendOld, "phone", userPojo.userPhone, userPojo, "mPhone")
                                             }}
                                        >
                                            {!canSendOld ? "( " + timeOld + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                        </div>
                                    </div>
                                </div>
                                <div className={"account-layout-modify-ele"}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        {<Tran intlKey={"ACCOUNT_VP_NEW"}/>}
                                    </div>
                                    <div className={"input-style-common"}>
                                        <Input
                                            className={"account-layout-modify-ele-input"}
                                            style={{height: '100%'}}
                                            size="middle"
                                            placeholder={intl.get("VPHONE_VER_PH")}
                                            // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => this.setCode(e, "secCode")}
                                        />
                                        <div className={"modal-win-send-button"}
                                             style={{left: '220px', color: !canSend ? "grey" : "#236BF3"}}
                                             onClick={() => {
                                                 canSend && this.handleSendNewPhone(secCodeOld, bindCode, setterPojo, resend, "phone")
                                             }}
                                        >
                                            {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                        </div>
                                    </div>
                                </div>

                                <div className={"account-layout-modify-ele"}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        {<Tran intlKey={"ACCOUNT_VPWD"}/>}
                                    </div>
                                    <div className={"input-style-common"}>
                                        <Input.Password
                                            className={"account-layout-modify-ele-input"}
                                            style={{height: '100%'}}
                                            size="middle"
                                            placeholder={intl.get("PASSWORD_PH")}
                                            // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                            iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                            onChange={(e) => {
                                                this.setCode(e, "vPwd")
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={"account-layout-modify-ele"}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        {userPojo.vGo ? <Tran intlKey={"ACCOUNT_VG"}/> : userPojo.vEmail &&
                                            <Tran intlKey={"ACCOUNT_VE"}/>}
                                    </div>
                                    {
                                        userPojo.vGo ?
                                            <div className={"input-style-common"}>
                                                <Input
                                                    className={"account-layout-modify-ele-input"}
                                                    style={{height: '100%'}}
                                                    size="middle"
                                                    placeholder={intl.get("VGO_VER_PH")}
                                                    // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                                    onChange={(e) => this.setCode(e, "secCodeL")}
                                                />
                                            </div>
                                            :
                                            <div className={"input-style-common"}>
                                                <Input
                                                    className={"account-layout-modify-ele-input"}
                                                    style={{height: '100%'}}
                                                    size="middle"
                                                    placeholder={intl.get("VEMAIL_VER_PH")}
                                                    // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                                    onChange={(e) => this.setCode(e, "secCodeL")}
                                                />
                                                <div className={"modal-win-send-button"}
                                                     style={{left: '220px', color: !canSendL ? "grey" : "#236BF3"}}
                                                     onClick={() => {
                                                         canSendL && this.handleSendButton(resendL, "email", userPojo.userEmail, userPojo, "double")
                                                     }}
                                                >
                                                    {!canSendL ? "( " + timeL + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                                </div>
                                            </div>
                                    }
                                </div>
                                {/*{*/}
                                {/*    userPojo.vGo || userPojo.vEmai ?*/}
                                {/*        <div className={"account-layout-modify-ele"}>*/}
                                {/*            <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>*/}
                                {/*                {userPojo.vGo ? <Tran intlKey={"ACCOUNT_VG"}/> :*/}
                                {/*                    <Tran intlKey={"ACCOUNT_VE"}/>}*/}
                                {/*            </div>*/}
                                {/*            <div style={{*/}
                                {/*                display: 'flex',*/}
                                {/*                flexDirection: 'row',*/}
                                {/*                justifyContent: 'space-between',*/}
                                {/*                alignItems: 'center',*/}
                                {/*                width: '280px',*/}
                                {/*                height: '40px'*/}
                                {/*            }}>*/}
                                {/*                <Input*/}
                                {/*                    className={"account-layout-modify-ele-input"}*/}
                                {/*                    style={{height: '100%'}}*/}
                                {/*                    size="middle"*/}
                                {/*                    placeholder={userPojo.vGo ? intl.get("VGO_VER_PH") : intl.get("VEMAIL_VER_PH")}*/}
                                {/*                    // prefix={userPojo.vGo ? <GoogleOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}*/}
                                {/*                    onChange={(e) => {*/}
                                {/*                        this.setCode(e, "secCodeL")*/}
                                {/*                    }}*/}
                                {/*                />*/}
                                {/*                {!userPojo.vGo &&*/}
                                {/*                    <div className={"modal-win-send-button"}*/}
                                {/*                         style={{left: '205px', color: !canSendL ? "grey" : "#236BF3"}}*/}
                                {/*                         onClick={() => {*/}
                                {/*                             canSendL && this.handleSendButton(resendL, "email", userPojo.userEmail, userPojo, "double")*/}
                                {/*                         }}*/}
                                {/*                    >*/}
                                {/*                        {!canSendL ? "( " + timeL + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}*/}
                                {/*                    </div>*/}
                                {/*                }*/}
                                {/*            </div>*/}
                                {/*        </div> : ""*/}
                                {/*}*/}
                                <div className={"account-layout-modify-ele"} style={{width: '45px'}}>
                                    <button className={"account-layout-bind-ele-button"}
                                            onClick={() => {
                                                // this.confirmPostM(bindCode, secCode, secCodeL, vPwd, setterPojo, userInfo, userPojo, type)
                                                this.confirmPostMPN(bindCode, secCode, secCodeL, setterPojo, userInfo, userPojo, "bPhone")
                                            }}
                                    >
                                        <Tran intlKey={"CONFIRM"}/>
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
                if (type === "mPwd") {
                    return (
                        <Fragment>
                            <div className={"account-layout-modify"}>
                                <div className={"account-layout-modify-title"}>
                                    <div style={{cursor: 'pointer'}} onClick={() => {
                                        this.setType("home")
                                    }}>
                                        <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                    </div>
                                    <Tran intlKey={"MODIFY"}/>
                                    {<Tran intlKey={"ACCOUNT_PWD"}/>}
                                </div>
                                <div className={"account-layout-modify-ele"}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        <Tran intlKey={"ACCOUNT"}/>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '280px',
                                        height: '40px',
                                        border: '0',
                                        background: '#F7F8FB'
                                    }}>
                                        <input
                                            className={"account-layout-modify-ele-input"}
                                            style={{
                                                height: '100%',
                                                paddingLeft: '20px',
                                                border: '1px solid #D9E0E9',
                                                color: '#777777'
                                            }}
                                            size="middle"
                                            disabled={true}
                                            value={userPojo.userName}
                                        />
                                    </div>
                                </div>
                                <div className={"account-layout-modify-ele"}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        <Tran intlKey={"ACCOUNT_PWD_MODIFY"}/>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '280px',
                                        height: '40px'
                                    }}>
                                        <Input.Password
                                            className={"account-layout-modify-ele-input"}
                                            style={{height: '100%'}}
                                            size="middle"
                                            placeholder={intl.get("ACCOUNT_PWD_MODIFY_PH")}
                                            // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                            iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                            onChange={(e) => this.setCode(e, "mPwd")}
                                        />
                                    </div>
                                </div>
                                <div className={"account-layout-modify-ele-input-notice"}>
                                    {this.getErrorMsg("userPwd")}
                                </div>
                                <div className={"account-layout-modify-ele"} style={{margin: 0}}>
                                    <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                        <Tran intlKey={"ACCOUNT_PWD_MODIFY_CONFIRM"}/>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '280px',
                                        height: '40px'
                                    }}>
                                        <Input.Password
                                            className={"account-layout-modify-ele-input"}
                                            style={{height: '100%'}}
                                            size="middle"
                                            placeholder={intl.get("ACCOUNT_PWD_MODIFY_CONFIRM_PH")}
                                            // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                            iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                            onChange={(e) => this.setCode(e, "vPwd")}
                                        />
                                    </div>
                                </div>
                                <div className={"account-layout-modify-ele-input-notice"}>
                                    {this.getErrorMsg("mPwd")}
                                </div>
                                <button className={"account-layout-bind-ele-button"}
                                        onClick={() => {
                                            this.confirmPostMP(bindCode, vPwd, setterPojo, userInfo, userPojo, type)
                                        }}
                                >
                                    <Tran intlKey={"CONFIRM"}/>
                                </button>
                            </div>
                        </Fragment>
                    )
                }
            } else {
                return (
                    <Fragment>
                        <div style={{textAlign: "center", width: "100%", marginTop: '35vh'}}>
                            <Spin tip={"Loading..."} size={"large"}/>
                        </div>
                    </Fragment>
                )
            }
        } else {
            if (userPojo) {
                if (type === "home") {
                    return (
                        <Fragment>
                            <div className={"mobile-account-title"}>
                                <Tran intlKey={"ACCOUNT_SETTING"}/>
                            </div>
                            <div className={"mobile-account-ele"}>
                                <div className={"mobile-account-ele-title"}>
                                    <Tran intlKey={"ACCOUNT_USERNAME"}/>
                                </div>
                                <div className={"mobile-account-ele-text"}>
                                    {userPojo.userName}
                                </div>
                            </div>
                            <div className={"mobile-account-ele"}>
                                <div className={"mobile-account-ele-title"}>
                                    <Tran intlKey={"ACCOUNT_UID"}/>
                                </div>
                                <div className={"mobile-account-ele-text"}>
                                    {userPojo.uId}
                                </div>
                            </div>
                            <div className={"mobile-account-ele"}>
                                <div className={"mobile-account-ele-title"}>
                                    <Tran intlKey={"ACCOUNT_PWD"}/>
                                </div>
                                <div className={"mobile-account-ele-text"} style={{color: '#337AF0'}}
                                     onClick={() => {this.setType("mPwd")}}
                                >
                                    <Tran intlKey={"MODIFY"}/>
                                    <RightOutlined style={{color: '#B5BBC9'}}/>
                                </div>
                            </div>
                            <div className={"mobile-account-ele"}>
                                <div className={"mobile-account-ele-title"}>
                                    <Tran intlKey={"ACCOUNT_VP"}/>
                                </div>
                                <div className={"mobile-account-ele-text"} style={{color: '#337AF0'}}
                                     onClick={() => {
                                         this.setType("bPhone")
                                     }}
                                >
                                    {
                                        userPojo.vPhone ?
                                            <Tran intlKey={"ACCOUNT_OPEN"}/> :
                                            ( userPojo.userPhone ? <Tran intlKey={"ACCOUNT_OPEN_UN"}/> : <Tran intlKey={"ACCOUNT_BIND_UN"}/>)
                                    }
                                    <RightOutlined style={{color: '#B5BBC9'}}/>
                                </div>
                            </div>
                            <div className={"mobile-account-ele"}>
                                <div className={"mobile-account-ele-title"}>
                                    <Tran intlKey={"ACCOUNT_VE"}/>
                                </div>
                                <div className={"mobile-account-ele-text"} style={{color: '#337AF0'}}
                                     onClick={() => {
                                         this.setType("bEmail")
                                     }}
                                >
                                    {
                                        userPojo.vEmail ?
                                            <Tran intlKey={"ACCOUNT_OPEN"}/> :
                                            ( userPojo.userEmail ? <Tran intlKey={"ACCOUNT_OPEN_UN"}/> : <Tran intlKey={"ACCOUNT_BIND_UN"}/>)
                                    }
                                    <RightOutlined style={{color: '#B5BBC9'}}/>
                                </div>
                            </div>
                            <div className={"mobile-account-ele"}>
                                <div className={"mobile-account-ele-title"}>
                                    <Tran intlKey={"ACCOUNT_VG"}/>
                                </div>
                                <div className={"mobile-account-ele-text"} style={{color: '#337AF0'}}
                                    // onClick={() => {this.setType("vGo")}}
                                     onClick={() => {
                                         this.genUserSetting(setterPojo, 4, userPojo.vGo ? "go-false" : "go-true", "vGo")
                                     }}
                                >
                                    {
                                        userPojo.vGo ?
                                            <Tran intlKey={"ACCOUNT_OPEN"}/> :
                                            ( userPojo.goKey ? <Tran intlKey={"ACCOUNT_OPEN_UN"}/> : <Tran intlKey={"ACCOUNT_BIND_UN"}/>)
                                    }
                                    <RightOutlined style={{color: '#B5BBC9'}}/>
                                </div>
                            </div>
                            <button className={"mobile-function-button"}
                                    onClick={() => {
                                        sessionStorage.removeItem("userInfo");
                                        window.location.href = '/login';
                                    }}
                            >
                                <Tran intlKey={"ACCOUNT_LOGOUT"}/>
                            </button>
                        </Fragment>
                    )
                }
                if (type === "mPwd") {
                    return (
                        <Fragment>
                            <div className={"mobile-account-title"}>
                                <div onClick={() => {
                                    this.setType("home")
                                }}>
                                    <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                </div>
                                <Tran intlKey={"MODIFY"}/>
                                <div style={{width: '5px'}}/>
                                <Tran intlKey={"ACCOUNT_PWD"}/>
                            </div>
                            <div className={"mobile-account-sub-title"} style={{marginTop: '5%'}}>
                                <Tran intlKey={"ACCOUNT_PWD_MODIFY"}/>
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <Input.Password
                                    className={"mobile-login-win-login-input"}
                                    placeholder={intl.get("ACCOUNT_PWD_MODIFY_PH")}
                                    // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                    iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                    onChange={(e) => this.setCode(e, "mPwd")}
                                />
                            </div>
                            <div className={"account-layout-modify-ele-input-notice"}
                                 style={{width: '100%', marginBottom: '10px', fontSize: '12px'}}>
                                {this.getErrorMsg("userPwd")}
                            </div>
                            <div className={"mobile-account-sub-title"}>
                                <Tran intlKey={"ACCOUNT_PWD_MODIFY_CONFIRM"}/>
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <Input.Password
                                    className={"mobile-login-win-login-input"}
                                    placeholder={intl.get("ACCOUNT_PWD_MODIFY_PH")}
                                    // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                    iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                    onChange={(e) => this.setCode(e, "vPwd")}
                                />
                            </div>
                            <div className={"account-layout-modify-ele-input-notice"}
                                 style={{width: '100%', fontSize: '12px'}}>
                                {this.getErrorMsg("mPwd")}
                            </div>
                            <button className={"mobile-function-button"}
                                    onClick={() => {
                                        this.confirmPostMP(bindCode, vPwd, setterPojo, userInfo, userPojo, "mPwd-v")
                                    }}
                            >
                                <Tran intlKey={"NEXT_STEP"}/>
                            </button>
                        </Fragment>
                    )
                }
                if (type === "mPwd-v") {
                    return (
                        <Fragment>
                            <div className={"mobile-account-title"}>
                                <div onClick={() => {
                                    this.setType("mPwd")
                                }}>
                                    <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                </div>
                                <Tran intlKey={"ACCOUNT_VERIFY"}/>
                            </div>
                            <div style={{height: '20px'}}/>
                            {
                                userPojo.vGo &&
                                <Fragment>
                                    <div className={"mobile-account-sub-title"} style={{marginTop: '5%'}}>
                                        <Tran intlKey={"ACCOUNT_VG"}/>
                                    </div>
                                    <div className={"mobile-account-ele-input"}>
                                        <Input
                                            className={"mobile-login-win-login-input"}
                                            size="middle"
                                            placeholder={intl.get("VGO_VER_PH")}
                                            // prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                            maxLength={8}
                                            onChange={(e) => {
                                                this.setCode(e, "goPwd")
                                            }}
                                        />
                                    </div>
                                </Fragment>
                            }
                            {
                                !userPojo.vGo && (userPojo.vEmail || userPojo.vPhone) &&
                                <Fragment>
                                    <div className={"mobile-account-sub-title"}>
                                        {userPojo.vEmail ? <Tran intlKey={"ACCOUNT_VE"}/> :
                                            <Tran intlKey={"ACCOUNT_VP"}/>}
                                    </div>
                                    <div className={"mobile-account-ele-input"}>
                                        <Input
                                            className={"mobile-login-win-login-input"}
                                            size="middle"
                                            placeholder={userPojo.vEmail ? intl.get("VEMAIL_VER_PH") : intl.get("VPHONE_VER_PH")}
                                            // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => this.setCode(e, userPojo.vEmail ? "secCodeL" : "secCode")}
                                        />
                                        {/*<div className={"modal-win-send-button"}*/}
                                        {/*     style={{left: '205px', color: !canSend ? "grey" : "#236BF3"}}*/}
                                        {/*     onClick={() => {canSend && this.handleSendButton(resend, userPojo.vEmail ? "email" : "phone", userPojo.vEmail ? userPojo.userEmail : userPojo.userPhone)}}*/}
                                        {/*>*/}
                                        {/*    {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className={"mobile-send-ver-button"}
                                         style={{justifyContent: 'flex-start', color: !canSend ? "grey" : "#5a91d9"}}
                                         onClick={() => {
                                             canSend && this.handleSendButton(resend, userPojo.vEmail ? "email" : "phone", userPojo.vEmail ? userPojo.userEmail : userPojo.userPhone, userPojo)
                                         }}
                                    >
                                        {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                    </div>
                                </Fragment>
                            }
                            <button className={"mobile-function-button"}
                                    onClick={() => (this.checkPost(this.state.secCode, "mPwd", userPojo, setterPojo))}
                            >
                                <Tran intlKey={"CONFIRM"}/><Tran intlKey={"MODIFY"}/>
                            </button>
                        </Fragment>
                    )
                }
                if (type === "bPhone" || type === "bEmail") {
                    return (
                        <Fragment>
                            <div className={"mobile-account-title"}>
                                <div onClick={() => {
                                    this.setType("home")
                                }}>
                                    <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                </div>
                                {type === "bPhone" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                            </div>
                            <div className={"mobile-account-ele"}>
                                <div className={"mobile-account-ele-title"}>
                                    <Tran intlKey={"ACCOUNT_BIND"}/>
                                    {type === "bPhone" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                </div>
                                <div className={"mobile-account-ele-text"}
                                     style={{fontSize: '14px', color: '#337AF0', pointerEvents: type === "bEmail" && userPojo.userEmail ? 'none' : null}}
                                     onClick={() => {
                                         this.setType(type === "bPhone" ?
                                             (userPojo.userPhone ? "mPhone" : "bPhone-v") : "bEmail-v")
                                     }}
                                >
                                    {type === "bPhone" ? (userPojo.userPhone ? <Tran intlKey={"MODIFY"}/> :
                                            <Fragment>
                                                <Tran intlKey={"ACCOUNT_BIND_UN"}/>
                                                <RightOutlined />
                                            </Fragment>
                                        )
                                        : (userPojo.userEmail ? userPojo.userEmail :
                                            <Fragment>
                                                <Tran intlKey={"ACCOUNT_BIND_UN"}/>
                                                <RightOutlined />
                                            </Fragment>
                                        )}
                                </div>
                            </div>
                            <div className={"mobile-account-ele"}>
                                <div className={"mobile-account-ele-title"}>
                                    {type === "bPhone" ? <Tran intlKey={"ACCOUNT_VP"}/> :
                                        <Tran intlKey={"ACCOUNT_VE"}/>}
                                </div>
                                <div className={"mobile-account-ele-text"} style={{fontSize: '14px', color: '#337AF0'}}>
                                    <Switch checked={type === "bPhone" ? userPojo.vPhone : userPojo.vEmail}
                                            onClick={() => {
                                                this.genUserSetting(setterPojo, 4,
                                                    type === "bPhone" ? userPojo.vPhone ? "phone-false" : "phone-true" : userPojo.vEmail ? "email-false" : "email-true",
                                                    type === "bPhone" ? "vPhone" : "vEmail")
                                            }
                                            }
                                    />
                                </div>
                            </div>
                            <button className={"mobile-function-button"}
                                    onClick={() => {
                                        this.confirmPostB(setterPojo, type)
                                    }}
                            >
                                <Tran intlKey={"CONFIRM"}/>
                                <div style={{width: '5px'}}/>
                                <Tran intlKey={"MODIFY"}/>
                            </button>
                        </Fragment>
                    )
                }
                if (type === "mPhone") {
                    return (
                        <Fragment>
                            <div className={"mobile-account-title"}>
                                <div onClick={() => {
                                    this.setType("bPhone")
                                }}>
                                    <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                </div>
                                <Tran intlKey={"MODIFY"}/>
                                <div style={{width: '5px'}}/>
                                <Tran intlKey={"PHONE"}/>
                            </div>
                            <div className={"mobile-account-sub-title"} style={{marginTop: '5%'}}>
                                {<Tran intlKey={"PHONE"}/>}
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    border: '0'
                                }}>
                                    <div className={"register-layout-win-country"}
                                         style={{alignItems: 'center', width: '80px', height: '20px'}}
                                         onClick={() => {
                                             this.setType("countryId", "mPhone")
                                         }}
                                    >
                                        {"+" + countryCode}
                                        <div className={"register-layout-win-country-icon"}>
                                            <DownOutlined/>
                                        </div>
                                    </div>
                                    <Input
                                        className={"mobile-login-win-login-input"}
                                        style={{paddingLeft: '90px'}}
                                        size="middle"
                                        placeholder={intl.get("PHONE_PH")}
                                        // prefix={type === "bPhone-v" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                        onChange={(e) => {
                                            this.setCode(e, "bPhone")
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"account-layout-modify-ele-input-notice"}
                                 style={{width: '100%', marginBottom: '10px', fontSize: '12px'}}>
                                {this.getErrorMsg(type === "bPhone-v" ? "userPhone" : "userEmail")}
                            </div>
                            <div className={"mobile-account-sub-title"}>
                                <Tran intlKey={"ACCOUNT_VP_OLD"}/>
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    border: '0'
                                }}>
                                    <Input
                                        className={"mobile-login-win-login-input"}
                                        size="middle"
                                        placeholder={intl.get("VPHONE_VER_PH")}
                                        // prefix={type === "bPhone-v" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                        onChange={(e) => {
                                            this.setCode(e, "secCodeOld")
                                        }}
                                    />
                                    <div className={"mobile-send-button"}
                                         style={{color: !canSendOld ? "grey" : "#236BF3"}}
                                         onClick={() => {
                                             canSendOld && this.handleSendButton(resendOld, "phone", userPojo.userPhone, userPojo, "mPhone")
                                         }}
                                    >
                                        {!canSendOld ? "( " + timeOld + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                    </div>
                                </div>
                            </div>
                            <div style={{height: '20px'}}/>
                            <div className={"mobile-account-sub-title"}>
                                <Tran intlKey={"ACCOUNT_VP_NEW"}/>
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    border: '0'
                                }}>
                                    <Input
                                        className={"mobile-login-win-login-input"}
                                        size="middle"
                                        placeholder={intl.get("VPHONE_VER_PH")}
                                        // prefix={type === "bPhone-v" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                        onChange={(e) => {
                                            this.setCode(e, "secCode")
                                        }}
                                    />
                                    <div className={"mobile-send-button"}
                                         style={{color: !canSend ? "grey" : "#236BF3"}}
                                         onClick={() => {
                                             canSend && this.handleSendNewPhone(secCodeOld, bindCode, setterPojo, resend, "phone")
                                         }}
                                    >
                                        {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                    </div>
                                </div>
                            </div>
                            <div style={{height: '20px'}}/>
                            <div className={"mobile-account-sub-title"}>
                                <Tran intlKey={"ACCOUNT_VPWD"}/>
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    border: '0'
                                }}>
                                    <Input
                                        className={"mobile-login-win-login-input"}
                                        size="middle"
                                        placeholder={intl.get("PASSWORD_PH")}
                                        // prefix={type === "bPhone-v" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                        onChange={(e) => {
                                            this.setCode(e, "vPwd")
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{height: '20px'}}/>
                            <div className={"mobile-account-sub-title"}>
                                {userPojo.vGo ? <Tran intlKey={"ACCOUNT_VG"}/> : userPojo.vEmail &&
                                    <Tran intlKey={"ACCOUNT_VE"}/>}
                            </div>
                            {
                                userPojo.vGo ?
                                    <div className={"mobile-account-ele-input"}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            border: '0'
                                        }}>
                                            <Input
                                                className={"mobile-login-win-login-input"}
                                                size="middle"
                                                placeholder={intl.get("VGO_VER_PH")}
                                                // prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                                onChange={(e) => {
                                                    this.setCode(e, "secCodeL")
                                                }}
                                            />
                                        </div>
                                    </div> :
                                    userPojo.vEmail &&
                                    <div className={"mobile-account-ele-input"}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            border: '0'
                                        }}>
                                            <Input
                                                className={"mobile-login-win-login-input"}
                                                size="middle"
                                                placeholder={intl.get("VEMAIL_VER_PH")}
                                                // prefix={<MailOutlined style={{color: "grey"}}/>}
                                                onChange={(e) => {
                                                    this.setCode(e, "secCodeL")
                                                }}
                                            />
                                            {
                                                <div className={"mobile-send-button"}
                                                     style={{color: !canSendL ? "grey" : "#236BF3"}}
                                                     onClick={() => {
                                                         canSendL && this.handleSendButton(resendL, "email", userPojo.userEmail, userPojo, "double")
                                                     }}
                                                >
                                                    {!canSendL ? "( " + timeL + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                                </div>
                                            }
                                        </div>
                                    </div>
                            }
                            <button className={"mobile-function-button"}
                                    onClick={() => {
                                        this.confirmPostMPN(bindCode, secCode, secCodeL, setterPojo, userInfo, userPojo, "bPhone")
                                    }}
                            >
                                <Tran intlKey={"CONFIRM"}/><Tran intlKey={"MODIFY"}/>
                            </button>
                        </Fragment>
                    )
                }
                if (type === "bPhone-v" || type === "bEmail-v") {
                    return (
                        <Fragment>
                            {
                                type === "bPhone-v" ?
                                    <div className={"mobile-account-title"}>
                                        <div onClick={() => {
                                            this.setType("bPhone")
                                        }}>
                                            <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                        </div>
                                        <Tran intlKey={"ACCOUNT_BIND"}/>
                                        <div style={{width: '5px'}}/>
                                        <Tran intlKey={"PHONE"}/>
                                    </div> :
                                    <div className={"mobile-account-title"}>
                                        <div onClick={() => {
                                            this.setType("bEmail")
                                        }}>
                                            <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                        </div>
                                        <Tran intlKey={"ACCOUNT_BIND"}/>
                                        <div style={{width: '5px'}}/>
                                        <Tran intlKey={"EMAIL"}/>
                                    </div>
                            }
                            {
                                type === "bEmail-v" &&
                                <div className={"mobile-account-ele-input"}
                                     style={{
                                         justifyContent: 'flex-start',
                                         paddingLeft: '10px',
                                         background: '#FDF7F7',
                                         color: '#DF5060'
                                     }}
                                >
                                    <ExclamationCircleOutlined style={{paddingRight: '10px'}}/>
                                    <Tran intlKey={"VEMAIL_BIND_TIP"}/>
                                </div>
                            }
                            <div className={"mobile-account-sub-title"} style={{marginTop: '5%'}}>
                                {type === "bPhone-v" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    border: '0'
                                }}>
                                    {type === "bPhone-v" ?
                                        <div className={"register-layout-win-country"}
                                             style={{alignItems: 'center', width: '80px', height: '20px'}}
                                             onClick={() => {
                                                 this.setType("countryId")
                                             }}
                                        >
                                            {"+"}{setterPojo.country}
                                            <div className={"register-layout-win-country-icon"}>
                                                <DownOutlined/>
                                            </div>
                                        </div> : null
                                    }
                                    <Input
                                        className={"mobile-login-win-login-input"}
                                        style={type === "bPhone-v" ? {paddingLeft: '90px'} : null}
                                        size="middle"
                                        placeholder={type === "bPhone-v" ? intl.get("PHONE_PH") : intl.get("EMAIL_PH")}
                                        // prefix={type === "bPhone-v" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                        onChange={(e) => {
                                            this.setCode(e, type === "bPhone-v" ? "bPhone" : "bEmail")
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"account-layout-modify-ele-input-notice"}
                                 style={{width: '100%', marginBottom: '10px', fontSize: '12px'}}>
                                {this.getErrorMsg(type === "bPhone-v" ? "userPhone" : "userEmail")}
                            </div>
                            <div className={"mobile-account-sub-title"}>
                                {type === "bPhone-v" ? <Tran intlKey={"ACCOUNT_VP"}/> : <Tran intlKey={"ACCOUNT_VE"}/>}
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    border: '0'
                                }}>
                                    <Input
                                        className={"mobile-login-win-login-input"}
                                        size="middle"
                                        placeholder={type === "bPhone-v" ? intl.get("VPHONE_VER_PH") : intl.get("VEMAIL_VER_PH")}
                                        // prefix={type === "bPhone-v" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                        onChange={(e) => {
                                            this.setCode(e, "secCode")
                                        }}
                                    />
                                    <div className={"mobile-send-button"}
                                         style={{color: !canSend ? "grey" : "#236BF3"}}
                                         onClick={() => {
                                             canSend && this.handleSendButton(resend, type === "bPhone-v" ? "phone" : "email", bindCode, setterPojo)
                                         }}
                                    >
                                        {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                    </div>
                                </div>
                            </div>
                            <div style={{height: '20px'}}/>
                            <div className={"mobile-account-sub-title"}>
                                {userPojo.vGo ? <Tran intlKey={"ACCOUNT_VG"}/> : userPojo.vEmail ?
                                    <Tran intlKey={"ACCOUNT_VE"}/> : type !== "bPhone-v" && userPojo.vPhone &&
                                    <Tran intlKey={"ACCOUNT_VP"}/>}
                            </div>
                            {
                                userPojo.vGo ?
                                    <div className={"mobile-account-ele-input"}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            border: '0'
                                        }}>
                                            <Input
                                                className={"mobile-login-win-login-input"}
                                                size="middle"
                                                placeholder={intl.get("VGO_VER_PH")}
                                                // prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                                onChange={(e) => {
                                                    this.setCode(e, "secCodeL")
                                                }}
                                            />
                                        </div>
                                    </div> :
                                    userPojo.vEmail ?
                                        <div className={"mobile-account-ele-input"}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                border: '0'
                                            }}>
                                                <Input
                                                    className={"mobile-login-win-login-input"}
                                                    size="middle"
                                                    placeholder={intl.get("VEMAIL_VER_PH")}
                                                    // prefix={<MailOutlined style={{color: "grey"}}/>}
                                                    onChange={(e) => {
                                                        this.setCode(e, "secCodeL")
                                                    }}
                                                />
                                                {
                                                    <div className={"mobile-send-button"}
                                                         style={{color: !canSendL ? "grey" : "#236BF3"}}
                                                         onClick={() => {
                                                             canSendL && this.handleSendButton(resendL, "email", userPojo.userEmail, userPojo, "double")
                                                         }}
                                                    >
                                                        {!canSendL ? "( " + timeL + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                                    </div>
                                                }
                                            </div>
                                        </div> :
                                        type !== "bPhone-v" && userPojo.vPhone &&
                                        <div className={"mobile-account-ele-input"}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                border: '0'
                                            }}>
                                                <Input
                                                    className={"mobile-login-win-login-input"}
                                                    size="middle"
                                                    placeholder={intl.get("VPHONE_VER_PH")}
                                                    // prefix={<MobileOutlined style={{color: "grey"}}/>}
                                                    onChange={(e) => {
                                                        this.setCode(e, "secCodeL")
                                                    }}
                                                />
                                                {
                                                    <div className={"mobile-send-button"}
                                                         style={{color: !canSendL ? "grey" : "#236BF3"}}
                                                         onClick={() => {
                                                             canSendL && this.handleSendButton(resendL, "phone", userPojo.userPhone, userPojo, "double")
                                                         }}
                                                    >
                                                        {!canSendL ? "( " + timeL + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                            }
                            <button className={"mobile-function-button"}
                                    onClick={() => {
                                        this.confirmPostB(setterPojo, type === "bPhone-v" ? "bPhone" : "bEmail")
                                    }}
                            >
                                <Tran intlKey={"CONFIRM"}/><Tran intlKey={"MODIFY"}/>
                            </button>
                        </Fragment>
                    )
                }
                if (type === "vPhone" || type === "vEmail") {
                    return (
                        <Fragment>
                            {
                                type === "vPhone" ?
                                    <div className={"mobile-account-title"}>
                                        <div onClick={() => {
                                            this.setType("bPhone")
                                        }}>
                                            <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                        </div>
                                        <Tran intlKey={"ACCOUNT_VERIFY_O"}/>
                                        <div style={{width: '5px'}}/>
                                        <Tran intlKey={"PHONE"}/>
                                    </div> :
                                    <div className={"mobile-account-title"}>
                                        <div onClick={() => {
                                            this.setType("bEmail")
                                        }}>
                                            <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                        </div>
                                        <Tran intlKey={"ACCOUNT_VERIFY_O"}/>
                                        <div style={{width: '5px'}}/>
                                        <Tran intlKey={"EMAIL"}/>
                                    </div>
                            }
                            <div className={"mobile-account-ele"}
                                 style={{justifyContent: 'flex-start', backgroundColor: '#FDF9F6'}}>
                                <div style={{marginRight: '5px', color: '#e78846'}}>
                                    <ExclamationCircleOutlined/>
                                </div>
                                <div className={"mobile-account-ele-text"}>
                                    <Tran intlKey={"REGISTER_VER_SEND"}/>
                                    {" "}
                                    {type === "vPhone" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                    {"："}
                                </div>
                                <div className={"mobile-account-ele-text"} style={{color: '#337AF0'}}>
                                    {type === "vPhone" ?
                                        userPojo.userPhone
                                        :
                                        userPojo.userEmail
                                    }
                                </div>
                            </div>
                            <div className={"mobile-account-sub-title"} style={{marginTop: '5%'}}>
                                {type === "vPhone" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    border: '0'
                                }}>
                                    <Input
                                        className={"mobile-login-win-login-input"}
                                        size="middle"
                                        placeholder={type === "vPhone" ? intl.get("PHONE_PH") : intl.get("EMAIL_PH")}
                                        // prefix={type === "vPhone" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                        value={type === "vPhone" ? userPojo.userPhone : userPojo.userEmail}
                                        disable={true}
                                    />
                                </div>
                            </div>
                            <div style={{height: '20px'}}/>
                            <div className={"mobile-account-sub-title"}>
                                {type === "vPhone" ? <Tran intlKey={"ACCOUNT_VP"}/> : <Tran intlKey={"ACCOUNT_VE"}/>}
                            </div>
                            <div className={"mobile-account-ele-input"}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    border: '0'
                                }}>
                                    <Input
                                        className={"mobile-login-win-login-input"}
                                        size="middle"
                                        placeholder={type === "vPhone" ? intl.get("VPHONE_VER_PH") : intl.get("VEMAIL_VER_PH")}
                                        // prefix={type === "vPhone" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                        onChange={(e) => {
                                            this.setCode(e, "secCode")
                                        }}
                                    />
                                    <div className={"mobile-send-button"}
                                         style={{color: !canSend ? "grey" : "#236BF3"}}
                                         onClick={() => {
                                             canSend && this.handleSendButton(resend, type === "vPhone" ? "phone" : "email", type === "vPhone" ? userPojo.userPhone : userPojo.userEmail, userPojo)
                                         }}
                                    >
                                        {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                    </div>
                                </div>
                            </div>
                            <button className={"mobile-function-button"}
                                    onClick={() => {
                                        this.confirmPostV(true, setterPojo)
                                    }}
                            >
                                <Tran intlKey={"CONFIRM"}/>
                            </button>
                        </Fragment>
                    )
                }
                if (type === "vGo") {
                    return (
                        <Fragment>
                            <div className={"mobile-account-title"} id={"pageHeader"}>
                                <div onClick={() => {
                                    this.setType("home")
                                }}>
                                    <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                </div>
                                <Tran intlKey={"ACCOUNT_VG"}/>
                            </div>
                            {
                                userPojo.vGo ?
                                    "" :
                                    <Fragment>
                                        <div className={"mobile-account-vgo-ele"}>
                                            <div className={"mobile-account-vgo-ele-label"}>
                                                1
                                            </div>
                                            <div className={"mobile-account-vgo-ele-content"}>
                                                <div className={"mobile-account-vgo-ele-title"}>
                                                    <Tran intlKey={"VGO_DOWN_TITLE"}/>
                                                </div>
                                                <div className={"mobile-account-vgo-ele-button"}>
                                                    <button className={"mobile-vgo-button"}
                                                            onClick={() => {
                                                                const w = window.open('about:blank');
                                                                w.location.href = appStoreUrl}
                                                            }
                                                    >
                                                        <img style={{transform: 'scale(0.6)'}} src={APPM} alt={"app"}/>
                                                        <Tran intlKey={"APP_STORE"}/>
                                                    </button>
                                                    <button className={"mobile-vgo-button"}
                                                            style={{marginLeft: '10px'}}
                                                            onClick={() => {
                                                                const w = window.open('about:blank');
                                                                w.location.href = googlePlayUrl}
                                                            }
                                                    >
                                                        <img style={{transform: 'scale(0.6)'}} src={GOOGLEM}
                                                             alt={"google"}/>
                                                        <Tran intlKey={"GOOGLE_PLAY"}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"mobile-account-vgo-ele"}>
                                            <div className={"mobile-account-vgo-ele-label"}>
                                                2
                                            </div>
                                            <div className={"mobile-account-vgo-ele-content"}>
                                                <div className={"mobile-account-vgo-ele-title"}>
                                                    <Tran intlKey={"VGO_INSTALL_TITLE"}/>
                                                </div>
                                                <div className={"mobile-account-vgo-ele-title"}
                                                     style={{fontSize: '12px', color: '#DF5060'}}>
                                                    <Tran intlKey={"VGO_INSTALL_DES"}/>
                                                </div>
                                                <div className={"mobile-account-vgo-ele-key"}>
                                                    <div className={"mobile-account-vgo-ele-input"}>
                                                        {userPojo.goKey ? userPojo.goKey : googleSecure.seKey}
                                                    </div>
                                                    <textarea
                                                        className={"rewards-layout-my-code-ele-content-input"}
                                                        id={"goKey"}
                                                        style={{fontWeight: 'bold'}}
                                                        value={userPojo.goKey ? userPojo.goKey : googleSecure.seKey}
                                                        // readOnly={true}
                                                    />
                                                    <div className={"mobile-account-vgo-ele-input-text"}
                                                         onClick={() => {copyFun("goKey")}}
                                                    >
                                                        <Tran intlKey={"COPY"}/>
                                                        <Tran intlKey={"VGO_SEKEY"}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                            }
                            <div className={"mobile-account-vgo-ele"}>
                                <div className={"mobile-account-vgo-ele-label"}>
                                    {userPojo.vGo ? 1 : 3}
                                    {/*{3}*/}
                                </div>
                                <div className={"mobile-account-vgo-ele-content"}>
                                    <div className={"mobile-account-vgo-ele-title"}>
                                        {
                                            userPojo.vGo ?
                                                <Tran intlKey={"VGO_VER_TITLE_CLOSE"}/> :
                                                <Tran intlKey={"VGO_VER_TITLE"}/>
                                        }
                                    </div>
                                    {
                                        userPojo.vEmail ?
                                            <div className={"mobile-account-vgo-ele-key"}>
                                                <input
                                                    className={"mobile-account-vgo-ele-input"}
                                                    placeholder={intl.get("VEMAIL_VER_PH")}
                                                    onChange={(e) => this.setCode(e, "secCode")}
                                                />
                                                <div className={"mobile-send-button"}
                                                     style={{color: !canSend ? "grey" : "#236BF3"}}
                                                     onClick={() => {
                                                         canSend && this.handleSendButton(resend, "email", userPojo.userEmail)
                                                     }}
                                                >
                                                    {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                                </div>
                                            </div> :
                                            <div className={"mobile-account-vgo-ele-key"}>
                                                <input
                                                    className={"mobile-account-vgo-ele-input"}
                                                    placeholder={intl.get("VPHONE_VER_PH")}
                                                    onChange={(e) => this.setCode(e, "secCode")}
                                                />
                                                <div className={"mobile-send-button"}
                                                     style={{color: !canSend ? "grey" : "#236BF3"}}
                                                     onClick={() => {
                                                         canSend && this.handleSendButton(resend, "phone", userPojo.userPhone, userPojo)
                                                     }}
                                                >
                                                    {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                                </div>
                                            </div>
                                    }
                                    <div className={"mobile-account-vgo-ele-key"}>
                                        <input
                                            className={"mobile-account-vgo-ele-input"}
                                            placeholder={intl.get("VGO_VER_PH")}
                                            onChange={(e) => this.setCode(e, "goPwd")}
                                        />
                                    </div>
                                    <button className={"mobile-function-button"}
                                            onClick={() => {
                                                this.confirmPostVGO(googleSecure, secCode, goPwd, userPojo, setterPojo)
                                            }}
                                            style={{backgroundColor: (secCode && goPwd) ? '' : 'gray'}}
                                    >
                                        <Tran intlKey={"CONFIRM"}/>
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
                if (type === "countryId") {
                    return (
                        <div className={"mobile-login-layout"}>
                            <div className={"mobile-login-ver"} style={{width: '95%', height: 'auto'}}>
                                <div className={"mobile-login-ver-title"}>
                                    <div onClick={() => {
                                        this.setType("bPhone-v")
                                    }}>
                                        <img src={BACK} alt={"back"} style={{paddingRight: '10px'}}/>
                                    </div>
                                    <Tran intlKey="COUNTRY_ID_CHOOSE"/>
                                </div>
                                <Input
                                    className={"mobile-login-win-login-input"}
                                    style={{margin: '20px 0'}}
                                    size="middle"
                                    placeholder={intl.get("COUNTRY_ID_CHOOSE_PH")}
                                    maxLength={20}
                                    onChange={(e) => {this.genSearch(e, listCountry)}}
                                    // prefix={<SearchOutlined style={{color: "grey"}}/>}
                                />
                                {countryId(listCommonCountry, listCountry, curList, this, isMobile, this.state.status === "mPhone" ? "mPhone" : "bPhone-v")}
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    genSearch = (e, list) => {
        let inputValue = e.target.value;
        console.log("ip search:", inputValue)
        let searchList = [];
        if (inputValue) {
            list.forEach((item) => {
                for (let i in item) {
                    if (item.hasOwnProperty(i) && item[i]) {
                        let hsVal = item[i] + "";
                        let value = inputValue.replace("+", "")
                        if (hsVal.includes(value)) {
                            console.log("hsVal:", hsVal)
                            searchList.push(item);
                        }
                    }
                }
            })
            console.log("ip list:", searchList)
        }
        this.setState({
            curList: searchList,
        })
    }

    getErrorMsg(props) {
        if (this.state.submitStatus !== 1) {
            return;
        }
        switch (props) {
            case "userPhone": {
                return this.state.bindCode === 0 || this.state.bindCode === "" ? userPhoneErrorMsg(intl) : "";
            }
            case "userEmail": {
                return this.state.bindCode === "" ? userEmailErrorMsg(intl) : "";
            }
            case "userPwd": {
                return this.state.bindCode === "" ? userPasswordErrorMsg(intl) : "";
            }
            case "mPwd": {
                return this.state.bindCode !== this.state.vPwd ? userPwdModifyErrorMsg(intl) : "";
            }
            default: {
                return "";
            }
        }
    }

    /* ip interface */
    genCountryCode = () => {
        sendGetRequest(getCountryCodeUrl).then(res => {
            console.log("区号：", res)
            let setterPojo = this.setterInfo.setterPojo;
            setterPojo["country"] = res.replace("+", "");
            let code = res.replace("+", "");
            this.setState({
                countryCode: code,
                setterPojo
            })
        })
    }

    changeCountryCode = (data, isMobile) => {
        let setterPojo = this.setterInfo.setterPojo;
        setterPojo["country"] = data;
        this.setState({
            type: !isMobile ? "bPhone" : "bPhone-v",
            setterPojo
        })
    }

    onChangeVer = (type, userPojo) => {
        if (type === "vPhone") {
            if (userPojo.userPhone !== 0 && userPojo.userPhone) {
                this.setState({
                    showModal: true,
                })
            } else {
                fail(findMsgByError("ACCOUNT_VP_UN"))
            }
        } else {
            if (userPojo.userEmail) {
                this.setState({
                    showModal: true,
                })
            } else {
                fail(findMsgByError("ACCOUNT_VE_UN"))
            }
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    handleSendNewPhone = (secCodeOld, bindCode, setterPojo, resend, status) => {
        if (secCodeOld) {
            sendGetRequest(checkSecCode, {
                secCode: secCodeOld,
                type: phoneCodeType,
            }).then(res => {
                console.log("用户设置API1-原手机验证码校验：", res);
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    this.setState({
                        oldPhoneChecker: res,
                    }, () => {
                        this.handleSendButton(resend, status, bindCode, setterPojo)
                    })
                }
            })
        } else {
            fail(findMsgByError("VER_CODE_OLD_ERROR"))
        }
    }

    handleSendButton = (resend, status, pojo, setterPojo, label) => {
        if (status === "email") {
            let lang = intl.get("LANG").toString() === "0" ? "zh" : "en";
            sendEmailValid(pojo, lang).then(result => {
                console.log("sendEmail：", result)
                if (result) {
                    handleCountDown(resend, this, label);
                    success(findMsgBySuccess("VER_SEND_SUCCESS"))
                } else {
                    fail(findMsgByError("VER_SEND_ERROR"))
                }
            })
        } else {
            console.log("接口：", pojo, setterPojo)
            sendPhoneValid(pojo, setterPojo.country).then(result => {
                console.log("sendPhone：", result)
                if (result) {
                    handleCountDown(resend, this, label);
                    success(findMsgBySuccess("VER_SEND_SUCCESS"))
                } else {
                    fail(findMsgByError("VER_SEND_ERROR"))
                }
            })
        }
    }

    setType = (type, label) => {
        if (type) {
            this.setState({
                type: type,
                status: label,
                submitStatus: 0,
                curList: [],
            })
        }
    }

    componentDidMount() {
        this.genCountryCode();
    }

    loadingPageContent = (type, userInfo, userPojo, setterPojo, showModal, isMobile, isUserLoad) => {
        if (!isUserLoad) {
            return <div/>
        } else {
            if (!isMobile) {
                return (
                    <Fragment>
                        {showModal &&
                            <ValidWin parent={this} display={showModal} status={type} resend={120} resendL={120}
                                      userPojo={userPojo} setterPojo={setterPojo}/>
                        }
                        <div className={"account-layout-main"}>
                            {userPojo && this.loadingMainWin(type, userInfo, userPojo, setterPojo, isMobile)}
                        </div>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        {/*<ValidWin parent={this} display={showModal} status={type} resend={10} resendL={10} userPojo={userPojo} setterPojo={setterPojo}/>*/}
                        <div style={{height: '80px'}}/>
                        <div className={"mobile-account-layout-main"}>
                            {userPojo && this.loadingMainWin(type, userInfo, userPojo, setterPojo, isMobile)}
                        </div>
                    </Fragment>
                )
            }
        }
    }

    // 用户基础信息
    setUsrInfo = (userInfo, userPojo, isUserLoad) => {
        this.setState({
            userInfo: userInfo,
            userPojo: userPojo,
            isUserLoad: isUserLoad,
        })
    }

    render() {
        let isMobile = this.state.isMobile;
        let type = this.state.type;
        let userInfo = this.state.userInfo;
        let userPojo = this.state.userPojo;
        let isUserLoad = this.state.isUserLoad;
        let setterPojo = this.setterInfo.setterPojo;
        let showModal = this.state.showModal;

        console.log("用户缓存信息：", userInfo)
        console.log("用户信息：", userPojo)
        console.log("谷歌信息：", this.state.googleSecure)
        console.log("用户更新信息：", this.setterInfo.setterPojo)
        console.log("type:", type)

        return (
            <PageLayout parent={this} pNameCN={intl.get("ACCOUNT_SETTING")} pNameEN={intl.get("ACCOUNT_SETTING_EN")}
                        needUser={true}
                        pContent={this.loadingPageContent(type, userInfo, userPojo, setterPojo, showModal, isMobile, isUserLoad)}
            />
        )
    }
}

export default Account;
