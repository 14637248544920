import React, {Component, Fragment} from "react";
import "./login.css";
import {isMobile} from "../../utils/MobileMgr";
import {userEmailChecker, userPasswordChecker, userPhoneChecker} from "../../utils/UserInputChecker";
import {
    checkSecCode,
    data,
    emailCodeType,
    error,
    findUidByNameUrl,
    genPojo,
    getCountryCodeUrl,
    modifyPwdCode,
    phoneCodeType,
    sendGetRequest,
    sendPostRequest,
    userLoginUrl,
    userSettingConfirmUrl,
    userSettingUrl
} from "../../utils/RequestUrlMgr";
import Tran from "../../config/IntTranslation";
import LOGO from "../../assets/icon/logo.png";
import {Dropdown, Input, Menu, Select, Spin} from "antd";
import {DownOutlined, EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import PageHeader from "../PageHeader";
import intl from "react-intl-universal";
import {connectionError, fail, findMsgByError, findMsgBySuccess, success} from "../../utils/ErrorMgr";
import ValidWin from "../component/ValidWin";
import {
    countryId,
    genLoginInfo,
    genRegisterInfo,
    getErrorMsg,
    langType,
    getMedia,
    handleCountDown,
    saveSession
} from "../../utils/Request";
import {sendEmailValid, sendPhoneValid} from "../../utils/Verfiy";
import {listCommonCountry, listCountry} from "../../config/countryCode";
import {changeLoginWay, checkPost, loadingRegisterProcess} from "../component/Common";
import EN from "../../locales/en-US";
import CN from "../../locales/zh-CN";
import DocumentTitle from "react-document-title";

const { Option } = Select;

const locales = {
    "en_US": EN,
    "zh_CN": CN,
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            submitStatus: 0,
            // status: 'login',
            status: 'register',
            showModal: false,
            modalContent: '',
            isLogin: false,
            registerResponse: {success: "", errorType: ""},
            loginResponse: {errorType: "", responseData: "", success: ""},
            userPojo: '',
            userInfo: {
                userName: '',
                userPwd: '',
                uId: '',
                inviterName: '',
            },
            mPwd: '',
            mPwdV: '',
            goPwd: '',
            param: '',
            type: 'home',
            secCode: '',
            second: 120,
            resend: 120,
            mediaResource: new Map(),
            isMediaLoad: false,
            inviteUId: this.props.match.params.inviteUId,
            countryStatus: false,
            countryCode: '',
            searchValue: '',
            curList: [],
            lang: this.props.match.params.lang,
            initDone: false,
        }
    }

    registerInfo = {
        registerPojo: {
            userName: '',
            country: '',
            regCode: 1,
            userPhone: '',
            userEmail: '',
            userPwd: '',
            inviteUId: '',
        }
    }

    loginInfo = {
        loginPojo: {
            userName: '',
            userPwd: '',
            step: 1,
            useUserName: false,
        }
    }

    setterInfo = {
        setterPojo: {
            userName: "",
            userPhone: "",
            userEmail: "",
            userPwd: "",
            uId: "",
            country: "",
            param: "", // 修改的参数
            type: "", // 修改的类型
        }
    }

    loadingVer = (isMobile) => {
        let pojo = this.loginInfo.loginPojo;
        let userName = pojo.userName;
        let userPwd = pojo.userPwd;
        console.log("pojo:", pojo)
        let userCheck = userName === "" || userPwd === "";
        if (!userCheck) {
            let loginPojo = genPojo(userLoginUrl, pojo);
            if (loginPojo) {
                console.log("loginPojo: ", loginPojo)
                sendPostRequest(loginPojo).then(res => {
                    this.setState({
                        loginResponse: res,
                    })
                    if (res && res.hasOwnProperty("success") && res.success) {
                        console.log("res: ", res)
                        let responseData = res[data];
                        let userInfo = this.state.userInfo;
                        userInfo["userName"] = responseData.userName;
                        userInfo["userPwd"] = pojo.userPwd;
                        saveSession("userInfo", userInfo)
                        console.log("userInfo: ", userInfo)
                        if (responseData.vGo) {
                            if (!isMobile) {
                                this.openModal("login");
                            } else {
                                this.setType("verify")
                            }
                        } else {
                            if (responseData.vPhone) {
                                if (!isMobile) {
                                    this.openModal("login");
                                } else {
                                    this.setType("verify")
                                }
                            } else {
                                if (!isMobile) {
                                    this.openModal("login");
                                } else {
                                    this.setType("verify")
                                }
                            }
                        }
                    } else {
                        if (res === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            fail(findMsgByError(res[error]))
                        }
                    }
                })
            }
        } else {
            this.setState({
                submitStatus: 1,
            })
        }
    }

    checkAllInput = (status) => {
        if (status === "login") {
            return true;
        } else {
            let countryCode = this.state.countryCode;
            let registerPojo = this.registerInfo.registerPojo;
            let regCode = registerPojo["regCode"];
            registerPojo["country"] = countryCode;
            this.setState({
                registerPojo
            })
            if (regCode === 1) {
                return !(registerPojo["userName"] === "" || registerPojo["userPwd"] === "" || registerPojo["country"] === "" || registerPojo["userPhone"] === "");
            } else {
                return !(registerPojo["userName"] === "" || registerPojo["userPwd"] === "" || registerPojo["country"] === "" || registerPojo["userEmail"] === "");
            }
        }
    }

    /* 弹窗 */
    openModal = (status, props, isMobile) => {
        let allInfoFilled = this.checkAllInput(status);
        if (allInfoFilled) {
            if (!isMobile) {
                this.setState({
                    showModal: true,
                })
            } else {
                this.setState({
                    type: props,
                })
            }
        }
        this.setState({
            submitStatus: 1,
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    // 修改密码
    confirmPostMP = (secCode, secCodeL, loginPojo, setterPojo, registerPojo, isMobile) => {
        if (!isMobile) {
            if (loginPojo.userPwd) {
                setterPojo.type = 6;
                setterPojo.userPwd = modifyPwdCode;
                setterPojo.param = loginPojo.userPwd;
                this.setState({
                        setterPojo,
                    },
                    () => {
                        let pojo = genPojo(userSettingUrl, setterPojo);
                        if (pojo) {
                            sendPostRequest(pojo).then(res => {
                                console.log("修改密码用户设置API1：", res)
                                if (res && res.hasOwnProperty("success") && res.success) {
                                    let param = this.state.param;
                                    let secCode = this.state.secCode;
                                    if (param) {
                                        let type = '';
                                        if (param.includes("@")) {
                                            type = emailCodeType;
                                        } else {
                                            type = phoneCodeType;
                                        }
                                        sendGetRequest(checkSecCode, {
                                            secCode: secCode,
                                            type: type,
                                        }).then(result => {
                                            console.log("修改密码验证：", result)
                                            if (result) {
                                                let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                                if (finalPojo) {
                                                    sendPostRequest(finalPojo).then(res => {
                                                        console.log("用户设置API2的finial:", res)
                                                        if (res && res.hasOwnProperty("success") && res.success) {
                                                            success(findMsgBySuccess("PWD_MODIFY_SUCCESS"), "/")
                                                        } else {
                                                            if (res === connectionError) {
                                                                fail(findMsgByError(connectionError));
                                                            } else {
                                                                fail(findMsgByError(res[error]))
                                                            }
                                                        }
                                                    })
                                                }
                                            } else {
                                                fail(findMsgByError("VER_CODE_ERROR"))
                                            }
                                        })
                                    }
                                }  else {
                                    if (res === connectionError) {
                                        fail(findMsgByError(connectionError));
                                    } else {
                                        fail(findMsgByError(res[error]))
                                    }
                                }
                            })
                        }
                    })
            }
        } else {
            if (secCode && secCodeL && secCode === secCodeL) {
                setterPojo.userPwd = modifyPwdCode;
                setterPojo.type = 6;
                setterPojo.param = secCode;
                this.setState({
                        setterPojo,
                    },
                    () => {
                        let pojo = genPojo(userSettingUrl, setterPojo);
                        console.log("修改密码用户设置AP1存入信息：", pojo)
                        if (pojo) {
                            sendPostRequest(pojo).then(res => {
                                console.log("修改密码用户设置API1：", res)
                                if (res && res.hasOwnProperty("success") && res.success) {
                                    let finalPojo = genPojo(userSettingConfirmUrl, setterPojo);
                                    if (finalPojo) {
                                        sendPostRequest(finalPojo).then(res => {
                                            console.log("用户设置API2的finial:", res)
                                            if (res && res.hasOwnProperty("success") && res.success) {
                                                success(findMsgBySuccess("PWD_MODIFY_SUCCESS"), "/")
                                            } else {
                                                if (res === connectionError) {
                                                    fail(findMsgByError(connectionError));
                                                } else {
                                                    fail(findMsgByError(res[error]))
                                                }
                                            }
                                        })
                                    }
                                } else {
                                    if (res === connectionError) {
                                        fail(findMsgByError(connectionError));
                                    } else {
                                        fail(findMsgByError(res[error]))
                                    }
                                }
                            })
                        }
                    })
            }
        }
        this.setState({
            submitStatus: 1,
        })
    }

    loadingLogin = (loginPojo, isMobile) => {
        let useUserName = loginPojo["useUserName"];
        if (!isMobile) {
            return (
                <div className={"login-layout-container"}>
                    <div className={"login-layout-win"}>
                        <div className={"login-layout-win-welcome"}>
                            <Tran intlKey="LOGIN_WELCOME"/>
                        </div>
                        <div className={"login-layout-win-login"}>
                            <div className={"login-layout-win-login-element"}>
                                <div className={"login-layout-win-login-text"}>
                                    {
                                        useUserName ?
                                            <Tran intlKey="USERNAME"/> :
                                            <Tran intlKey="PHONE_EMAIL"/>
                                    }
                                </div>
                                <div className={"login-layout-win-login-input-wrap"} key={useUserName ? "userNamePwd" : "otherPwd"}>
                                    <Input className={"login-layout-win-login-input"}
                                           style={{width: '100%', height: '100%'}}
                                           size="middle"
                                           placeholder={useUserName ? intl.get("ACCOUNT_PH") : intl.get("PHONE_EMAIL_PH")}
                                           maxLength={40}
                                           // prefix={<UserOutlined style={{color: "grey"}}/>}
                                           onChange={(e) => genLoginInfo(e, useUserName ? "userName" : "userNameL", this)}
                                           onKeyDown={e => this.onKeyDownChange(e)}
                                    />
                                </div>
                            </div>
                            <div className={"register-layout-win-input-notice"}>
                                {getErrorMsg(useUserName ? "userNameL" : "userNameLP", this)}
                            </div>
                            <div className={"login-layout-win-login-element"}>
                                <div className={"login-layout-win-login-text"}>
                                    <Tran intlKey="PASSWORD"/>
                                </div>
                                <div className={"login-layout-win-login-input-wrap"} key={useUserName ? "userName" : "other"}>
                                    <Input.Password className={"login-layout-win-login-input"}
                                                    style={{width: '100%', height: '100%'}}
                                                    size={"middle"}
                                                    placeholder={intl.get("PASSWORD_PH")}
                                                    maxLength={20}
                                                    // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                                    // iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                                    onChange={(e) => genLoginInfo(e, "userPwd", this)}
                                                    onKeyDown={e => this.onKeyDownChange(e)}
                                    />
                                </div>
                            </div>
                            <div className={"register-layout-win-input-notice"}>
                                {getErrorMsg("userPwdL", this)}
                            </div>
                            <div className={"login-layout-win-login-button"} onClick={() => {this.loadingVer(isMobile)}}>
                                <Tran intlKey="LOGIN"/>
                            </div>
                            <div className={"login-layout-win-login-other"}>
                                <div className={"login-layout-win-login-other-wrap"}>
                                    <div className={"login-layout-win-login-qr"} onClick={() => {this.changeStatus("register")}}>
                                        <Tran intlKey="REGISTER"/>
                                    </div>
                                    <span style={{margin: "0 6px 0 8px", color: "rgb(203,203,203)"}}>{"|"}</span>
                                    <div className={"login-layout-win-login-qr"} onClick={() => {changeLoginWay(loginPojo, this)}}>
                                        {
                                            useUserName ?
                                                <Tran intlKey="LOGIN_NO_USE_USERNAME"/> :
                                                <Tran intlKey="LOGIN_USE_USERNAME"/>
                                        }
                                    </div>
                                </div>
                                <div className={"login-layout-win-login-pf"} onClick={() => {this.changeStatus("fPwd")}}>
                                    <Tran intlKey="PASSWORD_FORGOT"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <Fragment>
                    <div className={"mobile-login-container"}>
                        <div className={"mobile-login-win"}>
                            <div className={"mobile-login-win-welcome"}>
                                <Tran intlKey="LOGIN_WELCOME"/>
                            </div>
                            <div className={"mobile-login-win-login"}>
                                <div className={"mobile-login-win-login-element"}>
                                    <div className={"mobile-input-wrap"} key={useUserName ? "userName" : "other"}>
                                        <Input
                                            className={"mobile-login-win-login-input"}
                                            size={"large"}
                                            placeholder={useUserName ? intl.get("ACCOUNT_PH") : intl.get("PHONE_EMAIL_PH")}
                                            maxLength={40}
                                            // prefix={<UserOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => genLoginInfo(e, useUserName ? "userName" : "userNameL", this)}
                                        />
                                    </div>
                                    <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', marginBottom: '20px', fontSize: '12px'}}>
                                        {getErrorMsg(useUserName ? "userNameL" : "userNameLP", this)}
                                    </div>
                                    <div className={"mobile-input-wrap"} key={useUserName ? "userNamePwd" : "otherPwd"}>
                                        <Input.Password
                                            className={"mobile-login-win-login-input"}
                                            placeholder={intl.get("PASSWORD_PH")}
                                            maxLength={20}
                                            // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                            iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                            onChange={(e) => genLoginInfo(e, "userPwd", this)}
                                        />
                                    </div>
                                    <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', marginBottom: '0', fontSize: '12px'}}>
                                        {getErrorMsg("userPwdL", this)}
                                    </div>
                                </div>
                                <div className={"mobile-login-win-login-button"} onClick={() => {this.loadingVer(isMobile)}}>
                                    <Tran intlKey="LOGIN"/>
                                </div>
                                <div className={"mobile-login-win-login-other"}>
                                    <div className={"mobile-login-win-login-other-wrap"}>
                                        <div className={"mobile-login-win-login-qr"}
                                             onClick={() => {this.changeStatus("register")}}
                                        >
                                            <Tran intlKey="REGISTER"/>
                                        </div>
                                        {/*<span style={{margin: "0 6px 0 8px", color: "rgb(203,203,203)"}}>{"|"}</span>*/}
                                        {/*<div className={"mobile-login-win-login-qr"}*/}
                                        {/*     onClick={() => {changeLoginWay(loginPojo, this)}}*/}
                                        {/*>*/}
                                        {/*    {*/}
                                        {/*        useUserName ?*/}
                                        {/*            <Tran intlKey="LOGIN_NO_USE_USERNAME"/> :*/}
                                        {/*            <Tran intlKey="LOGIN_USE_USERNAME"/>*/}
                                        {/*    }*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className={"mobile-login-win-login-pf"}
                                         onClick={() => {this.setType("fPwd")}}
                                    >
                                        <Tran intlKey="PASSWORD_FORGOT"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"mobile-login-copyright"}>
                        <div className={"mobile-login-copyright-text"}>
                            <Tran intlKey="COPYRIGHT"/>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    loadingRegister = (loginPojo, registerPojo, isMobile) => {
        if (!isMobile) {
            return (
                <div className={"register-layout-container"}>
                    <div className={"register-layout-win-nav"}>
                        <div className={registerPojo["regCode"] === 1 ? "nav-reg-selected" : "nav-reg"} onClick={() => {this.changeRegCode(1)}}>
                            <Tran intlKey="REGISTER_PHONE"/>
                        </div>
                        <div className={registerPojo["regCode"] === 2 ? "nav-reg-selected" : "nav-reg"} onClick={() => {this.changeRegCode(2)}}>
                            <Tran intlKey="REGISTER_EMAIL"/>
                        </div>
                    </div>
                    <div className={"register-layout-win-container"}>
                        {this.loadingRegisterWin(registerPojo, isMobile)}
                        <div className={"register-layout-win-button"} onClick={() => {this.openModal("register")}}>
                            <Tran intlKey="NEXT_STEP"/>
                        </div>
                        <div className={"register-layout-win-other"}>
                            <div className={"register-layout-win-other-left"}>
                                <Tran intlKey="HAS_ACCOUNT"/>
                            </div>
                            <div className={"register-layout-win-other-right"}  onClick={() => {this.changeStatus("login")}}>
                                <Tran intlKey="LOGIN_IMM"/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={"mobile-login-container"} style={{height: 'auto'}}>
                    <div className={"mobile-login-win"}>
                        <div className={"mobile-reg-win-title"}>
                            <div className={registerPojo["regCode"] === 1 ? "mobile-nav-reg-selected" : "mobile-nav-reg"}
                                 onClick={() => {this.changeRegCode(1)}}
                            >
                                <Tran intlKey="REGISTER_PHONE_M"/>
                            </div>
                            <div className={registerPojo["regCode"] === 2 ? "mobile-nav-reg-selected" : "mobile-nav-reg"}
                                 onClick={() => {this.changeRegCode(2)}}
                            >
                                <Tran intlKey="REGISTER_EMAIL_M"/>
                            </div>
                        </div>
                        <div className={"mobile-reg-win-container"}>
                            {this.loadingRegisterWin(registerPojo, isMobile)}
                            <div className={"mobile-function-button"}
                                 onClick={() => {
                                     this.openModal("register", registerPojo["regCode"] === 1 ? "regPhone" : "regEmail", isMobile)}
                                 }
                            >
                                <Tran intlKey="NEXT_STEP"/>
                            </div>
                            <div className={"mobile-register-layout-win-other"}>
                                <div className={"register-layout-win-other-left"}>
                                    <Tran intlKey="HAS_ACCOUNT"/>
                                </div>
                                <div className={"register-layout-win-other-right"}  onClick={() => {this.changeStatus("login")}}>
                                    <Tran intlKey="LOGIN_IMM"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"mobile-login-copyright"}>
                        <div className={"mobile-login-copyright-text"}>
                            <Tran intlKey="COPYRIGHT"/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    loadingRegisterWin = (registerPojo, isMobile) => {
        let inviteUId = this.state.inviteUId;
        let countryStatus = this.state.countryStatus;
        let countryCode = this.state.countryCode;
        let curList = this.state.curList;
        if (inviteUId) {
            registerPojo["inviteUId"] = inviteUId;
        }
        if (!isMobile) {
            return (
                loadingRegisterProcess(this, registerPojo, inviteUId, countryStatus, curList, isMobile)
            )
        } else {
            return (
                <Fragment>
                    <div className={"mobile-login-win-login-element"} style={{height: 'auto'}}>
                        <Input
                            className={"mobile-login-win-login-input"}
                            size={"large"}
                            placeholder={intl.get("ACCOUNT_PH")}
                            maxLength={20}
                            // prefix={<UserOutlined style={{color: "grey"}}/>}
                            onChange={(e) => genRegisterInfo(e, "userName", this)}
                        />
                        <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', marginBottom: '10px', fontSize: '12px'}}>
                            {getErrorMsg("userName", this)}
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '50px',border: '0'}}>
                            {
                                registerPojo["regCode"] === 1 ?
                                    <Fragment>
                                        <div
                                            className={"register-layout-win-country"}
                                            style={{alignItems: 'center', width: '80px', height: '20px'}}
                                            onClick={() => {this.setType("countryId")}}
                                        >
                                            {/*{"+" + registerPojo.country}*/}
                                            {"+" + countryCode}
                                            <div className={"register-layout-win-country-icon"}>
                                                <DownOutlined />
                                            </div>
                                        </div>
                                        <Input
                                            className={"mobile-login-win-login-input"}
                                            style={{height: '100%', paddingLeft: '90px'}}
                                            size="middle"
                                            placeholder={intl.get("PHONE_PH")}
                                            maxLength={20}
                                            // prefix={<MobileOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => genRegisterInfo(e, "userPhone", this)}
                                        />
                                    </Fragment> :
                                    <Fragment>
                                        <Input
                                            className={"mobile-login-win-login-input"}
                                            style={{height: '100%'}}
                                            size="middle"
                                            placeholder={intl.get("EMAIL_PH")}
                                            maxLength={50}
                                            // prefix={<MobileOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => genRegisterInfo(e, "userEmail", this)}
                                        />
                                    </Fragment>
                            }
                        </div>
                        <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', marginBottom: '10px', fontSize: '12px'}}>
                            {getErrorMsg(registerPojo["regCode"] === 1 ? "userPhone" : "userEmail", this)}
                        </div>
                        <Input.Password
                            className={"mobile-login-win-login-input"}
                            placeholder={intl.get("PASSWORD_PHL")}
                            maxLength={20}
                            // prefix={<KeyOutlined style={{color: "grey"}}/>}
                            // iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                            onChange={(e) => genRegisterInfo(e, "userPwd", this)}
                        />
                        <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', marginBottom: '10px', fontSize: '12px'}}>
                            {getErrorMsg("userPwd", this)}
                        </div>
                        <Input
                            className={"mobile-login-win-login-input"}
                            style={{color: inviteUId ? 'black' : '', fontWeight:  inviteUId ? 'lighter' : ''}}
                            size="middle"
                            placeholder={intl.get("INVITE_CODE_PH")}
                            value={inviteUId}
                            disabled={inviteUId && true}
                            maxLength={80}
                            // prefix={<UserAddOutlined style={{color: "grey"}}/>}
                            onChange={(e) => genRegisterInfo(e, "inviteUId", this)}
                        />
                    </div>
                </Fragment>
            )
        }
    }

    loadingForgetPwd = (loginPojo, setterPojo, isMobile) => {
        let time = this.state.second;
        let resend = this.state.resend;
        let canSend = time === resend;
        let secCode = this.state.secCode;

        if (!isMobile) {
            return (
                <div className={"login-layout-fPwd-container"}>
                    <div className={"login-layout-win"}>
                        <div className={"login-layout-win-welcome"} style={{height: '5%'}}>
                            <Tran intlKey="PASSWORD_FORGOT"/>
                        </div>
                        <div className={"login-layout-win-login"} style={{margin: '0 auto'}}>
                            <div className={"login-layout-win-login-element"} style={{height: '15%'}}>
                                <div className={"login-layout-win-login-text"} style={{width: '20%'}}>
                                    <Tran intlKey="USERNAME"/>
                                </div>
                                <Input className={"login-layout-win-login-input"}
                                       size="middle"
                                       style={{width: '73%'}}
                                       placeholder={intl.get("ACCOUNT_PH")}
                                       maxLength={20}
                                       // prefix={<UserOutlined style={{color: "grey"}}/>}
                                       onChange={(e) => genLoginInfo(e, "userName", this)}
                                       onKeyDown={e => this.onKeyDownChange(e)}
                                />
                            </div>
                            <div className={"register-layout-win-input-notice"}>
                                {getErrorMsg("userNameF", this)}
                            </div>
                            <div className={"login-layout-win-login-element"} style={{height: '15%'}}>
                                <div className={"login-layout-win-login-text"}>
                                    <Tran intlKey="PHONE_EMAIL"/>
                                </div>
                                <Input className={"login-layout-win-login-input"}
                                       size="middle"
                                       style={{width: '73%'}}
                                       placeholder={intl.get("PWD_FORGET_PH")}
                                       maxLength={40}
                                       // prefix={<MobileOutlined style={{color: "grey"}}/>}
                                       onChange={(e) => this.setCode(e, "param")}
                                       onKeyDown={e => this.onKeyDownChange(e)}
                                />
                            </div>
                            <div className={"register-layout-win-input-notice"}>
                                {getErrorMsg("param", this)}
                            </div>
                            <div className={"login-layout-win-login-element"} style={{height: '15%'}}>
                                <div className={"login-layout-win-login-text"} style={{width: '20%'}}>
                                    <Tran intlKey="VER_CODE"/>
                                </div>
                                <Input className={"login-layout-win-login-input"}
                                       style={{width: '73%'}}
                                       size="middle"
                                       placeholder={intl.get("VER_CODE_PH")}
                                       maxLength={10}
                                       // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                       onChange={(e) => this.setCode(e, "secCode")}
                                       onKeyDown={e => this.onKeyDownChange(e)}
                                />
                                <div className={"modal-win-send-button"}
                                        style={{color: !canSend ? "grey" : "#236BF3"}}
                                        onClick={() => {canSend && this.handleSendFPwd(resend, loginPojo, setterPojo)}}
                                >
                                    {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                </div>
                            </div>
                            <div className={"login-layout-win-login-element"} style={{marginTop: '20px', height: '15%'}}>
                                <div className={"login-layout-win-login-text"} style={{width: '20%'}}>
                                    <Tran intlKey="ACCOUNT_NEW_PWD"/>
                                </div>
                                <Input.Password
                                    className={"login-layout-win-login-input"}
                                    size={"middle"}
                                    style={{width: '73%'}}
                                    placeholder={intl.get("PASSWORD_PHL")}
                                    maxLength={20}
                                    // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                    iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                    onChange={(e) => genLoginInfo(e, "userPwd", this)}
                                    onKeyDown={e => this.onKeyDownChange(e)}
                                />
                            </div>
                            <div className={"register-layout-win-input-notice"}>
                                {getErrorMsg("userPwdL", this)}
                            </div>
                            <div className={"login-layout-win-login-button"} style={{marginTop: '10px'}}
                                 onClick={() => {this.confirmPostMP(secCode, "", loginPojo, setterPojo, "", isMobile)}}>
                                <Tran intlKey="CONFIRM_MODIFY"/>
                            </div>
                            <div className={"login-layout-win-login-other"} style={{margin: '0 auto'}}>
                                <div className={"login-layout-win-login-qr"} style={{width: "20%"}}
                                     onClick={() => {this.changeStatus("register")}}>
                                    <Tran intlKey="REGISTER"/>
                                </div>
                                <div className={"register-layout-win-other"} style={{height: 'auto', width: "80%"}}>
                                    <div style={{textAlign: 'right', color: '#666666', width: "100%"}}>
                                        <span>
                                            {intl.get("HAS_ACCOUNT")}
                                        </span>
                                        <span style={{color: '#236BF3', cursor: 'pointer'}} onClick={() => {this.changeStatus("login")}}>
                                            {intl.get("LOGIN_IMM")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    handleSendFPwd = (resend, loginPojo, setterPojo) => {
        let param = this.state.param;
        let lang = intl.get("LANG").toString() === "0" ? "zh" : "en";
        console.log(param)
        if (loginPojo.userName && param) {
            let type = '';
            if (param.includes("@")) {
                type = 'email';
            } else {
                type = 'phone';
            }
            console.log(type)
            console.log(param)
            sendGetRequest(findUidByNameUrl, {
                userName: loginPojo.userName,
                type: type,
                param: param,
            }).then(res => {
                console.log(res)
                if (res && res.hasOwnProperty("success") && res.success) {
                    setterPojo["userName"] = loginPojo.userName;
                    setterPojo["uId"] = res[data].uId;
                    this.setState({
                        setterPojo,
                    })
                    if (type === "phone") {
                        sendPhoneValid(param, res[data].country).then(result => {
                            console.log("sendPhone：", result)
                            if (result) {
                                handleCountDown(resend, this);
                                success(findMsgBySuccess("VER_SEND_SUCCESS"))
                            } else {
                                fail(findMsgByError("VER_SEND_ERROR"))
                            }
                        })
                    } else {
                        sendEmailValid(param, lang).then(result => {
                            console.log("sendEmail：", result)
                            if (result) {
                                handleCountDown(resend, this);
                                success(findMsgBySuccess("VER_SEND_SUCCESS"))
                            } else {
                                fail(findMsgByError("VER_SEND_ERROR"))
                            }
                        })
                    }
                } else {
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
        } else {
            this.setState({
                submitStatus: 1,
            })
        }
    }

    changeRegCode = (data) => {
        let registerPojo = this.registerInfo.registerPojo;
        console.log(registerPojo)
        registerPojo["regCode"] = data;
        registerPojo["userPhone"] = 0;
        registerPojo["userEmail"] = "";
        console.log("重置后：", registerPojo)
        this.setState({
            submitStatus: 0,
            registerPojo
        })
    }

    changeStatus = (data) => {
        this.setState({
            submitStatus: 0,
            status: data,
        })
    }

    /* ip interface */
    genCountryCode = () => {
        sendGetRequest(getCountryCodeUrl).then(res => {
            console.log("区号：", res)
            let code = res.replace("+", "");
            this.setState({
                countryCode: code,
            })
            // let registerPojo = this.registerInfo.registerPojo;
            // registerPojo["country"] = res.replaceAll("+", "");
            // this.setState({
            //     registerPojo
            // })
        })
    }

    loadingPageContent = (type, status, isLogin, userPojo, loginInfo, loginPojo, registerPojo, setterPojo, showModal, isMobile, mediaResource, isMediaLoad, langMenu, userInfo) => {
        if (!isMobile) {
            return (
                isMediaLoad ?
                <div className={"login-layout"}>
                    {
                        showModal &&
                        <ValidWin parent={this} display={showModal} status={status} resend={120}
                                  userInfo={userInfo} registerPojo={registerPojo} loginPojo={loginPojo} loginInfo={loginInfo} userPojo={userPojo}/>
                    }
                    <div className={"login-layout-wrapper"}>
                        <div className={"login-layout-header"}>
                            <div className={"login-layout-header-logo"}>
                                <div className={"login-layout-header-img"}>
                                    <img className={"login-img"} src={LOGO} alt={"banner"}/>
                                </div>
                                <div className={"login-layout-header-text"}>
                                    <Tran intlKey="BASE"/>
                                </div>
                            </div>
                            <div className={"login-page-language"}>
                                <Dropdown className={"login-page-language-box"} overlay={langMenu} placement="topCenter" trigger={['click']}>
                                    <div>
                                        {
                                            intl.get("LANG").toString() === "0" ?
                                                <Tran intlKey="CHINESE_SIMPLE"/> :  <Tran intlKey="ENGLISH"/>
                                        }
                                        <DownOutlined style={{marginLeft: '10px'}}/>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                        <div className={"login-layout-center"}>
                            <div className={"login-layout-slogan"}>
                                <div className={"login-layout-slogan-title"}>
                                    {mediaResource.get("SLOGAN")["itemContent"]}
                                </div>
                                <div className={"login-layout-slogan-des"}>
                                    {mediaResource.get("SLOGAN-CONTENT-ONE")["itemContent"]}
                                </div>
                                <div className={"login-layout-slogan-des"}>
                                    {mediaResource.get("SLOGAN-CONTENT-TWO")["itemContent"]}
                                </div>
                            </div>
                            {
                                status === "login" ? this.loadingLogin(loginPojo, isMobile) :
                                status === "register" ? this.loadingRegister(loginPojo, registerPojo, isMobile) :
                                    this.loadingForgetPwd(loginPojo, setterPojo, isMobile)
                            }
                        </div>
                        <div className={"login-layout-copyright"}>
                            <Tran intlKey="COPYRIGHT"/>
                        </div>
                    </div>
                    <DocumentTitle title={intl.get("DT")} key="title"/>
                </div> :
                <div className={"modal-bg-win"} style={{borderRadius: 0, background: 'rgba(0, 0, 0, 0.1)'}}>
                    <div style={{textAlign: "center", width: "100%", marginTop: '35vh'}}>
                        <Spin tip={intl.get("LOGIN_LOADING")} size={"large"}/>
                        <DocumentTitle title={intl.get("DT")} key="title"/>
                    </div>
                </div>
            )
        } else {
            let time = this.state.second;
            let resend = this.state.resend;
            let canSend = time === resend;

            let mPwd = this.state.mPwd;
            let mPwdV = this.state.mPwdV;

            if (type === "home") {
                return (
                    <div className={"mobile-login-layout"}>
                        <PageHeader isMobile={isMobile} isLogin={isLogin} userPojo={userPojo} isPro={false}/>
                        <div style={{height: '120px'}}/>
                        {status === "login" ? this.loadingLogin(loginPojo, isMobile) : this.loadingRegister(loginPojo, registerPojo, isMobile)}
                    </div>
                )
            }
            if (type === "verify") {
                return (
                    <div className={"mobile-login-layout"}>
                        <PageHeader isMobile={isMobile} isLogin={isLogin} userPojo={loginInfo}/>
                        <div style={{height: '120px'}}/>
                        <div className={"mobile-login-ver"}>
                            <div className={"mobile-login-ver-title"}>
                                {
                                    loginInfo.vGo ? <Tran intlKey="GO_VER"/> : (loginInfo.vPhone ? <Tran intlKey="ACCOUNT_VP"/> : <Tran intlKey="ACCOUNT_VE"/>)
                                }
                            </div>
                            {
                                loginInfo.vGo ?
                                    <Input
                                        className={"mobile-login-win-login-input"}
                                        style={{marginTop: '40px'}}
                                        size="middle"
                                        placeholder={intl.get("VGO_VER_PH")}
                                        maxLength={10}
                                        // prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                        onChange={(e) => {this.setCode(e, "secCode")}}
                                    /> :
                                    <Fragment>
                                        <div className={"mobile-login-ver-text"}>
                                            <Tran intlKey={"REGISTER_VER_SEND"}/>
                                            {" "}
                                            {loginInfo.vPhone ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                            {"："}
                                        </div>
                                        <div className={"mobile-login-ver-text"} style={{color: '#337AF0'}}>
                                            {loginInfo.vPhone ? loginInfo.userPhone : loginInfo.userEmail}
                                        </div>
                                        <Input
                                            className={"mobile-login-win-login-input"}
                                            size="middle"
                                            placeholder={loginInfo.vPhone ? intl.get("VPHONE_VER_PH") : intl.get("VEMAIL_VER_PH")}
                                            maxLength={loginInfo.vPhone ? 20 : 50}
                                            // prefix={loginInfo.vEmail ? <MailOutlined style={{color: "grey"}}/> : <MobileOutlined style={{color: "grey"}}/>}
                                            onChange={(e) => {this.setCode(e, "secCode")}}
                                        />
                                        <div className={"mobile-send-ver-button"}
                                             style={{color: !canSend ? "grey" : "#5a91d9"}}
                                             onClick={() => {canSend &&
                                             this.handleSendButton(resend, loginInfo.vPhone ? "phone" : "email",loginInfo.vPhone ? loginInfo.userPhone : loginInfo.userEmail, loginInfo)}}
                                        >
                                            {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_OBTAIN")}
                                        </div>
                                    </Fragment>
                            }
                            <div className={"mobile-function-button"}
                                 onClick={() => (checkPost(this, this.state.secCode, "login", status, loginInfo, loginPojo, userInfo))}
                            >
                                <Tran intlKey="LOGIN"/>
                            </div>
                        </div>
                    </div>
                )
            }
            if (type === "countryId") {
                let curList = this.state.curList;
                return (
                    <div className={"mobile-login-layout"}>
                        <PageHeader isMobile={isMobile} isLogin={isLogin} userPojo={loginInfo}/>
                        <div style={{height: '100px'}}/>
                        <div className={"mobile-login-ver"} style={{height: 'auto'}}>
                            <div className={"mobile-login-ver-title"}>
                                <Tran intlKey="COUNTRY_ID_CHOOSE"/>
                            </div>
                            <Input
                                className={"mobile-login-win-login-input"}
                                style={{margin: '20px 0'}}
                                size="middle"
                                placeholder={intl.get("COUNTRY_ID_CHOOSE_PH")}
                                maxLength={20}
                                onChange={(e) => {this.genSearch(e, listCountry, intl)}}
                                // prefix={<SearchOutlined style={{color: "grey"}}/>}
                            />
                            {countryId(listCommonCountry, listCountry, curList, this, isMobile)}
                        </div>
                    </div>
                )
            }
            if (type === "regPhone" || type === "regEmail") {
                return (
                    <div className={"mobile-login-layout"}>
                        <PageHeader isMobile={isMobile} isLogin={isLogin} userPojo={loginInfo}/>
                        <div style={{height: '120px'}}/>
                        <div className={"mobile-login-ver"}>
                            <div className={"mobile-login-ver-title"}>
                                {
                                    type === "regPhone" ? <Tran intlKey="ACCOUNT_VP"/> : <Tran intlKey="ACCOUNT_VE"/>
                                }
                            </div>
                            <div className={"mobile-login-ver-text"}>
                                <Tran intlKey={"REGISTER_VER_SEND"}/>
                                {" "}
                                {type === "regPhone" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                {"："}
                            </div>
                            <div className={"mobile-login-ver-text"} style={{color: '#337AF0'}}>
                                {type === "regPhone" ?
                                    registerPojo.userPhone
                                    :
                                    registerPojo.userEmail
                                }
                            </div>
                            <Input
                                className={"mobile-login-win-login-input"}
                                size="middle"
                                placeholder={type === "regPhone" ? intl.get("VPHONE_VER_PH") : intl.get("VEMAIL_VER_PH")}
                                maxLength={10}
                                // prefix={type === "regPhone" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                onChange={(e) => {this.setCode(e, "secCode")}}
                            />
                            <div className={"mobile-send-ver-button"}
                                 style={{color: !canSend ? "grey" : "#5a91d9"}}
                                 onClick={() => {canSend && this.handleSendButton(resend, type === "regPhone" ? "phone" : "email",  type === "regPhone" ? registerPojo.userPhone : registerPojo.userEmail, registerPojo)}}
                            >
                                {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_OBTAIN")}
                            </div>
                            <div className={"mobile-login-win-login-button"}
                                 onClick={() => (checkPost(this, this.state.secCode, "login", status, registerPojo, loginPojo, userInfo))}
                            >
                                <Tran intlKey="REGISTER"/>
                            </div>
                        </div>
                    </div>
                )
            }
            if (type === "fPwd") {
                return (
                    <div className={"mobile-login-layout"}>
                        <PageHeader isMobile={isMobile} isLogin={isLogin} userPojo={loginInfo}/>
                        <div style={{height: '120px'}}/>
                        <div className={"mobile-login-ver"}>
                            <div className={"mobile-login-ver-title"}>
                                <Tran intlKey="PASSWORD_FORGOT"/>
                            </div>
                            <div className={"mobile-login-ver-text"}>
                                <Tran intlKey="PASSWORD_FORGOT_TIP"/>
                            </div>
                            <Input
                                className={"mobile-login-win-login-input"}
                                key={"account"}
                                style={{marginTop: '20px'}}
                                placeholder={intl.get("ACCOUNT_PH")}
                                maxLength={20}
                                onChange={(e) => genLoginInfo(e, "userName", this)}
                            />
                            <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', margin: '10px 0', fontSize: '12px', color: '#777777'}}>
                                {"* "}<Tran intlKey={"PASSWORD_FORGET_NOTICE"}/>
                            </div>
                            <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', marginBottom: '0px', fontSize: '12px'}}>
                                {getErrorMsg("userNameF", this)}
                            </div>
                            <div className={"mobile-function-button"}
                                 onClick={() => (this.getSecCode(loginPojo, "fPwdV"))}
                            >
                                <Tran intlKey="VER_CODE_GET"/>
                            </div>
                        </div>
                    </div>
                )
            }
            if (type === "fPwdV") {
                return (
                    <div className={"mobile-login-layout"}>
                        <PageHeader isMobile={isMobile} isLogin={isLogin} userPojo={loginInfo}/>
                        <div style={{height: '120px'}}/>
                        <div className={"mobile-login-ver"}>
                            <div className={"mobile-login-ver-title"}>
                                <Tran intlKey="ACCOUNT_VERIFY"/>
                            </div>
                            <div className={"mobile-login-ver-text"} style={{marginTop: '10px'}}>
                                <Tran intlKey={"PHONE"}/>{"/"}<Tran intlKey={"EMAIL"}/>
                            </div>
                            <Input
                                className={"mobile-login-win-login-input"}
                                key={"mobile-email"}
                                style={{margin: '10px 0 0'}}
                                size="middle"
                                placeholder={intl.get("PWD_FORGET_PH")}
                                maxLength={40}
                                // prefix={<MobileOutlined style={{color: "grey"}}/>}
                                onChange={(e) => {this.setCode(e, "param")}}
                            />
                            <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', marginBottom: '5px', fontSize: '12px'}}>
                                {getErrorMsg("param", this)}
                            </div>
                            <div className={"mobile-login-ver-text"} style={{marginTop: '10px'}}>
                                <Tran intlKey={"VER_CODE"}/>
                            </div>
                            <Input
                                className={"mobile-login-win-login-input"}
                                size="middle"
                                placeholder={intl.get("VER_CODE_PH")}
                                maxLength={10}
                                // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                onChange={(e) => {this.setCode(e, "secCode")}}
                            />
                            <div className={"mobile-send-ver-button"}
                                 style={{color: !canSend ? "grey" : "#5a91d9"}}
                                 onClick={() => {canSend && this.handleSendFPwd(resend, loginPojo, setterPojo)}}
                            >
                                {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_OBTAIN")}
                            </div>
                            <div className={"mobile-function-button"}
                                 onClick={() => (this.checkSecCode("mPwd"))}
                            >
                                <Tran intlKey="ACCOUNT_PWD_MODIFY"/>
                            </div>
                        </div>
                    </div>
                )
            }
            if (type === "mPwd") {
                return (
                    <div className={"mobile-login-layout"}>
                        <PageHeader isMobile={isMobile} isLogin={isLogin} userPojo={loginInfo}/>
                        <div style={{height: '120px'}}/>
                        <div className={"mobile-login-ver"}>
                            <div className={"mobile-login-ver-title"}>
                                <Tran intlKey="ACCOUNT_PWD_MODIFY"/>
                            </div>
                            <div className={"mobile-login-ver-text"} style={{marginTop: '10px'}}>
                                <Tran intlKey={"ACCOUNT_PWD_MODIFY"}/>
                            </div>
                            <Input.Password
                                className={"mobile-login-win-login-input"}
                                placeholder={intl.get("ACCOUNT_PWD_MODIFY_PH")}
                                maxLength={20}
                                iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                onChange={(e) => this.setCode(e, "mPwd")}
                            />
                            <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', marginBottom: '10px', fontSize: '12px'}}>
                                {getErrorMsg("mPwd", this)}
                            </div>
                            <div className={"mobile-login-ver-text"} style={{marginTop: '10px'}}>
                                <Tran intlKey={"ACCOUNT_PWD_MODIFY_CONFIRM"}/>
                            </div>
                            <Input.Password
                                className={"mobile-login-win-login-input"}
                                placeholder={intl.get("ACCOUNT_PWD_MODIFY_CONFIRM_PH")}
                                maxLength={20}
                                iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                onChange={(e) => this.setCode(e, "mPwdV")}
                            />
                            <div className={"account-layout-modify-ele-input-notice"} style={{width: '100%', marginBottom: '10px', fontSize: '12px'}}>
                                {getErrorMsg("mPwdV", this)}
                            </div>
                            <div className={"mobile-login-win-login-button"}
                                 style={{margin: '20px auto 10px'}}
                                 onClick={() => (this.confirmPostMP(mPwd, mPwdV, loginPojo, setterPojo, registerPojo, isMobile))}
                            >
                                <Tran intlKey="CONFIRM"/>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    getSecCode = (loginPojo, props) => {
        let userCheck = loginPojo.userName === "";
        if (!userCheck) {
            this.setType(props)
        } else {
            this.setState({
                submitStatus: 1,
            })
        }
    }

    checkSecCode = (props) => {
        let param = this.state.param;
        let secCode = this.state.secCode;
        if (param) {
            let type = '';
            if (param.includes("@")) {
                type = emailCodeType;
            } else {
                type = phoneCodeType;
            }
            sendGetRequest(checkSecCode, {
                secCode: secCode,
                type: type,
            }).then(result => {
                console.log("修改密码验证：", result)
                if (result) {
                    this.setType(props)
                } else {
                    fail(findMsgByError("VER_CODE_ERROR"))
                }
            })
        }
    }

    setCode = (e, props) => {
        let inputValue = e.target.value;
        console.log("input：", inputValue)
        console.log(inputValue.includes("@"))
        console.log(inputValue.indexOf("@"))
        switch (props) {
            case "secCode": {
                if (inputValue) {
                    this.setState({
                        secCode: inputValue,
                    })
                } else {
                    this.setState({
                        secCode: "",
                    })
                }
                return;
            }
            case "param": {
                if (inputValue.length > 0) {
                    if (inputValue.includes("@")) {
                        console.log("email test")
                        if (userEmailChecker.test(inputValue)) {
                            console.log("email pass")
                            this.setState({
                                param: inputValue,
                            })
                        } else {
                            this.setState({
                                param: "",
                            })
                        }
                    } else {
                        if (userPhoneChecker.test(inputValue)) {
                            this.setState({
                                param: inputValue,
                            })
                        } else {
                            this.setState({
                                param: "",
                            })
                        }
                    }
                } else {
                    this.setState({
                        param: "",
                    })
                }
                return;
            }
            case "goPwd": {
                if (inputValue) {
                    this.setState({
                        goPwd: inputValue,
                    })
                } else {
                    this.setState({
                        goPwd: "",
                    })
                }
                return;
            }
            case "mPwd": {
                if (inputValue && userPasswordChecker.test(inputValue)) {
                    this.setState({
                        mPwd: inputValue,
                    })
                } else {
                    this.setState({
                        mPwd: "",
                    })
                }
                return;
            }
            case "mPwdV": {
                if (inputValue && userPasswordChecker.test(inputValue)) {
                    this.setState({
                        mPwdV: inputValue,
                    })
                } else {
                    this.setState({
                        mPwdV: "",
                    })
                }
                return;
            }
            default: {
                return;
            }
        }
    }

    handleSendButton = (resend, status, pojo, setterPojo, label) => {
        let lang = intl.get("LANG").toString() === "0" ? "zh" : "en";
        if (status === "email") {
            sendEmailValid(pojo, lang).then(result => {
                console.log("sendEmail：", result)
                if (result) {
                    handleCountDown(resend, this, label);
                    success(findMsgBySuccess("VER_SEND_SUCCESS"))
                } else {
                    fail(findMsgByError("VER_SEND_ERROR"))
                }
            })
        } else {
            console.log("接口：", pojo, setterPojo)
            sendPhoneValid(pojo, setterPojo.country).then(result => {
                console.log("sendPhone：", result)
                if (result) {
                    handleCountDown(resend, this, label);
                    success(findMsgBySuccess("VER_SEND_SUCCESS"))
                } else {
                    fail(findMsgByError("VER_SEND_ERROR"))
                }
            })
        }
    }

    setType = (type) => {
        this.setState({
            type: type,
        })
    }

    onKeyDownChange(e) {
        if (e.keyCode === 13) {
            // this.doSearch();
        }
    }

    genSearch = (e, list) => {
        let inputValue = e.target.value;
        console.log("ip search:", inputValue)
        let searchList = [];
        if (inputValue) {
            list.forEach((item) => {
                for (let i in item) {
                    if (item.hasOwnProperty(i) && item[i]) {
                        let hsVal = item[i] + "";
                        let value = inputValue.replace("+", "")
                        if (hsVal.includes(value)) {
                            console.log("hsVal:", hsVal)
                            searchList.push(item);
                        }
                    }
                }
            })
            // if (searchList.length === 0) {
            //     searchList.push(
            //         {key: intl.get("COUNTRY_ID_NO_DATA"), value: ""},
            //     );
            // }
            console.log("ip list:", searchList)
        }
        this.setState({
            curList: searchList,
        })
    }

    pageStatus = () => {
        let url = window.location.href;
        if (url.indexOf('login') > 1) {
            this.setState({
                status: 'login'
            })
        }
    }

    componentDidMount() {
        this.pageStatus();
        this.genCountryCode();
        getMedia(intl, "LOGIN", this);
    }

    render() {
        let isMobile = this.state.isMobile;

        let type = this.state.type;
        let status = this.state.status;

        let userInfo = this.state.userInfo;
        let isLogin = this.state.isLogin;
        let userPojo = this.state.userPojo;

        let registerPojo = this.registerInfo.registerPojo;
        let loginPojo = this.loginInfo.loginPojo;
        let loginInfo = this.state.loginResponse.responseData;

        let setterPojo = this.setterInfo.setterPojo;

        let mediaResource = this.state.mediaResource;
        let isMediaLoad = this.state.isMediaLoad;

        let showModal = this.state.showModal;

        const langMenu = (
            <Menu style={{paddingTop: "0px", paddingBottom: "0px", backgroundColor: "rgba(30, 67, 130, 0)"}}>
                <Menu.Item className={"login-page-language-item"} key={"zh"} onClick={() => langType("cn")}>
                    {intl.get("CHINESE")}
                </Menu.Item>
                <Menu.Item className={"login-page-language-item"} key={"en"} onClick={() => langType("en")}>
                    {intl.get("ENGLISH")}
                </Menu.Item>
            </Menu>
        );

        return (
            <Fragment>
                <DocumentTitle title={intl.get("DT")} key="title"/>
                {this.loadingPageContent(type, status, isLogin, userPojo, loginInfo, loginPojo, registerPojo, setterPojo, showModal, isMobile, mediaResource, isMediaLoad, langMenu, userInfo)}
            </Fragment>
        )
    }
}

export default Login;
