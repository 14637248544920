const isMobile = () => {
    let ua = navigator.userAgent;
    let ipad = (ua.match(/(iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)),
        isIphone = ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isTablet = ua.match(/(Tablet)\s+/),
        isMobile = ipad || isIphone || isAndroid || isTablet;

    let width = document.documentElement.clientWidth;
    let height = document.documentElement.clientHeight;

    let isTabWin = width < 1100 || height > width * 4;

    return !!isMobile || isTabWin;
}

export {isMobile}
