import React, {Component, Fragment} from "react";
import "../index.css";
import {Header} from "antd/es/layout/layout";
import MenuSelector from "./menu/MenuSelector";
import {Dropdown, Menu} from "antd";
import Tran from "../config/IntTranslation";
import intl from "react-intl-universal";
import LOGO from "../assets/icon/logo.png";
import {DownOutlined, MenuOutlined} from "@ant-design/icons";
import {langType} from "../utils/Request";

class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // isOpen: true,
        }
    }

    // setOpen = () => {
    //     let hasOverview = this.props.hasOverview;
    //     this.props.dom.setOpen(hasOverview);
    // }

    setOpen = () => {
        let status = this.state.isOpen;
        this.setState({
            isOpen: !status,
        })
    }

    // componentDidMount() {
    //     if (!this.props.displayStatus) {
    //         console.log("set closingggggggggg")
    //
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isMobile) {
            let isOpen = this.state.isOpen;
            if (isOpen !== prevState.isOpen) {
                this.props.dom.setDisplay(!isOpen)
            }
        }
    }

    handleCover = () => {
        if (this.props.displayStatus === false) {
            return <div className={"mobile-nav-layout-menu"}
                        style={{position: "absolute", right: "4%", width: "40px", background: "transparent"}}
                        onClick={() => this.props.dom.setClose()}
            />
        }
    }

    render() {
        let isMobile = this.props.isMobile;
        let isLogin = this.props.isLogin;
        // let isOpen = this.props.dom.props.dom.state.isOpen;
        // let isOpenLLL = this.state.isOpen;
        let pageName = this.props.pageName;
        let displayStatus = this.props.displayStatus;
        let isPro = this.props.isPro;
        let inviteUId = this.props.inviteUId;
        let lang = intl.get("LANG").toString() === "0";
        let loginUrl = lang ? "/cn/login" : "/en/login";
        let regUrl = lang ? "/cn/register" : "/en/register";

        console.log("isMobilePH:", isMobile)
        // console.log("isOpen:", isOpen)
        // console.log("isOpenLLL:", isOpenLLL)

        const langMenuM = (
            <Menu style={{paddingTop: "0px", paddingBottom: "0px", backgroundColor: "rgba(30, 67, 130, 0)"}}>
                <Menu.Item className={"mobile-nav-language-item"} key={"zh"} onClick={() => langType("cn")}>
                    {intl.get("CHINESE_SIMPLE")}
                </Menu.Item>
                <Menu.Item className={"mobile-nav-language-item"} key={"en"} onClick={() => langType("en")}>
                    {intl.get("ENGLISH")}
                </Menu.Item>
            </Menu>
        )

        if (!isMobile) {
            return (
                <Header className={"nav-layout"}>
                    <div className={"nav-logo"}>
                        <div className={"nav-logo-wrap"}>
                            <img className={"nav-logo-img"} src={LOGO} alt={"logo"}/>
                        </div>
                        <div className={"nav-logo-text"}>
                            <Tran intlKey={"BASE"}/>
                        </div>
                    </div>
                    <MenuSelector/>
                </Header>
            )
        } else {
            return (
                <div className={"mobile-nav-wrap"}>
                    <div className={"mobile-nav-layout"}>
                        <div className={"mobile-nav-layout-left"}
                             style={{width: isPro && '45%'}}
                        >
                            <div className={"mobile-nav-layout-logo"}>
                                <img className={"mobile-img"} src={LOGO} alt={"logo"}/>
                            </div>
                            <div className={"mobile-nav-layout-name"}
                                 style={{width: isPro ? '40%' : null, fontSize: !lang ? '14px' : null}}
                            >
                                <Tran intlKey="CZH"/>
                                {pageName && "-"}
                                {pageName}
                            </div>
                        </div>
                        <div className={"mobile-nav-layout-right"}
                             style={{width: isPro && '55%'}}
                        >
                            <Dropdown trigger={['click']} overlay={langMenuM} key={"lang"}>
                                <div className={"mobile-nav-layout-lang"}>
                                    {
                                        intl.get("LANG").toString() === "0" ?
                                            <Tran intlKey="CHINESE_SIMPLE"/> : <Tran intlKey="ENGLISH"/>
                                    }
                                    <DownOutlined/>
                                </div>
                            </Dropdown>
                            {this.handleCover()}
                            {
                                isPro ?
                                    <Fragment>
                                        <div className={"mobile-nav-layout-login"}
                                             onClick={() => {window.location.href = !inviteUId ? loginUrl : loginUrl + "/" + inviteUId}}
                                        >
                                            <Tran intlKey="LOGIN"/>
                                        </div>
                                        <div className={"mobile-nav-layout-login"}
                                             style={{width: !lang ? '65px' : null, background: '#3a3a3a'}}
                                             onClick={() => {window.location.href = !inviteUId ? regUrl : regUrl + "/" + inviteUId}}
                                        >
                                            <Tran intlKey="REGISTER"/>
                                        </div>
                                    </Fragment>
                                    :
                                    <Dropdown className={"mobile-nav-layout-menu"} trigger={['click']} key={"menu"}
                                              destroyPopupOnHide={true}
                                              overlay={
                                                  <MenuSelector isMobile={isMobile} isLogin={isLogin} dom={this} displayStatus={displayStatus} inviteUId={this.props.inviteUId}/>
                                              }
                                    >
                                        <MenuOutlined/>
                                    </Dropdown>
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default PageHeader;
