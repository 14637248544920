import React, {Component, Fragment} from "react";
import "antd/dist/antd.css";
import menus from "../../config/menuConfig";
import "../../index.css"
import {RightOutlined, UserOutlined, UserSwitchOutlined} from "@ant-design/icons";
import Tran from "../../config/IntTranslation";
import AVATAR from "../../assets/icon/icon_avatar.svg";
import AVATAR_UN from "../../assets/icon/icon_avatar_un.png";
import intl from "react-intl-universal";

class MenuSelector extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    renderMenuItem(menus, isLogin, isMobile, userPojo) {
        let lang = intl.get("LANG").toString() === "0";
        if (!isMobile) {
            return (
                <div className={"nav-menu"}>
                    {menus.map(item => {
                        return (
                            <div className={"nav-menu-item"} key={item.path} onClick={() => {
                                window.location.href = item.path
                            }}>
                                <div className={"nav-menu-item-icon"}>
                                    {item.icon}
                                </div>
                                <div className={"nav-menu-item-title"}>
                                    {item.title}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        } else {
            let inviteUId = this.props.inviteUId;
            return (
                <div className={"mobile-nav-menu"}>
                    <div className={"mobile-nav-menu-container"}>
                        <div className={"mobile-nav-menu-card"}>
                            <div className={"mobile-nav-menu-card-title"}>
                                <div className={"mobile-nav-menu-card-title-base"}
                                     style={{width: !lang ? '15%' : null}}
                                >
                                    <Tran intlKey="CZH_TTL"/>
                                </div>
                                <div className={"mobile-nav-menu-card-title-slogan"}
                                     style={{width: !lang ? '85%' : null}}
                                >
                                    <Tran intlKey="MOBILE_NAV_SLOGAN"/>
                                </div>
                            </div>
                            {
                                isLogin ?
                                    <div className={"mobile-nav-menu-card-login"}>
                                        <div className={"mobile-nav-menu-card-login-avatar"}>
                                            <img className={"img-size"} style={{objectFit: 'contain'}} src={AVATAR} alt={"avatar"}/>
                                        </div>
                                        <div className={"mobile-nav-menu-card-login-info"}>
                                            <div className={"mobile-nav-menu-card-login-username"}>
                                                {userPojo && userPojo.userName}
                                            </div>
                                            <div className={"mobile-nav-menu-card-login-id"}>
                                                {userPojo && "UID：" + userPojo.uId}
                                            </div>
                                        </div>
                                    </div> :
                                    <div className={"mobile-nav-menu-card-login"}>
                                        <div className={"mobile-nav-menu-card-login-avatar"}>
                                            <img className={"img-size"} style={{objectFit: 'contain'}} src={AVATAR_UN} alt={"avatar"}/>
                                        </div>
                                        <div className={"mobile-nav-menu-card-login-info"}
                                             onClick={() => {window.location.href = !inviteUId ? "/login" : "/login/" + inviteUId}}
                                        >
                                            <div className={"mobile-nav-menu-card-login-username"}>
                                                <Tran intlKey="LOGIN_REGISTER"/>
                                            </div>
                                            {/*<div className={"mobile-nav-menu-card-login-id"}>*/}
                                            {/*    <Tran intlKey="MOBILE_NAV_LOGIN"/>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                            }
                        </div>
                        {menus.map(item => {
                            return (
                                <div className={"mobile-nav-menu-item"} key={item.path} onClick={() => {
                                    window.location.href = item.path
                                }}>
                                    <div className={"mobile-nav-menu-item-icon"}>
                                        {item.iconM}
                                    </div>
                                    <div className={"mobile-nav-menu-item-title"}>
                                        {item.title}
                                    </div>
                                    <div className={"mobile-nav-menu-item-arrow"}>
                                        <RightOutlined/>
                                    </div>
                                </div>
                            )
                        })}
                        {
                            isLogin &&
                            <div className={"mobile-nav-menu-logout"}
                                 onClick={() => {
                                     sessionStorage.removeItem("userInfo");
                                     window.location.href = '/login';
                                 }}
                            >
                                <Tran intlKey="ACCOUNT_LOGOUT"/>
                            </div>
                        }
                    </div>
                </div>
            )
        }
    }

    componentDidMount() {
        if (this.props.isMobile) {
            if (this.props.displayStatus) {
                console.log(1111)
                this.props.dom.setOpen();
            }
        }
    }

    componentWillUnmount() {
        if (this.props.isMobile) {
            if (this.props.displayStatus) {
                console.log(2222)
                this.props.dom.setOpen();
            }
        }
    }

    render() {
        let isMobile = this.props.isMobile;
        let isLogin = this.props.isLogin;
        let userPojo = JSON.parse(sessionStorage.getItem("userInfo"));

        console.log("isM:", isMobile)

        return (
            <Fragment>
                {this.renderMenuItem(menus, isLogin, isMobile, userPojo)}
            </Fragment>
        )
    }
}

export default MenuSelector;
