import React, {Component, Fragment} from "react";
import "./index.css";
import Tran from "../../config/IntTranslation";
import {Space, Table} from "antd";
import {formatCurrency} from "../../utils/Math";
import Record_Empty from "../../assets/img/record_empty.svg";
import RS from "../../assets/icon/icon_success.svg";
import RF from "../../assets/icon/icon_fail.svg";
import RC from "../../assets/icon/icon_check.svg";
import REFRESH from "../../assets/icon/icon_refresh.svg";
import {timeFormat, toMaskName} from "../../utils/Request";
import intl from "react-intl-universal";

const { Column } = Table;

class OrderRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    loadingRecord = (orderData, inviterData, isMobile, type, currentPage, mobileReason) => {
        if (type === "my_record" || type === "private") {
            return (
                <Fragment>
                    {this.loadingMyRecord(orderData, currentPage, mobileReason, isMobile)}
                </Fragment>
            )
        } else if (type === "inviter_record" || type === "rewards") {
            return (
                <Fragment>
                    {this.loadingInviterRecord(inviterData, currentPage, isMobile)}
                </Fragment>
            )
        }
    }

    loadingMyRecord = (data, currentPage, mobileReason, isMobile) => {
        let lang = intl.get("LANG").toString() === "0";
        if (!isMobile) {
            if (data.length >= 1) {
                return (
                    <div className={"assets-layout-record-table-container"}>
                            <Table
                                key={"myTable"}
                                rowKey={record => record.orderNo}
                                dataSource = {data}
                                pagination = {{
                                    position: ['bottomRight'],
                                    current: currentPage,
                                    pageSize: 10,
                                    onChange: (page, pageSize) => {
                                        this.toCpParent(page)
                                        // this.setState({
                                        //     currentPage: page,
                                        // })
                                    }
                                }}
                                onRow = {record => {
                                    if (record.rejectReason) {
                                        return {
                                            onClick: event => {this.toModalParent(intl.get(record.rejectReason))}, // 点击行
                                        };
                                    }
                                }}
                            >
                                <Column
                                    title = {intl.get("ORDER_NUM")}
                                    dataIndex = "index"
                                    key = "index"
                                    align = "center"
                                    onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                        if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                            return {
                                                style:{
                                                    backgroundColor: '#F5F6FA', //此处需注意
                                                },
                                            };
                                        }
                                    })}
                                    render = {
                                        (text,record,index) => {
                                            return (
                                                `${(currentPage - 1) * 10 + (index + 1)}`
                                                //当前页数减1乘以每一页页数再加当前页序号+1
                                            )
                                        }
                                    }
                                />
                                <Column
                                    title = { () => {
                                        return (
                                            <Fragment>
                                                <div>
                                                    {intl.get("ORDER_PTIME")}
                                                </div>
                                                <div style={{transform: 'scale(0.8, 0.8)'}}>
                                                    {intl.get("UTC")}
                                                </div>
                                            </Fragment>
                                        )
                                    }}
                                    // title = {intl.get("ORDER_PTIME")}
                                    dataIndex = "pTime"
                                    key = "pTime"
                                    align = "center"
                                    onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                        if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                            return {
                                                style:{
                                                    backgroundColor: '#F5F6FA', //此处需注意
                                                },
                                            };
                                        }
                                    })}
                                />
                                <Column
                                    title = {intl.get("ORDER_NO")}
                                    dataIndex = "orderNo"
                                    key = "orderNo"
                                    align = "center"
                                    onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                        if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                            return {
                                                style:{
                                                    backgroundColor: '#F5F6FA', //此处需注意
                                                },
                                            };
                                        }
                                    })}
                                />
                                <Column
                                    title = {intl.get("ORDER_PAMOUNT")}
                                    dataIndex = "pAmount"
                                    key = "pAmount"
                                    align = "center"
                                    onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                        if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                            return {
                                                style:{
                                                    backgroundColor: '#F5F6FA', //此处需注意
                                                },
                                            };
                                        }
                                    })}
                                    render = {(record) => (
                                        <Fragment>{formatCurrency(record)}</Fragment>
                                    )}
                                />
                                <Column
                                    title = {intl.get("ORDER_USDTP")}
                                    dataIndex = "usdtPrice"
                                    key = "usdtPrice"
                                    align = "center"
                                    onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                        if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                            return {
                                                style:{
                                                    backgroundColor: '#F5F6FA', //此处需注意
                                                },
                                            };
                                        }
                                    })}
                                    render = {(record) => (
                                        <Fragment>{formatCurrency(record)}</Fragment>
                                    )}
                                />
                                <Column
                                    title = {intl.get("ORDER_STATUS")}
                                    dataIndex = "orderStatus"
                                    key = "orderStatus"
                                    align = "center"
                                    onCell = {
                                        ((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                            if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                                return {
                                                    style:{
                                                        backgroundColor: '#F5F6FA', //此处需注意
                                                    },
                                                };
                                            }
                                        })
                                    }
                                    render = {(record) => (
                                        this.loadingOrderStatus(record)
                                    )}
                                />
                            </Table>
                        </div>
                )
            } else {
                return (
                    <Fragment>
                        <div className={"assets-layout-record-empty"}>
                            <img src={Record_Empty} alt={"empty"}/>
                        </div>
                        <div className={"assets-layout-record-empty-text"}>
                            <Tran intlKey={"PRIVATE_RECORD_EMPTY"}/>
                        </div>
                    </Fragment>
                )
            }
        } else {
            if (data.length >= 1) {
                return (
                    <Fragment>
                        {
                            !lang &&
                            <div className={"mobile-utc"}>
                                <Tran intlKey={"UTC"}/>
                            </div>
                        }
                        <div className={"mobile-assets-layout-record-table-container"}>
                            {data.map((item, index) => {
                                return (
                                    <div className={"mobile-assets-layout-record-table"} id={item.orderNo} key={item.orderNo}>
                                        <div className={"mobile-assets-layout-record-table-icon-container"} id={item.orderStatus} key={item.orderStatus}>
                                            <div className={"mobile-assets-layout-record-table-icon"}>
                                                <img className={"mobile-img"}
                                                     src={item.orderStatus === 1 ? RS : item.orderStatus === 2 ? RF : RC}
                                                     alt={"icon"}/>
                                            </div>
                                            <div className={"mobile-assets-layout-record-table-icon-text"}
                                                 style={{color: item.orderStatus === 1 ? '#0D1E3D' : item.orderStatus === 2 ? '#DF5060' : '#74BF72'}}
                                            >
                                                {item.orderStatus === 1 ? <Tran intlKey={"PRIVATE_CHECK_SUCCESS"}/> : item.orderStatus === 2 ? <Tran intlKey={"PRIVATE_CHECK_FAIL"}/> : <Tran intlKey={"PRIVATE_CHECK"}/>}
                                            </div>
                                            {
                                                item.orderStatus === 2 &&
                                                <div className={"mobile-assets-layout-record-table-icon-text-fail"}
                                                    // onClick={() => {this.props.parent.changeType("fail")}}
                                                     onClick={() => {this.props.parent.genMobileReason(item.rejectReason)}}
                                                >
                                                    <Tran intlKey={"PRIVATE_CHECK_FAIL_R"}/>
                                                </div>
                                            }
                                        </div>
                                        {
                                            item.orderStatus === 2 &&
                                            <div style={{display: mobileReason ? "flex" : "none", color: '#8c1529', justifyContent: 'flex-end'}}>
                                                <Tran intlKey={item.rejectReason}/>
                                            </div>
                                            // <div style={{position: 'absolute', display: mobileReason ? "flex" : "none"}}>
                                            //     <Tran intlKey={item.rejectReason}/>
                                            // </div>
                                        }
                                        <div className={"mobile-assets-layout-record-table-num-container"}>
                                            <div className={"mobile-assets-layout-record-table-num-ele"}>
                                                <div className={"mobile-assets-layout-record-num"}>
                                                    {formatCurrency(item.pAmount)}
                                                </div>
                                                <div className={"mobile-assets-layout-record-num-title"}>
                                                    <Tran intlKey={"PRIVATE_TABLE_BUY"}/>
                                                </div>
                                            </div>
                                            <div className={"mobile-assets-layout-record-table-num-ele"}>
                                                <div className={"mobile-assets-layout-record-num"}>
                                                    {formatCurrency(item.usdtPrice)}
                                                </div>
                                                <div className={"mobile-assets-layout-record-num-title"}>
                                                    <Tran intlKey={"PRIVATE"}/>{" USDT"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"mobile-assets-layout-record-table-order-container"}>
                                            <div className={"mobile-assets-layout-record-table-order-info"}>
                                                <Tran intlKey={"PRIVATE_ORDER_NO"}/>{"："}
                                                {item.orderNo}
                                            </div>
                                            <div className={"mobile-assets-layout-record-table-order-info"}>
                                                {item.pTime}
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </Fragment>
                )
            } else {
                return (
                    <div className={"mobile-assets-layout-record-empty-container"}>
                        <div className={"mobile-assets-layout-record-empty"}>
                            <img className={"mobile-img"} src={Record_Empty} alt={"empty"}/>
                        </div>
                        <div className={"mobile-assets-layout-record-empty-text"}>
                            <Tran intlKey={"PRIVATE_RECORD_EMPTY"}/>
                        </div>
                    </div>
                )
            }
        }
    }

    loadingInviterRecord = (data, currentPage, isMobile) => {
        let lang = intl.get("LANG").toString() === "0";
        if (!isMobile) {
            if (data.length >= 1) {
                return (
                    <div className={"assets-layout-record-table-container"}>
                        <Table
                            key={"table"}
                            rowKey={record => record.userName}
                            dataSource = {data}
                            pagination = {{
                                position: ['bottomRight'],
                                current: currentPage,
                                onChange: (page, pageSize) => {
                                    this.toCpParent(page)
                                    // this.setState({
                                    //     currentPage: page,
                                    // })
                                }
                            }}
                        >
                            <Column
                                title = {intl.get("ORDER_NUM")}
                                dataIndex = "index"
                                key = "index"
                                align = "center"
                                onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                    if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                        return {
                                            style:{
                                                backgroundColor: '#F5F6FA', //此处需注意
                                            },
                                        };
                                    }
                                })}
                                render = {
                                    (text,record,index) => {
                                        return (
                                            `${(currentPage - 1) * 10 + (index + 1)}`
                                            //当前页数减1乘以每一页页数再加当前页序号+1
                                        )
                                    }
                                }
                            />
                            <Column
                                title = { () => {
                                    return (
                                        <Fragment>
                                            <div>
                                                {intl.get("REGISTER_TIME")}
                                            </div>
                                            <div style={{transform: 'scale(0.8, 0.8)'}}>
                                                {intl.get("UTC")}
                                            </div>
                                        </Fragment>
                                    )
                                }}
                                // title = {intl.get("REGISTER_TIME")}
                                dataIndex = "regTime"
                                key = "regTime"
                                align = "center"
                                onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                    if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                        return {
                                            style:{
                                                backgroundColor: '#F5F6FA', //此处需注意
                                            },
                                        };
                                    }
                                })}
                                render = {(record) => (
                                    <Fragment>{timeFormat(record)}</Fragment>
                                )}
                            />
                            <Column
                                title = { () => {
                                    return (
                                        <Fragment>
                                            <div>
                                                {intl.get("LATEST_PTIME")}
                                            </div>
                                            <div style={{transform: 'scale(0.8, 0.8)'}}>
                                                {intl.get("UTC")}
                                            </div>
                                        </Fragment>
                                    )
                                }}
                                // title = {intl.get("LATEST_PTIME")}
                                dataIndex = "lastPurchase"
                                key = "lastPurchase"
                                align = "center"
                                onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                    if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                        return {
                                            style:{
                                                backgroundColor: '#F5F6FA', //此处需注意
                                            },
                                        };
                                    }
                                })}
                                render = {(record) => (
                                    <Fragment>{record ? record : intl.get("PRIVATE_NO_ORDER")}</Fragment>
                                )}
                            />
                            <Column
                                title = {intl.get("ORDER_PAMOUNT")}
                                dataIndex = "purchaseCZH"
                                key = "purchaseCZH"
                                align = "center"
                                onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                    if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                        return {
                                            style:{
                                                backgroundColor: '#F5F6FA', //此处需注意
                                            },
                                        };
                                    }
                                })}
                                render = {(record) => (
                                    <Fragment>{formatCurrency(record)}</Fragment>
                                )}
                            />
                            <Column
                                title = {intl.get("USERNAME")}
                                dataIndex = "userName"
                                key = "userName"
                                align = "center"
                                onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                    if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                        return {
                                            style:{
                                                backgroundColor: '#F5F6FA', //此处需注意
                                            },
                                        };
                                    }
                                })}
                                render = {(record) => (
                                    <Fragment>{(toMaskName(record))}</Fragment>
                                )}
                            />
                            <Column
                                title = {intl.get("AWARD_CZH")}
                                dataIndex = "inviterAward"
                                key = "inviterAward"
                                align = "center"
                                onCell = {((record, rowIndex)=>{ // 传入两个形参，分别为行内容record与行index
                                    if(rowIndex % 2 === 1){ // 可根据参数内容进行判断，如果是偶数行，就将背景颜色设置为红色
                                        return {
                                            style:{
                                                backgroundColor: '#F5F6FA', //此处需注意
                                            },
                                        };
                                    }
                                })}
                                render = {(record) => (
                                    <Fragment>{formatCurrency(record)}</Fragment>
                                )}
                            />
                        </Table>
                    </div>
                )
            } else {
                return (
                    <Fragment>
                        <div className={"private-layout-record-empty"}>
                            <img src={Record_Empty} alt={"empty"}/>
                        </div>
                        <div className={"private-layout-record-empty-text"}>
                            <Tran intlKey={"PRIVATE_RECORD_EMPTY"}/>
                        </div>
                    </Fragment>
                )
            }
        } else {
            if (data.length >= 1) {
                return (
                    <Fragment>
                        {
                            !lang &&
                            <div className={"mobile-utc"}>
                                <Tran intlKey={"UTC"}/>
                            </div>
                        }
                        <div className={"mobile-assets-layout-record-table-container"}>
                        {data.map(item => {
                            return (
                                <div className={"mobile-assets-layout-record-table"} key={item.userName}>
                                    <div className={"mobile-assets-layout-record-table-icon-container"}>
                                        <div className={"mobile-assets-layout-record-table-pot"}
                                             style={{backgroundColor: item.purchaseCZH === '0' ? '#74BF72' : '#337AF0'}}
                                        />
                                        <div className={"mobile-assets-layout-record-table-icon-text"}>
                                            <Tran intlKey={"PRIVATE_NUMBER"}/>{"："}
                                            {toMaskName(item.userName)}
                                        </div>
                                    </div>
                                    <div className={"mobile-assets-layout-record-table-num-container"}>
                                        <div className={"mobile-assets-layout-record-table-num-ele"}>
                                            <div className={"mobile-assets-layout-record-num"}>
                                                {formatCurrency(item.purchaseCZH)}
                                            </div>
                                            <div className={"mobile-assets-layout-record-num-title"}>
                                                <Tran intlKey={"AWARD_CZH"}/>
                                            </div>
                                        </div>
                                        <div className={"mobile-assets-layout-record-table-num-ele"}>
                                            <div className={"mobile-assets-layout-record-num"}>
                                                {formatCurrency(item.inviterAward)}
                                            </div>
                                            <div className={"mobile-assets-layout-record-num-title"}>
                                                <Tran intlKey={"PRIVATE_TABLE_FRIEND_BUY"}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mobile-assets-layout-record-table-order-container"}>
                                        <div className={"mobile-assets-layout-record-table-order-info"}>
                                            <Tran intlKey={"REGISTER"}/>{"："}
                                            {timeFormat(item.regTime)}
                                        </div>
                                        <div className={"mobile-assets-layout-record-table-order-info"}>
                                            <Tran intlKey={"PRIVATE_SIMPLE"}/>{"："}
                                            {item.lastPurchase ? item.lastPurchase : <Tran intlKey={"PRIVATE_NO"}/>}
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                    </Fragment>
                )
            } else {
                return (
                    <div className={"mobile-assets-layout-record-empty-container"}>
                        <div className={"mobile-assets-layout-record-empty"}>
                            <img className={"mobile-img"} src={Record_Empty} alt={"empty"}/>
                        </div>
                        <div className={"mobile-assets-layout-record-empty-text"}>
                            <Tran intlKey={"PRIVATE_RECORD_EMPTY"}/>
                        </div>
                    </div>
                )
            }
        }
    }

    // 子类传值至父类
    toParent = (type) => {
        this.props.parent.getChildrenMsg(this, type, 1)
    }

    toCpParent = (page) => {
        this.props.parent.getCpMsg(this, page)
    }

    toModalParent = (data) => {
        if (data !== '') {
            this.props.parent.getModalMsg(this, true, data)
        }
    }

    loadingOrderStatus = (status) => {
        if (status === 0) {
            return (
                <Space size="middle" style={{color: '#2DBC51'}}>
                    <Fragment>
                        <Tran intlKey={"PRIVATE_CHECK"}/>
                    </Fragment>
                </Space>
            )
        } else if (status === 1) {
            return (
                <Space size="middle" style={{color: 'black'}}>
                    <Fragment>
                        <Tran intlKey={"PRIVATE_CHECK_SUCCESS"}/>
                    </Fragment>
                </Space>
            )
        } else {
            return (
                <Fragment>
                    <Space size="middle" direction="vertical">
                        <p style={{margin: '0', height: '22px', color: '#FA5E5F', cursor: 'pointer'}}>
                            <Tran intlKey={"PRIVATE_CHECK_FAIL"}/>
                        </p>
                        <a style={{height: '22px', color: '#FA5E5F'}} onClick={this.onRow}>
                            {intl.get("ORDER_CHECK_REASON")}
                        </a>
                    </Space>
                </Fragment>
            )
        }
    }

    render() {
        let type = this.props.type;
        let isMobile = this.props.isMobile;
        let currentPage = this.props.currentPage;
        let inviterData = this.props.inviterData;
        let orderData = this.props.orderData;
        let mobileReason = this.props.mobileReason;
        let lang = intl.get("LANG").toString() === "0";
        let isAssets = this.props.isAssets;

        console.log(type)

        if (!isMobile) {
            return (
                <Fragment>
                    {/*{showModal && this.loadingModal()}*/}
                    <div className={"assets-layout-record"}>
                        <div className={"assets-layout-record-title"}>
                            {
                                type === "private" ?
                                    <div className={"private-layout-record-title"}>
                                        <Tran intlKey={"PRIVATE_RECORD"}/>
                                    </div> : type === "rewards" ?
                                        <div className={"rewards-layout-record-title"}>
                                            <Tran intlKey={"MY_INVITE_RECORD"}/>
                                        </div> :
                                        <Fragment>
                                            <div className={type === "my_record" ? "assets-layout-record-title-type-selected" : "assets-layout-record-title-type"}
                                                 onClick={() => {this.toParent("my_record")}}>
                                                <Tran intlKey={"MY_RECORD"}/>
                                            </div>
                                            <div className={type === "inviter_record" ? "assets-layout-record-title-type-selected" : "assets-layout-record-title-type"}
                                                 onClick={() => {this.toParent("inviter_record")}}>
                                                <Tran intlKey={"INVITER_RECORD"}/>
                                            </div>
                                        </Fragment>
                            }
                            <button className={"refresh-button"} onClick={() => window.location.reload()}>
                                <img src={REFRESH} alt={"refresh"}/>
                                <Tran intlKey={"REFRESH"}/>
                            </button>
                        </div>
                        <div className={"assets-layout-record-content"}>
                            {this.loadingRecord(orderData, inviterData, isMobile, type, currentPage, mobileReason)}
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <div className={"mobile-assets-layout-record"}>
                    <div className={"mobile-assets-layout-record-title"}
                         style={{width: isAssets ? '100%' : null}}
                    >
                        {
                            type === "private" ?
                                <div className={"mobile-private-record-title"}>
                                    <Tran intlKey={"PRIVATE_RECORD"}/>
                                </div> : type === "rewards" ?
                                    <div className={"mobile-rewards-record-title"}>
                                        <Tran intlKey={"MY_INVITE_RECORD"}/>
                                    </div> :
                                    <Fragment>
                                        <div className={type === "my_record" ? "mobile-assets-layout-record-type-selected" : "mobile-assets-layout-record-type"}
                                             style={{fontSize: isAssets ? '14px' : null}}
                                             onClick={() => {this.toParent("my_record")}}>
                                            <Tran intlKey={"MY_RECORD"}/>
                                        </div>
                                        <div className={type === "inviter_record" ? "mobile-assets-layout-record-type-selected" : "mobile-assets-layout-record-type"}
                                             style={{fontSize: isAssets ? '14px' : null}}
                                             onClick={() => {this.toParent("inviter_record")}}>
                                            <Tran intlKey={"INVITER_RECORD"}/>
                                        </div>
                                    </Fragment>
                        }
                        {
                            this.props.isFresh === false ? "" :
                                <button className={"refresh-button"}
                                        style={{marginTop: '20px'}}
                                        onClick={() => window.location.reload()}
                                >
                                    <img src={REFRESH} alt={"refresh"}/>
                                    <Tran intlKey={"REFRESH"}/>
                                </button>
                        }
                    </div>
                    <div className={"mobile-assets-layout-record-content"}>
                        {this.loadingRecord(orderData, inviterData, isMobile, type, currentPage, mobileReason)}
                    </div>
                </div>
            )
        }
    }
}

export default OrderRecord;
