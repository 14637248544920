import React, {Component, Fragment} from "react";
import "./private.css";
import PageLayout from "../PageLayout";
import intl from "react-intl-universal";
import {
    data,
    error,
    findUserOrdersUrl,
    genPojo,
    processOrderUrl,
    sendGetRequest,
    sendPostRequest
} from "../../utils/RequestUrlMgr";
import {connectionError, fail, findMsgByError, findMsgBySuccess, success} from "../../utils/ErrorMgr";
import {genCoreInfos, getMedia, getServiceMedia, scrollTop, sortByTimeAsc, toChar} from "../../utils/Request";
import {isMobile} from "../../utils/MobileMgr";
import LoadingWin from "../component/LoadingWin";
import Tran from "../../config/IntTranslation";
import {orderPAmountChecker, orderPAmountEmptyErrorMsg, orderPAmountErrorMsg} from "../../utils/UserInputChecker";
import NP from "number-precision";
import {formatCurrency} from "../../utils/Math";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import CZH from "../../assets/icon/icon_czh.svg";
import USDT from "../../assets/icon/icon_usdt.svg";
import Private_Rule from "../../assets/img/private_rule_img.png";
import Finish from "../../assets/icon/icon_finish.svg";
import Fail from "../../assets/icon/icon_fail.png";
import CLOSE from "../../assets/icon/icon_close.svg";
import Banner from "../../assets/img/private_bg_m.png";
import PFAIL from "../../assets/icon/icon_private_fail_m.svg";
import OrderRecord from "../component/orderRecord";
import {Input} from "antd";
import {failModal, payModal} from "../component/Common";

class Private extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            isUserLoad: false,
            userInfo: '',
            userPojo: '',
            isCoreLoad: false,
            coreInfo: new Map(),
            isOrderLoad: false,
            orderData: [],
            type: 'private',
            step: '',
            currentPage: 1,
            showModal: false,
            modalContent: '',
            modalStatus: '',
            submitStatus: 0,
            pAmount: "",
            inputStatus: '',
            payStatus: false,
            mobileReason: false,
            qrStatus: 1,
            mediaResource: new Map(),
            isMediaLoad: false,
            mediaServiceResource: new Map(),
            isMediaServiceLoad: false,
            privateStatus: this.props.match.params.private,
            displayStatus: true,
        }
    }

    orderInfo = {
        orderPojo: {
            userName: '', // 用户名
            orderNo: '', // 订单号
            pTime: '', // 提交时间
            cTime: '', // 审核时间
            inviterName: '', // 邀请者用户名
            inviterAward: '', // 邀请者奖励
            rAward: '', // 实际奖励
            pAmount: '', // 认购数量
            rAmount: '', // 实际到账数量
            orderStatus: '', // 订单状态
            usdtPrice: '', // 价格
            rejectReason: '', // 驳回原因
        }
    }

    loadingPageContent = (isLoadFinished, step, status, payStatus, coreInfo, orderData, orderPojo, type, showModal, currentPage, modalContent, isMobile, mobileReason, mediaServiceResource, mediaResource, modalStatus) => {
        if (!isMobile) {
            return (
                isLoadFinished ?
                    <Fragment>
                        {showModal && this.loadingModal(showModal, modalContent, coreInfo, payStatus, mediaServiceResource, isMobile, modalStatus)}
                        <div className={"private-layout-main"}>
                            <div className={"private-layout-process"}>
                                {this.loadingProcess(step, status, coreInfo, orderData, orderPojo, isMobile)}
                            </div>
                            <div className={"private-layout-note"}>
                                {this.loadingNote(coreInfo, mediaResource, isMobile)}
                            </div>
                        </div>
                        <div className={"private-layout-msg"}>
                            <OrderRecord parent={this} isMobile={isMobile} type={type} showModal={showModal}
                                         orderData={orderData} currentPage={currentPage} modalContent={modalContent} mobileReason={mobileReason}/>
                        </div>
                    </Fragment> :
                    <LoadingWin parent={this} isMobile={isMobile}/>
            )
        } else {
            let visible = this.state.displayStatus;
            let modalContent = this.state.modalContent;
            let lang = intl.get("LANG").toString() === "0";
            if (type === "policy") {
                return (
                    <Fragment>
                        <div style={{height: '120px'}}/>
                        <div className={"mobile-policy-title"}>
                            <Tran intlKey={"PRIVATE_POLICY_TITLE"}/>
                        </div>
                        <div className={"mobile-policy-content"}>
                            {mediaResource.get("POLICY-ONE")["itemContent"]}
                            <br/>
                            {mediaResource.get("POLICY-TWO")["itemContent"]}
                            <br/>
                            {mediaResource.get("POLICY-THREE")["itemContent"]}
                        </div>
                        <button className={"mobile-qr-button"}
                                // onClick={() => {window.location.href = '/private'}}
                                onClick={() => this.changeType("private")}
                        >
                            <Tran intlKey={"FACE_TO_FACE_GET"}/>
                        </button>
                        <div style={{height: '40px'}}/>
                    </Fragment>
                )
            }
            if (type === "fail") {
                return (
                    <Fragment>
                        <div style={{height: '120px'}}/>
                        <div className={"mobile-fail-img"}>
                            <img className={"img-size"} src={PFAIL} alt={""}/>
                        </div>
                        <div style={{height: '20px'}}/>
                        <div className={"mobile-fail-title"}>
                            <Tran intlKey={"PRIVATE_CHECK_FAIL"}/>
                        </div>
                        <div className={"mobile-fail-content"}>
                            <Tran intlKey={modalContent}/>
                        </div>
                        <div style={{height: '10px'}}/>
                        <div className={"mobile-fail-contact"}>
                            <div className={"mobile-fail-contact-wrap"}>
                                <div className={"mobile-fail-contact-ele"}>
                                    <div className={"mobile-fail-contact-ele-text"}>
                                        {intl.get("CONTACT_QQ")}{"："}
                                    </div>
                                    <div className={"mobile-fail-contact-ele-info"}>
                                        {mediaServiceResource.get("QQ-SERVICE")["itemContent"]}
                                    </div>
                                    <div className={"mobile-fail-contact-ele-line"}/>
                                </div>
                                <div className={"mobile-fail-contact-ele"}>
                                    <div className={"mobile-fail-contact-ele-text"}>
                                        {intl.get("CONTACT_TELEGRAM")}{"："}
                                    </div>
                                    <div className={"mobile-fail-contact-ele-text"} style={{color: '#0D1E3D', fontWeight: 'bold'}}>
                                        {mediaServiceResource.get("TELEGRAM-SERVICE")["itemContent"]}
                                    </div>
                                    <div className={"mobile-fail-contact-ele-line"}/>
                                </div>
                                <div className={"mobile-fail-contact-ele"}>
                                    <div className={"mobile-fail-contact-ele-text"}>
                                        {intl.get("CONTACT_EMAIL")}{"："}
                                    </div>
                                    <div className={"mobile-fail-contact-ele-text"} style={{color: '#0D1E3D', fontWeight: 'bold'}}>
                                        {mediaServiceResource.get("WECHAT-SERVICE")["itemContent"]}
                                    </div>
                                    <div className={"mobile-fail-contact-ele-line"}/>
                                </div>
                                <div className={"mobile-fail-contact-ele"}>
                                    <div className={"mobile-fail-contact-ele-text"}>
                                        {intl.get("CONTACT_EMAIL")}{"："}
                                    </div>
                                    <div className={"mobile-fail-contact-ele-text"} style={{color: '#0D1E3D', fontWeight: 'bold'}}>
                                        {mediaServiceResource.get("EMAIL-SERVICE")["itemContent"]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className={"mobile-qr-button"}
                                style={{width: '90%'}}
                            // onClick={() => {window.location.href = '/private'}}
                                onClick={() => this.changeType("private")}
                        >
                            <Tran intlKey={"FACE_TO_FACE_GET"}/>
                        </button>
                        <div style={{height: '40px'}}/>
                    </Fragment>
                )
            }
            if (type === "private") {
                return (
                    isLoadFinished ?
                        <Fragment>
                            {showModal ? this.loadingModal(showModal, modalContent, coreInfo, payStatus, mediaServiceResource, isMobile, modalStatus) :
                                <div style={{display: visible ? '' : 'none'}}>
                                    <div className={"mobile-private-wrapper"}>
                                        <div className={"mobile-private-banner"}>
                                            <img className={"mobile-private-banner-img"} src={Banner} alt={"banner"}/>
                                            <div className={"mobile-private-banner-content"}>
                                                <div className={"mobile-private-banner-content-slogan"}>
                                                    <Tran intlKey="SLOGAN"/>
                                                </div>
                                                <div className={"mobile-private-banner-content-slogan-en"}>
                                                    {lang && <Tran intlKey="SLOGAN_EN"/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mobile-private-purchase"}>
                                        <div className={"mobile-private-purchase-label-container"}>
                                            <div className={step === "apply" || status === "apply" ? "mobile-private-purchase-label-selected" : "mobile-private-purchase-label"}>
                                                <div className={"mobile-private-purchase-label-index"}
                                                     onClick={() => {this.switchStep("apply")}}
                                                >
                                                    1
                                                </div>
                                                <div className={"mobile-private-purchase-label-text"}>
                                                    <Tran intlKey="PRIVATE_APPLY"/>
                                                </div>
                                            </div>
                                            <div className={"mobile-private-purchase-label-line"}/>
                                            <div className={step !== "apply" && status === "check" ? "mobile-private-purchase-label-selected" : "mobile-private-purchase-label"}>
                                                <div className={"mobile-private-purchase-label-index"}
                                                     onClick={() => {this.switchStep("check")}}
                                                >
                                                    2
                                                </div>
                                                <div className={"mobile-private-purchase-label-text"}>
                                                    <Tran intlKey="PRIVATE_CHECK"/>
                                                </div>
                                            </div>
                                            <div className={"mobile-private-purchase-label-line"}/>
                                            <div className={step !== "apply" && status === "finish" ? "mobile-private-purchase-label-selected" : "mobile-private-purchase-label"}>
                                                <div className={"mobile-private-purchase-label-index"}
                                                     onClick={() => {this.switchStep("finish")}}
                                                >
                                                    3
                                                </div>
                                                <div className={"mobile-private-purchase-label-text"}>
                                                    <Tran intlKey="PRIVATE_FINISH"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"mobile-private-purchase-table"}>
                                            {this.loadingProcess(step, status, coreInfo, orderData, orderPojo, isMobile)}
                                        </div>
                                    </div>
                                    <div className={"mobile-private-record"}>
                                        <OrderRecord parent={this} isMobile={isMobile} type={type} showModal={showModal}
                                                     orderData={orderData} currentPage={currentPage} modalContent={modalContent} mobileReason={mobileReason}/>
                                    </div>
                                </div>}
                        </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
                )
            }
        }
    }

    loadingProcess = (step, status, coreInfo, orderData, orderPojo, isMobile) => {
        if (!isMobile) {
            return (
                <Fragment>
                    <div className={"private-layout-process-title"}>
                        <Tran intlKey={"PRIVATE"}/>
                    </div>
                    <div className={"private-layout-process-timeline"}>
                        <div className={step === "apply" || status === "apply" ? "private-layout-process-timeline-ele-select" : "private-layout-process-timeline-ele"}
                             onClick={() => {this.switchStep("apply")}}
                        >
                            <div className={"private-layout-process-timeline-step"}>
                                1
                            </div>
                            <Tran intlKey="PRIVATE_APPLY"/>
                        </div>
                        <div className={"private-layout-process-timeline-line"}/>
                        <div className={step !== "apply" && status === "check" ? "private-layout-process-timeline-ele-select" : "private-layout-process-timeline-ele"}
                             onClick={() => {this.switchStep("check")}}
                        >
                            <div className={"private-layout-process-timeline-step"}>
                                2
                            </div>
                            <Tran intlKey="PRIVATE_CHECK"/>
                        </div>
                        <div className={"private-layout-process-timeline-line"}/>
                        <div className={step !== "apply" && status === "finish" ? "private-layout-process-timeline-ele-select" : "private-layout-process-timeline-ele"}
                             onClick={() => {this.switchStep("finish")}}
                        >
                            <div className={"private-layout-process-timeline-step"}>
                                3
                            </div>
                            <Tran intlKey="PRIVATE_FINISH"/>
                        </div>
                    </div>
                    <div className={"private-layout-process-container"}>
                        {this.loadingProcessDetail(step, status, coreInfo, orderData, orderPojo, isMobile)}
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    {this.loadingProcessDetail(step, status, coreInfo, orderData, orderPojo, isMobile)}
                </Fragment>
            )
        }
    }

    switchStep = (step) => {
        this.setState({
            step: step,
        })
    }

    loadingProcessDetail = (step, status, coreInfo, orderData, orderPojo, isMobile) => {
        let unitPrice = coreInfo.get("CZH_PRICE")["itemValue"]
        let minValue = coreInfo.get("CZH_MIN")["itemValue"];
        let inputStatus = this.state.inputStatus;
        let lang = intl.get("LANG").toString() === "0";
        if (!isMobile) {
            if (step === "apply" || status === "apply") {
                return (
                    <div className={"private-layout-process-table"}>
                        <div className={"private-layout-process-table-form"}>
                            <div className={"private-layout-process-table-form-element"}>
                                <div className={"private-layout-process-table-form-title"}>
                                    <Tran intlKey="PRIVATE_TABLE_BUY"/>
                                </div>
                                <div className={"private-layout-process-table-form-container"}
                                     style={{border: this.state.submitStatus === 1 && orderPojo["pAmount"] === "" ? '1px solid #DF5060' : ""}}
                                >
                                    <input className={"private-layout-process-table-form-input"}
                                           placeholder={inputStatus === false ? 0 : minValue + " " + intl.get("PRIVATE_BUY_PH")}
                                           onChange={(e) => this.genProcessOrder(e, minValue, unitPrice, "pAmount")}
                                           maxLength={20}
                                    />
                                    <div className={"private-layout-process-table-form-currency"} style={{color: '#5A1D7A'}}>
                                        <img className={"icon-style"} src={CZH} alt={"czh"}/>
                                        <div className={"currency-title"}>EHZ</div>
                                    </div>
                                </div>
                            </div>
                            <div className={"private-layout-process-table-form-input-notice"}>
                                {this.getErrorMsg("pAmount", minValue)}
                            </div>
                            <div className={"private-layout-process-table-form-element"}>
                                <div className={"private-layout-process-table-form-title"}>
                                    <Tran intlKey="PRIVATE_TABLE_PAY"/>
                                </div>
                                <div className={"private-layout-process-table-form-container"}>
                                    <input className={"private-layout-process-table-form-input"}
                                           placeholder={intl.get("PRIVATE_PAY_PH")}
                                           value={parseInt(NP.times(orderPojo.pAmount, unitPrice).toString())}
                                           disabled={true}
                                    />
                                    <div className={"private-layout-process-table-form-currency"} style={{color: '#1BA27A'}}>
                                        <img src={USDT} alt={"usdt"}/>
                                        <div className={"currency-title"}>USDT</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className={"private-layout-process-table-form-button"}
                                onClick={() => {this.genOrder(true, orderPojo)}}
                        >
                            <Tran intlKey="PRIVATE"/>
                        </button>
                        <div className={"overview-layout-private-table-form-note"} style={{width: '120%'}}>
                            <div className={"note-style"}>
                                <div style={{color: 'gray'}}>
                                    {/*<Tran intlKey="PRIVATE_TABLE_NOTE"/>*/}
                                    {intl.get("PRIVATE_TABLE_NOTE")}
                                </div>
                                <div style={{color: '#1859DE', cursor: 'pointer'}}
                                     onClick={() => {
                                         const w = window.open('about:blank');
                                         w.location.href = '/service'}
                                     }
                                >
                                    <Tran intlKey={"CONTACT_SERVICE"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (status === "check") {
                return (
                    <div className={"private-layout-process-check-container"}>
                        <div className={"private-layout-process-check-tip"}>
                            <div style={{width: 'auto'}}>
                                <ExclamationCircleOutlined style={{paddingRight: '5px'}}/>
                            </div>
                            <div className={"private-layout-process-check-tip-text"}>
                                <div style={{width: 'auto'}}>
                                    <Tran intlKey="PRIVATE_CHECK_TIP"/>
                                </div>
                                <div style={{width: '5px'}}/>
                                <div style={{color: 'blue', cursor: 'pointer'}}
                                     onClick={() => {
                                         const w = window.open('about:blank');
                                         w.location.href = '/service'}}
                                >
                                    <Tran intlKey="SERVICE"/>
                                </div>
                            </div>
                        </div>
                        <div className={"private-layout-process-check-table"}>
                            <div className={"private-layout-process-check-box"}>
                                <div className={"private-layout-process-check-box-ele"}>
                                    <Tran intlKey="PRIVATE_UNIT"/>{"："}
                                    <div>
                                        {unitPrice}（USDT)
                                    </div>
                                </div>
                                <div className={"private-layout-process-check-box-ele"}>
                                    <Tran intlKey="PRIVATE_NUM"/>{"："}
                                    <div>
                                        {formatCurrency(orderData[0].pAmount)}（EHZ)
                                    </div>
                                </div>
                                <div className={"private-layout-process-check-box-ele"}>
                                    <Tran intlKey="PRIVATE_PAY"/>{"："}
                                    <div>
                                        {formatCurrency(orderData[0].usdtPrice)}（USDT)
                                    </div>
                                </div>
                            </div>
                            <div className={"private-layout-process-check-box"} style={{borderLeft: '1px solid #F0F0F0'}}>
                                <div className={"private-layout-process-check-box-ele"}>
                                    <Tran intlKey="PRIVATE_TRANSFER"/>{"："}
                                    <div>
                                        {formatCurrency(orderData[0].usdtPrice)}（USDT)
                                    </div>
                                </div>
                                <div className={"private-layout-process-check-box-ele"}>
                                    <Tran intlKey="PRIVATE_ORDER_NO"/>{"："}
                                    <div>
                                        {orderData[0].orderNo}
                                    </div>
                                </div>
                                <div className={"private-layout-process-check-box-ele"}>
                                    <Tran intlKey="PRIVATE_PROCESS"/>{"："}
                                    <div style={{fontWeight: 'bold', color: '#236BF3'}}>
                                        <Tran intlKey="PRIVATE_CHECK"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className={"private-layout-process-check-button"}
                                onClick={() => {this.switchStep("apply")}}
                        >
                            <Tran intlKey="PRIVATE_REPURCHASE"/>
                        </button>
                    </div>
                )
            } else if (status === "finish") {
                return (
                    <div className={"private-layout-process-finish-container"}>
                        <div className={"private-layout-process-finish-icon"}>
                            <img className={"finish-icon"} src={orderData[0].orderStatus === 1 ? Finish : Fail} alt={"finish"}/>
                        </div>
                        <div className={"private-layout-process-finish-box"}>
                            <div className={"private-layout-process-finish-box-title"}>
                                {orderData[0].orderStatus === 1 ? <Tran intlKey="PRIVATE_SUCCESS"/> : <Tran intlKey="PRIVATE_FAIL"/>}
                            </div>
                            <div className={"private-layout-process-finish-box-ele"}>
                                <div>
                                    <Tran intlKey="PRIVATE_ORDER_NO"/>{"："}
                                </div>
                                <div>
                                    {orderData[0].orderNo}
                                </div>
                            </div>
                            {
                                orderData[0].orderStatus === 2 &&
                                <div className={"private-layout-process-finish-box-ele"}>
                                    <div>
                                        <Tran intlKey="PRIVATE_FAIL_REASON_NOTE"/>{"："}
                                    </div>
                                    <div>
                                        <Tran intlKey={orderData[0].rejectReason}/>
                                    </div>
                                </div>
                            }
                            <div className={"private-layout-process-finish-box-tip"}>
                                <Tran intlKey="PRIVATE_FINISH_TIP"/>
                                <div style={{width: '5px'}}/>
                                <div style={{color: 'blue', cursor: 'pointer'}}
                                     onClick={() => {
                                         const w = window.open('about:blank');
                                         w.location.href = '/service'}
                                     }
                                >
                                    <Tran intlKey="SERVICE"/>
                                </div>
                            </div>
                        </div>
                        <button className={"private-layout-process-finish-button"} onClick={() => {this.switchStep("apply")}}>
                            <Tran intlKey="PRIVATE_REPURCHASE"/>
                        </button>
                    </div>
                )
            }
        } else {
            if (step === "apply" || status === "apply") {
                return (
                    <Fragment>
                        <div className={"mobile-private-purchase-header"}>
                            <div className={"mobile-private-purchase-header-title"}>
                                <Tran intlKey="PRIVATE_TABLE_TITLE"/>
                            </div>
                            <div className={"mobile-private-purchase-header-rule"}
                                 onClick={() => this.changeType("policy")}
                            >
                                <Tran intlKey="PRIVATE_TABLE_RULE_LINK"/>
                            </div>
                        </div>
                        <div className={"mobile-private-purchase-price"}>
                            <div className={"mobile-price-icon"}>
                                <ExclamationCircleOutlined/>
                            </div>
                            <div className={"mobile-price-bold"}>
                                <Tran intlKey="MOBILE_PRIVATE_PRICE"/>
                            </div>
                            <div className={"mobile-price-gray"}>
                                {unitPrice}{" EHZ"}
                                {
                                    lang &&
                                    <Fragment>
                                        {"（"}
                                        {minValue.slice(0, -4)}
                                        <Tran intlKey={"PRIVATE_TABLE_RULE_TWO_UNIT"}/>
                                        <Tran intlKey="PRIVATE_TABLE_RULE_TWO"/>
                                        {"）"}
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <div className={"mobile-private-purchase-input"}>
                            <div className={"mobile-private-purchase-input-element"}
                                 style={{border: this.state.submitStatus === 1 && orderPojo["pAmount"] === "" ? '1px solid #DF5060' : ""}}
                            >
                                <div className={"mobile-input-element-text"}>
                                    <Tran intlKey="PRIVATE_TABLE_BUY"/>
                                </div>
                                <input className={"mobile-input-element-num"}
                                       placeholder={inputStatus === false ? 0 : formatCurrency(minValue) + " " + intl.get("PRIVATE_BUY_PH")}
                                       onChange={(e) => this.genProcessOrder(e, minValue, unitPrice, "pAmount")}
                                       maxLength={20}
                                />
                                <div className={"mobile-input-element-currency"}>
                                    <img className={"icon-style"} src={CZH} alt={"czh"}/>
                                    <div className={"mobile-input-element-currency-one"}>
                                        EHZ
                                    </div>
                                </div>
                            </div>
                            <div className={"mobile-input-notice"}>
                                {this.getErrorMsg("pAmount", minValue)}
                            </div>
                            <div className={"mobile-private-purchase-input-element"}>
                                <div className={"mobile-input-element-text"}>
                                    <Tran intlKey="PRIVATE_TABLE_PAY"/>
                                </div>
                                <Input className={"mobile-input-element-num"}
                                       placeholder={intl.get("PRIVATE_PAY_PH")}
                                       value={parseInt(NP.times(orderPojo.pAmount, unitPrice).toString())}
                                       disabled={true}
                                />
                                <div className={"mobile-input-element-currency"}>
                                    <img src={USDT} alt={"czh"}/>
                                    <div className={"mobile-input-element-currency-two"}>
                                        USDT
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"mobile-private-purchase-button"}
                             onClick={() => {this.genOrder(true, orderPojo)}}>
                            <Tran intlKey="PRIVATE_TABLE_TITLE"/>
                        </div>
                    </Fragment>
                )
            } else if (status === "check") {
                return (
                    <Fragment>
                        <div className={"mobile-private-check-title"}>
                            <Tran intlKey="PRIVATE_CHECK_INFO"/>
                        </div>
                        <div className={"mobile-private-check-info"}>
                            <div className={"mobile-private-check-info-ele"}>
                                <div className={"mobile-private-check-info-title"} style={{width: lang ? '70px' : '142px'}}>
                                    <Tran intlKey="PRIVATE_UNIT"/>{"："}
                                </div>
                                <div className={"mobile-private-check-info-content"}>
                                    {unitPrice} USDT
                                </div>
                            </div>
                            <div className={"mobile-private-check-info-ele"}>
                                <div className={"mobile-private-check-info-title"} style={{width: lang ? '70px' : '142px'}}>
                                    <Tran intlKey="PRIVATE_NUM"/>{"："}
                                </div>
                                <div className={"mobile-private-check-info-content"}>
                                    {formatCurrency(orderData[0].pAmount) + " EHZ"}
                                </div>
                            </div>
                            <div className={"mobile-private-check-info-ele"}>
                                <div className={"mobile-private-check-info-title"} style={{width: lang ? '70px' : '142px'}}>
                                    <Tran intlKey="PRIVATE_PAY"/>{"："}
                                </div>
                                <div className={"mobile-private-check-info-content"}>
                                    {formatCurrency(orderData[0].usdtPrice) + " USDT"}
                                </div>
                            </div>
                            <div className={"mobile-private-check-info-ele"}>
                                <div className={"mobile-private-check-info-title"} style={{width: lang ? '70px' : '142px'}}>
                                    <Tran intlKey="PRIVATE_TRANSFER"/>{"："}
                                </div>
                                <div className={"mobile-private-check-info-content"}>
                                    {formatCurrency(orderData[0].usdtPrice) + " USDT"}
                                </div>
                            </div>
                            <div className={"mobile-private-check-info-ele"}>
                                <div className={"mobile-private-check-info-title"} style={{width: lang ? '70px' : '142px'}}>
                                    <Tran intlKey="PRIVATE_ORDER_NO"/>{"："}
                                </div>
                                <div className={"mobile-private-check-info-content"}>
                                    {orderData[0].orderNo}
                                </div>
                            </div>
                            <div className={"mobile-private-check-info-ele"}>
                                <div className={"mobile-private-check-info-title"} style={{width: lang ? '70px' : '142px'}}>
                                    <Tran intlKey="PRIVATE_PROCESS"/>{"："}
                                </div>
                                <div className={"mobile-private-check-info-content"} style={{color: '#2DBC51'}}>
                                    <Tran intlKey={"PRIVATE_CHECK"}/>{"..."}
                                </div>
                            </div>
                        </div>
                        <button className={"mobile-private-check-button"} onClick={() => {this.switchStep("apply")}}>
                            <Tran intlKey="PRIVATE_REPURCHASE"/>
                        </button>
                        <div className={"mobile-private-check-tip"}>
                            <Tran intlKey="PRIVATE_CHECK_TIP"/>
                            <a style={{marginLeft: '5px', color: '#1859DE'}}
                                 onClick={() => {
                                     const w = window.open('about:blank');
                                     w.location.href = '/service'}
                                 }
                            >
                                <Tran intlKey="SERVICE"/>
                            </a>
                        </div>
                    </Fragment>
                )
            } else if (status === "finish") {
                return (
                   <Fragment>
                        <div className={"mobile-private-finish-img"}>
                            <img className={"finish-icon"} src={orderData[0].orderStatus === 1 ? Finish : Fail} alt={"finish"}/>
                        </div>
                       <div className={"mobile-private-finish-title"}>
                           {orderData[0].orderStatus === 1 ? <Tran intlKey="PRIVATE_FINISH"/> : <Tran intlKey="PRIVATE_FAIL"/>}
                       </div>
                       <div className={"mobile-private-finish-orderNo"}>
                           <Tran intlKey="PRIVATE_ORDER_NO"/>{"："}{orderData[0].orderNo}
                       </div>
                        <button className={"mobile-private-finish-button"}
                                onClick={() => {this.switchStep("apply")}}
                        >
                            <Tran intlKey="PRIVATE_REPURCHASE"/>
                        </button>
                        <div className={"mobile-private-finish-tip"}>
                            <Tran intlKey={"PRIVATE_FINISH_TIP"}/>
                            <div style={{width: '5px'}}/>
                            <div style={{color: '#337AF0'}}>
                                <Tran intlKey="SERVICE"/>
                            </div>
                        </div>
                   </Fragment>
                )
            }
        }
    }

    // order
    genProcessOrder = (e, minValue, unitPrice, props) => {
        let orderPojo = this.orderInfo.orderPojo;
        let inputValue = e.target.value;
        switch (props) {
            case "pAmount": {
                if (inputValue && orderPAmountChecker.test(inputValue) && parseInt(inputValue) >= parseInt(minValue)) {
                    orderPojo["pAmount"] = parseInt(inputValue);
                    orderPojo["usdtPrice"] = parseInt(NP.times(inputValue, unitPrice).toString());
                    this.setState({
                        inputStatus: true,
                        orderPojo
                    })
                } else {
                    orderPojo["pAmount"] = "";
                    this.setState({
                        inputStatus: true,
                        orderPojo
                    })
                }
                return;
            }
            default: {
                return;
            }
        }
    }

    genMobileReason = (item) => {
        this.setState({
            type: 'fail',
            modalContent: item,
        })
    }

    setMobileReason = () => {
        this.setState({
            mobileReason: true,
        })
    }

    getErrorMsg(props, minValue) {
        if (this.state.submitStatus !== 1) {
            return;
        }
        switch (props) {
            case "pAmount": {
                if (this.state.inputStatus) {
                    return this.orderInfo.orderPojo["pAmount"] === "" ? orderPAmountErrorMsg(intl) + minValue + " EHZ" : "";
                } else {
                    return orderPAmountEmptyErrorMsg(intl);
                }
            }
            default: {
                return "";
            }
        }
    }

    fillOrderInfo = (showModal, orderInfo) => {
        let userInfo = sessionStorage.getItem("userInfo");
        orderInfo["userName"] = JSON.parse(userInfo).userName;
        orderInfo["orderNo"] = "";
        orderInfo["pTime"] = "";
        orderInfo["cTime"] = null;
        orderInfo["inviterName"] = JSON.parse(userInfo).inviterName;
        orderInfo["inviterAward"] = 0;
        orderInfo["rAward"] = 0;
        // orderInfo["pAmount"] = "";
        orderInfo["rAmount"] = 0;
        orderInfo["orderStatus"] = 0;
        // orderInfo["usdtPrice"] = "";
        orderInfo["rejectReason"] = "";
        this.setState({
            orderInfo,
            showModal: showModal,
            modalContent: orderInfo,
            payStatus: true,
        },
            () => scrollTop() )
    }

    genOrder= (showModal, orderInfo) => {
        let allInfoFilled = orderInfo["pAmount"] !== "";
        console.log("allInfoFilled:", allInfoFilled)
        if (allInfoFilled) {
            this.fillOrderInfo(showModal, orderInfo);
            // console.log("弹窗：", orderInfo)
        }
        this.setState({
            inputStatus: this.state.inputStatus !== '',
            submitStatus: 1,
        })
    }

    openModal = (data, status) => {
        if (data !== '') {
            this.setState({
                showModal: true,
                modalContent: data,
                modalStatus: status,
            })
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    loadingModal = (showModal, modalContent, coreInfo, props, mediaServiceResource, isMobile, modalStatus) => {
        if (showModal === true) {
            if (modalStatus === "policy") {
                return (
                    <div className={"private-modal"}>
                        <div className={"modal-rule-win"}>
                            <div className={"modal-rule-win-title"}>
                                <Tran intlKey={"PRIVATE_POLICY_TITLE"}/>
                            </div>
                            <div className={"modal-rule-win-text"}>
                                {modalContent}
                            </div>
                        </div>
                        <button className={"private-modal-win-close"}
                                style={{top: 'calc(50% - 185px)', left: 'calc(50% + 300px)'}}
                                onClick={() => {this.closeModal()}}
                        >
                            <img className={"img-size"} src={CLOSE} alt={"clo"}/>
                        </button>
                    </div>
                )
            } else {
                return (
                    <div className={isMobile ? "mobile-private-modal" : "private-modal"}>
                        {this.loadingModalWin(modalContent, coreInfo, props, mediaServiceResource, isMobile)}
                    </div>
                )
            }
        }
    }

    changeQRStatus = (qrStatus) => {
        this.setState({
            qrStatus: qrStatus,
        })
    }

    changeType = (type) => {
        console.log(11111)
        this.setState({
            type: type,
        })
    }

    loadingModalWin = (modalContent, coreInfo, props, mediaServiceResource, isMobile) => {
        let qrStatus = this.state.qrStatus;
        let erc_link = toChar(coreInfo.get("USDT_LINK")["itemValue"], coreInfo.get("USDT_LINK")["itemSec"]);
        let trc_link = toChar(coreInfo.get("USDT_LINK_T")["itemValue"], coreInfo.get("USDT_LINK_T")["itemSec"]);
        if (modalContent) {
            if (!isMobile) {
                if (props) {
                    return (
                        payModal(modalContent, coreInfo, qrStatus, erc_link, trc_link, isMobile, this)
                    )
                } else {
                    if (mediaServiceResource) {
                        return (
                            failModal(modalContent, mediaServiceResource, this)
                        )
                    }
                }
            } else {
                if (props) {
                    return (
                        payModal(modalContent, coreInfo, qrStatus, erc_link, trc_link, isMobile, this)
                    )
                } else {
                    return (
                        payModal(modalContent, coreInfo, qrStatus, erc_link, trc_link, isMobile, this)
                    )
                }

            }
        }
    }

    genOrderPay = (orderInfo) => {
        let pojo = genPojo(processOrderUrl, orderInfo);
        console.log("私募认购传参：", pojo)
        if (pojo) {
            sendPostRequest(pojo).then(res => {
                console.log(res)
                if (res && res.hasOwnProperty("success") && res.success) {
                    success(findMsgBySuccess("PRIVATE_SUCCESS"), "/private");
                    this.setState({
                        submitStatus: 2,
                        showModal: false,
                    })
                } else {
                    fail(findMsgByError(res ? res.errorType : "UNDEFINED"));
                    this.setState({
                        submitStatus: 0,
                    })
                }
            })
        }
    }

    loadingNote = (coreInfo, mediaResource, isMobile) => {
        let minValue = coreInfo.get("CZH_MIN")["itemValue"];
        let policyContent = mediaResource.get("POLICY-ONE")["itemContent"] + '\n' + '\n' + mediaResource.get("POLICY-TWO")["itemContent"] + '\n' + '\n' + mediaResource.get("POLICY-THREE")["itemContent"];
        if (!isMobile) {
            return (
                <Fragment>
                    <div className={"private-layout-note-title"}>
                          <Tran intlKey={"PRIVATE_TABLE_TIP"}/>
                     </div>
                     <div className={"private-layout-note-element"}>
                         <div className={"private-layout-note-element-unit"}>
                             <Tran intlKey={"PRIVATE_NOTE_UNIT"}/>
                         </div>
                         <div className={"private-layout-note-element-num"}>
                             {coreInfo.get("CZH_PRICE")["itemValue"]}
                         </div>
                         <div className={"private-layout-note-element-currency"}>
                             USDT
                         </div>
                     </div>
                     <div className={"private-layout-note-element"}>
                         <div className={"private-layout-note-element-unit"}>
                             <Tran intlKey={"PRIVATE_NOTE_PURCHASE"}/>
                         </div>
                         <div className={"private-layout-note-element-num"}>
                             {minValue.slice(0, -4)}
                             <Tran intlKey={"PRIVATE_TABLE_RULE_TWO_UNIT"}/>
                         </div>
                         <div className={"private-layout-note-element-currency"}>
                             <Tran intlKey={"PRIVATE_TABLE_RULE_TWO"}/>
                         </div>
                     </div>
                     <div className={"private-layout-note-rule"}>
                         <div className={"private-layout-note-rule-text"}
                              onClick={() => {this.openModal(policyContent, "policy")}}
                         >
                             <ExclamationCircleOutlined style={{paddingRight: '5px'}}/>
                             <Tran intlKey={"PRIVATE_TABLE_RULE_LINK"}/>
                         </div>
                         <div className={"private-layout-note-rule-img"}>
                             <img className={"rule-img"} src={Private_Rule} alt={"rule"}/>
                         </div>
                     </div>
                </Fragment>
            )
        } else {
            //
        }
    }

    // 用户基础信息
    setUsrInfo = (userInfo, userPojo, isUserLoad) => {
        this.setState({
            userInfo: userInfo,
            userPojo: userPojo,
            isUserLoad: isUserLoad,
        })
    }

    // 用户订单
    genUserOrders () {
        let userInfo = sessionStorage.getItem("userInfo");
        if (userInfo) {
            sendGetRequest(findUserOrdersUrl, {
                userName: JSON.parse(userInfo).userName,
            }).then(res => {
                console.log("用户订单结果：", res)
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        orderData: sortByTimeAsc(res[data], "pTime"),
                        isOrderLoad: true,
                    });
                } else {
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
        }
    }

    // 父子类传参
    getChildrenMsg = (result, type, page) => {
        this.setState({
            type: type,
            currentPage: page,
        })
    }

    getCpMsg = (result, page) => {
        this.setState({
            currentPage: page,
        })
    }

    getModalMsg = (result, showModal, modalContent) => {
        this.setState({
            showModal: showModal,
            modalContent: modalContent,
        })
    }

    componentDidMount() {
        genCoreInfos(this);
        this.genUserOrders();
        getMedia(intl, "PRIVATE", this)
        getServiceMedia(intl, "SERVICE", this);
    }

    render() {
        let isMobile = this.state.isMobile;

        let coreInfo = this.state.coreInfo;
        let isCoreLoad = this.state.isCoreLoad;
        let orderData = this.state.orderData;
        let isOrderLoad = this.state.isOrderLoad;

        let mediaResource = this.state.mediaResource;
        let isMediaLoad = this.state.isMediaLoad;
        let mediaServiceResource = this.state.mediaServiceResource;
        let isMediaServiceLoad = this.state.isMediaServiceLoad;

        let isLoadFinished = isCoreLoad && isOrderLoad && isMediaLoad && isMediaServiceLoad;

        let step = this.state.step || (this.state.privateStatus === 'private' && 'apply');
        let orderPojo = this.orderInfo.orderPojo;

        let type = this.state.type;
        let showModal = this.state.showModal;
        let modalContent = this.state.modalContent;
        let modalStatus = this.state.modalStatus;
        let currentPage = this.state.currentPage;
        let payStatus = this.state.payStatus;

        let mobileReason = this.state.mobileReason;

        // console.log("用户缓存账户：", userInfo)
        // console.log("用户信息：", userPojo)
        // console.log("CZH信息：", coreInfo)
        // console.log("订单详情：", orderData)
        console.log("isLoadFinished：", isLoadFinished)

        let status = "apply";
        if (orderData.length >= 1) {
            let orderStatus = orderData && orderData[0].orderStatus;
            status = orderStatus === 0 ? "check" : orderStatus === 1 || orderStatus === 2 ? "finish" : "apply";
        }

        console.log("step", step)
        console.log("status", status)
        console.log("payStatus", payStatus)

        return (
            <PageLayout parent={this} pNameCN={intl.get("PRIVATE")} pNameEN={intl.get("PRIVATE_EN")}
                        needUser={false}
                        dom={this}
                        pContent={this.loadingPageContent(isLoadFinished, step, status, payStatus, coreInfo, orderData, orderPojo, type, showModal, currentPage, modalContent, isMobile, mobileReason, mediaServiceResource, mediaResource, modalStatus)}
            />
        )
    }
}

export default Private;
