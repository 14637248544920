import React, {Component, Fragment} from "react";
import "./service.css";
import {isMobile} from "../../utils/MobileMgr";
import Tran from "../../config/IntTranslation";
import WX from "../../assets/icon/icon_service_wechat.svg";
import QQ from "../../assets/icon/icon_service_qq.svg";
import TEL from "../../assets/icon/icon_service_tel.svg";
import EMAIL from "../../assets/icon/icon_service_email.svg";
import PageLayout from "../PageLayout";
import intl from "react-intl-universal";
import LoadingWin from "../component/LoadingWin";
import {getMedia} from "../../utils/Request";

class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            language: this.props.match.params.lang,
            mediaResource: new Map(),
            isMediaLoad: false,
        }
    }

    loadingPageContent = (isMediaLoad, mediaResource, isMobile) => {
        if (!isMobile) {
            return (
                isMediaLoad ?
                    <Fragment>
                        <div className={"service-layout-main"}>
                            <div className={"service-layout-main-service"}>
                                <div className={"service-layout-main-service-icon"}>
                                    <img className={"service-layout-main-service-icon-img"} src={QQ} alt={'wx'}/>
                                </div>
                                <div className={"service-layout-main-service-title"}>
                                    {intl.get("CONTACT_QQ")}
                                </div>
                                <div className={"service-layout-main-service-des"}>
                                    {mediaResource.get("QQ-SERVICE")["itemContent"]}
                                </div>
                            </div>
                            <div className={"service-layout-main-service"}>
                                <div className={"service-layout-main-service-icon"}>
                                    <img className={"service-layout-main-service-icon-img"} src={TEL} alt={'wx'}/>
                                </div>
                                <div className={"service-layout-main-service-title"}>
                                    {intl.get("CONTACT_TELEGRAM")}
                                </div>
                                <div className={"service-layout-main-service-des"}>
                                    {mediaResource.get("TELEGRAM-SERVICE")["itemContent"]}
                                </div>
                            </div>
                            <div className={"service-layout-main-service"}>
                                <div className={"service-layout-main-service-icon"}>
                                    <img className={"service-layout-main-service-icon-img"} src={EMAIL} alt={'wx'}/>
                                </div>
                                <div className={"service-layout-main-service-title"}>
                                    {intl.get("CONTACT_EMAIL")}
                                </div>
                                <div className={"service-layout-main-service-des"}>
                                    {mediaResource.get("WECHAT-SERVICE")["itemContent"]}
                                </div>
                            </div>
                            <div className={"service-layout-main-service"}>
                                <div className={"service-layout-main-service-icon"}>
                                    <img className={"service-layout-main-service-icon-img"} src={EMAIL} alt={'wx'}/>
                                </div>
                                <div className={"service-layout-main-service-title"}>
                                    {intl.get("CONTACT_EMAIL")}
                                </div>
                                <div className={"service-layout-main-service-des"}>
                                    {mediaResource.get("EMAIL-SERVICE")["itemContent"]}
                                </div>
                            </div>
                        </div>
                    </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
            )
        } else {
            return (
                isMediaLoad ?
                <Fragment>
                    <div style={{height: '100px'}}/>
                    <div className={"mobile-service-wrap"}>
                        <div className={"mobile-service-title"}>
                            <Tran intlKey={"CONTACT_TITLE"}/>
                        </div>
                        <div className={"mobile-service-container"}>
                            <div className={"mobile-service-ele"}>
                                <div className={"mobile-service-ele-title"}>
                                    <Tran intlKey={"CONTACT_QQ"}/>
                                </div>
                                <div className={"mobile-service-ele-des"}>
                                    {mediaResource.get("QQ-SERVICE")["itemContent"]}
                                </div>
                            </div>
                            <div className={"mobile-service-ele"}>
                                <div className={"mobile-service-ele-title"}>
                                    <Tran intlKey={"CONTACT_TELEGRAM"}/>
                                </div>
                                <div className={"mobile-service-ele-des"}>
                                    {mediaResource.get("TELEGRAM-SERVICE")["itemContent"]}
                                </div>
                            </div>
                            <div className={"mobile-service-ele"}>
                                <div className={"mobile-service-ele-title"}>
                                    <Tran intlKey={"CONTACT_EMAIL"}/>
                                </div>
                                <div className={"mobile-service-ele-des"}>
                                    {mediaResource.get("WECHAT-SERVICE")["itemContent"]}
                                </div>
                            </div>
                            <div className={"mobile-service-ele"}>
                                <div className={"mobile-service-ele-title"}>
                                    <Tran intlKey={"CONTACT_EMAIL"}/>
                                </div>
                                <div className={"mobile-service-ele-des"}>
                                    {mediaResource.get("EMAIL-SERVICE")["itemContent"]}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
            )
        }
    }

    componentDidMount() {
        getMedia(intl, "SERVICE", this);
    }

    render() {
        let isMobile = this.state.isMobile;
        let mediaResource = this.state.mediaResource;
        let isMediaLoad = this.state.isMediaLoad;

        return (
            <PageLayout parent={this} pNameCN={intl.get("SERVICE")} pNameEN={intl.get("SERVICE_EN")}
                        needUser={false}
                        pContent={this.loadingPageContent(isMediaLoad, mediaResource, isMobile)}
            />
        )
    }
}

export default Service;
