import React, {Fragment} from "react";
import Tran from "../../config/IntTranslation";
import {formatCurrency} from "../../utils/Math";
import {
    changeCountryStatus,
    copyFun,
    countryId,
    genRegisterInfo,
    getErrorMsg,
    register,
    saveSession, toMaskLong
} from "../../utils/Request";
import {
    checkSecCode,
    data, emailCodeType,
    genPojo,
    goValid, phoneCodeType,
    sendGetRequest,
    sendPostRequest,
    userLoginUrl,
    webHeader
} from "../../utils/RequestUrlMgr";
import CLOSE from "../../assets/icon/icon_close.svg";
import PUR_M from "../../assets/img/private_pur.png";
import "../private/private.css";
import Fail from "../../assets/icon/icon_fail.png";
import intl from "react-intl-universal";
import {connectionError, fail, findMsgByError, findMsgBySuccess, success} from "../../utils/ErrorMgr";
import {Dropdown, Input} from "antd";
import {listCommonCountry, listCountry} from "../../config/countryCode";
import {DownOutlined, EyeInvisibleOutlined, EyeTwoTone, UpOutlined} from "@ant-design/icons";

// register module
const loadingRegisterProcess = (dom, registerPojo, inviteUId, countryStatus, curList, isMobile) => {
    let countryCode = dom.state.countryCode;
    return (
        <Fragment>
            <div className={"register-layout-win-element"}>
                <div className={"register-layout-win-text"}>
                    <div className={"text-justify"}>
                        <Tran intlKey="USERNAME"/>
                    </div>
                </div>
                <Input className={"register-layout-win-input"}
                       size="middle"
                       placeholder={intl.get("USERNAME_PH")}
                       maxLength={20}
                    // prefix={<UserOutlined style={{color: "grey"}}/>}
                       onChange={(e) => genRegisterInfo(e, "userName", dom)}
                />
            </div>
            <div className={"register-layout-win-input-notice"}>
                {getErrorMsg("userName", dom)}
            </div>
            {
                registerPojo["regCode"] === 1 ?
                    <Fragment>
                        <div className={"register-layout-win-element"}>
                            <div className={"register-layout-win-text"}>
                                <div className={"text-justify"}>
                                    <Tran intlKey="PHONE"/>
                                </div>
                            </div>
                            <div className={"register-layout-win-phone"}>
                                <Dropdown
                                    overlay={countryId(listCommonCountry, listCountry, curList, dom, isMobile)}
                                    trigger={'click'}
                                    visible={countryStatus}
                                    onVisibleChange={visible => {
                                        dom.setState({
                                            countryStatus: visible,
                                        })
                                    }}
                                >
                                    <div className={"register-layout-win-country"}
                                         onClick={() => {changeCountryStatus(dom, countryStatus)}}>
                                        {/*{"+" + registerPojo["country"]}*/}
                                        {"+" + countryCode}
                                        <div className={"register-layout-win-country-icon"}>
                                            {
                                                !countryStatus ? <DownOutlined /> : <UpOutlined />
                                            }
                                        </div>
                                    </div>
                                </Dropdown>
                                <Input className={"register-layout-win-input"}
                                       size="middle"
                                       style={{paddingLeft: '30%', width: '100%', height: '100%'}}
                                       placeholder={intl.get("PHONE_PH")}
                                       maxLength={20}
                                    // prefix={<MobileOutlined style={{color: "grey"}}/>}
                                       onChange={(e) => genRegisterInfo(e, "userPhone", dom)}
                                />
                            </div>
                        </div>
                        <div className={"register-layout-win-input-notice"}>
                            {getErrorMsg("userPhone", dom)}
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className={"register-layout-win-element"}>
                            <div className={"register-layout-win-text"}>
                                <div className={"text-justify"}>
                                    <Tran intlKey="EMAIL"/>
                                </div>
                            </div>
                            <Input className={"register-layout-win-input"}
                                   size="middle"
                                   placeholder={intl.get("EMAIL_PH")}
                                   maxLength={50}
                                // prefix={<MailOutlined style={{color: "grey"}}/>}
                                   onChange={(e) => genRegisterInfo(e, "userEmail", dom)}
                            />
                        </div>
                        <div className={"register-layout-win-input-notice"}>
                            {getErrorMsg("userEmail", dom)}
                        </div>
                    </Fragment>
            }
            <div className={"register-layout-win-element"}>
                <div className={"register-layout-win-text"}>
                    <div className={"text-justify"}>
                        <Tran intlKey="PASSWORD"/>
                    </div>
                </div>
                <Input.Password
                    className={"register-layout-win-input"}
                    size={"middle"}
                    placeholder={intl.get("PASSWORD_PHL")}
                    maxLength={20}
                    // prefix={<KeyOutlined style={{color: "grey"}}/>}
                    iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                    onChange={(e) => genRegisterInfo(e, "userPwd", dom)}
                />
            </div>
            <div className={"register-layout-win-input-notice"}>
                {getErrorMsg("userPwd", dom)}
            </div>
            <div className={"register-layout-win-element"}>
                <div className={"register-layout-win-text"}>
                    <div className={"text-justify"}>
                        <Tran intlKey="INVITE_CODE"/>
                    </div>
                </div>
                <Input className={"register-layout-win-input"}
                       style={{color: inviteUId ? 'black' : '', fontWeight:  inviteUId ? 'lighter' : ''}}
                       size="middle"
                       placeholder={intl.get("INVITE_CODE_PH")}
                       maxLength={80}
                       value={inviteUId}
                       disabled={inviteUId && true}
                    // prefix={<UserAddOutlined style={{color: "grey"}}/>}
                       onChange={(e) => genRegisterInfo(e, "inviteUId", dom)}
                />
            </div>
        </Fragment>
    )
}

// pay module
const payModal = (modalContent, coreInfo, qrStatus, erc_link, trc_link, isMobile, dom) => {
    if (!isMobile) {
        return (
            <Fragment>
                <div className={"private-modal-win"} style={{zIndex: '99', padding: "15px 0", justifyContent: 'space-between', top: 'calc(50% - 300px)', height: '600px'}}>
                    <div className={"private-modal-win-title"}>
                        <Tran intlKey={"PRIVATE_PROCESS_PAY"}/>
                    </div>
                    <div className={"private-modal-win-container-pay"}>
                        <div className={"private-modal-win-container-title"}>
                            {"01/  "}<Tran intlKey={"PRIVATE_PROCESS_INFO_CONFIRM"}/>
                        </div>
                        <div className={"private-modal-win-container-content"}>
                            <div className={"private-modal-win-container-text"}>
                                <Tran intlKey={"PRIVATE_UNIT"}/>{"："}{coreInfo.get("CZH_PRICE")["itemValue"]}{"（USDT）"}
                            </div>
                            <div className={"private-modal-win-container-text"}>
                                <Tran intlKey={"PRIVATE_NUM"}/>{"："}{formatCurrency(modalContent.pAmount)}{"（EHZ）"}
                            </div>
                            <div className={"private-modal-win-container-text"}>
                                <Tran intlKey={"PRIVATE_PAY"}/>{"："}{formatCurrency(modalContent.usdtPrice)}{"（USDT）"}
                            </div>
                        </div>
                    </div>
                    <div className={"private-modal-win-container-pay"}>
                        <div className={"private-modal-win-container-title"}>
                            {"02/  "}<Tran intlKey={"PRIVATE_PROCESS_USDT_CONFIRM"}/>
                        </div>
                        <div className={"private-modal-win-container-content"} style={{height: '280px'}}>
                            <div className={"private-modal-win-container-sub-title"}>
                                <div className={qrStatus === 1 ? "private-modal-win-qr-selected" : "private-modal-win-qr"}
                                     onClick={() => {dom.changeQRStatus(1)}}
                                >
                                    {"TRC20"}
                                </div>
                                <div className={qrStatus === 2 ? "private-modal-win-qr-selected" : "private-modal-win-qr"}
                                     onClick={() => {dom.changeQRStatus(2)}}
                                >
                                    {"ERC20"}
                                </div>
                            </div>
                            <div className={"private-modal-win-qr-content"}>
                                <div className={"private-modal-win-qr-content-text"} style={{textAlign: 'left', paddingLeft: '10px'}}>
                                    {qrStatus === 1 ? trc_link : erc_link}
                                </div>
                                <input className={"rewards-layout-my-code-ele-content-input"}
                                       id={"qrLink"}
                                       value={qrStatus === 1 ? trc_link : erc_link}
                                       readOnly={true}
                                />
                                <button className={"private-modal-win-qr-content-copy"}
                                        onClick={() => {copyFun("qrLink")}}>
                                    <Tran intlKey={"COPY"}/>
                                </button>
                            </div>
                            <div className={"private-modal-win-container-text"} style={{textAlign: 'center', fontSize: '12px'}}>
                                {qrStatus === 1 ? "TRC20" : "ERC20"}<Tran intlKey={"PURCHASE_QR_ADDRESS"}/>
                            </div>
                            <div className={"private-modal-win-container-qr"}>
                                <img className={"img-size"}
                                     src={qrStatus === 1 ? webHeader + coreInfo.get("USDT_QR_T")["itemValue"] : webHeader + coreInfo.get("USDT_QR")["itemValue"]}
                                     alt={"qr"}/>
                            </div>
                        </div>
                    </div>
                    <button className={"private-modal-win-button"}
                            style={{marginTop: '5px'}}
                            onClick={() => {dom.genOrderPay(modalContent)}}
                    >
                        <Tran intlKey="PRIVATE_PROCESS_PAY_CONFIRM"/>
                    </button>
                    <div className={"private-modal-win-tip"}>
                        <Tran intlKey="PRIVATE_FINISH_TIP"/>
                        <div style={{width: '5px'}}/>
                        <div style={{color: 'blue', cursor: 'pointer'}}
                             onClick={() => {
                                 const w = window.open('about:blank');
                                 w.location.href = '/service'}
                             }
                        >
                            <Tran intlKey="SERVICE"/>
                        </div>
                    </div>
                </div>
                <button className={"private-modal-win-close"}
                        style={{top: 'calc(50% - 310px)'}}
                        onClick={() => {dom.closeModal()}}
                >
                    <img src={CLOSE} alt={"clo"}/>
                </button>
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <div className={"mobile-private-modal-win-bg"} id={"pageHeader"}>
                    <img style={{width: '100%', height: '100%'}} src={PUR_M} alt={"bg"}/>
                </div>
                <div className={"mobile-private-modal-win"}>
                    <div className={"mobile-private-modal-win-title"}>
                        <Tran intlKey={"PRIVATE_PROCESS_PAY"}/>
                    </div>
                    <div className={"mobile-private-modal-win-container"}>
                        <div className={"mobile-private-modal-win-container-title"}>
                            {"01/  "}<Tran intlKey={"PRIVATE_PROCESS_INFO_CONFIRM"}/>
                        </div>
                        <div className={"mobile-private-modal-win-container-content"}>
                            <div className={"mobile-private-modal-win-container-text"}>
                                <div style={{color: '#777777'}}>
                                    <Tran intlKey={"PRIVATE_UNIT"}/>{"："}
                                </div>
                                {coreInfo.get("CZH_PRICE")["itemValue"]}{"（USDT）"}
                            </div>
                            <div className={"mobile-private-modal-win-container-text"}>
                                <div style={{color: '#777777'}}>
                                    <Tran intlKey={"PRIVATE_NUM"}/>{"："}
                                </div>
                                {formatCurrency(modalContent.pAmount)}{"（EHZ）"}
                            </div>
                            <div className={"mobile-private-modal-win-container-text"}>
                                <div style={{color: '#777777'}}>
                                    <Tran intlKey={"PRIVATE_PAY"}/>{"："}
                                </div>
                                {formatCurrency(modalContent.usdtPrice)}{"（USDT）"}
                            </div>
                        </div>
                    </div>
                    <div className={"mobile-private-modal-win-container"}>
                        <div className={"mobile-private-modal-win-container-title"}>
                            {"02/  "}<Tran intlKey={"PRIVATE_PROCESS_USDT_CONFIRM"}/>
                        </div>
                        <div className={"mobile-private-modal-win-container-content"} style={{height: '280px'}}>
                            <div className={"mobile-private-modal-win-container-sub-title"}>
                                <div className={qrStatus === 1 ? "mobile-private-modal-win-qr-selected" : "mobile-private-modal-win-qr"}
                                     onClick={() => {dom.changeQRStatus(1)}}
                                >
                                    {"TRC20"}
                                </div>
                                <div className={qrStatus === 2 ? "mobile-private-modal-win-qr-selected" : "mobile-private-modal-win-qr"}
                                     onClick={() => {dom.changeQRStatus(2)}}
                                >
                                    {"ERC20"}
                                </div>
                            </div>
                            <div className={"private-modal-win-qr-content"}>
                                <div className={"private-modal-win-qr-content-text"} style={{textAlign: 'left', paddingLeft: '10px', overflow: 'hidden'}}>
                                    {qrStatus === 1 ? trc_link : erc_link}
                                </div>
                                <textarea className={"rewards-layout-my-code-ele-content-input"}
                                          id={"qrLink"}
                                          value={qrStatus === 1 ? trc_link : erc_link}
                                          // readOnly={true}
                                />
                                <div className={"private-modal-win-qr-content-copy"}
                                        onClick={() => {copyFun("qrLink")}}>
                                    <Tran intlKey={"COPY"}/>
                                </div>
                            </div>
                            <div className={"private-modal-win-container-text"} style={{textAlign: 'center', fontSize: '12px'}}>
                                {qrStatus === 1 ? "TRC20" : "ERC20"}<Tran intlKey={"PURCHASE_QR_ADDRESS"}/>
                            </div>
                            <div className={"private-modal-win-container-qr"}>
                                <img style={{width: '100%', height: '100%'}}
                                     src={qrStatus === 1 ? webHeader + coreInfo.get("USDT_QR_T")["itemValue"] : webHeader + coreInfo.get("USDT_QR")["itemValue"]}
                                     alt={"qr"}/>
                            </div>
                            <div style={{height: '5px'}}/>
                        </div>
                    </div>
                    <button className={"mobile-function-button"}
                            style={{margin: '10px auto', width: 'calc(100% - 40px)'}}
                            onClick={() => {dom.genOrderPay(modalContent)}}
                    >
                        <Tran intlKey="PRIVATE_PROCESS_PAY_CONFIRM"/>
                    </button>
                    <div className={"mobile-private-modal-win-tip"}>
                        <Tran intlKey="PRIVATE_PAY_TIP"/>
                        <div style={{color: 'blue'}}
                             onClick={() => {
                                 const w = window.open('about:blank');
                                 w.location.href = '/service'
                             }}
                        >
                            <Tran intlKey="SERVICE"/>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

// fail module
const failModal = (modalContent, mediaServiceResource, dom) => {
    return (
        <Fragment>
            <div className={"private-modal-win"} style={{padding: '10px 0'}}>
                <div className={"private-modal-win-icon"}>
                    <img className={"private-modal-win-icon-img"} src={Fail} alt={"fail"}/>
                </div>
                <div className={"private-modal-win-title"}>
                    <Tran intlKey={"PRIVATE_FAIL"}/>
                </div>
                <div className={"private-modal-win-reason"}>
                    <Tran intlKey={"PRIVATE_FAIL_REASON_NOTE"}/>
                    {modalContent}
                </div>
                <div className={"private-modal-win-tip"}>
                    <Tran intlKey="PRIVATE_FINISH_TIP"/>
                    <div style={{color: 'blue', cursor:' pointer'}} onClick={() => window.location.href = '/service'}>
                        <Tran intlKey="SERVICE"/>
                    </div>
                </div>
                <div className={"private-modal-win-service"}>
                    <div className={"private-modal-win-service-text"}>
                        {intl.get("CONTACT_QQ")}{"："}
                        <div style={{color: '#727682', fontWeight: 'bold'}}>
                            {mediaServiceResource.get("QQ-SERVICE")["itemContent"]}
                        </div>
                    </div>
                    <div className={"private-modal-win-service-text"}>
                        {intl.get("CONTACT_TELEGRAM")}{"："}
                        <div style={{color: '#727682', fontWeight: 'bold'}}>
                            {mediaServiceResource.get("TELEGRAM-SERVICE")["itemContent"]}
                        </div>
                    </div>
                    <div className={"private-modal-win-service-text"}>
                        {intl.get("CONTACT_EMAIL")}{"："}
                        <div style={{color: '#727682', fontWeight: 'bold'}}>
                            {mediaServiceResource.get("WECHAT-SERVICE")["itemContent"]}
                        </div>
                    </div>
                    <div className={"private-modal-win-service-text"}>
                        {intl.get("CONTACT_EMAIL")}{"："}
                        <div style={{color: '#727682', fontWeight: 'bold'}}>
                            {mediaServiceResource.get("EMAIL-SERVICE")["itemContent"]}
                        </div>
                    </div>
                </div>
                <button className={"private-modal-win-button"} onClick={() => window.location.href = '/private'}>
                    <Tran intlKey={"PRIVATE_REPURCHASE"}/>
                </button>
            </div>
            <button className={"private-modal-win-close"}
                    onClick={() => {dom.closeModal()}}
            >
                <img src={CLOSE} alt={"clo"}/>
            </button>
        </Fragment>
    )
}

// check module
const genStepPost = (dom, result, type, status, pojo, loginPojo, userInfo) => {
    if (result === connectionError) {
        fail(findMsgByError(connectionError));
    } else {
        if (result) {
            if (status === type) {
                loginPojo["userName"] = pojo.userName;
                loginPojo["step"] = 2;
                loginPojo["useUserName"] = true;
                dom.setState({
                        loginPojo,
                    },
                    () => {
                        let loginPojoT = genPojo(userLoginUrl, loginPojo);
                        console.log("用户登录传参：", loginPojoT)
                        if (loginPojoT) {
                            sendPostRequest(loginPojoT).then(res => {
                                console.log(res);
                                userInfo["userName"] = res[data].userName;
                                userInfo["uId"] = res[data].uId;
                                userInfo["inviterName"] = res[data].inviterName;
                                console.log(userInfo);
                                saveSession("userInfo", userInfo)
                                dom.setState({
                                    userPojo: res[data],
                                })
                                success(findMsgBySuccess("LOGIN_SUCCESS"), "/overview");
                            })
                        }
                    })
            } else {
                register(dom, pojo, loginPojo, userInfo);
            }
        } else {
            fail(findMsgByError("VER_CODE_ERROR"))
        }
    }
}

const checkPost = (dom, code, type, status, pojo, loginPojo, userInfo) => {
    if (status === type && pojo.vGo) {
        sendGetRequest(goValid, {
            key: pojo.goKey,
            goPwd: code,
        }).then(result => {
            genStepPost(dom, result, type, status, pojo, loginPojo, userInfo)
        })
    } else {
        let sendType = status === type ? (pojo.vPhone ? phoneCodeType : emailCodeType) : (pojo["regCode"] === 1 ? phoneCodeType : emailCodeType);
        sendGetRequest(checkSecCode, {
            secCode: code,
            type: sendType,
        }).then(result => {
            genStepPost(dom, result, type, status, pojo, loginPojo, userInfo)
        })
    }
}

const changeLoginWay = (loginPojo, dom) => {
    let useUserName = loginPojo["useUserName"]
    loginPojo["useUserName"] = !useUserName;
    loginPojo["userName"] = "";
    loginPojo["userPwd"] = "";
    dom.setState({
        loginPojo,
        submitStatus: 0,
    })
}

export {
    loadingRegisterProcess,
    payModal,
    failModal,
    genStepPost,
    checkPost,
    changeLoginWay
}
