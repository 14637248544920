import React, { Component, Fragment } from "react";
import 'antd/dist/antd.css';
import PageRoutes from "../../PageRoutes";

class MenuMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    render() {
        let Menus = () => {
            return (
                <Fragment>
                    <PageRoutes/>
                </Fragment>
            )
        }

        return (
            <Menus/>
        )
    }
}

export default MenuMgr;
