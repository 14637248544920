import np from "number-precision";

const formatCurrency = (num) => {
    if (num && num !== 0) {
        num = num.toString().replace(',','');
        for (let i = 0; i < Math.floor((num.length-(1+i))/3); i++){
            // 货币，三位数字一间隔
            num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
        }
        return (num);
    } else {
        return num;
    }
}

const numToPercent = (a, b) => {
    // let numF1 = num1.replace(',' , '');
    // let numF2 = num2.replace(',' , '');
    let ttl = np.plus(a, b);
    return {
        ttl: ttl,
        perA: np.round(np.divide(a, np.plus(a, b)) * 100, 2) + '%',
        perB: np.round(np.divide(b, np.plus(a, b)) * 100, 2) + '%',
    };
}

export {
    formatCurrency,
    numToPercent,
}
