import React, {Component} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import MenuMgr from "./pages/menu/MenuMgr";
import './index.css';
import intl from "react-intl-universal";
import EN from "./locales/en-US";
import CN from "./locales/zh-CN";

const locales = {
    "en": EN,
    "cn": CN,
};

class App extends Component{
    constructor(props) {
        super(props);
        this.state = {
            initDone: false,
            urlLangDone: false,
        }
    };

    loadLocales = () => {
        intl.init({
            currentLocale: localStorage.getItem('lang_type') || 'cn',
            locales,
        })
            .then(() => {
                this.setState({initDone: true});
            });
    };

    initLang = () => {
        let url = window.location.href;
        let lang = localStorage.getItem('lang_type');
        let params = url.split("://");
        let urlHeader = params[0];
        let ct = params[1];
        let hasLang = false;
        let ctLength = ct.split("/").length;
        let ctOne = ct.split("/")[1];
        if (ctOne === "") {
            this.loadLocales();
            return;
        }
        let param = ct.split("/")[2];
        let code = lang;
        if (ctOne === "cn" || ctOne === "en") {
            hasLang = true;
        }
        if (ctLength === 2 && !ctOne) {
            hasLang = true;
            let toUrl = urlHeader + "://" + ct.split("/")[0] + "/" + code;
            console.log("当前页面url为：", toUrl)
            window.location.href = toUrl;
            // return;
        }
        if (lang && !hasLang) {
            if (ctLength === 2 && ctOne) {
                let toUrl = urlHeader + "://" + ct.split("/")[0] + "/" + (ctOne === code ? "" : code + "/") + ctOne;
                console.log("当前页面url为：", toUrl)
                window.location.href = toUrl;
            }
            if (ctLength === 3 && ctOne && param) {
                let toUrl = urlHeader + "://" + ct.split("/")[0] + "/" + (ctOne === code ? "" : code + "/") + ctOne + "/"  + param;
                console.log("当前页面url为：", toUrl)
                window.location.href = toUrl;
            }
        }
        let urlKeys = url.split("://")[1].split("/");
        console.log(urlKeys)
        let defaultL = urlKeys.length < 2 ? 'cn' : urlKeys[1];
        this.switchLang(defaultL);
        this.setState({
            urlLangDone: true,
        }, this.loadLocales)
    }

    switchLang = (lang) => {
        switch (lang) {
            case "cn": {
               return  localStorage.setItem('lang_type', 'cn');
            }
            case "en": {
                return  localStorage.setItem('lang_type', 'en');
            }
            default: {
                return;
            }
        }
    }


    componentDidMount() {
        this.initLang();
    }

    render() {
        let initDone = this.state.initDone;
        return (
            initDone ? <div className={"layout"}>
                <Router>
                    <MenuMgr/>
                </Router>
            </div> : null
        )
    }
}

export default App;
