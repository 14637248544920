import {
    emailValid,
    goInitValid,
    goValid,
    phoneValid,
    sendGetRequest,
    serviceEmailTemp,
    servicePhoneInterTemp,
    servicePhoneTemp
} from "./RequestUrlMgr";
import {connectionError, fail, findMsgByError} from "./ErrorMgr";

const sendPhoneValid = (pojo, country) => {
    let tempId = country + "" === "86" ? servicePhoneTemp : servicePhoneInterTemp;
    console.log(tempId)
    return sendGetRequest(phoneValid, {
        phone: pojo,
        code: country,
        tempId: tempId,
    }).then(result => {
        console.log("手机验证结果：", result)
        if (result === connectionError) {
            fail(findMsgByError(connectionError));
            return false;
        } else {
            return result;
        }
    })
}

const sendEmailValid = (pojo, lang) => {
    let subject = lang === 'en' ?  'EHZ' : '财经贝 EHZ';
    return sendGetRequest(emailValid, {
        email: pojo,
        tempId: lang,
        subject: subject
    }).then(result => {
        console.log("邮箱验证结果：", result)
        if (result === connectionError) {
            fail(findMsgByError(connectionError));
            return false;
        } else {
            return result;
        }
    })
}

const sendGoInitValid = (pojo) => {
    return sendGetRequest(goInitValid, {
        userName: pojo.userName,
    }).then(result => {
        console.log("谷歌验证结果：", result)
        if (result === connectionError) {
            fail(findMsgByError(connectionError));
            return false;
        } else {
            return result;
        }
    })
}

const sendGoValid = (key, goPwd) => {
    if (key && goPwd) {
        console.log("谷歌二次验证传参：", key, goPwd)
        return sendGetRequest(goValid, {
            key: key,
            goPwd: goPwd,
        }).then(result => {
            console.log("谷歌二次验证结果：", result)
            if (result === connectionError) {
                fail(findMsgByError(connectionError));
                return false;
            } else {
                return result;
            }
        })
    }
}

export {
    sendPhoneValid,
    sendEmailValid,
    sendGoInitValid,
    sendGoValid,
}
