import {message} from "antd";
import intl from "react-intl-universal";

const connectionError = "CT_ERROR";

const fail = (failInfo) => {
    message
        .error({content: failInfo, duration: 2, style: {marginTop: "10vh"}})
        .then(() => {})
}

const failDur = (failInfo, duration) => {
    message
        .error({content: failInfo, duration: duration, style: {marginTop: "10vh"}})
        .then(() => {})
}

const failReturn = (failInfo, url) => {
    message
        .error({content: failInfo, duration: 2, style: {marginTop: "10vh"}})
        .then(() => {
        window.location.href = url
    })
}

const success = (successInfo, url) => {
    message
        .success({content: successInfo, duration: 2, style: {marginTop: "10vh"}})
        .then(() => {
            if (url) {
                window.location.href = url
            }
    })
}

const findMsgByError = (error) => {
    switch (error) {
        case connectionError: {
            return intl.get("CONNECTION_ERROR");
        }
        case "SYSTEM_ERROR": {
            return intl.get("CONNECTION_ERROR");
        }
        case "INPUT_ERROR": {
            return intl.get("INPUT_ERROR");
        }
        case "USER_REPEAT": {
            return intl.get("USER_REPEAT");
        }
        case "PHONE_REPEAT": {
            return intl.get("PHONE_REPEAT");
        }
        case "EMAIL_REPEAT": {
            return intl.get("EMAIL_REPEAT");
        }
        case "NOT_MATCH_ERROR": {
            return intl.get("NOT_MATCH_ERROR");
        }
        case "WRONG_PASS_ERROR": {
            return intl.get("WRONG_PASS_ERROR");
        }
        case "TIME_OUT": {
            return intl.get("TIME_OUT");
        }
        case "LOGIN_FIRST": {
            return intl.get("LOGIN_FIRST");
        }
        case "VALID_ERROR": {
            return intl.get("VALID_ERROR");
        }
        case "INVITER_ERROR": {
            return intl.get("INVITER_ERROR");
        }
        case "VER_CODE_ERROR": {
            return intl.get("VER_CODE_ERROR");
        }
        case "VER_SEND_ERROR": {
            return intl.get("VER_SEND_ERROR");
        }
        case "VER_OPEN_ERROR": {
            return intl.get("VER_OPEN_ERROR");
        }
        case "VER_CLOSE_ERROR": {
            return intl.get("VER_CLOSE_ERROR");
        }
        case "DATA_LOAD_ERROR": {
            return intl.get("DATA_LOAD_ERROR");
        }
        case "COPY_FAIL_ERROR": {
            return intl.get("COPY_FAIL_ERROR");
        }
        case "LOGIN_FIRST_ERROR": {
            return intl.get("LOGIN_FIRST_ERROR");
        }
        case "ACCOUNT_VP_UN": {
            return intl.get("ACCOUNT_VP_UN");
        }
        case "ACCOUNT_VE_UN": {
            return intl.get("ACCOUNT_VE_UN");
        }
        case "VER_GO_CODE_ERROR": {
            return intl.get("VER_GO_CODE_ERROR");
        }
        case "VER_CODE_OLD_ERROR": {
            return intl.get("VER_CODE_OLD_ERROR");
        }
        case "VER_CODE_EMPTY": {
            return intl.get("VER_CODE_EMPTY");
        }
        case "OLD_USER_BY_PHONE": {
            return intl.get("OLD_USER_BY_PHONE");
        }
        default: {
            return intl.get("DEFAULT_ERROR");
        }
    }
}

const findMsgBySuccess = (success) => {
    switch (success) {
        case "LOGIN_SUCCESS": {
            return intl.get("LOGIN_SUCCESS");
        }
        case "REGISTER_SUCCESS": {
            return intl.get("REGISTER_SUCCESS");
        }
        case "COPY_SUCCESS": {
            return intl.get("COPY_SUCCESS");
        }
        case "PWD_MODIFY_SUCCESS": {
            return intl.get("PWD_MODIFY_SUCCESS");
        }
        case "VER_SEND_SUCCESS": {
            return intl.get("VER_SEND_SUCCESS");
        }
        case "PRIVATE_SUCCESS": {
            return intl.get("PRIVATE_SUCCESS_TIP");
        }
        case "ACCOUNT_VG_SUCCESS": {
            return intl.get("ACCOUNT_VG_SUCCESS");
        }
        case "PE_MODIFY_SUCCESS": {
            return intl.get("PE_MODIFY_SUCCESS");
        }
        case "ACCOUNT_VERIFY_SUCCESS": {
            return intl.get("ACCOUNT_VERIFY_SUCCESS");
        }
        default: {
            return intl.get("DEFAULT_SUCCESS");
        }
    }
}

export {
    fail,
    failDur,
    success,
    failReturn,
    connectionError,
    findMsgByError,
    findMsgBySuccess
}
