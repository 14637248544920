import React, {Component, Fragment} from "react";
import "./about.css";
import {isMobile} from "../../utils/MobileMgr";
import Tran from "../../config/IntTranslation";
import WH from "../../assets/icon/icon_white_paper.svg";
import VI from "../../assets/icon/icon_value_introduction.svg";
import PageLayout from "../PageLayout";
import intl from "react-intl-universal";
import BANNER_M from "../../assets/img/about_banner_m.png";
import WEBSITE from "../../assets/img/about_website.png";
import Footer from "../footer/Footer";
import {changeType, getMedia} from "../../utils/Request";
import LoadingWin from "../component/LoadingWin";
import MenuSelector from "../menu/MenuSelector";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            type: 'home',
            language: this.props.match.params.lang,
            mediaResource: new Map(),
            isMediaLoad: false,
            isOpen: false,
            visible: true,
            displayStatus: true,
        }
    }

    setCloseMenu = () => {
        let dis = this.state.displayStatus;
        this.setState({
            displayStatus: !dis,
        })
    }

    setDisplay = () => {
        console.log(121212)
        let status = this.state.displayStatus;
        this.setState({
            displayStatus: !status,
        })
    }

    loadMenu = (visible, isMobile) => {
        if (visible) {
            return null;
        }
        return (
            <MenuSelector isMobile={isMobile} isLogin={true} dom={this}/>
        )
    }

    loadingPageContent = (isMediaLoad, mediaResource, isMobile, type) => {
        if (!isMobile) {
            return (
                isMediaLoad ?
                <Fragment>
                    <div className={"about-layout-main"}>
                        <div className={"about-layout-brief"}>
                            {this.loadingAbout(mediaResource, isMobile)}
                        </div>
                    </div>
                    <div className={"about-layout-msg"}>
                        <div className={"about-layout-download"}>
                            <div className={"about-layout-download-title"}>
                                <Tran intlKey={"CZH_TTL"}/>
                            </div>
                            <div className={"about-layout-download-wh"}>
                                <img src={WH} alt={"wh"}/>
                                <div className={"about-layout-download-wh-text"}
                                     onClick={() => {
                                         const w = window.open('about:blank');
                                         w.location.href = mediaResource.get("LINK-ONE-LINK")["itemContent"]}
                                     }
                                >
                                    {mediaResource.get("LINK-ONE-NAME")["itemContent"]}
                                </div>
                            </div>
                            {/*<div className={"about-layout-download-vi"}>*/}
                            {/*    <img src={VI} alt={"wh"}/>*/}
                            {/*    <div className={"about-layout-download-vi-text"}*/}
                            {/*         onClick={() => {*/}
                            {/*             const w = window.open('about:blank');*/}
                            {/*             w.location.href = mediaResource.get("LINK-TWO-LINK")["itemContent"]}*/}
                            {/*         }*/}
                            {/*    >*/}
                            {/*        {mediaResource.get("LINK-TWO-NAME")["itemContent"]}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className={"about-layout-website"}>
                            <div className={"about-layout-website-title"}>
                                <Tran intlKey={"WEBSITE"}/>
                            </div>
                            <div className={"about-layout-website-text"}>
                                <Tran intlKey={"WEBSITE_SHARE"}/>
                            </div>
                            <button className={"about-layout-website-button"}
                                    onClick={() => {
                                        const w = window.open('about:blank');
                                        w.location.href = 'https://www.cjz.vip'}
                                    }
                            >
                                <Tran intlKey={"WEBSITE_ENTRY"}/>
                            </button>
                        </div>
                    </div>
                </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
            )
        } else {
            let visible = this.state.displayStatus;
            if (type === "policy") {
                return (
                    <Fragment>
                        <div style={{height: '80px'}} id={"pageHeader"}/>
                        <div className={"mobile-policy-title"}>
                            <Tran intlKey={"PRIVATE_POLICY_TITLE"}/>
                        </div>
                        <div className={"mobile-policy-content"}>
                            {mediaResource.get("POLICY-ONE")["itemContent"]}
                            <br/>
                            <br/>
                            {mediaResource.get("POLICY-TWO")["itemContent"]}
                            <br/>
                            <br/>
                            {mediaResource.get("POLICY-THREE")["itemContent"]}
                            <br/>
                            <br/>
                            {mediaResource.get("POLICY-FOUR")["itemContent"]}
                        </div>
                        <button className={"mobile-qr-button"}
                                onClick={() => changeType("home", this)}
                        >
                            <Tran intlKey={"FACE_TO_FACE_GET"}/>
                        </button>
                        <div style={{height: '40px'}}/>
                    </Fragment>
                )
            } else {
                return (
                    isMediaLoad ?
                        <Fragment>
                            <div style={{height: '80px'}} id={"pageHeader"}/>
                            <Fragment>
                                {this.loadMenu(visible, isMobile)}
                                <div style={{display: visible ? '' : 'none'}}>
                                    <div style={{backgroundColor: '#F1F3F8'}}>
                                        <div className={"mobile-about-banner"}>
                                            <img className={"mobile-img"} src={BANNER_M} alt={"banner"}/>
                                        </div>
                                        <div className={"mobile-about-wrap"}>
                                            {this.loadingAbout(mediaResource, isMobile)}
                                        </div>
                                        <div className={"mobile-about-button"}
                                             onClick={() => {
                                                 const w = window.open('about:blank');
                                                 w.location.href = mediaResource.get("LINK-ONE-LINK")["itemContent"]
                                             }
                                             }
                                        >
                                            {mediaResource.get("LINK-ONE-NAME")["itemContent"]}
                                        </div>
                                        {/*<div className={"mobile-about-button"} style={{backgroundColor: '#1F3865'}}*/}
                                        {/*     onClick={() => {*/}
                                        {/*         const w = window.open('about:blank');*/}
                                        {/*         w.location.href = mediaResource.get("LINK-TWO-LINK")["itemContent"]*/}
                                        {/*     }}*/}
                                        {/*>*/}
                                        {/*    {mediaResource.get("LINK-TWO-NAME")["itemContent"]}*/}
                                        {/*</div>*/}
                                        <Footer isMobile={isMobile} dom={this}/>
                                    </div>
                                </div>
                            </Fragment>
                        </Fragment>: <LoadingWin parent={this} isMobile={isMobile}/>
                )
            }
        }
    }

    handleEmpty = (mediaResource, title) => {
        return mediaResource.get(title)["itemContent"] === "";
    }

    loadingAbout = (mediaResource, isMobile) => {
        if (!isMobile) {
            return (
                <Fragment>
                    <div className={"about-item-container"}>
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-DES")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}>
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-ONE")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-ONE")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}>
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-TWO")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-TWO")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}>
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-THREE")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-THREE")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}>
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-FOUR")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-FOUR")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}>
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-FIVE")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-FIVE")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}
                         style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SIX") ? '0' : null, height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SIX") ? '0' : null}}
                    >
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-SIX")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-SIX")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}
                         style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SEVEN") ? '0' : null, height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SEVEN") ? '0' : null}}
                    >
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-SEVEN")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-SEVEN")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}
                         style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-EIGHT") ? '0' : null, height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-EIGHT") ? '0' : null}}
                    >
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-EIGHT")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-EIGHT")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}
                         style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-NINE") ? '0' : null, height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-NINE") ? '0' : null}}
                    >
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-NINE")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-NINE")["itemContent"]}
                        </div>
                    </div>
                    <div className={"about-item-container"}
                        style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-TEN") ? '0' : null, height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-TEN") ? '0' : null}}
                    >
                        <div className={"about-item-title"}>
                            {mediaResource.get("CZH-INTRO-TITLE-TEN")["itemContent"]}
                        </div>
                        <div className={"about-item-content"}>
                            {mediaResource.get("CZH-INTRO-CONTENT-TEN")["itemContent"]}
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className={"mobile-about-item-title"}>
                        {mediaResource.get("CZH-INTRO-TITLE")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}>
                        {mediaResource.get("CZH-INTRO-DES")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"} style={{color: 'black'}}>
                        {mediaResource.get("CZH-INTRO-TITLE-ONE")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}>
                        {mediaResource.get("CZH-INTRO-CONTENT-ONE")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"} style={{color: 'black'}}>
                        {mediaResource.get("CZH-INTRO-TITLE-TWO")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}>
                        {mediaResource.get("CZH-INTRO-CONTENT-TWO")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"} style={{color: 'black'}}>
                        {mediaResource.get("CZH-INTRO-TITLE-THREE")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}>
                        {mediaResource.get("CZH-INTRO-CONTENT-THREE")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"} style={{color: 'black'}}>
                        {mediaResource.get("CZH-INTRO-TITLE-FOUR")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}>
                        {mediaResource.get("CZH-INTRO-CONTENT-FOUR")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"} style={{color: 'black'}}>
                        {mediaResource.get("CZH-INTRO-TITLE-FIVE")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}>
                        {mediaResource.get("CZH-INTRO-CONTENT-FIVE")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"}
                         style={{color: 'black', margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SIX") ? '0 auto' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SIX") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-TITLE-SIX")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}
                         style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SIX") ? '0' : null,
                             padding: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SIX") ? '0' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SIX") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-CONTENT-SIX")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"}
                         style={{color: 'black', margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SEVEN") ? '0 auto' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SEVEN") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-TITLE-SEVEN")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}
                         style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SEVEN") ? '0' : null,
                             padding: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SEVEN") ? '0' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-SEVEN") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-CONTENT-SEVEN")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"}
                         style={{color: 'black', margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-EIGHT") ? '0 auto' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-EIGHT") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-TITLE-EIGHT")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}
                         style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-EIGHT") ? '0' : null,
                             padding: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-EIGHT") ? '0' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-EIGHT") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-CONTENT-EIGHT")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"}
                         style={{color: 'black', margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-NINE") ? '0 auto' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-NINE") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-TITLE-NINE")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}
                         style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-NINE") ? '0' : null,
                             padding: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-NINE") ? '0' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-NINE") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-CONTENT-NINE")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item-title"}
                         style={{color: 'black', margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-TEN") ? '0 auto' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-TEN") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-TITLE-TEN")["itemContent"]}
                    </div>
                    <div className={"mobile-about-item"}
                         style={{margin: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-TEN") ? '0' : null,
                             padding: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-TEN") ? '0' : null,
                             height: this.handleEmpty(mediaResource, "CZH-INTRO-TITLE-TEN") ? '0' : null}}
                    >
                        {mediaResource.get("CZH-INTRO-CONTENT-TEN")["itemContent"]}
                    </div>
                </Fragment>
            )
        }
    }

    componentDidMount() {
        getMedia(intl, "ABOUT", this);
    }

    render() {
        let isMobile = this.state.isMobile;
        let type = this.state.type;
        let mediaResource = this.state.mediaResource;
        let isMediaLoad = this.state.isMediaLoad;

        return (
            <PageLayout parent={this} pNameCN={intl.get("ABOUT")} pNameEN={intl.get("ABOUT_EN")}
                        needUser={false}
                        dom = {this}
                        pContent={this.loadingPageContent(isMediaLoad, mediaResource, isMobile, type)}
            />
        )
    }
}

export default About;
