import axios from "axios";
import { connectionError } from "./ErrorMgr";

const webHeader = "https://h.cjz.vip";

// const urlHeader = "http://localhost:8081/web";

const urlHeader = "/user/web";

/* 获取国家手机代码 */
const getCountryCodeUrl = urlHeader + "/getCountryCode";

/* 用户注册 */
const newUserUrl = urlHeader + "/userRegister";

/* 手机验证码 */
const phoneValid = urlHeader + "/sendPhoneSec";

/* 邮箱验证码 */
const emailValid = urlHeader + "/sendEmailSec";

/* 谷歌验证相关 */
/* 获取谷歌验证初始化信息 */
const goInitValid = urlHeader + "/initGoogleSec";
/* 校验谷歌验证码 */
const goValid = urlHeader + "/checkGoogle";

/* 验证码校验 */
const checkSecCode = urlHeader + "/checkSecCode";

/* 验证码校验手机port */
const servicePhoneTemp = "1316066";

const servicePhoneInterTemp = "1317741";

/* 验证码校验邮箱port */
const serviceEmailTemp = '20049';

/* 验证码校验手机type */
const phoneCodeType = "phCode";

/* 验证码校验邮箱type */
const emailCodeType = "emCode";

/* 用户登录 */
const userLoginUrl = urlHeader + "/userLogin";

const data = "responseData";

const error = "errorType";

/* 用户设置 */
/* 用户设置api1 */
const userSettingUrl = urlHeader + "/userSetting";
/* 用户设置api2 */
const userSettingConfirmUrl = urlHeader + "/userSettingConfirm";

/* 获取uId */
const findUidByNameUrl = urlHeader + "/findUidByName";

/* 修改密码code */
const modifyPwdCode = "asdefnasef111%99eesbzdfedsa!#$";

/* 用户信息更新 */
const findUserInfoUrl = urlHeader + "/findUserInfo";

/* 用户奖励信息相关 */
const findUserAwardsUrl = urlHeader + "/findUserAwards";

/* 用户订单记录列表 */
const findUserOrdersUrl = urlHeader + "/findUserOrders";

/* 用户提交订单 */
const processOrderUrl = urlHeader + "/processOrder";

/* 获取CZH相关基本信息 */
/* Step 1 */
const findCoreInfosUrl = urlHeader + "/findCoreInfos";

const findCoreInfoSec = "44934da08d22db5f339eb5bdd711b83f83ed0d3c";

/* Step 2 */
const findCoreCallBackInfosUrl = urlHeader + "/findCoreCallBackInfos";

/* CORE_ERROR */
const handleErrorUrl = urlHeader + "/handleError";

/* 获取静态资源 */
const getMediaUrl = urlHeader + "/mediaByPageAndLang";

async function sendPostRequest(pojo) {
    axios.defaults.withCredentials = true;
    return await axios.post(pojo.url, JSON.stringify(pojo.data)).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error);
        return connectionError;
    })
}

async function sendGetRequest(url, params) {
    axios.defaults.withCredentials = true;
    return await axios.get(url, {
        params: params
    }).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error);
        return connectionError;
    })
}

const genPojo = (url, data) => {
    return {
        url: url,
        data: data,
    }
}

export {
    webHeader,
    getCountryCodeUrl,
    newUserUrl,
    phoneValid,
    emailValid,
    goInitValid,
    goValid,
    checkSecCode,
    servicePhoneTemp,
    servicePhoneInterTemp,
    serviceEmailTemp,
    phoneCodeType,
    emailCodeType,
    userLoginUrl,
    data,
    error,
    userSettingUrl,
    userSettingConfirmUrl,
    findUidByNameUrl,
    modifyPwdCode,
    findUserInfoUrl,
    findUserAwardsUrl,
    findUserOrdersUrl,
    processOrderUrl,
    findCoreInfosUrl,
    findCoreInfoSec,
    findCoreCallBackInfosUrl,
    handleErrorUrl,
    getMediaUrl,
    sendPostRequest,
    sendGetRequest,
    genPojo,
}

