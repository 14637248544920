import React, {Fragment} from 'react';
import './index.css'
import {Input} from "antd";
import Tran from "../../config/IntTranslation";
import intl from "react-intl-universal";
import {MailOutlined, MobileOutlined, SecurityScanOutlined} from "@ant-design/icons";
import {handleCountDown} from "../../utils/Request";
import {fail, findMsgByError, findMsgBySuccess, success} from "../../utils/ErrorMgr";
import {sendEmailValid, sendPhoneValid} from "../../utils/Verfiy";
import CLOSE from "../../assets/icon/icon_close.svg";
import CLOSE_GRAY from "../../assets/icon/icon_close_gray.svg";
import {checkPost} from "./Common";

class ValidWin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            second: this.props.resend,
            secondL: this.props.resendL,
            secCode: "",
        }
    }

    handleSendButton = (resend, status, pojo, label) => {
        let lang = intl.get("LANG").toString() === "0" ? "zh" : "en";
        console.log("发送pojo: ", pojo)
        if (status === "login") {
            if (pojo.vGo) {
                console.log("vGo start")
            } else {
                if (pojo.vPhone) {
                    sendPhoneValid(pojo.userPhone, pojo.country).then(result => {
                        console.log("sendPhone：", result)
                        if (result) {
                            handleCountDown(resend, this);
                            success(findMsgBySuccess("VER_SEND_SUCCESS"))
                        } else {
                            fail(findMsgByError("VER_SEND_ERROR"))
                        }
                    })
                } else {
                    sendEmailValid(pojo.userEmail, lang).then(result => {
                        console.log("sendEmail：", result)
                        if (result) {
                            handleCountDown(resend, this);
                            success(findMsgBySuccess("VER_SEND_SUCCESS"))
                        } else {
                            fail(findMsgByError("VER_SEND_ERROR"))
                        }
                    })
                }
            }
        } else if (status === "register") {
            if (pojo["regCode"] === 1) {
                sendPhoneValid(pojo.userPhone, pojo.country).then(result => {
                    console.log("sendPhone：", result)
                    if (result) {
                        handleCountDown(resend, this);
                        success(findMsgBySuccess("VER_SEND_SUCCESS"))
                    } else {
                        fail(findMsgByError("VER_SEND_ERROR"))
                    }
                })
            } else {
                sendEmailValid(pojo.userEmail, lang).then(result => {
                    console.log("sendEmail：", result)
                    if (result) {
                        handleCountDown(resend, this);
                        success(findMsgBySuccess("VER_SEND_SUCCESS"))
                    } else {
                        fail(findMsgByError("VER_SEND_ERROR"))
                    }
                })
            }
        } else if (status === "vPhone") {
            sendPhoneValid(pojo.userPhone, pojo.country).then(result => {
                console.log("sendPhone：", result)
                if (result) {
                    handleCountDown(resend, this);
                    success(findMsgBySuccess("VER_SEND_SUCCESS"))
                } else {
                    fail(findMsgByError("VER_SEND_ERROR"))
                }
            })
        } else if (status === "vEmail") {
            sendEmailValid(pojo.userEmail, lang).then(result => {
                console.log("sendEmail：", result)
                if (result) {
                    handleCountDown(resend, this, label);
                    success(findMsgBySuccess("VER_SEND_SUCCESS"))
                } else {
                    fail(findMsgByError("VER_SEND_ERROR"))
                }
            })
        }
    }

    setCode(e) {
        let inputValue = e.target.value;
        this.setState({
            secCode: inputValue,
        })
    }

    render() {
        let status = this.props.status;
        let userInfo = this.props.userInfo;
        let registerPojo = this.props.registerPojo;
        let loginPojo = this.props.loginPojo;
        let loginInfo = this.props.loginInfo;
        let userPojo = this.props.userPojo;
        let setterPojo = this.props.setterPojo;

        let time = this.state.second;
        let resend = this.props.resend;
        let canSend = time === resend;

        let timeL = this.state.secondL;
        let resendL = this.props.resendL;
        let canSendL = timeL === resendL;

        if (status === "login") {
            return (
                <div className={"modal-bg-win"} style={{display: this.props.display ? "" : "none"}}>
                    <div className={"modal-go-win"}>
                        <div className={"modal-go-win-title"}>
                            {
                                loginInfo.vGo ?
                                    <div className={"modal-go-win-vgo-title"}>
                                        <Tran intlKey={"VGO_VER_TIP"}/>
                                        <Tran intlKey={"VGO_VER_PH"}/>
                                    </div>
                                    :
                                    <Tran intlKey={"REGISTER_VER_TITLE"}/>
                            }
                        </div>
                        {
                            loginInfo.vGo ?
                                <Fragment>
                                    <div style={{height: '30px'}}/>
                                    <div className={"modal-go-win-ver"}>
                                        <Input
                                            className={"modal-go-win-input"}
                                            size="middle" placeholder={intl.get("REGISTER_VER_TITLE")}
                                            prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                            maxLength={8}
                                            onChange={(e) => this.setCode(e)}
                                        />
                                    </div>
                                    <div style={{height: '50px'}}/>
                                </Fragment> :
                                <Fragment>
                                    <div className={"modal-win-send"}>
                                        <div style={{textAlign: 'center', color: '#666666'}}>
                                            <span>
                                                {intl.get("REGISTER_VER_SEND")}
                                                {" "}
                                                {loginInfo.vPhone ? intl.get("PHONE") : intl.get("EMAIL")}
                                                {"："}
                                            </span>
                                            <span style={{ color: '#236BF3'}}>
                                                {loginInfo.vPhone ? loginInfo.userPhone : loginInfo.userEmail}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={"modal-win-ver"}>
                                        <Input
                                            className={"modal-win-input"}
                                            size="middle"
                                            style={{width: '100%'}}
                                            placeholder={intl.get("REGISTER_VER_TITLE")}
                                            prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                            maxLength={8}
                                            onChange={(e) => this.setCode(e)}
                                        />
                                        {
                                            !loginInfo.vGo ?
                                                <div className={"modal-win-send-button"}
                                                     style={{position: 'relative', marginLeft: '5px', right: '0', height: '45px', border: '0', borderRadius: '5px', background: !canSend ? "#666666" : "#236BF3", color: 'white'}}
                                                     onClick={() => {canSend && this.handleSendButton(resend, status, loginInfo)}}
                                                >
                                                    {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                                </div> : null
                                        }
                                    </div>
                                </Fragment>

                        }
                        <button className={"login-go-modal-reg-button"}
                                onClick={() => (checkPost(this, this.state.secCode, "login", status, loginInfo, loginPojo, userInfo))}
                        >
                            <Tran intlKey={"LOGIN"}/>
                        </button>
                    </div>
                    <button className={"modal-go-win-close"}
                            style={{top: 'calc(50% - 140px)', left: 'calc(50% + 150px)'}}
                            onClick={() => {this.props.parent.closeModal()}}
                    >
                        <img className={"img-size"} src={CLOSE_GRAY} alt={"close"}/>
                    </button>
                </div>
            )
        }
        if (status === "register") {
            return (
                <div className={"modal-bg-win"} style={{display: this.props.display ? "" : "none"}}>
                    <div className={"modal-go-win"}>
                        <div className={"modal-win-title"}>
                            <Tran intlKey={"REGISTER_VER_TITLE"}/>
                        </div>
                        <div className={"modal-win-send"}>
                            <div>
                                <Tran intlKey={"REGISTER_VER_SEND"}/>
                                {" "}
                                {registerPojo["regCode"] === 1 ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                {"："}
                            </div>
                            <div style={{ color: '#236BF3'}}>
                                {registerPojo["regCode"] === 1 ? registerPojo["userPhone"] : registerPojo["userEmail"]}
                            </div>
                        </div>
                        <div className={"modal-win-ver"}>
                            <Input
                                className={"modal-win-input"}
                                size="middle"
                                style={{width: '100%'}}
                                placeholder={intl.get("REGISTER_VER_TITLE")}
                                prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                maxLength={8}
                                onChange={(e) => this.setCode(e)}
                            />
                            <div className={"modal-win-send-button"}
                                 style={{position: 'relative', marginLeft: '5px', right: '0', height: '45px', border: '0', borderRadius: '5px', background: !canSend ? "#666666" : "#236BF3", color: 'white'}}
                                 onClick={() => {canSend && this.handleSendButton(resend, status, registerPojo)}}
                            >
                                {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                            </div>
                        </div>
                        <button className={"login-modal-reg-button"}
                                onClick={() => (checkPost(this, this.state.secCode, "login", status, registerPojo, loginPojo, userInfo))}
                        >
                            <Tran intlKey={"REGISTER"}/>
                        </button>
                    </div>
                    <button className={"modal-go-win-close"}
                            style={{top: 'calc(50% - 140px)', left: 'calc(50% + 150px)'}}
                            onClick={() => {this.props.parent.closeModal()}}
                    >
                        <img className={"img-size"} src={CLOSE_GRAY} alt={"close"}/>
                    </button>
                </div>
            )
        }
        if (status === "vPhone" || status === "vEmail") {
            return (
                <div className={"modal-bg-win"} style={{display: this.props.display ? "" : "none"}}>
                    <div className={"modal-win"} style={{height: '350px'}}>
                        <div className={"modal-win-title"}>
                            {status === "vPhone" ? <Tran intlKey={"VPHONE_VER_PH"}/> : <Tran intlKey={"VEMAIL_VER_PH"}/>}
                        </div>
                        <div className={"modal-win-title"} style={{fontSize: '12px', color: '#666666'}}>
                            <Tran intlKey={"VER_TIP"}/>
                        </div>
                        <div className={"modal-win-ver"} style={{flexDirection: 'column', alignItems: 'flex-start', height: '80px'}}>
                            <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                {status === "vPhone" ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                            </div>
                            <Input
                                className={"modal-win-input"}
                                style={{width: '100%'}}
                                size="middle"
                                value={status === "vPhone" ? userPojo.userPhone : userPojo.userEmail}
                                prefix={status === "vPhone" ? <MobileOutlined style={{color: "grey"}}/> : <MailOutlined style={{color: "grey"}}/>}
                                disabled={"true"}
                            />
                        </div>
                        <div className={"modal-win-ver"}  style={{flexDirection: 'column', alignItems: 'flex-start', height: '80px'}}>
                            <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                <Tran intlKey={"VER_CODE"}/>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '80px'}}>
                                <Input
                                    className={"modal-win-input"}
                                    style={{width: '100%'}}
                                    size="middle" placeholder={intl.get("REGISTER_VER_TITLE")}
                                    prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                    maxLength={8}
                                    onChange={(e) => this.setCode(e)}
                                />
                                <div className={"modal-win-send-button"}
                                        style={{left: '250px', color: !canSend ? "grey" : "#236BF3"}}
                                        onClick={() => {canSend && this.handleSendButton(resend, status, userPojo)}}
                                >
                                    {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                </div>
                            </div>
                        </div>
                        <button className={"login-modal-reg-button"} style={{marginTop: '20px', height: '45px'}}
                                onClick={() => (this.props.parent.checkPost(this.state.secCode, status))}
                        >
                            <Tran intlKey={"CONFIRM"}/>
                        </button>
                    </div>
                    <button className={"modal-win-close"} onClick={() => {
                        this.props.parent.closeModal()
                    }}>
                        <img className={"img-size"} src={CLOSE} alt={"close"}/>
                    </button>
                </div>
            )
        }
        if (status === "mPwd") {
            return (
                <div className={"modal-bg-win"} style={{display: this.props.display ? "" : "none"}}>
                    <div className={"modal-go-win"}>
                        <div className={"modal-go-win-title"}>
                            <Tran intlKey={"ACCOUNT_VERIFY"}/>
                        </div>
                        {
                            userPojo.vGo &&
                            <div className={"modal-win-ver"}  style={{flexDirection: 'column', alignItems: 'flex-start', height: '80px'}}>
                                <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                    <Tran intlKey={"ACCOUNT_VG"}/>
                                </div>
                                <Input
                                    className={"modal-go-win-input"}
                                    size="middle"
                                    placeholder={intl.get("VGO_VER_PH")}
                                    prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                    maxLength={8}
                                    onChange={(e) => {
                                        this.props.parent.setCode(e, "goPwd")
                                    }}
                                />
                            </div>
                        }
                        {
                            userPojo.vGo && (userPojo.vEmail || userPojo.vPhone) &&
                            <div className={"modal-win-ver"}  style={{flexDirection: 'column', alignItems: 'flex-start', height: '80px'}}>
                                <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                    {userPojo.vEmail ? <Tran intlKey={"ACCOUNT_VE"}/> : <Tran intlKey={"ACCOUNT_VP"}/>}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '80px'}}>
                                    <Input
                                        className={"modal-win-input"}
                                        style={{width: '100%', height: '100%'}}
                                        size="middle"
                                        placeholder={userPojo.vEmail ? intl.get("VEMAIL_VER_PH") : intl.get("VPHONE_VER_PH")}
                                        prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                        maxLength={8}
                                        onChange={(e) => {
                                            this.props.parent.setCode(e, "secCode")
                                        }}
                                    />
                                    <div className={"modal-win-send-button"}
                                            style={{left: '275px', color: !canSend ? "grey" : "#236BF3"}}
                                            onClick={() => {canSend && this.handleSendButton(resend, userPojo.vEmail ? "email" : "phone", userPojo)}}
                                    >
                                        {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !userPojo.vGo && userPojo.vPhone &&
                            <div className={"modal-win-ver"}  style={{flexDirection: 'column', alignItems: 'flex-start', height: '80px'}}>
                                <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                    <Tran intlKey={"ACCOUNT_VP"}/>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '80px'}}>
                                    <Input
                                        className={"modal-win-input"}
                                        style={{width: '100%', height: '100%'}}
                                        size="middle"
                                        placeholder={intl.get("VPHONE_VER_PH")}
                                        prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                        maxLength={8}
                                        onChange={(e) => {
                                            this.props.parent.setCode(e, "secCode")
                                        }}
                                    />
                                    <div className={"modal-win-send-button"}
                                            style={{left: '275px', color: !canSend ? "grey" : "#236BF3"}}
                                            onClick={() => {canSend && this.handleSendButton(resend, "vPhone", userPojo)}}
                                    >
                                        {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !userPojo.vGo && userPojo.vEmail &&
                            <div className={"modal-win-ver"}  style={{flexDirection: 'column', alignItems: 'flex-start', height: '80px'}}>
                                <div style={{fontSize: '14px', color: '#666666', lineHeight: '40px'}}>
                                    <Tran intlKey={"ACCOUNT_VE"}/>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '80px'}}>
                                    <Input
                                        className={"modal-win-input"}
                                        style={{width: '100%', height: '100%'}}
                                        size="middle"
                                        placeholder={intl.get("VEMAIL_VER_PH")}
                                        prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                        maxLength={8}
                                        onChange={(e) => {
                                            this.props.parent.setCode(e, "secCodeL")
                                        }}
                                    />
                                    <div className={"modal-win-send-button"}
                                            style={{left: '275px', color: !canSendL ? "grey" : "#236BF3"}}
                                            onClick={() => {canSendL && this.handleSendButton(resendL, "vEmail", userPojo, "secCodeL")}}
                                    >
                                        {!canSendL ? "( " + timeL + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                    </div>
                                </div>
                            </div>
                        }
                        <button className={"login-go-modal-reg-button"}
                                onClick={() => (this.props.parent.checkPost(this.state.secCode, status, userPojo, setterPojo))}
                        >
                            <Tran intlKey={"CONFIRM"}/>
                        </button>
                        <button className={"modal-go-win-close"} onClick={() => {
                            this.props.parent.closeModal()
                        }}>
                            <img className={"img-size"} src={CLOSE} alt={"close"}/>
                        </button>
                    </div>
                </div>
            )
        }
    }
}

export default ValidWin;
