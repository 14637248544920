// eslint-disable-next-line import/no-anonymous-default-export
export default {
    LANG: 0,
    LANGUAGE: '语言',
    CHINESE: '简体中文',
    CHINESE_SIMPLE: '简体中文',
    ENGLISH: 'English',
    OVERVIEW: '总览',
    OVERVIEW_EN: 'Overview',
    ABOUT: 'EHZ介绍',
    ABOUT_EN: 'About EHZ',
    PRIVATE: '私募认购',
    PRIVATE_EN: 'Private placement',
    ASSETS: 'EHZ资产',
    ASSETS_EN: 'EHZ assets',
    REWARDS: '邀请奖励',
    REWARDS_EN: 'Invitation rewards',
    ACCOUNT_SETTING: '账户设置',
    ACCOUNT_SETTING_EN: 'Settings',
    SERVICE: '联系客服',
    SERVICE_EN: 'Contact support',
    CATALOGUE_CENTER: '目录中心',
    PROMOTION: '推广',

    /*documentTitle*/
    DT: '财经贝EHZ — 真正的价值币，私募认购中',

    /* 推广页 */
    /* 导航 */
    CZH: '财经贝',
    CZH_EN: 'EHZ',
    CZH_TTL: '财经贝EHZ',
    BASE: '财经贝-私募认购',
    PERSONAL_ENTRY: '进入私募认购个人中心',
    LOGIN: '登录',
    REGISTER: '注册',
    REGISTER_FREE: '免费注册',

    /* banner */
    SLOGAN: '财经贝EHZ—真正的价值币',
    SLOGAN_EN: 'Real value coin',
    PRIVATE_BUTTON: '财经贝EHZ，私募认购开启！',

    /* 私募认购流程 */
    PRIVATE_TABLE_TITLE: '私募认购',
    PRIVATE_TABLE_RULE_ONE: '私募认购单价：',
    PRIVATE_TABLE_RULE_TWO_UNIT: '万',
    PRIVATE_TABLE_RULE_TWO: '个EHZ起购',
    PRIVATE_TABLE_RULE_LINK: '私募认购规则',
    PRIVATE_TABLE_BUY: '认购数量',
    PRIVATE_TABLE_FRIEND_BUY: '好友认购数量',
    PRIVATE_TABLE_BUY_NUMBER: '个起购',
    PRIVATE_TABLE_PAY: '需支付',
    PURCHASE_PROCESS: '私募认购流程',
    PURCHASE_QR_ADDRESS: '二维码付款地址',
    PRIVATE_POLICY_TITLE: '私募认购规则',

    /* 邀请奖励详细政策 */
    REWARDS_POLICY_TITLE: '邀请奖励政策',

    /* 简介 */
    ABOUT_TITLE: '财经贝EHZ简介',
    ABOUT_TITLE_EN: 'Introduction to EHZ',

    /* 联系方式 */
    CONTACT_TITLE: '联系方式',
    CONTACT_TITLE_EN: 'contact information',
    CONTACT_WECHAT: '客服微信',
    CONTACT_QQ: '客服QQ',
    CONTACT_TELEGRAM: 'Telegram',
    CONTACT_EMAIL: '客服邮箱',

    /* footer */
    PERSONAL_CENTER: '个人中心',
    SUBSCRIBE_RULE: '认购规则',
    ABOUT_TITLE_SHORTHAND: 'EHZ介绍',
    WHITE_PAPER_SHORTHAND: '白皮书——详细介绍',
    VALUE_INTRODUCTION_SHORTHAND: '价值价格支撑介绍',
    COPYRIGHT: 'Copyright © 财经贝 版权所有 All Rights Reserved.',

    /* 登录 */
    ACCOUNT: '账号',
    PASSWORD: '密码',
    ACCOUNT_PH: '请输入3到20位用户名',
    PASSWORD_PH: '请输入登录密码',
    LOGIN_WELCOME: 'Hello，' + '\n' + '欢迎来到财经贝',
    REGISTER_QUICK: '快速注册',
    PASSWORD_FORGOT: '忘记密码',
    PASSWORD_FORGOT_TIP: '我们会发送验证到您绑定的手机/邮箱',
    PASSWORD_FORGET_NOTICE: '如果忘记用户名，请联系客服',
    LOGIN_USE_USERNAME: '用户名登录',
    LOGIN_NO_USE_USERNAME: '手机/邮箱登录',
    PHONE_EMAIL: '手机 / 邮箱',
    PHONE_EMAIL_PH: '请输入手机或邮箱',
    GO_VER: '谷歌登录安全验证',

    /* 注册 */
    REGISTER_PHONE: '手机注册',
    REGISTER_EMAIL: '邮箱注册',
    REGISTER_PHONE_M: '手机注册',
    REGISTER_EMAIL_M: '邮箱注册',
    USERNAME: '用户名',
    PHONE: '手机',
    EMAIL: '邮箱',
    GOOGLE: '谷歌',
    INVITE_CODE: '邀请码',
    USERNAME_PH: '请输入3-20位用户名',
    PHONE_PH: '请输入手机号',
    EMAIL_PH: '请输入邮箱',
    PASSWORD_PHL: '请设置6-20位登陆密码',
    INVITE_CODE_PH: '（选填）请输入邀请码',
    NEXT_STEP: '下一步',
    HAS_ACCOUNT: '已有账号,',
    LOGIN_IMM: '立即登录',
    REGISTER_VER_TITLE: '请输入验证码',
    PWD_FORGET_PH: '请输入用户名对应手机或邮箱',
    PWD_FORGET_USERNAME_ERROR: '请输入用户名',
    REGISTER_VER_SEND: '发送验证码至',

    /* mobile */
    LOGIN_REGISTER: '登录/注册',
    MOBILE_PRIVATE_BUTTON: '开启私募认购',
    MOBILE_PRIVATE_PRICE: '私募认购单价：',
    MOBILE_PRIVATE_PRICE_T: '0.01 EHZ',

    /* overview */
    MY_ASSETS: '个人资产',
    PURCHASE_CZH: '已认购数量',
    AWARD_CZH: '奖励数量',
    TOTAL_CZH: '总量',
    PRIVATE_TABLE_TIP: '认购须知',
    PRIVATE_BUY_PH: '起购',
    PRIVATE_PAY_PH: '900',
    PRIVATE_TABLE_NOTE: '如需其他支付方式认购请',
    MY_INVITE_CODE: '我的邀请码',
    INVITE_TIP: '价值分享/邀请好友与奖励',
    OFFICIAL_SERVICE: '财经贝官方客服',
    CONTACT_SERVICE: '联系客服',
    PRIVATE_PLACEMENT_TU: '私募认购教程',

    /* about */
    WEBSITE: '财经贝官网',
    WEBSITE_SHARE: '权威财经平台，价值、与您分享',
    WEBSITE_ENTRY: '前往官网',

    /* private */
    PRIVATE_SIMPLE: '认购',
    PRIVATE_RECORD: '私募认购记录',
    PRIVATE_RECORD_EMPTY: '暂无数据',
    PRIVATE_NOTE_UNIT : 'EHZ私募认购价格/每个',
    PRIVATE_NOTE_PURCHASE: 'EHZ私募认购数量',
    PRIVATE_APPLY: '填写信息',
    PRIVATE_CHECK: '审核中',
    PRIVATE_CHECK_INFO: '审核信息',
    PRIVATE_CHECK_SUCCESS: '审核成功',
    PRIVATE_CHECK_FAIL: '审核失败',
    PRIVATE_CHECK_FAIL_R: '查看原因',
    PRIVATE_FINISH: '认购结果',
    PRIVATE_CHECK_TIP: '温馨提示：认购信息已提交管理人员，请耐心等待结果。若有疑问，请点击',
    PRIVATE_REPURCHASE: '再次认购',
    PRIVATE_NUMBER: '会员',
    PRIVATE_NO_ORDER: '暂未认购',
    PRIVATE_UNIT: '认购单价',
    PRIVATE_NUM: '认购数量',
    PRIVATE_PAY: '需要支付',
    PRIVATE_PAY_TIP: '温馨提示：如需其他支付方式认购请',
    PRIVATE_TRANSFER: '转账数量',
    PRIVATE_ORDER_NO: '订单编号',
    PRIVATE_PROCESS: '管理进度',
    PRIVATE_FINISH_TIP: '温馨提示：若有疑问，请点击',
    PRIVATE_SUCCESS: '认购成功',
    PRIVATE_FAIL: '认购失败',
    PRIVATE_FAIL_REASON_NOTE: '审核失败原因：',
    PRIVATE_PROCESS_PAY: '认购付款',
    PRIVATE_PROCESS_INFO_CONFIRM: '确认认购信息',
    PRIVATE_PROCESS_USDT_CONFIRM: '选择USDT付款地址',
    PRIVATE_PROCESS_USDT_ADDRESS: 'ERC20二维码收款地址',
    PRIVATE_PROCESS_PAY_CONFIRM: '已支付请点击此处',

    /* order */
    ORDER_NUM: '序号',
    ORDER_PTIME: '认购时间',
    ORDER_NO: '订单编号',
    ORDER_PAMOUNT: '认购数量',
    ORDER_USDTP: '私募认购USDT',
    ORDER_STATUS: '认购状态',
    ORDER_CHECK_REASON: '查看原因',
    REGISTER_TIME: '注册时间',
    LATEST_PTIME: '最近认购时间',
    PRIVATE_NO: "暂未认购",
    UTC: '',

    /* order rejectReason */
    UNPAID: '未支付款项，请联系客服',
    PAYMENT_MISMATCH: '支付款项数额不正确，请联系客服',
    UNREASONED: '驳回原因，请联系客服',

    /* assets */
    MY_RECORD: '个人认购记录',
    INVITER_RECORD: '邀请者认购记录',
    REFRESH: '刷新',

    /* rewards */
    MY_INVITE: '我的邀请',
    MY_INVITE_RECORD: '我的邀请记录',
    INVITE_NUM: '邀请人数',
    PURCHASE_NUM: '认购人数',
    MY_REWARDS: '我的奖励',
    REWARDS_BANNER_TITLE: '财经贝EHZ-真正的价值币 值得分享',
    REWARDS_BANNER_CONTENT: '邀请好友私募认购，您可获得2%到5%奖励。',
    REWARDS_BANNER_BUTTON: '查看奖励说明',
    REWARDS_INVITE_INFO_ONE: '邀请好友私募认购获得',
    REWARDS_INVITE_INFO_TWO: '2%-5% 的财经贝EHZ',
    REWARDS_INVITE_INFO_THREE: '奖励',
    INVITE_CODE_LINK_REG: '邀请链接（注册）',
    INVITE_CODE_LINK_SHARE: '邀请链接（分享）',
    INVITE_POSTER: '海报邀请',
    COPY: '复制',
    DOWNLOAD_TO_DESK: '下载到电脑',
    FACE_TO_FACE: '面对面邀请',
    FACE_TO_FACE_TIP: '请扫描上方二维码接受好友邀请',
    FACE_TO_FACE_GET: '我知道了',
    DOWNLOAD_IMG: '保存海报',
    DOWNLOAD_IMG_TIP: '请点击此处下载海报到电脑',
    DOWNLOAD_IMG_TIP_ONE: '请点击此处保存海报（首选）；或者截图',
    CLOSE: '关闭',

    /* account */
    ACCOUNT_BASE: '基础信息',
    ACCOUNT_USERNAME: "账户昵称",
    ACCOUNT_UID: 'UID',
    ACCOUNT_SAFETY: '账号安全',
    ACCOUNT_PWD: '登录密码',
    ACCOUNT_PWD_M: '修改密码',
    ACCOUNT_VERIFY: '安全验证',
    ACCOUNT_VP_OLD: '原手机短信验证',
    ACCOUNT_VP_NEW: '新手机短信验证',
    ACCOUNT_VP: '短信验证',
    ACCOUNT_VPWD: '登录密码验证',
    ACCOUNT_VE: '邮箱验证',
    ACCOUNT_VG: '谷歌验证',
    ACCOUNT_BIND: '绑定',
    ACCOUNT_BIND_UN: '未绑定',
    ACCOUNT_OPEN: '已开启',
    ACCOUNT_OPEN_UN: '未开启',
    ACCOUNT_VP_UN: '暂未绑定手机',
    ACCOUNT_VE_UN: '暂未绑定邮箱',
    ACCOUNT_VG_UN: '暂未绑定谷歌验证',
    ACCOUNT_VG_BIND: '已绑定谷歌验证',
    ACCOUNT_VG_SUCCESS: '谷歌验证操作成功！',
    ACCOUNT_VERIFY_O: '开启验证',
    ACCOUNT_VERIFY_C: '关闭验证',
    ACCOUNT_VERIFY_SUCCESS: '开启或关闭验证成功，请确定保存',
    ACCOUNT_LOGOUT: '退出当前账号',
    VGO_ABSTRACT: '谷歌验证器是一款动态口令工具，工作原理类似短信动态验证。绑定后每30s生成一个动态验证码。',
    VGO_DOWN_TITLE: '下载或者在应用商店搜索“Google Authentication”应用',
    VGO_DOWN_DES: 'ios用户登录app store搜索“Authentication”下载，安卓用户登录应用商店使用手机浏览器搜索“谷歌验证器”下载。',
    VGO_INSTALL_TITLE: '安装完成后打开“Google Authentication”，扫下方二维码或手动输入密钥，得到6位数验证码',
    VGO_INSTALL_DES: '请务必妥善保管谷歌验证密钥，以免更换或丢失手机导致无法换绑',
    VGO_VER_TITLE: '请将您获得的验证码填入下方输入框中并完成验证',
    VGO_VER_TITLE_CLOSE: '关闭谷歌验证',
    APP_STORE: 'App Store',
    GOOGLE_PLAY: 'Google Play',
    VGO_SEKEY: '密钥',
    VEMAIL_VER_PH: '请输入邮箱验证码',
    VEMAIL_BIND_TIP: '温馨提示：绑定之后邮箱不能修改',
    VPHONE_VER_PH: '请输入手机验证码',
    VGO_VER_PH: '请输入谷歌验证码',
    VGO_VER_TIP: '您已开通谷歌安全验证',
    CONFIRM: '确定',
    CONFIRM_MODIFY: '确定修改',
    VER_TIP: '为了确保为您本人操作，请先进行安全验证。',
    VER_CODE: '验证码',
    VER_CODE_PH: '请输入验证码',
    VER_CODE_SEND: '发送验证',
    VER_CODE_GET: '获取验证码',
    VER_CODE_OBTAIN: '获取验证码',
    SECOND: '秒',
    MODIFY: '修改',
    ACCOUNT_NEW_PWD: '新密码',
    ACCOUNT_PWD_MODIFY: '设置新密码',
    ACCOUNT_PWD_MODIFY_PH: '请输入新密码',
    ACCOUNT_PWD_MODIFY_CONFIRM: '确认新密码',
    ACCOUNT_PWD_MODIFY_CONFIRM_PH: '请确认新密码',
    COUNTRY_ID_CHOOSE: '选择区号',
    COUNTRY_ID_CHOOSE_PH: '请输入区号选择国家',
    COUNTRY_ID_NO_DATA: '未搜索到结果，请重新搜索',

    /* mobile nav */
    MOBILE_NAV_SLOGAN: '真正的价值币，私募认购中',
    MOBILE_NAV_LOGIN: '点击登录 享受更多精彩信息',

    /* mobile overview */
    FUNCTION_CENTER: '目录导航',
    FUNCTION_CENTER_CAL: '私募认购中心导航',
    CLICK_MORE: '点击查看导航目录',
    INVITE_TITLE: '邀请好友',

    /* mobile assets */
    ASSETS_TTL: '总资产',

    /* input error msg */
    USER_NAME_ERROR: '用户名由3-20位英文字母或数字组成',
    USER_PHONE_ERROR: '请输入正确的手机号',
    USER_EMAIL_ERROR: '邮箱格式错误',
    USER_PWD_ERROR: '密码由6~20字符组成，不能为纯数字',
    USER_PWD_MOD_ERROR: '密码与确认密码不一致，请重新输入！',
    ORDER_PAMOUNT_ERROR: '认购数量不能低于起步数量：',
    ORDER_PAMOUNT_EMPTY_ERROR: '请填写认购数量',
    USER_FPWD_ERROR: '请输入正确的手机或邮箱',

    /* error msg */
    CONNECTION_ERROR: '网络连接错误，请稍后重试！',
    INPUT_ERROR: '请求参数无效，请输入正确参数！',
    USER_REPEAT: '用户名已经存在，请重新填写用户名！',
    PHONE_REPEAT: '手机号码已经存在，请重新填写手机号码！',
    EMAIL_REPEAT: '邮箱已经存在，请重新填写邮箱地址！',
    NOT_MATCH_ERROR: '账号不存在或密码错误，请重新输入！',
    WRONG_PASS_ERROR: '密码错误，请输入正确密码！',
    TIME_OUT: '该操作已超时，请在有效时间内完成对应操作！',
    VALID_ERROR: '当前认证已失效，请重新认证后执行该操作！',
    INVITER_ERROR: '邀请者不存在，请重新输入！',
    DEFAULT_ERROR: '系统错误，请稍后重试！',
    VER_CODE_ERROR: '验证码错误！请重新输入！',
    VER_CODE_OLD_ERROR: '原手机验证码错误！请重新输入！',
    VER_GO_CODE_ERROR: '谷歌验证码错误！请重新输入！',
    VER_PHONE_CODE_ERROR: '手机验证码错误！请重新输入！',
    VER_EMAIL_CODE_ERROR: '邮箱验证码错误！请重新输入！',
    VER_SEND_ERROR: '验证码发送失败，请稍后重试！',
    VER_OPEN_ERROR: '请先绑定手机号或邮箱！',
    VER_CLOSE_ERROR: '至少开启手机或邮箱中任一验证方式！',
    DATA_LOAD_ERROR: '数据加载失败，请联系客服！',
    COPY_FAIL_ERROR: '复制失败，请手动复制！',
    LOGIN_FIRST_ERROR: '请先登录账号！',
    VER_CODE_EMPTY: '请输入验证码！',
    OLD_USER_BY_PHONE: '老用户请用手机号登录',

    /* success msg */
    LOGIN_SUCCESS: '登陆成功！',
    REGISTER_SUCCESS: '注册成功！',
    COPY_SUCCESS: '复制成功！',
    PWD_MODIFY_SUCCESS: '登录密码修改成功！请重新登录！',
    PE_MODIFY_SUCCESS: '手机或邮箱修改成功！',
    VER_SEND_SUCCESS: '验证码发送成功！请注意查收！',
    PRIVATE_SUCCESS_TIP: '该次认购已成功提交！请等待审核！',
    DEFAULT_SUCCESS: '操作成功！',

    /* modal msg */
    LOGIN_LOADING: '财经贝欢迎您的登录!',
    DATA_LOADING: '数据加载中，请稍后...',

    /* country ip */
    COMMON_USE: '常用',
    ALL_COUNTRY: '全部国籍',
    CHINA: "中国",
    FRANCE: "法国",
    GERMANY: "德国",
    INDONESIA: "印度尼西亚",
    KOREA: "韩国",
    NETHERLANDS: "荷兰",
    RUSSIA: "俄罗斯",
    SPAIN: "西班牙",
    THAILAND: "泰国",
    TURKEY: "土耳其",
    UNITED_KINGDOM: "英国",
    VIETNAM: "越南",
    INDIA: "印度",
    AFGHANISTAN: "阿富汗",
    ALBANIA: "阿尔巴尼亚",
    ALGERIA: "阿尔及利亚",
    ANDORRA: "安道尔",
    ANGOLA: "安哥拉",
    ANGUILLA: "安圭拉",
    ANTIGUA_AND_BARBUDA: "安提瓜和巴布达",
    ARGENTINA: "阿根廷",
    ARMENIA: "亚美尼亚",
    AUSTRALIA: "澳大利亚",
    AUSTRIA: "奥地利",
    AZERBAIJAN: "阿塞拜疆",
    BAHAMAS: "巴哈马",
    BAHRAIN: "巴林",
    BANGLADESH: "孟加拉国",
    BARBADOS: "巴巴多斯",
    BELARUS: "白俄罗斯",
    BELGIUM: "比利时",
    BELIZE: "伯利兹",
    BENIN: "贝宁",
    BERMUDA: "百慕大",
    BHUTAN: "不丹",
    BOLIVIA: "玻利维亚（多民族国）",
    BOSNIA_AND_HERZEGOVINA: "波斯尼亚和黑塞哥维那",
    BOTSWANA: "博茨瓦纳",
    BRAZIL: "巴西",
    VIRGIN_ISLANDS_UNITED_KINGDOM: "维尔京群岛（英国）",
    BRUNEI_DARUSSALAM: "文莱达鲁萨兰国",
    BULGARIA: "保加利亚",
    BURKINA_FASO: "布基纳法索",
    BURUNDI: "布隆迪",
    CAMBODIA: "柬埔寨",
    CAMEROON: "喀麦隆",
    CANADA: "加拿大",
    CAPE_VERDE: "佛得角",
    CAYMAN_ISLANDS: "开曼群岛",
    CENTRAL_AFRICAN_REPUBLIC: "中非共和国",
    CHAD: "乍得",
    CHILE: "智利",
    COLOMBIA: "哥伦比亚",
    COMOROS: "科摩罗",
    CONGO: "刚果",
    DEMOCRATIC_REPUBLIC_OF_CONGO: "刚果（民主共和国）",
    COSTA_RICA: "哥斯达黎加",
    IVORY_COAST: "科特迪瓦",
    CROATIA: "克罗地亚",
    CUBA: "古巴",
    CURAçAO: "库拉索",
    CYPRUS: "塞浦路斯",
    CZECH: "捷克",
    DENMARK: "丹麦",
    DJIBOUTI: "吉布提",
    DOMINICA: "多米尼克",
    DOMINICAN_REPUBLIC: "多米尼加共和国",
    ECUADOR: "厄瓜多尔",
    EGYPT: "埃及",
    EL_SALVADOR: "萨尔瓦多",
    EQUATORIAL_GUINEA: "赤道几内亚",
    ERITREA: "厄立特里亚",
    ESTONIA: "爱沙尼亚",
    ETHIOPIA: "埃塞俄比亚",
    FIJI: "斐济",
    FINLAND: "芬兰",
    GABON: "加蓬",
    GAMBIA: "冈比亚",
    GEORGIA: "格鲁吉亚",
    GHANA: "加纳",
    GREECE: "希腊",
    GRENADA: "格林纳达",
    GUADELOUPE: "瓜德罗普岛",
    GUATEMALA: "危地马拉",
    GUERNSEY: "根西岛",
    GUINEA: "几内亚",
    GUINEA_BISSAU: "几内亚比绍",
    GUYANA: "圭亚那",
    HAITI: "海地",
    HONDURAS: "洪都拉斯",
    HONG_KONG_CHINA: "香港（中国）",
    HUNGARY: "匈牙利",
    ICELAND: "冰岛",
    IRAN: "伊朗（伊斯兰共和国）",
    IRAQ: "伊拉克",
    IRELAND: "爱尔兰",
    ISRAEL: "以色列",
    ITALY: "意大利",
    JAMAICA: "牙买加",
    JAPAN: "日本",
    JORDAN: "约旦",
    KAZAKHSTAN: "哈萨克斯坦",
    KENYA: "肯尼亚",
    KIRIBATI: "基里巴斯",
    NORTH_KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA: "朝鲜民主主义人民共和国",
    KOSOVO: "科索沃",
    KUWAIT: "科威特",
    KYRGYZSTAN: "吉尔吉斯斯坦",
    LAO_PEOPLES_DEMOCRATIC_REPUBLIC: "老挝人民民主共和国",
    LATVIA: "拉脱维亚",
    LEBANON: "黎巴嫩",
    LESOTHO: "莱索托",
    LIBERIA: "利比里亚",
    LIBYA: "利比亚",
    LIECHTENSTEIN: "列支敦士登",
    LITHUANIA: "立陶宛",
    LUXEMBOURG: "卢森堡",
    MACAO_CHINA: "澳门（中国）",
    REPUBLIC_OF_MACEDONIA: "马其顿共和国",
    MADAGASCAR: "马达加斯加",
    MALAWI: "马拉维",
    MALAYSIA: "马来西亚",
    MALDIVES: "马尔代夫",
    MALI: "马里",
    MALTA: "马耳他",
    MARSHALL_ISLANDS: "马绍尔群岛",
    MARTINIQUE: "马提尼克",
    MAURITANIA: "毛里塔尼亚",
    MAURITIUS: "毛里求斯",
    MEXICO: "墨西哥",
    FEDERATED_STATES_OF_MICRONESIA: "密克罗尼西亚（联邦）",
    REPUBLIC_OF_MOLDOVA: "摩尔多瓦共和国",
    MONACO: "摩纳哥",
    MONGOLIA: "蒙古",
    MONTENEGRO: "黑山",
    MOROCCO: "摩洛哥",
    MOZAMBIQUE: "莫桑比克",
    MYANMAR: "缅甸",
    NAMIBIA: "纳米比亚",
    NAURU: "瑙鲁",
    NEPAL: "尼泊尔",
    NEW_CALEDONIA: "新喀里多尼亚",
    NEW_ZEALAND: "新西兰",
    NICARAGUA: "尼加拉瓜",
    NIGER: "尼日尔",
    NIGERIA: "尼日利亚",
    NORWAY: "挪威",
    OMAN: "阿曼",
    PAKISTAN: "巴基斯坦",
    PALAU: "帕劳",
    PALESTINE_PALESTINE: "巴勒斯坦",
    PANAMA: "巴拿马",
    PAPUA_NEW_GUINEA: "巴布亚新几内亚",
    PARAGUAY: "巴拉圭",
    PERU: "秘鲁",
    PHILIPPINES: "菲律宾",
    POLAND: "波兰",
    FRENCH_POLYNESIA: "法属波利尼西亚",
    PORTUGAL: "葡萄牙",
    QATAR: "卡塔尔",
    REUNION_ISLAND: "留尼旺岛",
    ROMANIA: "罗马尼亚",
    RWANDA: "卢旺达",
    SAINT_KITTS_AND_NEVIS: "圣基茨和尼维斯",
    SAINT_LUCIA: "圣卢西亚",
    SAINT_VINCENT_AND_THE_GRENADINES: "圣文森特和格林纳丁斯",
    SAMOA: "萨摩亚",
    SAN_MARINO: "圣马力诺",
    SAO_TOME_AND_PRINCIPE: "圣多美和普林西比",
    SAUDI_ARABIA: "沙特阿拉伯",
    SENEGAL: "塞内加尔",
    SERBIA: "塞尔维亚",
    SEYCHELLES: "塞舌尔",
    SIERRA_LEONE: "塞拉利昂",
    SINGAPORE: "新加坡",
    SLOVAKIA: "斯洛伐克",
    SLOVENIA: "斯洛文尼亚",
    SOLOMON_ISLANDS: "所罗门群岛",
    SOMALIA: "索马里",
    SOUTH_AFRICA: "南非",
    SOUTH_SUDAN: "南苏丹",
    SRI_LANKA: "斯里兰卡",
    SUDAN: "苏丹",
    SURINAME: "苏里南",
    SWAZILAND: "斯威士兰",
    SWEDEN: "瑞典",
    SWITZERLAND: "瑞士",
    SYRIAN_ARAB_REPUBLIC: "阿拉伯叙利亚共和国",
    TAIWAN_CHINA: "台湾（中国）",
    TAJIKISTAN: "塔吉克斯坦",
    UNITED_REPUBLIC_OF_TANZANIA: "坦桑尼亚联合共和国",
    GIBRALTAR: "直布罗陀",
    COOK_ISLANDS: "库克群岛",
    EAST_TIMOR: "东帝汶",
    TOGO: "多哥",
    TONGA: "汤加",
    TRINIDAD_AND_TOBAGO: "特立尼达和多巴哥",
    TUNISIA: "突尼斯",
    TURKMENISTAN: "土库曼斯坦",
    TUVALU: "图瓦卢",
    VIRGIN_ISLANDS_US: "维尔京群岛（美属）",
    UGANDA: "乌干达",
    UKRAINE: "乌克兰",
    UNITED_ARAB_EMIRATES: "阿联酋",
    UNITED_STATES: "美国",
    URUGUAY: "乌拉圭",
    UZBEKISTAN: "乌兹别克斯坦",
    VANUATU: "瓦努阿图",
    VATICAN_CITY: "罗马教廷",
    VENEZUELA: "委内瑞拉玻利瓦尔",
    YEMEN: "也门",
    ZAMBIA: "赞比亚",
    ZIMBABWE: "津巴布韦",
}
