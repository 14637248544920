import React, {Component} from "react";
import 'antd/dist/antd.css';
import './index.css'
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Content} from "antd/lib/layout/layout";
import Login from "./pages/login/Login";
import Promotion from "./pages/promotion/Promotion";
import Overview from "./pages/overview/Overview";
import About from "./pages/about/About";
import Private from "./pages/private/Private";
import Assets from "./pages/assets/Assets";
import Rewards from "./pages/rewards/Rewards";
import Account from "./pages/account/Account";
import Service from "./pages/service/Service";

const Routes = [
    {
        ct: Promotion,
        root: "",
        params: [
            {
                levelLang: "/",
                levelParam: "",
            },
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
            {
                levelLang: "/cn",
                levelParam: "/pro/:inviteUId",
            },
            {
                levelLang: "/en",
                levelParam: "/pro/:inviteUId",
            },
        ]
    },
    {
        ct: Login,
        root: "/login",
        params: [
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
            {
                levelLang: "/cn",
                levelParam: "/:inviteUId",
            },
            {
                levelLang: "/en",
                levelParam: "/:inviteUId",
            },
        ]
    },
    {
        ct: Login,
        root: "/register",
        params: [
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
            {
                levelLang: "/cn",
                levelParam: "/:inviteUId",
            },
            {
                levelLang: "/en",
                levelParam: "/:inviteUId",
            },
        ]
    },
    {
        ct: Overview,
        root: "/overview",
        params: [
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
        ]
    },
    {
        ct: About,
        root: "/about",
        params: [
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
        ]
    },
    {
        ct: Private,
        root: "/private",
        params: [
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
            {
                levelLang: "/cn",
                levelParam: "/:private",
            },
            {
                levelLang: "/en",
                levelParam: "/:private",
            },
        ]
    },
    {
        ct: Assets,
        root: "/assets",
        params: [
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
        ]
    },
    {
        ct: Rewards,
        root: "/rewards",
        params: [
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
        ]
    },
    {
        ct: Account,
        root: "/account",
        params: [
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
        ]
    },
    {
        ct: Service,
        root: "/service",
        params: [
            {
                levelLang: "/cn",
                levelParam: "",
            },
            {
                levelLang: "/en",
                levelParam: "",
            },
        ]
    },
]

class PageRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    loadingRoutes = (Routes) => {
        return (
            Routes.map(function(item) {
                return (
                    item.params.map(function (param) {
                        return (
                            <Route
                                exact={true}
                                path={param.levelLang + item.root + param.levelParam}
                                component={item.ct}
                            />
                        )
                    })
                )
            })
        )
    }

    render() {
        return (
            <Router>
                <div>
                    {/*跳转时不重复显示主页exact*/}
                    {this.loadingRoutes(Routes)}
                </div>
            </Router>
        )
    }
}

export default PageRoutes;
