import React, {Component, Fragment} from "react";
import "./overview.css";
import {ExclamationCircleOutlined, MenuOutlined} from "@ant-design/icons";
import Tran from "../../config/IntTranslation";
import {isMobile} from "../../utils/MobileMgr";
import {formatCurrency, numToPercent} from "../../utils/Math";
import intl from "react-intl-universal";
import QQ from "../../assets/icon/icon_qq.svg";
import WECHAT from "../../assets/icon/icon_wx.svg";
import TEL from "../../assets/icon/icon_tel.svg";
import EMAIL from "../../assets/icon/icon_email.svg";
import CZH from "../../assets/icon/icon_czh.svg";
import USDT from "../../assets/icon/icon_usdt.svg";
import Banner from "../../assets/img/overview_banner_m.png";
import CENTER from "../../assets/icon/icon_center.svg";
import ARROW from "../../assets/icon/icon_arrow.svg";
import YQHY from "../../assets/img/overview_yqhy.png";
import YQHYEN from "../../assets/img/overview_yqhy_en.png";
import JS from "../../assets/img/overview_js.png";
import JSEN from "../../assets/img/overview_js_en.png";
import BANNER from "../../assets/img/overview_banner.png";
import TIP from "../../assets/icon/icon_overview_pur_tip.svg";
import Footer from "../footer/Footer";
import {changeType, genCoreInfos, getMedia, getServiceMedia, scrollTop, toChar} from "../../utils/Request";
import NP from "number-precision";
import PageLayout from "../PageLayout";
import {genPojo, processOrderUrl, sendPostRequest, webHeader} from "../../utils/RequestUrlMgr";
import {fail, findMsgByError, findMsgBySuccess, success} from "../../utils/ErrorMgr";
import LoadingWin from "../component/LoadingWin";
import {orderPAmountChecker, orderPAmountEmptyErrorMsg, orderPAmountErrorMsg} from "../../utils/UserInputChecker";
import {payModal} from "../component/Common";
import CLOSE from "../../assets/icon/icon_close.svg";
import MenuSelector from "../menu/MenuSelector";
import PageHeader from "../PageHeader";
import {Dropdown} from "antd";
import TUTORIAL from "../../assets/icon/icon_process.png";

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            type: 'home',
            userPojo: "",
            isCoreLoad: false,
            coreInfo: new Map(),
            showModal: false,
            modalStatus: '',
            modalContent: '',
            submitStatus: 0,
            pAmount: '',
            inputStatus: '',
            payStatus: false,
            isUserLoad: false,
            language: intl.get("LANG").toString() === "0" ? "zh" : "en",
            mediaResource: new Map(),
            isMediaLoad: false,
            mediaServiceResource: new Map(),
            isMediaServiceLoad: false,
            qrStatus: 1,
            isOpen: false,
            visible: true,
            displayStatus: true,
        }
    }

    orderInfo = {
        orderPojo: {
            userName: '', // 用户名
            orderNo: '', // 订单号
            pTime: '', // 提交时间
            cTime: '', // 审核时间
            inviterName: '', // 邀请者用户名
            inviterAward: '', // 邀请者奖励
            rAward: '', // 实际奖励
            pAmount: '', // 认购数量
            rAmount: '', // 实际到账数量
            orderStatus: '', // 订单状态
            usdtPrice: '', // 价格
            rejectReason: '', // 驳回原因
        }
    }

    setCloseMenu = () => {
        let dis = this.state.displayStatus;
        this.setState({
            displayStatus: !dis,
        })
    }

    changeType = (type) => {
        this.setState({
            type: type,
        })
    }

    loadingAssets = (userPojo, mediaResource, isMobile) => {
        let ttl = numToPercent(userPojo.purchaseCZH, userPojo.awardCZH).ttl;
        let perPur = numToPercent(userPojo.purchaseCZH, userPojo.awardCZH).perA;
        let perAwa = numToPercent(userPojo.purchaseCZH, userPojo.awardCZH).perB;
        let length = ttl.toString().length;
        let lang = intl.get("LANG").toString() === "0";

        if (!isMobile) {
            return (
                <Fragment>
                    <div className={"overview-layout-assets-title"}>
                        <Tran intlKey={"MY_ASSETS"}/>
                    </div>
                    <div className={"overview-layout-assets-content"}>
                        <div className={"overview-layout-assets-element"}>
                            <div className={"overview-layout-assets-element-title"}>
                                <div className={"overview-layout-assets-element-title-pot"}
                                     style={{backgroundColor: '#50C66E'}}/>
                                <div className={"overview-layout-assets-element-title-base"}>
                                    <Tran intlKey={"CZH_EN"}/>
                                </div>
                                <div className={"overview-layout-assets-element-title-text"}>
                                    <Tran intlKey={"PURCHASE_CZH"}/>
                                </div>
                            </div>
                            <div className={"overview-layout-assets-element-num"}>
                                <div className={"overview-layout-assets-element-num-num"}>
                                    {formatCurrency(userPojo.purchaseCZH)}
                                </div>
                                <div className={"overview-layout-assets-element-num-percent"}
                                     style={{width: perPur, borderBottom: '2px solid #50C66E'}}/>
                            </div>
                        </div>
                        <div className={"overview-layout-assets-element"}>
                            <div className={"overview-layout-assets-element-title"}>
                                <div className={"overview-layout-assets-element-title-pot"}
                                     style={{backgroundColor: '#666EF5'}}/>
                                <div className={"overview-layout-assets-element-title-base"}>
                                    <Tran intlKey={"CZH_EN"}/>
                                </div>
                                <div className={"overview-layout-assets-element-title-text"}>
                                    <Tran intlKey={"AWARD_CZH"}/>
                                </div>
                            </div>
                            <div className={"overview-layout-assets-element-num"}>
                                <div className={"overview-layout-assets-element-num-num"}>
                                    {formatCurrency(userPojo.awardCZH)}
                                </div>
                                <div className={"overview-layout-assets-element-num-percent"}
                                     style={{width: perAwa, borderBottom: '2px solid #666EF5'}}/>
                            </div>
                        </div>
                        <div className={"overview-layout-assets-element"}>
                            <div className={"overview-layout-assets-element-title"}>
                                <div className={"overview-layout-assets-element-title-pot"}
                                     style={{backgroundColor: '#166BE5'}}/>
                                <div className={"overview-layout-assets-element-title-base"}>
                                    <Tran intlKey={"CZH_EN"}/>
                                </div>
                                <div className={"overview-layout-assets-element-title-text"}>
                                    <Tran intlKey={"TOTAL_CZH"}/>
                                </div>
                            </div>
                            <div className={"overview-layout-assets-element-num"}>
                                <div className={"overview-layout-assets-element-num-num"}>
                                    {formatCurrency(ttl)}
                                </div>
                                <div className={"overview-layout-assets-element-num-percent"}
                                     style={{width: '100%', borderBottom: '2px solid #166BE5'}}/>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <div className={"mobile-overview-assets-container"}>
                    <div className={"mobile-overview-assets-layout"}>
                        <div className={"mobile-overview-assets-title"}>
                            <Tran intlKey={"MY_ASSETS"}/>
                        </div>
                        <div className={"mobile-overview-assets-rule-wrap"}>
                            <div className={"mobile-overview-assets-rule"}
                                 onClick={() => {
                                     const w = window.open('about:blank');
                                     w.location.href = mediaResource.get("PRIVATE-PLACEMENT-TUTORIAL-LINK")["itemContent"]}
                                 }
                            >
                                <span>{mediaResource.get("PRIVATE-PLACEMENT-TUTORIAL")["itemContent"]}</span>
                            </div>
                            <div style={{width: '20px'}}/>
                            <div className={"mobile-overview-assets-rule"}
                                 onClick={() => changeType("policy", this)}
                            >
                                <Tran intlKey="PRIVATE_TABLE_RULE_LINK"/>
                            </div>
                        </div>
                    </div>
                    <div className={"mobile-overview-assets-content"}>
                        <div className={"mobile-overview-assets-element"}>
                            <div className={"mobile-overview-assets-element-pot"} style={{backgroundColor: '#50C66E'}}/>
                            <div className={"mobile-overview-assets-element-num"}
                                 style={{fontSize: length === 11 ? '14px' : '16px'}}>
                                {userPojo.purchaseCZH}
                            </div>
                            <div className={"mobile-overview-assets-element-text"}>
                                <Tran intlKey={"CZH_EN"}/>
                                <div style={{width: '5px'}}/>
                                <Tran intlKey={"PURCHASE_CZH"}/>
                            </div>
                        </div>
                        <div className={"mobile-overview-assets-element"}>
                            <div className={"mobile-overview-assets-element-pot"} style={{backgroundColor: '#FDB32A'}}/>
                            <div className={"mobile-overview-assets-element-num"}
                                 style={{fontSize: length === 11 ? '14px' : '16px'}}>
                                {userPojo.awardCZH}
                            </div>
                            <div className={"mobile-overview-assets-element-text"}>
                                <Tran intlKey={"CZH_EN"}/>
                                <div style={{width: '5px'}}/>
                                <Tran intlKey={"AWARD_CZH"}/>
                            </div>
                        </div>
                        <div className={"mobile-overview-assets-element"}>
                            <div className={"mobile-overview-assets-element-pot"} style={{backgroundColor: '#337AF0'}}/>
                            <div className={"mobile-overview-assets-element-num"}
                                 style={{fontSize: length === 11 ? '14px' : '16px'}}>
                                {ttl}
                            </div>
                            <div className={"mobile-overview-assets-element-text"}>
                                <Tran intlKey={"CZH_EN"}/>
                                <div style={{width: '5px'}}/>
                                <Tran intlKey={"TOTAL_CZH"}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    openModal = (data, status) => {
        if (data !== '') {
            this.setState({
                showModal: true,
                modalContent: data,
                modalStatus: status,
            })
        }
    }

    loadingPrivate = (lang, coreInfo, orderPojo, isMobile, mediaResource) => {
        let unitPrice = coreInfo.get("CZH_PRICE")["itemValue"]
        let minValue = coreInfo.get("CZH_MIN")["itemValue"];
        let inputStatus = this.state.inputStatus;
        let policyContent = mediaResource.get("POLICY-ONE")["itemContent"] + '\n' + '\n' + mediaResource.get("POLICY-TWO")["itemContent"] + '\n' + '\n' + mediaResource.get("POLICY-THREE")["itemContent"] + '\n' + '\n' + mediaResource.get("POLICY-FOUR")["itemContent"];
        if (!isMobile) {
            return (
                <Fragment>
                    <div className={"overview-layout-private-title"}>
                        <Tran intlKey={"PRIVATE"}/>
                        <div className={"overview-layout-private-table-tip-wrap"}>
                            <div className={"overview-layout-private-table-tip-sub-title"}
                                 onClick={() => {
                                     const w = window.open('about:blank');
                                     w.location.href = mediaResource.get("PRIVATE-PLACEMENT-TUTORIAL-LINK")["itemContent"]}
                                 }
                            >
                                <img className={"icon-style-other"} src={TUTORIAL} alt={"TUTORIAL"}/>
                                <span>{mediaResource.get("PRIVATE-PLACEMENT-TUTORIAL")["itemContent"]}</span>
                            </div>
                            <div style={{width: '10px'}}/>
                            <div className={"overview-layout-private-table-tip-sub-title"}
                                 onClick={() => {this.openModal(policyContent, "policy")}}
                            >
                                <ExclamationCircleOutlined style={{paddingRight: '5px'}}/>
                                <Tran intlKey={"PRIVATE_TABLE_RULE_LINK"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"overview-layout-private-table"}>
                        <div className={"overview-layout-private-table-tip"}>
                            <div className={"overview-layout-private-table-tip-icon"}>
                                <img className={"img-size"} src={TIP} alt={"tip"}/>
                            </div>
                            <div className={"overview-layout-private-table-tip-content"}>
                                <div className={"overview-layout-private-table-tip-title"}>
                                    <Tran intlKey={"PRIVATE_TABLE_TIP"}/>
                                </div>
                                <div className={"overview-layout-private-table-tip-text"}
                                >
                                    <Tran intlKey={"PRIVATE_TABLE_RULE_ONE"}/>
                                    {unitPrice}
                                    {" USDT"}
                                    {" ("}
                                    {
                                        lang ?
                                            <Fragment>
                                                {minValue.slice(0, -4)}
                                                <Tran intlKey={"PRIVATE_TABLE_RULE_TWO_UNIT"}/>
                                                <Tran intlKey={"PRIVATE_TABLE_RULE_TWO"}/>
                                            </Fragment> :
                                            <div className={"text-style"}>
                                                <Tran intlKey={"PRIVATE_TABLE_RULE_TWO"}/>
                                                <div style={{width: '3px'}}/>
                                                {minValue}
                                                <div style={{width: '3px'}}/>
                                                <Tran intlKey={"CZH"}/>
                                            </div>
                                    }
                                    {")"}
                                </div>
                            </div>
                        </div>
                        <div className={"overview-layout-private-table-form"}>
                            <div className={"overview-layout-private-table-form-element"}>
                                <div className={"overview-layout-private-table-form-title"}>
                                    <Tran intlKey="PRIVATE_TABLE_BUY"/>
                                </div>
                                <div className={"overview-layout-private-table-form-container"}
                                     style={{border: this.state.submitStatus === 1 && orderPojo["pAmount"] === "" ? '1px solid #DF5060' : ""}}
                                >
                                    <input className={"overview-layout-private-table-form-input"}
                                           placeholder={inputStatus === false ? 0 : formatCurrency(minValue) + " " + intl.get("PRIVATE_BUY_PH")}
                                           onChange={(e) => this.genProcessOrder(e, minValue, unitPrice, "pAmount")}
                                           maxLength={20}
                                    />
                                    <div className={"overview-layout-private-table-form-currency"}
                                         style={{color: '#5A1D7A'}}>
                                        <img className={"icon-style"} src={CZH} alt={"czh"}/>
                                        <div className={"currency-title"}>EHZ</div>
                                    </div>
                                </div>
                            </div>
                            <div className={"private-layout-process-table-form-input-notice"} style={{margin: '0 auto', width: '80%'}}>
                                {this.getErrorMsg("pAmount", minValue)}
                            </div>
                            <div className={"overview-layout-private-table-form-element"}>
                                <div className={"overview-layout-private-table-form-title"}>
                                    <Tran intlKey="PRIVATE_TABLE_PAY"/>
                                </div>
                                <div className={"overview-layout-private-table-form-container"}>
                                    <input className={"overview-layout-private-table-form-input"}
                                           placeholder={intl.get("PRIVATE_PAY_PH")}
                                           value={parseInt(NP.times(orderPojo.pAmount, unitPrice).toString())}
                                           disabled={true}
                                           readOnly={true}
                                    />
                                    <div className={"overview-layout-private-table-form-currency"}
                                         style={{color: '#1BA27A'}}>
                                        <img src={USDT} alt={"usdt"}/>
                                        <div className={"currency-title"}>USDT</div>
                                    </div>
                                </div>
                            </div>
                            <button className={"overview-layout-private-table-form-button"}
                                    onClick={() => {
                                        this.genOrder(true, orderPojo)
                                    }}
                            >
                                <Tran intlKey="PRIVATE"/>
                            </button>
                            <div className={"overview-layout-private-table-form-note"}>
                                <div className={"note-style"}>
                                    <div style={{color: 'gray'}}>
                                        {intl.get("PRIVATE_TABLE_NOTE")}
                                    </div>
                                    <div style={{color: '#1859DE', cursor: 'pointer'}} onClick={() => window.location.href = "/service"}>
                                        <Tran intlKey="CONTACT_SERVICE"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className={"mobile-overview-purchase-table-header"}>
                        <div className={"pot-small-size"}/>
                        <div className={"pot-big-size"}/>
                        <div className={"mobile-overview-purchase-table-header-title"}>
                            <Tran intlKey="PRIVATE_TABLE_TITLE"/>
                        </div>
                        <div className={"pot-big-size"} style={{marginLeft: '10px'}}/>
                        <div className={"pot-small-size"}/>
                    </div>
                    {/*<div className={"mobile-overview-purchase-label-container"}>*/}
                    {/*    <div className={"mobile-overview-purchase-label-selected"}>*/}
                    {/*        <div className={"mobile-overview-purchase-label-index-selected"}>*/}
                    {/*            1*/}
                    {/*        </div>*/}
                    {/*        <div className={"mobile-overview-purchase-label-text-selected"}>*/}
                    {/*            <Tran intlKey="PRIVATE_APPLY"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={"mobile-overview-purchase-label-line"}/>*/}
                    {/*    <div className={"mobile-overview-purchase-label"}>*/}
                    {/*        <div className={"mobile-overview-purchase-label-index"}>*/}
                    {/*            2*/}
                    {/*        </div>*/}
                    {/*        <div className={"mobile-overview-purchase-label-text"}>*/}
                    {/*            <Tran intlKey="PRIVATE_CHECK"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={"mobile-overview-purchase-label-line"}/>*/}
                    {/*    <div className={"mobile-overview-purchase-label"}>*/}
                    {/*        <div className={"mobile-overview-purchase-label-index"}>*/}
                    {/*            3*/}
                    {/*        </div>*/}
                    {/*        <div className={"mobile-overview-purchase-label-text"}>*/}
                    {/*            <Tran intlKey="PRIVATE_FINISH"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={"mobile-overview-purchase-table-price"}>
                        <div className={"mobile-price-icon"}>
                            <ExclamationCircleOutlined/>
                        </div>
                        <div className={"mobile-price-bold"}>
                            <Tran intlKey="MOBILE_PRIVATE_PRICE"/>
                        </div>
                        <div className={"mobile-price-gray"}>
                            {unitPrice}
                            {" USDT"}
                            {
                                lang ?
                                    <Fragment>
                                        {" ("}
                                        {minValue.slice(0, -4)}
                                        <Tran intlKey={"PRIVATE_TABLE_RULE_TWO_UNIT"}/>
                                        <Tran intlKey={"PRIVATE_TABLE_RULE_TWO"}/>{")"}
                                    </Fragment> : null
                            }
                        </div>
                    </div>
                    <div className={"mobile-overview-purchase-table-input"}>
                        <div className={"mobile-overview-purchase-table-input-element"}
                             style={{border: this.state.submitStatus === 1 && orderPojo["pAmount"] === "" ? '1px solid #DF5060' : ""}}
                        >
                            <div className={"mobile-input-element-text"}>
                                <Tran intlKey="PRIVATE_TABLE_BUY"/>
                            </div>
                            <input className={"mobile-input-element-num"}
                                   placeholder={inputStatus === false ? 0 : formatCurrency(minValue) + " " + intl.get("PRIVATE_BUY_PH")}
                                   onChange={(e) => this.genProcessOrder(e, minValue, unitPrice, "pAmount")}
                                   maxLength={20}
                            />
                            <div className={"mobile-input-element-currency"}>
                                <img className={"icon-style"} src={CZH} alt={"czh"}/>
                                <div className={"mobile-input-element-currency-one"}>
                                    EHZ
                                </div>
                            </div>
                        </div>
                        <div className={"mobile-input-notice"}>
                            {this.getErrorMsg("pAmount", minValue)}
                        </div>
                        <div className={"mobile-overview-purchase-table-input-element"}>
                            <div className={"mobile-input-element-text"}>
                                <Tran intlKey="PRIVATE_TABLE_PAY"/>
                            </div>
                            <input className={"mobile-input-element-num"}
                                   placeholder={intl.get("PRIVATE_PAY_PH")}
                                   value={parseInt(NP.times(orderPojo.pAmount, unitPrice).toString())}
                                   disabled={true}
                            />
                            <div className={"mobile-input-element-currency"}>
                                <img src={USDT} alt={"czh"}/>
                                <div className={"mobile-input-element-currency-two"}>
                                    USDT
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{height: '10px'}}/>
                    <div className={"mobile-click-button"}
                         onClick={() => {
                             this.genOrder(true, orderPojo)
                         }}
                    >
                        <Tran intlKey="PRIVATE_TABLE_TITLE"/>
                    </div>
                </Fragment>
            )
        }
    }

    loadingService = (mediaServiceResource) => {
        return (
            <Fragment>
                <div className={"overview-layout-official-content"}>
                    <div className={"overview-layout-official-title"}>
                        <Tran intlKey="OFFICIAL_SERVICE"/>
                    </div>
                    <div className={"overview-layout-official-list"}>
                        <div className={"overview-layout-official-text"}>
                            <img src={QQ} style={{fontSize: '20px', paddingRight: '20px'}} alt={"qq"}/>
                            {intl.get("CONTACT_QQ")}{': '}{mediaServiceResource.get("QQ-SERVICE")["itemContent"]}
                        </div>
                        <div className={"overview-layout-official-text"}>
                            <img src={TEL} style={{fontSize: '20px', paddingRight: '20px'}} alt={"tel"}/>
                            {intl.get("CONTACT_TELEGRAM")}{': '}{mediaServiceResource.get("TELEGRAM-SERVICE")["itemContent"]}
                        </div>
                        <div className={"overview-layout-official-text"}>
                            <img src={EMAIL} style={{fontSize: '20px', paddingRight: '20px'}} alt={"email"}/>
                            {intl.get("CONTACT_EMAIL")}{': '}{mediaServiceResource.get("WECHAT-SERVICE")["itemContent"]}
                        </div>
                        <div className={"overview-layout-official-text"}>
                            <img src={EMAIL} style={{fontSize: '20px', paddingRight: '20px'}} alt={"email"}/>
                            {intl.get("CONTACT_EMAIL")}{': '}{mediaServiceResource.get("EMAIL-SERVICE")["itemContent"]}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    loadingPageContent = (lang, isCoreLoad, userPojo, coreInfo, orderPojo, showModal, modalContent, isMobile, isUserLoad, mediaResource, mediaServiceResource, isAllLoad, modalStatus, type) => {
        if (!isUserLoad) {
            return (
               <div/>
            )
        }
        if (!isMobile) {
            return (
                isAllLoad ?
                    <Fragment>
                        {showModal && this.loadingModal(showModal, modalContent, coreInfo, isMobile, modalStatus)}
                        <div className={"overview-layout-main"}>
                            <div className={"overview-layout-assets"}>
                                {this.loadingAssets(userPojo, mediaResource, isMobile)}
                            </div>
                            <div className={"overview-layout-private"}>
                                {coreInfo && this.loadingPrivate(lang, coreInfo, orderPojo, isMobile, mediaResource)}
                            </div>
                        </div>
                        <div className={"overview-layout-msg"}>
                            <div className={"overview-layout-banner"}>
                                <img className={"img-size"}
                                     src={mediaResource && webHeader + mediaResource.get("BANNER")["itemContent"]}
                                     alt={"banner"}/>
                            </div>
                            <div className={"overview-layout-invitation"} onClick={() => {window.location.href = "/rewards"}}>
                                <div className={"overview-layout-invitation-content"}>
                                    <div className={"overview-layout-invitation-title"}>
                                        <Tran intlKey={"MY_INVITE_CODE"}/>
                                    </div>
                                    <div className={"overview-layout-invitation-text"}>
                                        <Tran intlKey={"INVITE_TIP"}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"overview-layout-official"}>
                                {this.loadingService(mediaServiceResource)}
                            </div>
                        </div>
                    </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
            )
        } else {
            let visible = this.state.displayStatus;
            if (type === "policy") {
                return (
                    <Fragment>
                        <div style={{height: '80px'}} id={"pageHeader"}/>
                        <div className={"mobile-policy-title"}>
                            <Tran intlKey={"PRIVATE_POLICY_TITLE"}/>
                        </div>
                        <div className={"mobile-policy-content"}>
                            {mediaResource.get("POLICY-ONE")["itemContent"]}
                            <br/>
                            <br/>
                            {mediaResource.get("POLICY-TWO")["itemContent"]}
                            <br/>
                            <br/>
                            {mediaResource.get("POLICY-THREE")["itemContent"]}
                            <br/>
                            <br/>
                            {mediaResource.get("POLICY-FOUR")["itemContent"]}
                        </div>
                        <button className={"mobile-qr-button"}
                                onClick={() => changeType("home", this)}
                        >
                            <Tran intlKey={"FACE_TO_FACE_GET"}/>
                        </button>
                        <div style={{height: '40px'}}/>
                    </Fragment>
                )
            } else {
                return (
                    isAllLoad ?
                        <Fragment>
                            {showModal && this.loadingModal(showModal, modalContent, coreInfo, isMobile, modalStatus)}
                            <div style={{height: '80px'}} id={"pageHeader"}/>
                            {
                                !showModal ?
                                    <Fragment>
                                        {this.loadMenu(visible, isMobile)}
                                        <div style={{display: visible ? '' : 'none'}}>
                                            <div className={"mobile-overview-wrapper"}>
                                                <div className={"mobile-overview-banner"} style={{height: lang ? null : '450px'}}>
                                                    <img className={"mobile-overview-banner-img"} src={Banner}
                                                         alt={"banner"}/>
                                                    <div className={"mobile-overview-banner-content"}
                                                         style={{top: lang ? null : '-380px', width: lang ? null : '88%'}}
                                                    >
                                                        <div className={"mobile-overview-banner-content-slogan"}>
                                                            <Tran intlKey="SLOGAN"/>
                                                        </div>
                                                        <div className={"mobile-overview-banner-content-slogan-detail"}>
                                                            {mediaResource.get("SLOGAN-CONTENT")["itemContent"]}
                                                        </div>
                                                        <button
                                                            className={"mobile-overview-banner-content-private-button"}
                                                            onClick={() => window.location.href = '/private/private'}
                                                        >
                                                            <Tran intlKey="MOBILE_PRIVATE_BUTTON"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"mobile-overview-purchase"}>
                                                <div className={"mobile-overview-purchase-table"}>
                                                    {coreInfo && this.loadingPrivate(lang, coreInfo, orderPojo, isMobile, mediaResource)}
                                                </div>
                                            </div>
                                            <div className={"mobile-overview-assets"}>
                                                {this.loadingAssets(userPojo, mediaResource, isMobile)}
                                            </div>
                                            <div className={"mobile-overview-center"}>
                                                <div className={"mobile-overview-center-container"}>
                                                    <div className={"mobile-overview-center-title"}>
                                                        <Tran intlKey="FUNCTION_CENTER"/>
                                                    </div>
                                                    <div className={"mobile-overview-center-content"}
                                                         onClick={() => this.setDisplay()}
                                                    >
                                                        <div className={"mobile-overview-center-icon"}>
                                                            <img className={"mobile-img"} src={CENTER} alt={"center"}/>
                                                        </div>
                                                        <div className={"mobile-overview-center-content-title"}>
                                                            <div
                                                                className={"mobile-overview-center-content-title-main"}>
                                                                <Tran intlKey="FUNCTION_CENTER_CAL"/>
                                                            </div>
                                                            <div className={"mobile-overview-center-content-title-sub"}>
                                                                <Tran intlKey="CLICK_MORE"/>
                                                            </div>
                                                        </div>
                                                        <div className={"mobile-overview-center-arrow"}>
                                                            <img className={"mobile-img"} src={ARROW} alt={"arrow"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"mobile-overview-invite"}>
                                                <div className={"mobile-overview-invite-container"}>
                                                    <div className={"mobile-overview-invite-title"}>
                                                        <Tran intlKey="INVITE_TITLE"/>
                                                    </div>
                                                    <div className={"mobile-overview-invite-content"}>
                                                        <div className={"mobile-overview-invite-img"}
                                                             onClick={() => window.location.href = '/rewards'}>
                                                            <img className={"mobile-img"} src={lang ? YQHY : YQHYEN} alt={"yqhy"}/>
                                                        </div>
                                                        <div className={"mobile-overview-invite-img"}
                                                             onClick={() => window.location.href = '/about'}>
                                                            <img className={"mobile-img"} src={lang ? JS : JSEN} alt={"js"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Footer isMobile={isMobile} dom={this}/>
                                        </div>
                                    </Fragment>
                                    :
                                    ""
                            }
                        </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
                )
            }
        }
    }

    loadMenu = (visible, isMobile) => {
        if (visible) {
            return null;
        }
        console.log("adddddddddd show  menu")
        return (
            <MenuSelector isMobile={isMobile} isLogin={true} dom={this}/>
        )
    }

    // order
    genProcessOrder = (e, minValue, unitPrice, props) => {
        let orderPojo = this.orderInfo.orderPojo;
        let inputValue = e.target.value;
        switch (props) {
            case "pAmount": {
                if (inputValue && orderPAmountChecker.test(inputValue) && parseInt(inputValue) >= parseInt(minValue)) {
                    orderPojo["pAmount"] = parseInt(inputValue);
                    orderPojo["usdtPrice"] = parseInt(NP.times(inputValue, unitPrice).toString());
                    this.setState({
                        inputStatus: true,
                        orderPojo
                    })
                } else {
                    orderPojo["pAmount"] = "";
                    this.setState({
                        inputStatus: true,
                        orderPojo
                    })
                }
                return;
            }
            default: {
                return;
            }
        }
    }

    getErrorMsg(props, minValue) {
        if (this.state.submitStatus !== 1) {
            return;
        }
        switch (props) {
            case "pAmount": {
                if (this.state.inputStatus) {
                    return this.orderInfo.orderPojo["pAmount"] === "" ? orderPAmountErrorMsg(intl) + minValue + " EHZ" : "";
                } else {
                    return orderPAmountEmptyErrorMsg(intl);
                }
            }
            default: {
                return "";
            }
        }
    }

    fillOrderInfo = (showModal, orderInfo) => {
        let userInfo = sessionStorage.getItem("userInfo");
        orderInfo["userName"] = JSON.parse(userInfo).userName;
        orderInfo["orderNo"] = "";
        orderInfo["pTime"] = "";
        orderInfo["cTime"] = null;
        orderInfo["inviterName"] = JSON.parse(userInfo).inviterName;
        orderInfo["inviterAward"] = 0;
        orderInfo["rAward"] = 0;
        // orderInfo["pAmount"] = "";
        orderInfo["rAmount"] = 0;
        orderInfo["orderStatus"] = 0;
        // orderInfo["usdtPrice"] = "";
        orderInfo["rejectReason"] = "";
        this.setState({
            orderInfo,
            showModal: showModal,
            modalContent: orderInfo,
            payStatus: true,
        },
            () => scrollTop())
    }

    genOrder = (showModal, orderInfo) => {
        let allInfoFilled = orderInfo["pAmount"] !== "";
        console.log("allInfoFilled:", allInfoFilled)
        if (allInfoFilled) {
            this.fillOrderInfo(showModal, orderInfo);
            // console.log("弹窗：", orderInfo)
        }
        this.setState({
            inputStatus: this.state.inputStatus !== '',
            submitStatus: 1,
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    loadingModal = (showModal, modalContent, coreInfo, isMobile, modalStatus) => {
        if (showModal === true) {
            if (modalStatus === "policy") {
                return (
                    <div className={"private-modal"}>
                        <div className={"modal-rule-win"}>
                            <div className={"modal-rule-win-title"}>
                                <Tran intlKey={"PRIVATE_POLICY_TITLE"}/>
                            </div>
                            <div className={"modal-rule-win-text"}>
                                {modalContent}
                            </div>
                        </div>
                        <button className={"private-modal-win-close"}
                                style={{top: 'calc(50% - 185px)', left: 'calc(50% + 300px)'}}
                                onClick={() => {this.closeModal()}}
                        >
                            <img className={"img-size"} src={CLOSE} alt={"clo"}/>
                        </button>
                    </div>
                )
            } else {
                return (
                    <div className={isMobile ? "mobile-private-modal" : "private-modal"}>
                        {this.loadingModalWin(modalContent, coreInfo, isMobile)}
                    </div>
                )
            }
        }
    }

    changeQRStatus = (qrStatus) => {
        this.setState({
            qrStatus: qrStatus,
        })
    }

    loadingModalWin = (modalContent, coreInfo, isMobile) => {
        let qrStatus = this.state.qrStatus;
        let erc_link = toChar(coreInfo.get("USDT_LINK")["itemValue"], coreInfo.get("USDT_LINK")["itemSec"]);
        let trc_link = toChar(coreInfo.get("USDT_LINK_T")["itemValue"], coreInfo.get("USDT_LINK_T")["itemSec"]);
        if (modalContent) {
            return (
                payModal(modalContent, coreInfo, qrStatus, erc_link, trc_link, isMobile, this)
            )
        }
    }

    genOrderPay = (orderInfo) => {
        let pojo = genPojo(processOrderUrl, orderInfo);
        console.log("私募认购传参：", pojo)
        if (pojo) {
            sendPostRequest(pojo).then(res => {
                console.log(res)
                if (res && res.hasOwnProperty("success") && res.success) {
                    success(findMsgBySuccess("PRIVATE_SUCCESS"), "/private");
                    this.setState({
                        submitStatus: 2,
                        showModal: false,
                    })
                } else {
                    fail(findMsgByError(res ? res.errorType : "UNDEFINED"));
                    this.setState({
                        submitStatus: 0,
                    })
                }
            })
        }
    }

    // 用户基础信息
    setUsrInfo = (userInfo, userPojo, isUserLoad) => {
        this.setState({
            userPojo: userPojo,
            isUserLoad: isUserLoad,
        })
    }

    setDisplay = () => {
        console.log(121212)
        let status = this.state.displayStatus;
        this.setState({
            displayStatus: !status,
        })
    }

    componentDidMount() {
        genCoreInfos(this);
        getMedia(intl, "OVERVIEW", this);
        getServiceMedia(intl, "SERVICE", this);
    }

    render() {
        let isMobile = this.state.isMobile;
        let userPojo = this.state.userPojo;
        let isUserLoad = this.state.isUserLoad;

        let type = this.state.type;
        let orderPojo = this.orderInfo.orderPojo;

        let coreInfo = this.state.coreInfo;
        let isCoreLoad = this.state.isCoreLoad;

        let showModal = this.state.showModal;
        let modalStatus = this.state.modalStatus;
        let modalContent = this.state.modalContent;

        let mediaResource = this.state.mediaResource;
        let isMediaLoad = this.state.isMediaLoad;
        let mediaServiceResource = this.state.mediaServiceResource;
        let isMediaServiceLoad = this.state.isMediaServiceLoad;

        let lang = intl.get("LANG").toString() === "0";

        let isAllLoad = isUserLoad && isCoreLoad && isMediaLoad && isMediaServiceLoad;

        console.log("用户信息：", userPojo)
        console.log("CZH信息：", coreInfo)
        console.log("inputStatus:", this.state.inputStatus)

        return (
            <PageLayout parent={this} pNameCN={intl.get("OVERVIEW")} pNameEN={intl.get("OVERVIEW_EN")}
                        needUser={true}
                        dom = {this}
                        pContent={this.loadingPageContent(lang, isCoreLoad, userPojo, coreInfo, orderPojo, showModal, modalContent, isMobile, isUserLoad, mediaResource, mediaServiceResource, isAllLoad, modalStatus, type)}
            />
        )
    }
}

export default Overview;
