import React, {Component, Fragment} from "react";
import "./footer.css";
import Tran from "../../config/IntTranslation";
import {Collapse} from "antd";
import LOGO from "../../assets/icon/logo.png";
import {changeType, getMedia, getServiceMedia} from "../../utils/Request";
import LoadingWin from "../component/LoadingWin";
import intl from "react-intl-universal";

const { Panel } = Collapse;

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: this.props.language,
            mediaResource: new Map(),
            isMediaLoad: false,
            mediaServiceResource: new Map(),
            isMediaServiceLoad: false,
        }
    }

    /* footer */
    loadingFooterL = (mediaResource, mediaServiceResource, inviteUId) => {
        return (
            <Fragment>
                <div className={"footer-container-link"}>
                    <div className={"footer-container-link-title"}>
                        <Tran intlKey="PRIVATE"/>
                    </div>
                    <div className={"footer-container-link-con"}>
                        <div className={"footer-container-link-con-text"}
                             onClick={() =>{window.location.href = !inviteUId ? "/login" : "/login/" + inviteUId}}
                        >
                            <Tran intlKey="PERSONAL_CENTER"/>
                        </div>
                        <div className={"footer-container-link-con-text"}
                             onClick={() => {this.props.dom.openModal("rule")}}
                        >
                            <Tran intlKey="SUBSCRIBE_RULE"/>
                        </div>
                    </div>
                </div>
                <div className={"footer-container-link"}>
                    <div className={"footer-container-link-title"}>
                        <Tran intlKey="ABOUT_TITLE_SHORTHAND"/>
                    </div>
                    <div className={"footer-container-link-con"}>
                        <div className={"footer-container-link-con-text"}
                             onClick={() => {
                                 const w = window.open('about:blank');
                                 w.location.href = mediaResource.get("LINK-ONE-LINK")["itemContent"]}
                             }
                        >
                            {mediaResource.get("LINK-ONE-NAME")["itemContent"]}
                        </div>
                        {/*<div className={"footer-container-link-con-text"}*/}
                        {/*     onClick={() => {*/}
                        {/*         const w = window.open('about:blank');*/}
                        {/*         w.location.href = mediaResource.get("LINK-TWO-LINK")["itemContent"]}*/}
                        {/*     }*/}
                        {/*>*/}
                        {/*    {mediaResource.get("LINK-TWO-NAME")["itemContent"]}*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={"footer-container-link"}>
                    <div className={"footer-container-link-title"}>
                        <Tran intlKey="CONTACT_TITLE"/>
                    </div>
                    <div className={"footer-container-link-con"}>
                        <div className={"footer-container-link-con-text"}>
                            {intl.get("CONTACT_QQ")}{': '}{mediaServiceResource.get("QQ-SERVICE")["itemContent"]}
                        </div>
                        <div className={"footer-container-link-con-text"}>
                            {intl.get("CONTACT_TELEGRAM")}{': '}{mediaServiceResource.get("TELEGRAM-SERVICE")["itemContent"]}
                        </div>
                        <div className={"footer-container-link-con-text"}>
                            {intl.get("CONTACT_EMAIL")}{': '}{mediaServiceResource.get("WECHAT-SERVICE")["itemContent"]}
                        </div>
                        <div className={"footer-container-link-con-text"}>
                            {intl.get("CONTACT_EMAIL")}{': '}{mediaServiceResource.get("EMAIL-SERVICE")["itemContent"]}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    loadingLink = (data) => {
        let items = [];
        if (data) {
            data.forEach(function (item, index) {
                let element = (
                    <div className={"footer-container-link-con-text"} key={index}>
                        {item}
                    </div>
                );
                items.push(element);
            })
            return items;
        }
    }

    componentDidMount() {
        getMedia(intl, "ABOUT", this);
        getServiceMedia(intl, "SERVICE", this);
    }

    render() {
        let isMobile = this.props.isMobile;
        let mediaResource = this.state.mediaResource;
        let isMediaLoad = this.state.isMediaLoad;
        let mediaServiceResource = this.state.mediaServiceResource;
        let isMediaServiceLoad = this.state.isMediaServiceLoad;
        let isAllLoad = isMediaLoad && isMediaServiceLoad;
        let inviteUId = this.props.inviteUId;
        let isPro = this.props.isPro;
        let toUrl = isPro ? (!inviteUId ? "/login" : "/login/" + inviteUId) : '/private'

        if (!isMobile) {
            return (
                isAllLoad ?
                <div className={"footer"}>
                    <div className={"footer-wrapper"}>
                        <div className={"footer-container"}>
                            <div className={"footer-container-area"}>
                                <div className={"footer-container-logo"}>
                                    <div className={"footer-container-logo-img"}>
                                        <img className={"mobile-img"} src={LOGO} alt={"logo"}/>
                                    </div>
                                    <div className={"footer-container-logo-text"}>
                                        <Tran intlKey="BASE"/>
                                    </div>
                                </div>
                                <div className={"footer-container-link-area"}>
                                    {this.loadingFooterL(mediaResource, mediaServiceResource, inviteUId)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"footer-copyright"}>
                        <Tran intlKey="COPYRIGHT"/>
                    </div>
                </div> : <LoadingWin parent={this} isMobile={isMobile}/>
            )
        } else {
            return (
                isAllLoad ?
                <div className={"mobile-footer"}>
                    <Collapse bordered={false} expandIconPosition={'right'}>
                        <Panel header={<Tran intlKey="PRIVATE"/>} key="1" className={"mobile-footer-panel"}>
                            <p className={"mobile-footer-panel-p"}
                               onClick={() => {window.location.href = toUrl}}
                            >
                                <Tran intlKey="PRIVATE"/>
                            </p>
                            <p className={"mobile-footer-panel-p"}
                               onClick={() => changeType("policy", this.props.dom)}
                            >
                                <Tran intlKey="SUBSCRIBE_RULE"/>
                            </p>
                        </Panel>
                        {/*<div key="2" className={"mobile-footer-panel-div"}*/}
                        {/*       style={{paddingLeft: '16px', fontSize: '16px'}}*/}
                        {/*       onClick={() => this.props.dom.setDisplay()}*/}
                        {/*>*/}
                        {/*    <Tran intlKey="CATALOGUE_CENTER"/>*/}
                        {/*</div>*/}
                        <Panel header={<Tran intlKey="CONTACT_TITLE"/>} key="3" className={"mobile-footer-panel"}>
                            <p className={"mobile-footer-panel-p"}>
                                {intl.get("CONTACT_QQ")}{': '}{mediaServiceResource.get("QQ-SERVICE")["itemContent"]}
                            </p>
                            <p className={"mobile-footer-panel-p"}>
                                {intl.get("CONTACT_TELEGRAM")}{': '}{mediaServiceResource.get("TELEGRAM-SERVICE")["itemContent"]}
                            </p>
                            <p className={"mobile-footer-panel-p"}>
                                {intl.get("CONTACT_EMAIL")}{': '}{mediaServiceResource.get("WECHAT-SERVICE")["itemContent"]}
                            </p>
                            <p className={"mobile-footer-panel-p"}>
                                {intl.get("CONTACT_EMAIL")}{': '}{mediaServiceResource.get("EMAIL-SERVICE")["itemContent"]}
                            </p>
                        </Panel>
                    </Collapse>
                    <div className={"mobile-footer-copyright"}>
                        <Tran intlKey="COPYRIGHT"/>
                    </div>
                </div> : <LoadingWin parent={this} isMobile={isMobile}/>
            )
        }
    }
}

export default Footer;
