import React from "react";
import Tran from "./IntTranslation";
import MENU_OVERVIEW from "../assets/icon/icon_overview.svg";
import MENU_ABOUT from "../assets/icon/icon_about.svg";
import MENU_PRIVATE from "../assets/icon/icon_private.svg";
import MENU_ASSETS from "../assets/icon/icon_assets.svg";
import MENU_REWARDS from "../assets/icon/icon_rewards.svg";
import MENU_ACCOUNT from "../assets/icon/icon_account.svg";
import MENU_SERVICE from "../assets/icon/icon_service.svg";
import MENU_OVERVIEW_M from "../assets/icon/icon_overview_m.svg";
import MENU_ABOUT_M from "../assets/icon/icon_about_m.svg";
import MENU_PRIVATE_M from "../assets/icon/icon_private_m.svg";
import MENU_ASSETS_M from "../assets/icon/icon_assets_m.svg";
import MENU_REWARDS_M from "../assets/icon/icon_rewards_m.svg";
import MENU_ACCOUNT_M from "../assets/icon/icon_account_m.svg";
import MENU_SERVICE_M from "../assets/icon/icon_service_m.svg";


const menus = [
    {
        path: "/overview",
        title: <Tran intlKey="OVERVIEW"/>,
        breadcrumbName: "overview",
        icon: <img src={MENU_OVERVIEW} alt={"overview"}/>,
        iconM: <img className={"mobile-img"} src={MENU_OVERVIEW_M} alt={"overview"}/>,
        role: 1,
    },
    {
        path: "/about",
        title: <Tran intlKey="ABOUT"/>,
        breadcrumbName: "about",
        icon: <img src={MENU_ABOUT} alt={"about"}/>,
        iconM: <img className={"mobile-img"} src={MENU_ABOUT_M} alt={"about"}/>,
        role: 1,
    },
    {
        path: "/private",
        title: <Tran intlKey="PRIVATE"/>,
        breadcrumbName: "private",
        icon: <img src={MENU_PRIVATE} alt={"private"}/>,
        iconM: <img className={"mobile-img"} src={MENU_PRIVATE_M} alt={"private"}/>,
        role: 1,
    },
    {
        path: "/assets",
        title: <Tran intlKey="ASSETS"/>,
        breadcrumbName: "assets",
        icon: <img src={MENU_ASSETS} alt={"assets"}/>,
        iconM: <img className={"mobile-img"} src={MENU_ASSETS_M} alt={"assets"}/>,
        role: 1,
    },
    {
        path: "/rewards",
        title: <Tran intlKey="REWARDS"/>,
        breadcrumbName: "rewards",
        icon: <img src={MENU_REWARDS} alt={"rewards"}/>,
        iconM: <img className={"mobile-img"} src={MENU_REWARDS_M} alt={"rewards"}/>,
        role: 1,
    },
    {
        path: "/account",
        title: <Tran intlKey="ACCOUNT_SETTING"/>,
        breadcrumbName: "account",
        icon: <img src={MENU_ACCOUNT} alt={"account"}/>,
        iconM: <img className={"mobile-img"} src={MENU_ACCOUNT_M} alt={"account"}/>,
        role: 1,
    },
    {
        path: "/service",
        title: <Tran intlKey="SERVICE"/>,
        breadcrumbName: "service",
        icon: <img src={MENU_SERVICE} alt={"service"}/>,
        iconM: <img className={"mobile-img"} src={MENU_SERVICE_M} alt={"service"}/>,
        role: 1,
    },
]

export default menus;
