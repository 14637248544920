const listCommonCountry = [
    {key: "KOREA", value: "82"},
    {key: "CHINA", value: "86"},
    {key: "UNITED_KINGDOM", value: "44"},
    {key: "JAPAN", value: "81"},
    {key: "HONG_KONG_CHINA", value: "852"},
    {key: "TAIWAN_CHINA", value: "886"},
    {key: "FRANCE", value: "33"},
    {key: "INDONESIA", value: "62"},
    {key: "RUSSIA", value: "7"},
    {key: "SPAIN", value: "34"},
    {key: "THAILAND", value: "66"},
    {key: "TURKEY", value: "90"},
    {key: "VIETNAM", value: "84"},
    {key: "INDIA", value: "91"},
];

const listCountry = [
    {key: "AFGHANISTAN", value: "93"},
    {key: "ALBANIA", value: "355"},
    {key: "ALGERIA", value: "213"},
    {key: "ANDORRA", value: "376"},
    {key: "ANGOLA", value: "244"},
    {key: "ANGUILLA", value: "1264"},
    {key: "ANTIGUA_AND_BARBUDA", value: "1268"},
    {key: "ARGENTINA", value: "54"},
    {key: "ARMENIA", value: "374"},
    {key: "AUSTRALIA", value: "61"},
    {key: "AUSTRIA", value: "43"},
    {key: "AZERBAIJAN", value: "994"},
    {key: "BAHAMAS", value: "1242"},
    {key: "BAHRAIN", value: "973"},
    {key: "BANGLADESH", value: "880"},
    {key: "BARBADOS", value: "1246"},
    {key: "BELARUS", value: "375"},
    {key: "BELGIUM", value: "32"},
    {key: "BELIZE", value: "501"},
    {key: "BENIN", value: "229"},
    {key: "BERMUDA", value: "1441"},
    {key: "BHUTAN", value: "975"},
    {key: "BOLIVIA", value: "591"},
    {key: "BOSNIA_AND_HERZEGOVINA", value: "387"},
    {key: "BOTSWANA", value: "267"},
    {key: "BRAZIL", value: "55"},
    {key: "VIRGIN_ISLANDS_UNITED_KINGDOM", value: "1284"},
    {key: "BRUNEI_DARUSSALAM", value: "673"},
    {key: "BULGARIA", value: "359"},
    {key: "BURKINA_FASO", value: "226"},
    {key: "BURUNDI", value: "257"},
    {key: "CAMBODIA", value: "855"},
    {key: "CAMEROON", value: "237"},
    {key: "CANADA", value: "1"},
    {key: "CAPE_VERDE", value: "238"},
    {key: "CAYMAN_ISLANDS", value: "1345"},
    {key: "CENTRAL_AFRICAN_REPUBLIC", value: "236"},
    {key: "CHAD", value: "235"},
    {key: "CHINA", value: "86"},
    {key: "CHILE", value: "56"},
    {key: "COLOMBIA", value: "57"},
    {key: "COMOROS", value: "269"},
    {key: "CONGO", value: "242"},
    {key: "DEMOCRATIC_REPUBLIC_OF_CONGO", value: "243"},
    {key: "COSTA_RICA", value: "506"},
    {key: "IVORY_COAST", value: "225"},
    {key: "CROATIA", value: "385"},
    {key: "CUBA", value: "53"},
    {key: "CURAçAO", value: "599"},
    {key: "CYPRUS", value: "357"},
    {key: "CZECH", value: "420"},
    {key: "DENMARK", value: "45"},
    {key: "DJIBOUTI", value: "253"},
    {key: "DOMINICA", value: "1767"},
    {key: "DOMINICAN_REPUBLIC", value: "1829"},
    {key: "DOMINICAN_REPUBLIC", value: "1809"},
    {key: "DOMINICAN_REPUBLIC", value: "1849"},
    {key: "ECUADOR", value: "593"},
    {key: "EGYPT", value: "20"},
    {key: "EL_SALVADOR", value: "503"},
    {key: "EQUATORIAL_GUINEA", value: "240"},
    {key: "ERITREA", value: "291"},
    {key: "ESTONIA", value: "372"},
    {key: "ETHIOPIA", value: "251"},
    {key: "FRANCE", value: "33"},
    {key: "FIJI", value: "679"},
    {key: "FINLAND", value: "358"},
    {key: "GABON", value: "241"},
    {key: "GAMBIA", value: "220"},
    {key: "GEORGIA", value: "995"},
    {key: "GERMANY", value: "49"},
    {key: "GHANA", value: "233"},
    {key: "GREECE", value: "30"},
    {key: "GRENADA", value: "1473"},
    {key: "GUADELOUPE", value: "590"},
    {key: "GUATEMALA", value: "502"},
    {key: "GUERNSEY", value: "1481"},
    {key: "GUINEA", value: "224"},
    {key: "GUINEA_BISSAU", value: "245"},
    {key: "GUYANA", value: "592"},
    {key: "HAITI", value: "509"},
    {key: "HONDURAS", value: "504"},
    {key: "HONG_KONG_CHINA", value: "852"},
    {key: "HUNGARY", value: "36"},
    {key: "ICELAND", value: "354"},
    {key: "INDONESIA", value: "62"},
    {key: "INDIA", value: "91"},
    {key: "IRAN", value: "98"},
    {key: "IRAQ", value: "964"},
    {key: "IRELAND", value: "353"},
    {key: "ISRAEL", value: "972"},
    {key: "ITALY", value: "39"},
    {key: "JAMAICA", value: "1876"},
    {key: "JAPAN", value: "81"},
    {key: "JORDAN", value: "962"},
    {key: "KAZAKHSTAN", value: "7"},
    {key: "KENYA", value: "254"},
    {key: "KIRIBATI", value: "686"},
    {key: "KOREA", value: "82"},
    {key: "NORTH_KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA", value: "850"},
    {key: "KOSOVO", value: "383"},
    {key: "KUWAIT", value: "965"},
    {key: "KYRGYZSTAN", value: "996"},
    {key: "LAO_PEOPLES_DEMOCRATIC_REPUBLIC", value: "856"},
    {key: "LATVIA", value: "371"},
    {key: "LEBANON", value: "961"},
    {key: "LESOTHO", value: "266"},
    {key: "LIBERIA", value: "231"},
    {key: "LIBYA", value: "218"},
    {key: "LIECHTENSTEIN", value: "423"},
    {key: "LITHUANIA", value: "370"},
    {key: "LUXEMBOURG", value: "352"},
    {key: "MACAO_CHINA", value: "853"},
    {key: "REPUBLIC_OF_MACEDONIA", value: "389"},
    {key: "MADAGASCAR", value: "261"},
    {key: "MALAWI", value: "265"},
    {key: "MALAYSIA", value: "60"},
    {key: "MALDIVES", value: "960"},
    {key: "MALI", value: "223"},
    {key: "MALTA", value: "356"},
    {key: "MARSHALL_ISLANDS", value: "692"},
    {key: "MARTINIQUE", value: "596"},
    {key: "MAURITANIA", value: "222"},
    {key: "MAURITIUS", value: "230"},
    {key: "MEXICO", value: "52"},
    {key: "FEDERATED_STATES_OF_MICRONESIA", value: "691"},
    {key: "REPUBLIC_OF_MOLDOVA", value: "373"},
    {key: "MONACO", value: "377"},
    {key: "MONGOLIA", value: "976"},
    {key: "MONTENEGRO", value: "382"},
    {key: "MOROCCO", value: "212"},
    {key: "MOZAMBIQUE", value: "258"},
    {key: "MYANMAR", value: "95"},
    {key: "NAMIBIA", value: "264"},
    {key: "NAURU", value: "674"},
    {key: "NEPAL", value: "977"},
    {key: "NETHERLANDS", value: "31"},
    {key: "NEW_CALEDONIA", value: "687"},
    {key: "NEW_ZEALAND", value: "64"},
    {key: "NICARAGUA", value: "505"},
    {key: "NIGER", value: "227"},
    {key: "NIGERIA", value: "234"},
    {key: "NORWAY", value: "47"},
    {key: "OMAN", value: "968"},
    {key: "PAKISTAN", value: "92"},
    {key: "PALAU", value: "680"},
    {key: "PALESTINE_PALESTINE", value: "970"},
    {key: "PANAMA", value: "507"},
    {key: "PAPUA_NEW_GUINEA", value: "675"},
    {key: "PARAGUAY", value: "595"},
    {key: "PERU", value: "51"},
    {key: "PHILIPPINES", value: "63"},
    {key: "POLAND", value: "48"},
    {key: "FRENCH_POLYNESIA", value: "689"},
    {key: "PORTUGAL", value: "351"},
    {key: "QATAR", value: "974"},
    {key: "REUNION_ISLAND", value: "262"},
    {key: "ROMANIA", value: "40"},
    {key: "RUSSIA", value: "7"},
    {key: "RWANDA", value: "250"},
    {key: "SAINT_KITTS_AND_NEVIS", value: "1869"},
    {key: "SAINT_LUCIA", value: "1758"},
    {key: "SAINT_VINCENT_AND_THE_GRENADINES", value: "1784"},
    {key: "SAMOA", value: "685"},
    {key: "SAN_MARINO", value: "378"},
    {key: "SAO_TOME_AND_PRINCIPE", value: "239"},
    {key: "SAUDI_ARABIA", value: "966"},
    {key: "SENEGAL", value: "221"},
    {key: "SERBIA", value: "381"},
    {key: "SEYCHELLES", value: "248"},
    {key: "SIERRA_LEONE", value: "232"},
    {key: "SINGAPORE", value: "65"},
    {key: "SLOVAKIA", value: "421"},
    {key: "SLOVENIA", value: "386"},
    {key: "SOLOMON_ISLANDS", value: "677"},
    {key: "SOMALIA", value: "252"},
    {key: "SOUTH_AFRICA", value: "27"},
    {key: "SOUTH_SUDAN", value: "211"},
    {key: "SPAIN", value: "34"},
    {key: "SRI_LANKA", value: "94"},
    {key: "SUDAN", value: "249"},
    {key: "SURINAME", value: "597"},
    {key: "SWAZILAND", value: "268"},
    {key: "SWEDEN", value: "46"},
    {key: "SWITZERLAND", value: "41"},
    {key: "SYRIAN_ARAB_REPUBLIC", value: "963"},
    {key: "TAIWAN_CHINA", value: "886"},
    {key: "TAJIKISTAN", value: "992"},
    {key: "UNITED_REPUBLIC_OF_TANZANIA", value: "255"},
    {key: "GIBRALTAR", value: "350"},
    {key: "COOK_ISLANDS", value: "682"},
    {key: "EAST_TIMOR", value: "670"},
    {key: "THAILAND", value: "66"},
    {key: "TOGO", value: "228"},
    {key: "TONGA", value: "676"},
    {key: "TRINIDAD_AND_TOBAGO", value: "1868"},
    {key: "TUNISIA", value: "216"},
    {key: "TURKEY", value: "90"},
    {key: "TURKMENISTAN", value: "993"},
    {key: "TUVALU", value: "688"},
    {key: "VIRGIN_ISLANDS_US", value: "1340"},
    {key: "UGANDA", value: "256"},
    {key: "UKRAINE", value: "380"},
    {key: "UNITED_ARAB_EMIRATES", value: "971"},
    {key: "UNITED_KINGDOM", value: "44"},
    {key: "UNITED_STATES", value: "1"},
    {key: "URUGUAY", value: "598"},
    {key: "UZBEKISTAN", value: "998"},
    {key: "VANUATU", value: "678"},
    {key: "VATICAN_CITY", value: "379"},
    {key: "VIETNAM", value: "84"},
    {key: "VENEZUELA", value: "58"},
    {key: "YEMEN", value: "967"},
    {key: "ZAMBIA", value: "260"},
    {key: "ZIMBABWE", value: "263"},
]

export {
    listCommonCountry,
    listCountry
}
