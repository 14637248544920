import React, {Component, Fragment} from "react";
import "./assets.css";
import {isMobile} from "../../utils/MobileMgr";
import Tran from "../../config/IntTranslation";
import intl from "react-intl-universal";
import Purchase from "../../assets/icon/icon_purchase.svg";
import Awards from "../../assets/icon/icon_award.svg";
import Ttl from "../../assets/icon/icon_ttl.svg";
import Fail from "../../assets/icon/icon_fail.png";
import CLOSE from "../../assets/icon/icon_close.svg";
import {formatCurrency, numToPercent} from "../../utils/Math";
import {getServiceMedia, sortByTimeAsc} from "../../utils/Request";
import OrderRecord from "../component/orderRecord";
import LoadingWin from "../component/LoadingWin";
import {data, findUserAwardsUrl, findUserOrdersUrl, sendGetRequest} from "../../utils/RequestUrlMgr";
import {connectionError, fail, findMsgByError} from "../../utils/ErrorMgr";
import PageLayout from "../PageLayout";
import {failModal} from "../component/Common";
import PFAIL from "../../assets/icon/icon_private_fail_m.svg";

class Assets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            isUserLoad: false,
            userPojo: '',
            orderData: [],
            inviterData: [],
            isOrderLoad: false,
            isInviterLoad: false,
            showModal: false,
            modalContent: '',
            type: 'my_record',
            currentPage: 1,
            mobileReason: false,
            mediaServiceResource: new Map(),
            isMediaServiceLoad: false,
            displayStatus: true,
        }
    }

    loadingAssets = (userPojo, isMobile) => {
        let ttl = numToPercent(userPojo.purchaseCZH, userPojo.awardCZH).ttl;
        if (!isMobile) {
            return (
                <Fragment>
                    <div className={"assets-layout-assets-title"}>
                        <Tran intlKey={"MY_ASSETS"}/>
                    </div>
                    <div className={"assets-layout-assets-content"}>
                        <div className={"assets-layout-assets-element"} style={{backgroundColor: 'rgba(232, 245, 241, 0.5)'}}>
                            <div className={"assets-layout-assets-element-icon"}>
                                <img className={"assets-layout-assets-element-icon-img"} src={Purchase} alt={"icon"}/>
                            </div>
                            <div className={"assets-layout-assets-element-content"}>
                                <div className={"assets-layout-assets-element-title"}>
                                    <div>
                                        <Tran intlKey={"CZH_EN"}/>
                                    </div>
                                    <div style={{paddingLeft: '10px'}}>
                                        <Tran intlKey={"PURCHASE_CZH"}/>
                                    </div>
                                </div>
                                <div className={"assets-layout-assets-element-num"}>
                                    {formatCurrency(userPojo.purchaseCZH)}
                                </div>
                            </div>
                        </div>
                        <div className={"assets-layout-assets-element"} style={{backgroundColor: 'rgba(247,247,254,0.5)'}}>
                            <div className={"assets-layout-assets-element-icon"}>
                                <img className={"assets-layout-assets-element-icon-img"} src={Awards} alt={"icon"}/>
                            </div>
                            <div className={"assets-layout-assets-element-content"}>
                                <div className={"assets-layout-assets-element-title"}>
                                    <div>
                                        <Tran intlKey={"CZH_EN"}/>
                                    </div>
                                    <div style={{paddingLeft: '10px'}}>
                                        <Tran intlKey={"AWARD_CZH"}/>
                                    </div>
                                </div>
                                <div className={"assets-layout-assets-element-num"}>
                                    {formatCurrency(userPojo.awardCZH)}
                                </div>
                            </div>
                        </div>
                        <div className={"assets-layout-assets-element"} style={{backgroundColor: 'rgba(248,250,255,0.5)'}}>
                            <div className={"assets-layout-assets-element-icon"}>
                                <img className={"assets-layout-assets-element-icon-img"} src={Ttl} alt={"icon"}/>
                            </div>
                            <div className={"assets-layout-assets-element-content"}>
                                <div className={"assets-layout-assets-element-title"}>
                                    <div>
                                        <Tran intlKey={"CZH_EN"}/>
                                    </div>
                                    <div style={{paddingLeft: '10px'}}>
                                        <Tran intlKey={"TOTAL_CZH"}/>
                                    </div>
                                </div>
                                <div className={"assets-layout-assets-element-num"}>
                                    {formatCurrency(ttl)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className={"mobile-assets-layout-assets-ttl"}>
                        <div className={"mobile-assets-layout-assets-ttl-title"}>
                            <Tran intlKey={"CZH_EN"}/>
                            <div style={{width: '5px'}}/>
                            <Tran intlKey={"ASSETS_TTL"}/>
                        </div>
                        <div className={"mobile-assets-layout-assets-ttl-num"}>
                            {formatCurrency(ttl)}
                        </div>
                    </div>
                    <div className={"mobile-assets-layout-assets-det"}>
                        <div className={"mobile-assets-layout-assets-det-ele"}>
                            <div className={"mobile-assets-layout-assets-det-title"}>
                                <Tran intlKey={"CZH_EN"}/>
                                <div style={{width: '5px'}}/>
                                <Tran intlKey={"PURCHASE_CZH"}/>
                            </div>
                            <div className={"mobile-assets-layout-assets-det-num"}>
                                {formatCurrency(userPojo.purchaseCZH)}
                            </div>
                        </div>
                        <div className={"mobile-assets-layout-assets-det-ele"} style={{borderLeft: '1px solid rgba(255, 255, 255, 0.3)'}}>
                            <div className={"mobile-assets-layout-assets-det-title"}>
                                <Tran intlKey={"CZH_EN"}/>
                                <div style={{width: '5px'}}/>
                                <Tran intlKey={"AWARD_CZH"}/>
                            </div>
                            <div className={"mobile-assets-layout-assets-det-num"}>
                                {formatCurrency(userPojo.awardCZH)}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    /* 弹窗 */
    openModal = (data) => {
        if (data !== '') {
            this.setState({
                showModal: true,
                modalContent: data,
            })
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    loadingModal = (showModal, modalContent, mediaServiceResource) => {
        if (showModal === true) {
            return (
                <div className={"assets-modal"}>
                    {this.loadingModalWin(modalContent, mediaServiceResource)}
                </div>
            )
        }
    }

    loadingModalWin = (modalContent, mediaServiceResource) => {
        if (modalContent && mediaServiceResource) {
            return (
                failModal(modalContent, mediaServiceResource, this)
            )
        }
    }

    // 父子类传参
    getChildrenMsg = (result, type, page) => {
        this.setState({
            type: type,
            currentPage: page,
        })
    }

    getCpMsg = (result, page) => {
        this.setState({
            currentPage: page,
        })
    }

    getModalMsg = (result, showModal, modalContent) => {
        this.setState({
            showModal: showModal,
            modalContent: modalContent,
        })
    }

    genUserOrders = () => {
        let userInfo = sessionStorage.getItem("userInfo");
        if (userInfo) {
            sendGetRequest(findUserOrdersUrl, {
                userName: JSON.parse(userInfo).userName,
            }).then(res => {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        orderData: sortByTimeAsc(res[data], "pTime"),
                        isOrderLoad: true,
                    })
                    sendGetRequest(findUserAwardsUrl, {
                        userName: JSON.parse(userInfo).userName,
                    }).then(res => {
                        if (res && res.hasOwnProperty("success") && res.success) {
                            let dataOrigin = sortByTimeAsc(res[data], "regTime")
                            let inviterData = sortByTimeAsc(dataOrigin, "lastPurchase");
                            this.setState({
                                inviterData: inviterData,
                                isInviterLoad: true,
                            })
                        } else {
                            if (res === connectionError) {
                                fail(findMsgByError(connectionError));
                                return false;
                            } else {
                                return res;
                            }
                        }
                    })
                } else {
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                        return false;
                    } else {
                        return res;
                    }
                }
            })
        }
    }

    loadingPageContent = (isAllLoad, orderData, inviterData, userPojo, type, showModal, modalContent, currentPage, mobileReason, isMobile, isUserLoad, mediaServiceResource) => {
        if (!isUserLoad) {
            return <div/>
        } else {
            if (!isMobile) {
                return (
                    isAllLoad ?
                        <Fragment>
                            {showModal && this.loadingModal(showModal, modalContent, mediaServiceResource)}
                            <div className={"assets-layout-main"}>
                                <div className={"assets-layout-assets"}>
                                    {this.loadingAssets(userPojo, isMobile)}
                                </div>
                            </div>
                            <div className={"assets-layout-msg"}>
                                <OrderRecord parent={this} isMobile={isMobile} type={type} orderData={orderData} inviterData={inviterData}
                                             showModal={showModal} currentPage={currentPage} modalContent={modalContent}/>
                            </div>
                        </Fragment> :
                        <LoadingWin parent={this} isMobile={isMobile}/>
                )
            } else {
                let visible = this.state.displayStatus;
                let modalContent = this.state.modalContent;
                if (type === "fail") {
                    return (
                        <Fragment>
                            <div style={{height: '120px'}}/>
                            <div className={"mobile-fail-img"}>
                                <img className={"img-size"} src={PFAIL} alt={""}/>
                            </div>
                            <div style={{height: '20px'}}/>
                            <div className={"mobile-fail-title"}>
                                <Tran intlKey={"PRIVATE_CHECK_FAIL"}/>
                            </div>
                            <div className={"mobile-fail-content"}>
                                <Tran intlKey={modalContent}/>
                            </div>
                            <div style={{height: '10px'}}/>
                            <div className={"mobile-fail-contact"}>
                                <div className={"mobile-fail-contact-wrap"}>
                                    <div className={"mobile-fail-contact-ele"}>
                                        <div className={"mobile-fail-contact-ele-text"}>
                                            {intl.get("CONTACT_QQ")}{"："}
                                        </div>
                                        <div className={"mobile-fail-contact-ele-info"}>
                                            {mediaServiceResource.get("QQ-SERVICE")["itemContent"]}
                                        </div>
                                        <div className={"mobile-fail-contact-ele-line"}/>
                                    </div>
                                    <div className={"mobile-fail-contact-ele"}>
                                        <div className={"mobile-fail-contact-ele-text"}>
                                            {intl.get("CONTACT_TELEGRAM")}{"："}
                                        </div>
                                        <div className={"mobile-fail-contact-ele-text"} style={{color: '#0D1E3D', fontWeight: 'bold'}}>
                                            {mediaServiceResource.get("TELEGRAM-SERVICE")["itemContent"]}
                                        </div>
                                        <div className={"mobile-fail-contact-ele-line"}/>
                                    </div>
                                    <div className={"mobile-fail-contact-ele"}>
                                        <div className={"mobile-fail-contact-ele-text"}>
                                            {intl.get("CONTACT_EMAIL")}{"："}
                                        </div>
                                        <div className={"mobile-fail-contact-ele-text"} style={{color: '#0D1E3D', fontWeight: 'bold'}}>
                                            {mediaServiceResource.get("WECHAT-SERVICE")["itemContent"]}
                                        </div>
                                        <div className={"mobile-fail-contact-ele-line"}/>
                                    </div>
                                    <div className={"mobile-fail-contact-ele"}>
                                        <div className={"mobile-fail-contact-ele-text"}>
                                            {intl.get("CONTACT_EMAIL")}{"："}
                                        </div>
                                        <div className={"mobile-fail-contact-ele-text"} style={{color: '#0D1E3D', fontWeight: 'bold'}}>
                                            {mediaServiceResource.get("EMAIL-SERVICE")["itemContent"]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className={"mobile-qr-button"}
                                    style={{width: '90%'}}
                                // onClick={() => {window.location.href = '/private'}}
                                    onClick={() => this.changeType("my_record")}
                            >
                                <Tran intlKey={"FACE_TO_FACE_GET"}/>
                            </button>
                            <div style={{height: '40px'}}/>
                        </Fragment>
                    )
                }
                else {
                    return (
                        isAllLoad ?
                            <Fragment>
                                <div style={{height: '80px'}}/>
                                <div style={{display: visible ? '' : 'none'}}>
                                    <div className={"mobile-assets-layout-wrapper"}>
                                        <div className={"mobile-assets-layout-assets"}>
                                            {userPojo && this.loadingAssets(userPojo, isMobile)}
                                        </div>
                                        <div className={"corner-area"}>
                                            <div className={"corner"}/>
                                        </div>
                                        <OrderRecord parent={this} isAssets={true}
                                                     isMobile={isMobile} type={type} orderData={orderData} inviterData={inviterData} isFresh={false}
                                                     showModal={showModal} currentPage={currentPage} modalContent={modalContent} mobileReason={mobileReason}/>
                                    </div>
                                </div>
                            </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
                    )
                }
            }
        }
    }

    genMobileReason = (item) => {
        this.setState({
            type: 'fail',
            modalContent: item,
        })
    }

    changeType = (type) => {
        console.log(11111)
        this.setState({
            type: type,
        })
    }

    setMobileReason = () => {
        this.setState({
            mobileReason: true,
        })
    }

    // 用户基础信息
    setUsrInfo = (userInfo, userPojo, isUserLoad) => {
        this.setState({
            userPojo: userPojo,
            isUserLoad: isUserLoad,
        })
    }

    componentDidMount() {
        this.genUserOrders();
        getServiceMedia(intl, "SERVICE", this);
    }

    render() {
        let type = this.state.type;
        let isMobile = this.state.isMobile;

        let isUserLoad = this.state.isUserLoad;
        let userPojo = this.state.userPojo;

        let orderData = this.state.orderData;
        let isOrderLoad = this.state.isOrderLoad;
        let inviterData = this.state.inviterData;
        let isInviterLoad = this.state.isInviterLoad;

        let mediaServiceResource = this.state.mediaServiceResource;
        let isMediaServiceLoad = this.state.isMediaServiceLoad;

        let isAllLoad = isOrderLoad && isInviterLoad && isMediaServiceLoad;

        let showModal = this.state.showModal;
        let currentPage = this.state.currentPage;
        let modalContent = this.state.modalContent;
        let mobileReason = this.state.mobileReason;

        console.log("用户信息：", userPojo)
        console.log("订单详情：", orderData)
        console.log("邀请详情：", inviterData)

        return (
            <PageLayout parent={this} pNameCN={intl.get("ASSETS")} pNameEN={intl.get("ASSETS_EN")}
                        needUser={true}
                        dom={this}
                        pContent={this.loadingPageContent(isAllLoad, orderData, inviterData, userPojo, type, showModal, modalContent, currentPage, mobileReason, isMobile, isUserLoad, mediaServiceResource)}
            />
        )
    }
}

export default Assets;
