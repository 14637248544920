import React, {Component, Fragment} from "react";
import "./promotion.css";
import {Collapse, Dropdown, Input, Menu} from "antd";
import intl from "react-intl-universal";
import {Header} from "antd/es/layout/layout";
import Tran from "../../config/IntTranslation";
import {isMobile} from "../../utils/MobileMgr";
import {DownOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import PageHeader from "../PageHeader";
import Footer from "../footer/Footer";
import {
    orderPAmountChecker,
    userEmailChecker,
    userPasswordChecker,
    userPhoneChecker
} from "../../utils/UserInputChecker";
import {
    data,
    error,
    genPojo,
    getCountryCodeUrl,
    sendGetRequest,
    sendPostRequest,
    userLoginUrl, webHeader
} from "../../utils/RequestUrlMgr";
import {
    changeType,
    genCoreInfos,
    genLoginInfo,
    getErrorMsg,
    langType,
    getMedia,
    getServiceMedia,
    handleCountDown,
    saveSession
} from "../../utils/Request";
import {connectionError, fail, findMsgByError, findMsgBySuccess, success} from "../../utils/ErrorMgr";
import {sendEmailValid, sendPhoneValid} from "../../utils/Verfiy";
import LOGO from "../../assets/icon/logo.png";
import BANNER from "../../assets/img/promotion_banner.png";
import BANNER_M from "../../assets/img/promotion_banner_m.png";
import PRO_ONE from "../../assets/icon/promtion_pro_one.svg";
import PRO_TWO from "../../assets/icon/promtion_pro_two.svg";
import PRO_THREE from "../../assets/icon/promtion_pro_three.svg";
import PRO_FOUR from "../../assets/icon/promtion_pro_four.svg";
import WECHAT from "../../assets/icon/promotion_wechat.svg";
import QQ from "../../assets/icon/promotion_qq.svg";
import TEL from "../../assets/icon/promotion_tel.svg";
import EMAIL from "../../assets/icon/promotion_email.svg";
import PRO_INTR from "../../assets/img/promotion_intr.png";
import CZH from "../../assets/icon/icon_czh.svg";
import USDT from "../../assets/icon/icon_usdt.svg";
import TITLE_BG from "../../assets/icon/promotion_process_title_bg.svg";
import TITLE_BG_TWO from "../../assets/icon/promotion_about_title_bg.svg";
import BPS from "../../assets/icon/promotion_icon_bps.svg";
import CLOSE from "../../assets/icon/icon_close.svg";
import CLOSE_GRAY from "../../assets/icon/icon_close_gray.svg";
import TUTORIAL from "../../assets/icon/icon_process.png";
import {changeLoginWay, checkPost, loadingRegisterProcess} from "../component/Common";
import NP from "number-precision";
import PageLayout from "../PageLayout";
import MenuSelector from "../menu/MenuSelector";
import TIP from "../../assets/icon/icon_tip.svg";
import LoadingWin from "../component/LoadingWin";
import DocumentTitle from "react-document-title";

const { Panel } = Collapse;

class Promotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            isLogin: false,
            isCoreLoad: false,
            coreInfo: new Map(),
            status: 'login',
            type: 'home',
            submitStatus: 0,
            modalType: "",
            showModal: false,
            modalStatus: '',
            modalContent: '',
            registerResponse: {success: "", errorType: ""},
            loginResponse: {errorType: "", responseData: "", success: ""},
            regCode: 1,
            mPwd: '',
            mPwdV: '',
            goPwd: '',
            param: '',
            secCode: '',
            second: 120,
            resend: 120,
            userPojo: '',
            userInfo: {
                userName: '',
                userPwd: '',
                uId: '',
                inviterName: '',
            },
            language: this.props.match.params.lang,
            mediaResource: new Map(),
            isMediaLoad: false,
            mediaServiceResource: new Map(),
            isMediaServiceLoad: false,
            countryStatus: false,
            inviteUId: this.props.match.params.inviteUId,
            payDefault: 900,
            buyValue: '',
            payValue: '',
            displayStatus: true,
            currentDisplay: true,
            otherDisplay: true,
            countryCode: intl.get("LANG").toString() === "0" ? "86" : "66",
            curList: [],
        }
    }

    registerInfo = {
        registerPojo: {
            userName: '',
            country: '',
            regCode: 1,
            userPhone: '',
            userEmail: '',
            userPwd: '',
            inviteUId: '',
        }
    }

    loginInfo = {
        loginPojo: {
            userName: '',
            userPwd: '',
            step: 1,
            useUserName: false,
        }
    }

    /* header */
    loadingHeader = (langMenu, inviteUId) => {
        let isMobile = this.state.isMobile;
        let isLogin = this.state.isLogin;
        let userPojo = this.state.userPojo;

        let lang = intl.get("LANG").toString() === "0";
        let loginUrl = lang ? "/cn/login" : "/en/login";
        let regUrl = lang ? "/cn/register" : "/en/register";

        if (!isMobile) {
            return (
                <Header className={"promotion-header"}>
                    <div className={"promotion-header-nav"}>
                        <div className={"promotion-header-nav-logo"}>
                            <div className={"promotion-header-nav-logo-img"}>
                                <img className={"mobile-img"} src={LOGO} alt={"logo"}/>
                            </div>
                            <div className={"promotion-header-nav-logo-text"}>
                                <Tran intlKey="BASE"/>
                            </div>
                        </div>
                        <div className={"promotion-header-nav-function"}>
                            <div className={"promotion-header-nav-function-personal"}
                                 onClick={() => {window.location.href = !inviteUId ? loginUrl : loginUrl + "/" +  inviteUId}}
                            >
                                <Tran intlKey="PERSONAL_ENTRY"/>
                                {/*<div className={"promotion-header-nav-function-personal-icon"}>*/}
                                {/*    <img className={"img-size"} src={CENTER} alt={"center"}/>*/}
                                {/*</div>*/}
                            </div>
                            <div className={"promotion-header-nav-function-login"}
                                 onClick={() => {window.location.href = !inviteUId ? loginUrl : loginUrl + "/" + inviteUId}}
                                 // onClick={() => {this.openModal("login")}}
                            >
                                <Tran intlKey="LOGIN"/>
                            </div>
                            <div className={"promotion-header-nav-function-register"}
                                 onClick={() => {window.location.href = !inviteUId ? regUrl : regUrl + "/" + inviteUId}}
                                 // onClick={() => {this.openModal("register")}}
                            >
                                <Tran intlKey="REGISTER"/>
                            </div>
                        </div>
                        <div className={"promotion-language-box"}>
                            <Dropdown className={"login-page-language-box"} overlay={langMenu} placement="topCenter" trigger={['click']}>
                                <div>
                                    {
                                        intl.get("LANG").toString() === "0" ?
                                            <Tran intlKey="CHINESE_SIMPLE"/> :  <Tran intlKey="ENGLISH"/>
                                    }
                                    <DownOutlined style={{marginLeft: '10px'}}/>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </Header>
            );
        } else {
          return (
              <div className={"mobile-promotion-header"}>
                 <PageHeader isMobile={isMobile} isLogin={isLogin} userPojo={userPojo} dom={this} pageName={intl.get("PROMOTION")}/>
              </div>
          )
        }
    }

    checkAllInput = (status) => {
        if (status === "login") {
            return true;
        } else {
            let countryCode = this.state.countryCode;
            let registerPojo = this.registerInfo.registerPojo;
            let regCode = registerPojo["regCode"];
            registerPojo["country"] = countryCode;
            console.log(registerPojo);
            if (regCode === 1) {
                return !(registerPojo["userName"] === "" || registerPojo["userPwd"] === "" || registerPojo["country"] === "" || registerPojo["userPhone"] === "");
            } else {
                return !(registerPojo["userName"] === "" || registerPojo["userPwd"] === "" || registerPojo["country"] === "" || registerPojo["userEmail"] === "");
            }
        }
    }

    /* 弹窗 */
    openModal = (status, props, isMobile) => {
        if (props) {
            let allInfoFilled = this.checkAllInput(status);
            console.log("弹窗:", allInfoFilled)
            if (allInfoFilled) {
                if (!isMobile) {
                    this.setState({
                        status: status,
                        showModal: true,
                    })
                } else {
                    this.setState({
                        type: props,
                    })
                }
            }
        this.setState({
            submitStatus: 1,
        })
        } else {
            if (!isMobile) {
                this.setState({
                    status: status,
                    showModal: true,
                })
            } else {
                this.setState({
                    type: props,
                })
            }
            this.setState({
                submitStatus: 0,
            })
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    loadingModal = (showModal, status, loginPojo, loginInfo, registerPojo, userInfo, mediaResource, isMobile, loginUrl, regUrl) => {
        if (showModal === true) {
            return (
                <div className={"promotion-modal"}>
                    {this.loadingModalWin(status, loginPojo, loginInfo, registerPojo, userInfo, mediaResource, isMobile, loginUrl, regUrl)}
                </div>
            )
        }
    }

    loadingModalMobile = (showModal, modalContent) => {
        if (showModal === true) {
            return (
                <div className={"private-modal"}>
                    <div className={"private-modal-win"}
                         style={{
                             justifyContent: 'flex-start',
                             top: 'calc(50% - 175px)',
                             left: 'calc(50% - 300px)',
                             padding: '20px 0',
                             width: '600px',
                             height: '350px'
                         }}
                    >
                        <div className={"private-modal-win-title"} style={{height: '15%'}}>
                            <Tran intlKey={"PRIVATE_POLICY_TITLE"}/>
                        </div>
                        <div className={"private-modal-win-container"}
                             style={{width: '90%', lineHeight: '30px', color: '#666666'}}>
                            {modalContent}
                        </div>
                    </div>
                    <button className={"private-modal-win-close"}
                            style={{top: 'calc(50% - 185px)', left: 'calc(50% + 300px)'}}
                            onClick={() => {
                                this.closeModal()
                            }}
                    >
                        <img className={"img-size"} src={CLOSE} alt={"clo"}/>
                    </button>
                </div>
            )
        }
    }

    loadingModalWin = (status, loginPojo, loginInfo, registerPojo, userInfo, mediaResource, isMobile, loginUrl, regUrl) => {
        // let status = this.state.status;
        let regCode = this.state.regCode;
        // let loginPojo = this.loginInfo.loginPojo;
        // let loginInfo = this.state.loginResponse.responseData;
        // let registerPojo = this.registerInfo.registerPojo;
        let useUserName = loginPojo && loginPojo["useUserName"];
        let time = this.state.second;
        let resend = this.state.resend;
        let canSend = time === resend;
        if (status === "login") {
            return (
                <Fragment>
                    <div className={"promotion-modal-login-win"}>
                        <div className={"login-layout-win-welcome"}>
                            <Tran intlKey="LOGIN_WELCOME"/>
                        </div>
                        <div className={"login-layout-win-login"}>
                            <div className={"login-layout-win-login-element"}>
                                <div className={"login-layout-win-login-text"}>
                                    {
                                        useUserName ?
                                            <Tran intlKey="ACCOUNT"/> :
                                            <Tran intlKey="PHONE_EMAIL"/>
                                    }
                                </div>
                                <div className={"login-layout-win-login-input-wrap"} key={useUserName ? "userNamePwd" : "otherPwd"}>
                                    <Input className={"login-layout-win-login-input"}
                                           size="middle"
                                           style={{width: '100%', height: '100%'}}
                                           placeholder={useUserName ? intl.get("ACCOUNT_PH") : intl.get("PHONE_EMAIL_PH")}
                                           maxLength={20}
                                           // prefix={<UserOutlined style={{color: "grey"}}/>}
                                           onChange={(e) => genLoginInfo(e, useUserName ? "userName" : "userNameL", this)}
                                           onKeyDown={e => this.onKeyDownChange(e)}
                                    />
                                </div>
                            </div>
                            <div className={"register-layout-win-input-notice"}>
                                {getErrorMsg(useUserName ? "userNameL" : "userNameLP", this)}
                            </div>
                            <div className={"login-layout-win-login-element"}>
                                <div className={"login-layout-win-login-text"}>
                                    <Tran intlKey="PASSWORD"/>
                                </div>
                                <div className={"login-layout-win-login-input-wrap"} key={useUserName ? "userName" : "other"}>
                                    <Input.Password
                                        className={"login-layout-win-login-input"}
                                        size={"middle"}
                                        style={{width: '100%', height: '100%'}}
                                        placeholder={intl.get("PASSWORD_PH")}
                                        maxLength={20}
                                        // prefix={<KeyOutlined style={{color: "grey"}}/>}
                                        iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                        onChange={(e) => genLoginInfo(e, "userPwd", this)}
                                        onKeyDown={e => this.onKeyDownChange(e)}
                                    />
                                </div>
                            </div>
                            <div className={"register-layout-win-input-notice"}>
                                {getErrorMsg("userPwdL", this)}
                            </div>
                            <div className={"login-layout-win-login-button"} onClick={() => {this.loadingVer(isMobile)}}>
                                <Tran intlKey="LOGIN"/>
                            </div>
                            <div className={"login-layout-win-login-other"}>
                                <div className={"login-layout-win-login-qr"} onClick={() => {this.openModal("register")}}>
                                    <Tran intlKey="REGISTER"/>
                                </div>
                                <div className={"login-layout-win-login-qr"} onClick={() => {changeLoginWay(loginPojo, this)}}>
                                    {
                                        useUserName ?
                                            <Tran intlKey="LOGIN_NO_USE_USERNAME"/> :
                                            <Tran intlKey="LOGIN_USE_USERNAME"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className={"promotion-modal-login-win-close"} onClick={() => {this.closeModal()}}>
                        <img src={CLOSE} alt={"clo"}/>
                    </button>
                </Fragment>
            )
        }
        if (status === "verify") {
            return (
                <Fragment>
                    <div className={"modal-go-win"}>
                        <div className={"modal-go-win-title"}>
                            {
                                loginInfo.vGo ?
                                    <Tran intlKey={"VGO_VER_PH"}/> :
                                    <Tran intlKey={"REGISTER_VER_TITLE"}/>
                            }
                        </div>
                        {
                            loginInfo.vGo ?
                                <Fragment>
                                    <div style={{height: '50px'}}/>
                                    <div className={"modal-go-win-ver"}>
                                        <Input
                                            className={"modal-go-win-input"}
                                            size="middle" placeholder={intl.get("REGISTER_VER_TITLE")}
                                            // prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                            maxLength={8}
                                            onChange={(e) => this.setCode(e, "secCode")}
                                        />
                                    </div>
                                    <div style={{height: '50px'}}/>
                                </Fragment> :
                                <Fragment>
                                    <div className={"modal-win-send"}>
                                        <div style={{color: '#666666'}}>
                                            <Tran intlKey={"REGISTER_VER_SEND"}/>
                                            {" "}
                                            {loginInfo.vPhone ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                            {"："}
                                        </div>
                                        <div style={{ color: '#236BF3'}}>
                                            {loginInfo.vPhone ? loginInfo.userPhone : loginInfo.userEmail}
                                        </div>
                                    </div>
                                    <div className={"modal-win-ver"}>
                                        <Input
                                            className={"modal-win-input"}
                                            size="middle"
                                            style={{width: '100%'}}
                                            placeholder={intl.get("REGISTER_VER_TITLE")}
                                            // prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                            maxLength={8}
                                            onChange={(e) => this.setCode(e, "secCode")}
                                        />
                                        <div className={"modal-win-send-button"}
                                             style={{position: 'relative', marginLeft: '5px', right: '0', height: '45px', border: '0', borderRadius: '5px', background: !canSend ? "#666666" : "#236BF3", color: 'white'}}
                                             onClick={() => {canSend && this.handleSendButton(resend, status, loginInfo)}}
                                        >
                                            {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                                        </div>
                                    </div>
                                </Fragment>
                        }
                        <button className={"login-go-modal-reg-button"}
                                onClick={() => (checkPost(this, this.state.secCode, "verify", status, loginInfo, loginPojo, userInfo))}
                        >
                            <Tran intlKey={"LOGIN"}/>
                        </button>
                    </div>
                    <button className={"modal-go-win-close"}
                            style={{top: 'calc(50% - 140px)', left: 'calc(50% + 150px)'}}
                            onClick={() => {this.closeModal()}}
                    >
                        <img className={"img-size"} src={CLOSE_GRAY} alt={"close"}/>
                    </button>
                </Fragment>
            )
        }
        if (status === "register") {
            return (
                <Fragment>
                    <div className={"promotion-modal-register-win"}>
                        <div className={"promotion-modal-register-win-nav"}>
                            <div className={regCode === 1 ? "nav-reg-selected" : "nav-reg"} onClick={() => {this.changeRegCode(1)}}>
                                <Tran intlKey="REGISTER_PHONE"/>
                            </div>
                            <div className={regCode === 2 ? "nav-reg-selected" : "nav-reg"} onClick={() => {this.changeRegCode(2)}}>
                                <Tran intlKey="REGISTER_EMAIL"/>
                            </div>
                        </div>
                        <div className={"promotion-modal-register-win-container"}>
                            {this.loadingRegister(registerPojo, isMobile)}
                            <div className={"login-layout-win-login-button"} onClick={() => {this.openModal("regV", "regV")}}>
                                <Tran intlKey="NEXT_STEP"/>
                            </div>
                            <div className={"promotion-modal-register-win-other"}>
                                <div className={"promotion-modal-register-win-other-left"}>
                                    <Tran intlKey="HAS_ACCOUNT"/>
                                </div>
                                <div className={"promotion-modal-register-win-other-right"} onClick={() => {this.openModal("login")}}>
                                    <Tran intlKey="LOGIN_IMM"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className={"promotion-modal-login-win-close"}
                            style={{top: 'calc(50% - 235px)', left: 'calc(50% + 200px)'}}
                            onClick={() => {this.closeModal()}}>
                        <img src={CLOSE} alt={"clo"}/>
                    </button>
                </Fragment>
            )
        }
        if (status === "regV") {
            return (
                <Fragment>
                    <div className={"modal-go-win"}>
                        <div className={"modal-go-win-title"}>
                            <Tran intlKey={"REGISTER_VER_TITLE"}/>
                        </div>
                        <div className={"modal-win-send"}>
                            <div>
                                <Tran intlKey={"REGISTER_VER_SEND"}/>
                                {" "}
                                {registerPojo["regCode"] === 1 ? <Tran intlKey={"PHONE"}/> : <Tran intlKey={"EMAIL"}/>}
                                {"："}
                            </div>
                            <div style={{ color: '#236BF3'}}>
                                {registerPojo["regCode"] === 1 ? registerPojo["userPhone"] : registerPojo["userEmail"]}
                            </div>
                        </div>
                        <div className={"modal-win-ver"}>
                            <Input
                                className={"modal-win-input"}
                                size="middle"
                                style={{width: '100%'}}
                                placeholder={intl.get("REGISTER_VER_TITLE")}
                                // prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                maxLength={8}
                                onChange={(e) => this.setCode(e, "secCode")}
                            />
                            <div className={"modal-win-send-button"}
                                 style={{position: 'relative', marginLeft: '5px', right: '0', height: '45px', border: '0', borderRadius: '5px', background: !canSend ? "#666666" : "#236BF3", color: 'white'}}
                                 onClick={() => {canSend && this.handleSendButton(resend, status, registerPojo)}}
                            >
                                {!canSend ? "( " + time + " ) " + intl.get("SECOND") : intl.get("VER_CODE_SEND")}
                            </div>
                        </div>
                        <button className={"login-go-modal-reg-button"}
                                onClick={() => (checkPost(this, this.state.secCode, "verify", status, registerPojo, loginPojo, userInfo))}
                        >
                            <Tran intlKey={"REGISTER"}/>
                        </button>
                    </div>
                    <button className={"modal-go-win-close"}
                            style={{top: 'calc(50% - 140px)', left: 'calc(50% + 150px)'}}
                            onClick={() => {this.closeModal()}}
                    >
                        <img className={"img-size"} src={CLOSE_GRAY} alt={"close"}/>
                    </button>
                </Fragment>
            )
        }
        if (status === "rule") {
            return (
                <Fragment>
                    <div className={"modal-rule-win"}>
                        <div className={"modal-rule-win-title"}>
                            <Tran intlKey="PRIVATE_POLICY_TITLE"/>
                        </div>
                        <div className={"modal-rule-win-text"}>
                            {mediaResource.get("POLICY-ONE")["itemContent"]}
                            <br/>
                            <br/>
                            {mediaResource.get("POLICY-TWO")["itemContent"]}
                            <br/>
                            <br/>
                            {mediaResource.get("POLICY-THREE")["itemContent"]}
                            <br/>
                            <br/>
                            {mediaResource.get("POLICY-FOUR")["itemContent"]}
                        </div>
                    </div>
                    <button className={"modal-rule-win-close"} onClick={() => {this.closeModal()}}>
                        <img src={CLOSE} alt={"clo"}/>
                    </button>
                </Fragment>
            )
        }
    }

    handleSendButton = (resend, status, pojo, label) => {
        let lang = intl.get("LANG").toString() === "0" ? "zh" : "en";
        if (status === "verify") {
            if (pojo.vGo) {
                console.log("vGo start")
            } else {
                if (pojo.vPhone) {
                    sendPhoneValid(pojo.userPhone, pojo.country).then(result => {
                        console.log("sendPhone：", result)
                        if (result) {
                            handleCountDown(resend, this);
                            success(findMsgBySuccess("VER_SEND_SUCCESS"))
                        } else {
                            fail(findMsgByError("VER_SEND_ERROR"))
                        }
                    })
                } else {
                    sendEmailValid(pojo.userEmail, lang).then(result => {
                        console.log("sendEmail：", result)
                        if (result) {
                            handleCountDown(resend, this);
                            success(findMsgBySuccess("VER_SEND_SUCCESS"))
                        } else {
                            fail(findMsgByError("VER_SEND_ERROR"))
                        }
                    })
                }
            }
        }
        if (status === "regV") {
            if (pojo["regCode"] === 1) {
                sendPhoneValid(pojo.userPhone, pojo.country).then(result => {
                    console.log("sendPhone：", result)
                    if (result) {
                        handleCountDown(resend, this);
                        success(findMsgBySuccess("VER_SEND_SUCCESS"))
                    } else {
                        fail(findMsgByError("VER_SEND_ERROR"))
                    }
                })
            } else {
                sendEmailValid(pojo.userEmail, lang).then(result => {
                    console.log("sendEmail：", result)
                    if (result) {
                        handleCountDown(resend, this);
                        success(findMsgBySuccess("VER_SEND_SUCCESS"))
                    } else {
                        fail(findMsgByError("VER_SEND_ERROR"))
                    }
                })
            }
        }
    }

    setCode = (e, props) => {
        let inputValue = e.target.value;
        console.log("input：", inputValue)
        switch (props) {
            case "secCode": {
                if (inputValue) {
                    this.setState({
                        secCode: inputValue,
                    })
                } else {
                    this.setState({
                        secCode: "",
                    })
                }
                return;
            }
            case "param": {
                if (inputValue.length > 0) {
                    if (inputValue.includes("@")) {
                        console.log("email test")
                        if (userEmailChecker.test(inputValue)) {
                            console.log("email pass")
                            this.setState({
                                param: inputValue,
                            })
                        } else {
                            this.setState({
                                param: "",
                            })
                        }
                    } else {
                        if (userPhoneChecker.test(inputValue)) {
                            this.setState({
                                param: inputValue,
                            })
                        } else {
                            this.setState({
                                param: "",
                            })
                        }
                    }
                } else {
                    this.setState({
                        param: "",
                    })
                }
                return;
            }
            case "goPwd": {
                if (inputValue) {
                    this.setState({
                        goPwd: inputValue,
                    })
                } else {
                    this.setState({
                        goPwd: "",
                    })
                }
                return;
            }
            case "mPwd": {
                if (inputValue && userPasswordChecker.test(inputValue)) {
                    this.setState({
                        mPwd: inputValue,
                    })
                } else {
                    this.setState({
                        mPwd: "",
                    })
                }
                return;
            }
            case "mPwdV": {
                if (inputValue && userPasswordChecker.test(inputValue)) {
                    this.setState({
                        mPwdV: inputValue,
                    })
                } else {
                    this.setState({
                        mPwdV: "",
                    })
                }
                return;
            }
            default: {
                return;
            }
        }
    }

    loadingVer = () => {
        let pojo = this.loginInfo.loginPojo;
        let userName = pojo.userName;
        let userPwd = pojo.userPwd;
        let userCheck = userName === "" || userPwd === "";
        if (!userCheck) {
            let loginPojo = genPojo(userLoginUrl, pojo);
            console.log("用户登录传参：", pojo)
            if (loginPojo) {
                console.log("11111：", pojo)
                sendPostRequest(loginPojo).then(res => {
                    console.log(res);
                    this.setState({
                        loginResponse: res,
                    })
                    if (res && res.hasOwnProperty("success") && res.success) {
                        let responseData = res[data];
                        let userInfo = this.state.userInfo;
                        userInfo["userName"] = pojo.userName;
                        userInfo["userPwd"] = pojo.userPwd;
                        saveSession("userInfo", userInfo)
                        console.log("登陆验证res：", responseData);
                        this.setStatus("verify")
                    } else {
                        if (res === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            fail(findMsgByError(res[error]))
                        }
                    }
                })
            }
        } else {
            this.setState({
                submitStatus: 1,
            })
        }
    }

    setStatus = (type) => {
        this.setState({
            status: type,
        })
    }

    onKeyDownChange(e) {
        if (e.keyCode === 13) {
            // this.register()
        }
    }

    changeRegCode = (data) => {
        let registerPojo = this.registerInfo.registerPojo;
        console.log(registerPojo)
        registerPojo["regCode"] = data;
        registerPojo["userPhone"] = 0;
        registerPojo["userEmail"] = "";
        console.log("重置后：", registerPojo)
        this.setState({
            regCode: data,
            submitStatus: 0,
            registerPojo
        })
    }

    genSearch = (e, list) => {
        let inputValue = e.target.value;
        console.log("ip search:", inputValue)
        let searchList = [];
        if (inputValue) {
            list.forEach((item) => {
                for (let i in item) {
                    if (item.hasOwnProperty(i) && item[i]) {
                        let hsVal = item[i] + "";
                        let value = inputValue.replace("+", "")
                        if (hsVal.includes(value)) {
                            console.log("hsVal:", hsVal)
                            searchList.push(item);
                        }
                    }
                }
            })
            console.log("ip list:", searchList)
        }
        this.setState({
            curList: searchList,
        })
    }

    loadingRegister = (registerPojo, isMobile) => {
        let inviteUId = this.state.inviteUId;
        let countryStatus = this.state.countryStatus;
        let curList = this.state.curList;
        if (inviteUId) {
            registerPojo["inviteUId"] = inviteUId;
        }
        return (
            loadingRegisterProcess(this, registerPojo, inviteUId, countryStatus, curList, isMobile)
        )
    }

    /* ip interface */
    genCountryCode = () => {
        sendGetRequest(getCountryCodeUrl).then(res => {
            console.log("区号：", res)
            // let registerPojo = this.registerInfo.registerPojo;
            // registerPojo["country"] = res.replaceAll("+", "");
            // this.setState({
            //     registerPojo
            // })
            let code = res.replace("+", "");
            this.setState({
                countryCode: code,
            })
        })
    }

    genProcessOrder = (e, minValue, unitPrice, props) => {
        let inputValue = e.target.value;
        switch (props) {
            case "pAmount": {
                if (inputValue && orderPAmountChecker.test(inputValue) && parseInt(inputValue) >= parseInt(minValue)) {
                    let buyValueInput = parseInt(inputValue);
                    let payValueInput = parseInt(NP.times(inputValue, unitPrice).toString());
                    this.setState({
                        buyValue: buyValueInput,
                        payValue: payValueInput,
                    })
                } else {
                    this.setState({
                        buyValue: '',
                        payValue: '',
                    })
                }
                return;
            }
            default: {
                return;
            }
        }
    }

    setDisplay = (status) => {
        this.setState({
            displayStatus: status,
        })
    }

    loadMenu = (visible, isMobile) => {
        if (visible) {
            return null;
        }
        return (
            <MenuSelector isMobile={isMobile} isLogin={false} dom={this}/>
        )
    }

    loadingPageContent = (isAllLoad, isMediaLoad, isMediaServiceLoad, displayStatus, mediaResource, coreInfo, mediaServiceResource, showModal, modalContent, type, isMobile, buyValue, payValue, payDefault, loginUrl, regUrl) => {
        if (type === "policy") {
            return (
                <Fragment>
                    <div style={{height: '80px'}} id={"pageHeader"}/>
                    <div className={"mobile-policy-title"}>
                        <Tran intlKey={"PRIVATE_POLICY_TITLE"}/>
                    </div>
                    <div className={"mobile-policy-content"}>
                        {mediaResource.get("POLICY-ONE")["itemContent"]}
                        <br/>
                        <br/>
                        {mediaResource.get("POLICY-TWO")["itemContent"]}
                        <br/>
                        <br/>
                        {mediaResource.get("POLICY-THREE")["itemContent"]}
                        <br/>
                        <br/>
                        {mediaResource.get("POLICY-FOUR")["itemContent"]}
                    </div>
                    <button className={"mobile-qr-button"}
                            onClick={() => changeType("home", this)}
                    >
                        <Tran intlKey={"FACE_TO_FACE_GET"}/>
                    </button>
                    <div style={{height: '40px'}}/>
                </Fragment>
            )
        } else {
            let inviteUId = this.state.inviteUId;
            let visible = this.state.displayStatus;
            let lang = intl.get("LANG").toString() === "0";
            return (
                <div className={"mobile-promotion-layout"}>
                    <div style={{height: '80px'}} id={"pageHeader"}/>
                    {
                        isAllLoad ?
                            <Fragment>
                                {this.loadMenu(visible, isMobile)}
                                <div style={{display: displayStatus ? '' : 'none'}}>
                                    <div className={"mobile-promotion-wrapper"}>
                                        <div className={"mobile-promotion-wrapper-banner"} style={{height: !lang ? '450px' : null}}>
                                            <img className={"mobile-promotion-wrapper-banner-img"} src={BANNER_M} alt={"banner"}/>
                                            <div className={"mobile-promotion-wrapper-banner-content"} style={{top: !lang ? '-380px' : null, width: !lang ? '88%' : null}}>
                                                <div className={"mobile-promotion-wrapper-banner-content-slogan"}>
                                                    <Tran intlKey="SLOGAN"/>
                                                </div>
                                                <div className={"mobile-promotion-wrapper-banner-content-slogan-detail"}>
                                                    {mediaResource.get("BANNER-CONTENT")["itemContent"]}
                                                </div>
                                                <button className={"mobile-promotion-wrapper-banner-content-private-button"}
                                                        onClick={() => {window.location.href = !inviteUId ? loginUrl : loginUrl + inviteUId}}
                                                >
                                                    <Tran intlKey="MOBILE_PRIVATE_BUTTON"/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className={"mobile-promotion-wrapper-purchase"}>
                                            <div className={"mobile-promotion-wrapper-purchase-table"}>
                                                <div className={"mobile-promotion-wrapper-purchase-table-header"}>
                                                    <div className={"mobile-promotion-wrapper-purchase-table-header-title"}>
                                                        <Tran intlKey="PRIVATE_TABLE_TITLE"/>
                                                    </div>
                                                    <div className={"mobile-promotion-wrapper-purchase-table-header-rule"}
                                                         onClick={() => changeType("policy", this)}
                                                    >
                                                        <Tran intlKey="PRIVATE_TABLE_RULE_LINK"/>
                                                    </div>
                                                </div>
                                                <div className={"mobile-promotion-wrapper-purchase-table-price"}>
                                                    <div className={"mobile-price-icon"}>
                                                        {/*<ExclamationCircleOutlined/>*/}
                                                        <img className={"img-size"} src={TIP} alt={""}/>
                                                    </div>
                                                    <div className={"mobile-price-bold"}>
                                                        <Tran intlKey="MOBILE_PRIVATE_PRICE"/>
                                                    </div>
                                                    <div className={"mobile-price-gray"}>
                                                        {coreInfo && coreInfo.get("CZH_PRICE")["itemValue"]}
                                                        {" USDT"}
                                                        {
                                                            lang ?
                                                                <Fragment>
                                                                    （
                                                                    {coreInfo && coreInfo.get("CZH_MIN")["itemValue"].slice(0, -4)}
                                                                    <Tran intlKey={"PRIVATE_TABLE_RULE_TWO_UNIT"}/>
                                                                    <Tran intlKey="PRIVATE_TABLE_RULE_TWO"/>
                                                                    ）
                                                                </Fragment> : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className={"mobile-promotion-wrapper-purchase-table-input"}>
                                                    <div className={"mobile-promotion-wrapper-purchase-table-input-element"}>
                                                        <div className={"mobile-input-element-text"}>
                                                            <Tran intlKey="PRIVATE_TABLE_BUY"/>
                                                        </div>
                                                        <input
                                                            className={"mobile-input-element-num"}
                                                            placeholder={coreInfo.get("CZH_MIN")["itemValue"] + " " + intl.get("PRIVATE_BUY_PH")}
                                                            onChange={(e) => this.genProcessOrder(e, coreInfo.get("CZH_MIN")["itemValue"], coreInfo.get("CZH_PRICE")["itemValue"], "pAmount")}
                                                            maxLength={20}
                                                        />
                                                        {/*<div className={"mobile-input-element-num"}>*/}
                                                        {/*    {formatCurrency(coreInfo.get("CZH_MIN")["itemValue"])}*/}
                                                        {/*    <Tran intlKey={"PRIVATE_BUY_PH"}/>*/}
                                                        {/*</div>*/}
                                                        <div className={"mobile-input-element-currency"}>
                                                            <img className={"icon-style"} src={CZH} alt={"czh"}/>
                                                            <div className={"mobile-input-element-currency-one"}>
                                                                EHZ
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"mobile-promotion-wrapper-purchase-table-input-element"}>
                                                        <div className={"mobile-input-element-text"}>
                                                            <Tran intlKey="PRIVATE_TABLE_PAY"/>
                                                        </div>
                                                        <input
                                                            className={"mobile-input-element-num"}
                                                            placeholder={intl.get("PRIVATE_PAY_PH")}
                                                            value={payValue ? parseInt(NP.times(buyValue, coreInfo.get("CZH_PRICE")["itemValue"]).toString()) : payDefault}
                                                            disabled={true}
                                                        />
                                                        {/*<div className={"mobile-input-element-num"}>*/}
                                                        {/*    1,000*/}
                                                        {/*</div>*/}
                                                        <div className={"mobile-input-element-currency"}>
                                                            <img src={USDT} alt={""}/>
                                                            <div className={"mobile-input-element-currency-two"}>
                                                                USDT
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"mobile-promotion-wrapper-purchase-table-button"}
                                                     onClick={() => {window.location.href = !inviteUId ? loginUrl : loginUrl + inviteUId}}
                                                >
                                                    <Tran intlKey="PRIVATE_TABLE_TITLE"/>
                                                </div>
                                            </div>
                                            <div className={"mobile-rule-link"}
                                                 onClick={() => {
                                                     const w = window.open('about:blank');
                                                     w.location.href = mediaResource.get("PRIVATE-PLACEMENT-TUTORIAL-LINK")["itemContent"]}
                                                 }
                                            >
                                                <span>{mediaResource.get("PRIVATE-PLACEMENT-TUTORIAL")["itemContent"]}</span>
                                            </div>
                                        </div>
                                        <div className={"mobile-promotion-wrapper-about"}>
                                            <div className={"mobile-promotion-wrapper-about-area"}>
                                                <div className={"mobile-promotion-wrapper-about-content"}>
                                                    <div className={"mobile-promotion-wrapper-about-content-title"}>
                                                        <Tran intlKey="ABOUT_TITLE"/>
                                                    </div>
                                                    <div className={"mobile-promotion-wrapper-about-content-title-en"}>
                                                        {lang ? <Tran intlKey="ABOUT_TITLE_EN"/> : null}
                                                    </div>
                                                    <div className={"mobile-promotion-wrapper-about-content-des"}
                                                         style={{margin: !lang ? '0 auto' : null, width: !lang ? '100%' : null}}
                                                    >
                                                        {mediaResource && mediaResource.get("INTRODUCE-CONTENT")["itemContent"]}
                                                    </div>
                                                </div>
                                                <div className={"mobile-promotion-wrapper-about-img"}>
                                                    <img className={"img-size-contain"} src={PRO_INTR} alt={"pro"}/>
                                                </div>
                                                <div className={"mobile-promotion-wrapper-about-content-link"}>
                                                    <div className={"mobile-promotion-wrapper-about-content-download"}
                                                         style={{backgroundColor: 'rgba(255, 255, 255, 0.08)', border: '1px solid rgba(255, 255, 255, 0.5)'}}
                                                         onClick={() => {
                                                             const w = window.open('about:blank');
                                                             w.location.href = mediaResource.get("LINK-ONE-LINK")["itemContent"]}
                                                         }
                                                    >
                                                        {mediaResource.get("LINK-ONE-NAME")["itemContent"]}
                                                    </div>
                                                    {/*<div className={"mobile-promotion-wrapper-about-content-download"}*/}
                                                    {/*     style={{backgroundColor: 'rgba(255, 255, 255, 0.08)', border: '1px solid rgba(255, 255, 255, 0.5)'}}*/}
                                                    {/*     onClick={() => {*/}
                                                    {/*         const w = window.open('about:blank');*/}
                                                    {/*         w.location.href = mediaResource.get("LINK-TWO-LINK")["itemContent"]}*/}
                                                    {/*     }*/}
                                                    {/*>*/}
                                                    {/*    {mediaResource.get("LINK-TWO-NAME")["itemContent"]}*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"mobile-promotion-wrapper-contact"}>
                                            <div className={"mobile-promotion-wrapper-contact-area"}>
                                                <div className={"mobile-promotion-wrapper-contact-title"}>
                                                    <Tran intlKey="CONTACT_TITLE"/>
                                                </div>
                                                <div className={"mobile-promotion-wrapper-contact-title-en"}>
                                                    <Tran intlKey="CONTACT_TITLE_EN"/>
                                                </div>
                                                <div className={"mobile-promotion-wrapper-contact-way"}>
                                                    <div className={"mobile-promotion-wrapper-contact-way-element"}>
                                                        <div className={"mobile-promotion-wrapper-contact-way-element-icon"}>
                                                            <img className={"img-size"} src={QQ} alt={'icon'}/>
                                                        </div>
                                                        <div className={"mobile-promotion-wrapper-contact-way-element-content"}>
                                                            <div className={"mobile-promotion-wrapper-contact-way-element-title"}>
                                                                {intl.get("CONTACT_QQ")}
                                                            </div>
                                                            <div className={"mobile-promotion-wrapper-contact-way-element-des"}>
                                                                {mediaServiceResource.get("QQ-SERVICE")["itemContent"]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"mobile-promotion-wrapper-contact-way-element"}>
                                                        <div className={"mobile-promotion-wrapper-contact-way-element-icon"}>
                                                            <img className={"img-size"} src={TEL} alt={'icon'}/>
                                                        </div>
                                                        <div className={"mobile-promotion-wrapper-contact-way-element-content"}>
                                                            <div className={"mobile-promotion-wrapper-contact-way-element-title"}>
                                                                {intl.get("CONTACT_TELEGRAM")}
                                                            </div>
                                                            <div className={"mobile-promotion-wrapper-contact-way-element-des"}>
                                                                {mediaServiceResource.get("TELEGRAM-SERVICE")["itemContent"]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"mobile-promotion-wrapper-contact-way-element"}>
                                                        <div className={"mobile-promotion-wrapper-contact-way-element-icon"}>
                                                            <img className={"img-size"} src={EMAIL} alt={'icon'}/>
                                                        </div>
                                                        <div className={"mobile-promotion-wrapper-contact-way-element-content"}>
                                                            <div className={"mobile-promotion-wrapper-contact-way-element-title"}>
                                                                {intl.get("CONTACT_EMAIL")}
                                                            </div>
                                                            <div className={"mobile-promotion-wrapper-contact-way-element-des"}>
                                                                {mediaServiceResource.get("WECHAT-SERVICE")["itemContent"]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"mobile-promotion-wrapper-contact-way-element"}>
                                                        <div className={"mobile-promotion-wrapper-contact-way-element-icon"}>
                                                            <img className={"img-size"} src={EMAIL} alt={'icon'}/>
                                                        </div>
                                                        <div className={"mobile-promotion-wrapper-contact-way-element-content"}>
                                                            <div className={"mobile-promotion-wrapper-contact-way-element-title"}>
                                                                {intl.get("CONTACT_EMAIL")}
                                                            </div>
                                                            <div className={"mobile-promotion-wrapper-contact-way-element-des"}>
                                                                {mediaServiceResource.get("EMAIL-SERVICE")["itemContent"]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer isMobile={isMobile} inviteUId={inviteUId} isPro={true} dom={this}/>
                                </div>
                            </Fragment> : null
                    }
                </div>
            )
        }
    }

    // setDisplay = () => {
    //     console.log(121212)
    //     let status = this.state.displayStatus;
    //     this.setState({
    //         displayStatus: !status,
    //     })
    // }

    setCloseMenu = () => {
        let dis = this.state.displayStatus;
        this.setState({
            displayStatus: !dis,
        })
    }

    componentDidMount() {
        this.genCountryCode();
        genCoreInfos(this);
        getMedia(intl, "PROMOTION", this);
        getServiceMedia(intl, "SERVICE", this);
    }

    render() {
        const langMenu = (
            <Menu style={{paddingTop: "0px", paddingBottom: "0px", backgroundColor: "rgba(30, 67, 130, 0)"}}>
                <Menu.Item className={"promotion-language-item"} key={"zh"} onClick={() => langType("cn")}>
                    {intl.get("CHINESE")}
                </Menu.Item>
                <Menu.Item className={"promotion-language-item"} key={"en"} onClick={() => langType("en")}>
                    {intl.get("ENGLISH")}
                </Menu.Item>
            </Menu>
        );

        let isMobile = this.state.isMobile;

        let type = this.state.type;
        let status = this.state.status;

        let isLogin = this.state.isLogin;
        let userPojo = this.state.userPojo;
        let userInfo = this.state.userInfo;

        let coreInfo = this.state.coreInfo;
        let isCoreLoad = this.state.isCoreLoad;

        let registerPojo = this.registerInfo.registerPojo;
        let loginPojo = this.loginInfo.loginPojo;
        let loginInfo = this.state.loginResponse.responseData;

        let showModal = this.state.showModal;
        let modalContent = this.state.modalContent;

        let mediaResource = this.state.mediaResource;
        let isMediaLoad = this.state.isMediaLoad;
        let mediaServiceResource = this.state.mediaServiceResource;
        let isMediaServiceLoad = this.state.isMediaServiceLoad;

        let buyValue = this.state.buyValue;
        let payValue = this.state.payValue;
        let payDefault = this.state.payDefault;

        let displayStatus = this.state.displayStatus;

        let inviteUId = this.state.inviteUId;

        let lang = intl.get("LANG").toString() === "0";
        let loginUrl = lang ? "/login" : "/login";
        let regUrl = lang ? "/cn/register" : "/en/register";

        let isAllLoad = isCoreLoad && isMediaLoad && isMediaServiceLoad;

        let language = this.state.language;
        console.log("language:", language)

        if (!isMobile) {
            return (
                <div className={"promotion-layout"}>
                    {showModal && (this.loadingModal(showModal, status, loginPojo, loginInfo, registerPojo, userInfo, mediaResource, isMobile, loginUrl, regUrl))}
                    {
                        isMediaLoad && isMediaServiceLoad && isCoreLoad ?
                        <Fragment>
                            {this.loadingHeader(langMenu, inviteUId)}
                            <div className={"promotion-wrapper"}>
                                <div className={"promotion-wrapper-banner"}
                                     style={{height: lang ? '600px' : null}}
                                >
                                    <img className={"promotion-wrapper-banner-img"}
                                         // src={BANNER}
                                         src={mediaResource && webHeader + mediaResource.get("BANNER")["itemContent"]}
                                         alt={"banner"}/>
                                    <div className={"promotion-wrapper-banner-content"}
                                         style={{top: !lang ? '100px' : '130px'}}
                                    >
                                        <div className={"promotion-wrapper-banner-content-slogan"}>
                                            <Tran intlKey="SLOGAN"/>
                                        </div>
                                        <div className={"promotion-wrapper-banner-content-slogan-detail"}>
                                            {mediaResource.get("BANNER-CONTENT")["itemContent"]}
                                        </div>
                                        <button className={"promotion-wrapper-banner-content-private-button"}
                                                onClick={() => {window.location.href = !inviteUId ? loginUrl : loginUrl + "/" + inviteUId}}
                                        >
                                            <Tran intlKey="PRIVATE_BUTTON"/>
                                        </button>
                                    </div>
                                </div>
                                <div className={"promotion-wrapper-purchase"}>
                                    <div className={"promotion-wrapper-purchase-banner"}>
                                        <div className={"promotion-wrapper-purchase-table"}>
                                            <div className={"promotion-wrapper-purchase-table-title"}>
                                                <Tran intlKey={"PRIVATE_TABLE_TITLE"}/>
                                            </div>
                                            <div className={"promotion-wrapper-purchase-table-rule"}>
                                                <div className={"promotion-wrapper-purchase-table-rule-one"}>
                                                    <Tran intlKey="PRIVATE_TABLE_RULE_ONE"/>
                                                    <div style={{width: '5px'}}/>
                                                    {coreInfo.get("CZH_PRICE")["itemValue"]}
                                                    {" USDT"}
                                                </div>
                                                <div className={"promotion-wrapper-purchase-table-rule-two"}>
                                                    {
                                                        lang ?
                                                            <Fragment>
                                                                {coreInfo.get("CZH_MIN")["itemValue"].slice(0, -4)}
                                                                <Tran intlKey={"PRIVATE_TABLE_RULE_TWO_UNIT"}/>
                                                                <Tran intlKey={"PRIVATE_TABLE_RULE_TWO"}/>
                                                            </Fragment> :
                                                            <Fragment>
                                                                <Tran intlKey={"PRIVATE_TABLE_RULE_TWO"}/>
                                                                <div style={{width: '5px'}}/>
                                                                {coreInfo.get("CZH_MIN")["itemValue"]}
                                                                <div style={{width: '5px'}}/>
                                                                <Tran intlKey={"CZH"}/>
                                                            </Fragment>
                                                    }
                                                </div>
                                                <div className={"promotion-wrapper-purchase-table-rule-link"}
                                                     onClick={() => {this.openModal("rule")}}>
                                                    <ExclamationCircleOutlined style={{paddingRight: '5px'}}/>
                                                    <Tran intlKey="PRIVATE_TABLE_RULE_LINK"/>
                                                </div>
                                                <div style={{width: '10px'}}/>
                                                <div className={"promotion-wrapper-purchase-table-rule-link"}
                                                     onClick={() => {
                                                         const w = window.open('about:blank');
                                                         w.location.href = mediaResource.get("PRIVATE-PLACEMENT-TUTORIAL-LINK")["itemContent"]}
                                                     }
                                                >
                                                    <img className={"icon-style-other"} src={TUTORIAL} alt={"TUTORIAL"}/>
                                                    <span>{mediaResource.get("PRIVATE-PLACEMENT-TUTORIAL")["itemContent"]}</span>
                                                </div>
                                            </div>
                                            <div className={"promotion-wrapper-purchase-table-channel"}>
                                                <div className={"promotion-wrapper-purchase-table-channel-grid"}>
                                                    <div className={"promotion-wrapper-purchase-table-channel-grid-title"}>
                                                        <Tran intlKey="PRIVATE_TABLE_BUY"/>
                                                    </div>
                                                    <div className={"promotion-wrapper-purchase-table-channel-grid-content"}>
                                                        <div className={"promotion-wrapper-purchase-table-channel-grid-content-num"}>
                                                            <input
                                                                className={"promotion-private-input"}
                                                                placeholder={coreInfo.get("CZH_MIN")["itemValue"] + " " + intl.get("PRIVATE_BUY_PH")}
                                                                onChange={(e) => this.genProcessOrder(e, coreInfo.get("CZH_MIN")["itemValue"], coreInfo.get("CZH_PRICE")["itemValue"], "pAmount")}
                                                                maxLength={20}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={"promotion-wrapper-purchase-table-channel-grid-currency"}>
                                                        <div className={"promotion-currency-icon"}>
                                                            <img className={"icon-style"} src={CZH} alt={'czh'}/>
                                                        </div>
                                                        EHZ
                                                    </div>
                                                </div>
                                                <div className={"promotion-wrapper-purchase-table-channel-grid-symbol"}>
                                                    =
                                                </div>
                                                <div className={"promotion-wrapper-purchase-table-channel-grid"}>
                                                    <div className={"promotion-wrapper-purchase-table-channel-grid-title"}>
                                                        <Tran intlKey="PRIVATE_TABLE_PAY"/>
                                                    </div>
                                                    <div className={"promotion-wrapper-purchase-table-channel-grid-content"}>
                                                        <div className={"promotion-wrapper-purchase-table-channel-grid-content-num-pay"}>
                                                            <input
                                                                className={"promotion-private-input"}
                                                                placeholder={intl.get("PRIVATE_PAY_PH")}
                                                                value={payValue ? parseInt(NP.times(buyValue, coreInfo.get("CZH_PRICE")["itemValue"]).toString()) : payDefault}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={"promotion-wrapper-purchase-table-channel-grid-currency"}>
                                                        <div className={"promotion-currency-icon"}>
                                                            <img src={USDT} alt={'usdt'}/>
                                                        </div>
                                                        USDT
                                                    </div>
                                                </div>
                                                <div className={"promotion-wrapper-purchase-table-channel-button"}
                                                     onClick={() => {window.location.href = !inviteUId ? loginUrl : loginUrl + "/" + inviteUId}}
                                                >
                                                    <Tran intlKey={"PRIVATE_TABLE_TITLE"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"promotion-wrapper-purchase-process"}>
                                            <div className={"promotion-wrapper-purchase-process-title"}>
                                                <Tran intlKey="PURCHASE_PROCESS"/>
                                            </div>
                                            <div className={"promotion-wrapper-purchase-process-title-bg"}>
                                                <img src={TITLE_BG} alt={"bg"}/>
                                            </div>
                                            <div className={"promotion-wrapper-purchase-process-table"}>
                                                <div className={"promotion-wrapper-purchase-process-table-element"}>
                                                    <div className={"promotion-wrapper-purchase-process-table-element-icon"}>
                                                        <img className={"img-size"} src={PRO_ONE} alt={"one"}/>
                                                    </div>
                                                    <div className={"promotion-wrapper-purchase-process-table-element-content"}>
                                                        <div className={"promotion-wrapper-purchase-process-table-element-title"}>
                                                            {mediaResource.get("PRIVATE-APPLY-TITLE")["itemContent"]}
                                                        </div>
                                                        <div className={"promotion-wrapper-purchase-process-table-element-des"}>
                                                            {mediaResource.get("PRIVATE-APPLY")["itemContent"]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"promotion-wrapper-purchase-process-table-element"}>
                                                    <div className={"promotion-wrapper-purchase-process-table-element-icon"}>
                                                        <img className={"img-size"} src={PRO_TWO} alt={"one"}/>
                                                    </div>
                                                    <div className={"promotion-wrapper-purchase-process-table-element-content"}>
                                                        <div className={"promotion-wrapper-purchase-process-table-element-title"}>
                                                            {mediaResource.get("PRIVATE-DES-TITLE")["itemContent"]}
                                                        </div>
                                                        <div className={"promotion-wrapper-purchase-process-table-element-des"}>
                                                            {mediaResource.get("PRIVATE-DES")["itemContent"]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"promotion-wrapper-purchase-process-table-element"}>
                                                    <div className={"promotion-wrapper-purchase-process-table-element-icon"}>
                                                        <img className={"img-size"} src={PRO_THREE} alt={"one"}/>
                                                    </div>
                                                    <div className={"promotion-wrapper-purchase-process-table-element-content"}>
                                                        <div className={"promotion-wrapper-purchase-process-table-element-title"}>
                                                            {mediaResource.get("PRIVATE-AUDIT-TITLE")["itemContent"]}
                                                        </div>
                                                        <div className={"promotion-wrapper-purchase-process-table-element-des"}>
                                                            {mediaResource.get("PRIVATE-AUDIT")["itemContent"]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"promotion-wrapper-purchase-process-table-element"}>
                                                    <div className={"promotion-wrapper-purchase-process-table-element-icon"}>
                                                        <img className={"img-size"} src={PRO_FOUR} alt={"one"}/>
                                                    </div>
                                                    <div className={"promotion-wrapper-purchase-process-table-element-content"}>
                                                        <div className={"promotion-wrapper-purchase-process-table-element-title"}>
                                                            {mediaResource.get("PRIVATE-COMPLETE-TITLE")["itemContent"]}
                                                        </div>
                                                        <div className={"promotion-wrapper-purchase-process-table-element-des"}>
                                                            {mediaResource.get("PRIVATE-COMPLETE")["itemContent"]}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"promotion-wrapper-about"}>
                                    <div className={"promotion-wrapper-about-area"}>
                                        <div className={"promotion-wrapper-about-content"}>
                                            <div className={"promotion-wrapper-about-content-title"}>
                                                <Tran intlKey="ABOUT_TITLE"/>
                                            </div>
                                            <div className={"promotion-wrapper-about-content-title-bg"}>
                                                <img src={TITLE_BG_TWO} alt={"bg"}/>
                                            </div>
                                            <div className={"promotion-wrapper-about-content-des"}>
                                                {mediaResource && mediaResource.get("INTRODUCE-CONTENT")["itemContent"]}
                                            </div>
                                            <div className={"promotion-wrapper-about-content-download"}
                                                 style={{backgroundColor: 'rgba(255, 255, 255, 0.08)', border: '1px solid rgba(255, 255, 255, 0.5)'}}
                                                 onClick={() => {
                                                     const w = window.open('about:blank');
                                                     w.location.href = mediaResource.get("LINK-ONE-LINK")["itemContent"]}
                                                 }
                                            >
                                                <img className={"promotion-wrapper-about-content-download-icon"} src={BPS} alt={"linkOne"}/>
                                                {mediaResource.get("LINK-ONE-NAME")["itemContent"]}
                                            </div>
                                            {/*<div className={"promotion-wrapper-about-content-download"}*/}
                                            {/*     onClick={() => {*/}
                                            {/*         const w = window.open('about:blank');*/}
                                            {/*         w.location.href = mediaResource.get("LINK-TWO-LINK")["itemContent"]}*/}
                                            {/*     }*/}
                                            {/*>*/}
                                            {/*    <img className={"promotion-wrapper-about-content-download-icon"} src={JZZC} alt={"linkTwo"}/>*/}
                                            {/*    {mediaResource.get("LINK-TWO-NAME")["itemContent"]}*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className={"promotion-wrapper-about-img"}>
                                            <img className={"img-size"} src={PRO_INTR} alt={"bg"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"promotion-wrapper-contact"}>
                                    <div className={"promotion-wrapper-contact-title"}>
                                        <Tran intlKey="CONTACT_TITLE"/>
                                    </div>
                                    <div className={"promotion-wrapper-contact-title-bg"}>
                                        <img src={TITLE_BG} alt={"bg"}/>
                                    </div>
                                    <div className={"promotion-wrapper-contact-way"}>
                                        <div className={"promotion-wrapper-contact-way-element"}>
                                            <div className={"promotion-wrapper-contact-way-element-icon"}>
                                                <img className={"img-size"} src={QQ} alt={'icon'}/>
                                            </div>
                                            <div className={"promotion-wrapper-contact-way-element-content"}>
                                                <div className={"promotion-wrapper-contact-way-element-title"}>
                                                    {intl.get("CONTACT_QQ")}
                                                </div>
                                                <div className={"promotion-wrapper-contact-way-element-des"}>
                                                    {mediaServiceResource.get("QQ-SERVICE")["itemContent"]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"promotion-wrapper-contact-way-element"}>
                                            <div className={"promotion-wrapper-contact-way-element-icon"}>
                                                <img className={"img-size"} src={TEL} alt={'icon'}/>
                                            </div>
                                            <div className={"promotion-wrapper-contact-way-element-content"}>
                                                <div className={"promotion-wrapper-contact-way-element-title"}>
                                                    {intl.get("CONTACT_TELEGRAM")}
                                                </div>
                                                <div className={"promotion-wrapper-contact-way-element-des"}>
                                                    {mediaServiceResource.get("TELEGRAM-SERVICE")["itemContent"]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"promotion-wrapper-contact-way-element"}>
                                            <div className={"promotion-wrapper-contact-way-element-icon"}>
                                                <img className={"img-size"} src={EMAIL} alt={'icon'}/>
                                            </div>
                                            <div className={"promotion-wrapper-contact-way-element-content"}>
                                                <div className={"promotion-wrapper-contact-way-element-title"}>
                                                    {intl.get("CONTACT_EMAIL")}
                                                </div>
                                                <div className={"promotion-wrapper-contact-way-element-des"}>
                                                    {mediaServiceResource.get("WECHAT-SERVICE")["itemContent"]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"promotion-wrapper-contact-way-element"}>
                                            <div className={"promotion-wrapper-contact-way-element-icon"}>
                                                <img className={"img-size"} src={EMAIL} alt={'icon'}/>
                                            </div>
                                            <div className={"promotion-wrapper-contact-way-element-content"}>
                                                <div className={"promotion-wrapper-contact-way-element-title"}>
                                                    {intl.get("CONTACT_EMAIL")}
                                                </div>
                                                <div className={"promotion-wrapper-contact-way-element-des"}>
                                                    {mediaServiceResource.get("EMAIL-SERVICE")["itemContent"]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer isMobile={isMobile} dom={this}/>

                        </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
                    }
                    <DocumentTitle title={intl.get("DT")} key="title"/>
                </div>
            )
        } else {
            return (
                <div>
                    <DocumentTitle title={intl.get("DT")} key="title"/>
                    <PageLayout parent={this}
                                needUser={false}
                                isPro={true}
                                dom={this}
                                inviteUId={this.state.inviteUId}
                                pContent={this.loadingPageContent(isAllLoad, isMediaLoad, isMediaServiceLoad, displayStatus, mediaResource, coreInfo, mediaServiceResource, showModal, modalContent, type, isMobile, buyValue, payValue, payDefault, loginUrl, regUrl)}
                    />
                </div>
            )
        }
    }
}

export default Promotion;
