// eslint-disable-next-line import/no-anonymous-default-export
export default {
    LANG: 1,
    LANGUAGE: 'Language',
    CHINESE: '简体中文',
    CHINESE_SIMPLE: '简体中文',
    ENGLISH: 'English',
    OVERVIEW: 'Overview',
    OVERVIEW_EN: 'Overview',
    ABOUT: 'About EHZ',
    ABOUT_EN: 'About EHZ',
    PRIVATE: 'Private placement',
    PRIVATE_EN: 'Private placement',
    ASSETS: 'EHZ assets',
    ASSETS_EN: 'EHZ assets',
    REWARDS: 'Invitation rewards',
    REWARDS_EN: 'Invitation rewards',
    ACCOUNT_SETTING: 'Settings',
    ACCOUNT_SETTING_EN: 'Settings',
    SERVICE: 'Contact support',
    SERVICE_EN: 'Contact support',
    CATALOGUE_CENTER: '目录中心',
    PROMOTION: '推广',

    /*documentTitle*/
    DT: 'EHZ — the real value coin, private placement in progress',

    /* 推广页 */
    /* 导航 */
    CZH: 'EHZ',
    CZH_EN: 'EHZ',
    CZH_TTL: 'EHZ',
    BASE: 'EHZ - Private placement',
    PERSONAL_ENTRY: 'Account Center',
    LOGIN: 'Login',
    REGISTER: 'Register',
    REGISTER_FREE: '免费注册',

    /* banner */
    SLOGAN: 'EHZ - the real value coin',
    SLOGAN_EN: 'Real value coin',
    PRIVATE_BUTTON: 'EHZ - Private placement started!',

    /* 私募认购流程 */
    PRIVATE_TABLE_TITLE: 'Private placement',
    PRIVATE_TABLE_RULE_ONE: 'Subscription price: ',
    PRIVATE_TABLE_RULE_TWO_UNIT: '万',
    PRIVATE_TABLE_RULE_TWO: 'At least',
    PRIVATE_TABLE_RULE_LINK: 'Subscription rules',
    PRIVATE_TABLE_BUY: 'Subscription amount',
    PRIVATE_TABLE_FRIEND_BUY: 'Friends subscription amount',
    PRIVATE_TABLE_BUY_NUMBER: 'At least',
    PRIVATE_TABLE_PAY: 'To pay',
    PURCHASE_PROCESS: 'Process',
    PURCHASE_QR_ADDRESS: ' QR code payment address',
    PRIVATE_POLICY_TITLE: 'Subscription rules',

    /* 邀请奖励详细政策 */
    REWARDS_POLICY_TITLE: 'Invitation rewards rules',

    /* 简介 */
    ABOUT_TITLE: 'Introduction to EHZ',
    ABOUT_TITLE_EN: 'Introduction to EHZ',

    /* 联系方式 */
    CONTACT_TITLE: 'Contact info',
    CONTACT_TITLE_EN: 'Contact info',
    CONTACT_WECHAT: 'WeChat',
    CONTACT_QQ: 'QQ',
    CONTACT_TELEGRAM: 'Telegram',
    CONTACT_EMAIL: 'Email',

    /* footer */
    PERSONAL_CENTER: 'Account Center',
    SUBSCRIBE_RULE: 'Subscription rules',
    ABOUT_TITLE_SHORTHAND: 'About EHZ',
    WHITE_PAPER_SHORTHAND: 'White Paper - Details',
    VALUE_INTRODUCTION_SHORTHAND: 'Value and price support intro',
    COPYRIGHT: 'Copyright © EHZ All Rights Reserved.',

    /* 登录 */
    ACCOUNT: 'Account',
    PASSWORD: 'Password',
    ACCOUNT_PH: 'Enter your username',
    PASSWORD_PH: 'Enter your login password',
    LOGIN_WELCOME: 'Hello，' + '\n' + 'Welcome to EHZ',
    REGISTER_QUICK: 'Register',
    PASSWORD_FORGOT: 'Forgot your password',
    PASSWORD_FORGOT_TIP: 'Send a verification code to your linked phone/email',
    PASSWORD_FORGET_NOTICE: 'Forgot your username? Contact support',
    LOGIN_USE_USERNAME: 'Account login',
    LOGIN_NO_USE_USERNAME: 'Phone/Email login',
    PHONE_EMAIL: 'Phone/Email',
    PHONE_EMAIL_PH: 'Enter your phone number/email',
    GO_VER: '谷歌登录安全验证',

    /* 注册 */
    REGISTER_PHONE: 'Register by phone',
    REGISTER_EMAIL: 'Register by email',
    REGISTER_PHONE_M: 'By phone',
    REGISTER_EMAIL_M: 'By email',
    USERNAME: 'Username',
    PHONE: 'Phone',
    EMAIL: 'Email',
    GOOGLE: 'Google',
    INVITE_CODE: 'Invitation code',
    USERNAME_PH: 'Create a username of 3-20 characters',
    PHONE_PH: 'Enter your phone number',
    EMAIL_PH: 'Enter your email',
    PASSWORD_PHL: 'Set a password of 6-20 characters',
    INVITE_CODE_PH: '(Optional) Enter the invitation code',
    NEXT_STEP: 'Next step',
    HAS_ACCOUNT: 'Already have an account,',
    LOGIN_IMM: 'Log in now',
    REGISTER_VER_TITLE: 'Enter verification code',
    PWD_FORGET_PH: 'Enter phone or email of the username',
    PWD_FORGET_USERNAME_ERROR: 'Enter your username',
    REGISTER_VER_SEND: 'Send code to',

    /* mobile */
    LOGIN_REGISTER: 'Login/register',
    MOBILE_PRIVATE_BUTTON: 'Start private placement',
    MOBILE_PRIVATE_PRICE: 'Private placement unit price：',
    MOBILE_PRIVATE_PRICE_T: '0.01 EHZ',

    /* overview */
    MY_ASSETS: 'My assets',
    PURCHASE_CZH: 'Subscriptions',
    AWARD_CZH: 'Rewards',
    TOTAL_CZH: 'Total',
    PRIVATE_TABLE_TIP: 'Notice',
    PRIVATE_BUY_PH: 'at least',
    PRIVATE_PAY_PH: '900',
    PRIVATE_TABLE_NOTE: 'For other payment modes,',
    MY_INVITE_CODE: 'My invitation code',
    INVITE_TIP: 'Value sharing/invitation and rewards',
    OFFICIAL_SERVICE: 'Official support of EHZ',
    CONTACT_SERVICE: ' please contact our support',
    PRIVATE_PLACEMENT_TU: 'Private Placement Tutorial',

    /* about */
    WEBSITE: 'EHZ official site',
    WEBSITE_SHARE: 'An authoritative financial platform' + '\n' + 'Value, shared with you',
    WEBSITE_ENTRY: 'Visit the official site',

    /* private */
    PRIVATE_SIMPLE: 'Subscription',
    PRIVATE_RECORD: 'Private placement records',
    PRIVATE_RECORD_EMPTY: 'No data',
    PRIVATE_NOTE_UNIT : 'EHZ Private placement price/each',
    PRIVATE_NOTE_PURCHASE: 'EHZ Private placement amount',
    PRIVATE_APPLY: 'Fill in info',
    PRIVATE_CHECK: 'Auditing',
    PRIVATE_CHECK_INFO: 'Audit info',
    PRIVATE_CHECK_SUCCESS: 'Audit success',
    PRIVATE_CHECK_FAIL: 'Audit failure',
    PRIVATE_CHECK_FAIL_R: 'Check reason',
    PRIVATE_FINISH: 'Subscription result',
    PRIVATE_CHECK_TIP: 'Tip: The subscription info has been submitted.For any queries, please',
    PRIVATE_REPURCHASE: 'Subscribe again',
    PRIVATE_NUMBER: 'Member',
    PRIVATE_NO_ORDER: 'Not yet subscribed',
    PRIVATE_UNIT: 'Subscription unit price',
    PRIVATE_NUM: 'Subscription amount',
    PRIVATE_PAY: 'To pay',
    PRIVATE_PAY_TIP: 'Tip: For other payment modes, please',
    PRIVATE_TRANSFER: 'Transfer amount',
    PRIVATE_ORDER_NO: 'Order No',
    PRIVATE_PROCESS: 'Progress',
    PRIVATE_FINISH_TIP: 'Tip: For any queries, please ',
    PRIVATE_SUCCESS: 'Subscription successful',
    PRIVATE_FAIL: 'Subscription failure',
    PRIVATE_FAIL_REASON_NOTE: 'Audit failure due to:',
    PRIVATE_PROCESS_PAY: 'Subscription payment',
    PRIVATE_PROCESS_INFO_CONFIRM: 'Confirm subscription info',
    PRIVATE_PROCESS_USDT_CONFIRM: 'Select USDT payment address',
    PRIVATE_PROCESS_USDT_ADDRESS: 'ERC20 QR code payment address',
    PRIVATE_PROCESS_PAY_CONFIRM: 'Click here after payment',

    /* order */
    ORDER_NUM: 'No.',
    ORDER_PTIME: 'Subscription time',
    ORDER_NO: 'Order no.',
    ORDER_PAMOUNT: 'Subscription amount',
    ORDER_USDTP: 'Private placement USDT',
    ORDER_STATUS: 'Subscription status',
    ORDER_CHECK_REASON: 'Check reason',
    REGISTER_TIME: 'Registration time',
    LATEST_PTIME: 'Latest subscription time',
    PRIVATE_NO: "none",
    UTC: 'UTC-8 time zone',

    /* order rejectReason */
    UNPAID: 'Outstanding amount, please contact our support.',
    PAYMENT_MISMATCH: 'The outstanding amount is not correct, please contact our support.',
    UNREASONED: 'Please contact our support for the reason for rejection.',

    /* assets */
    MY_RECORD: 'My subscription records',
    INVITER_RECORD: 'Invitee subscription records',
    REFRESH: 'Refresh',

    /* rewards */
    MY_INVITE: 'My invitation',
    MY_INVITE_RECORD: 'My invitation records',
    INVITE_NUM: 'Number invited',
    PURCHASE_NUM: 'Number Subscribed',
    MY_REWARDS: 'Rewards',
    REWARDS_BANNER_TITLE: 'EHZ - The real value coin, worth sharing',
    REWARDS_BANNER_CONTENT: 'Invite friends to join in, and get a EHZ reward of 2% to 5%.',
    REWARDS_BANNER_BUTTON: 'Check reward info',
    REWARDS_INVITE_INFO_ONE: 'Invite friends to join in,',
    REWARDS_INVITE_INFO_TWO: 'and get a reward of 2% to 5%',
    REWARDS_INVITE_INFO_THREE: '',
    INVITE_CODE_LINK_REG: 'Invitation link (registration)',
    INVITE_CODE_LINK_SHARE: 'Invitation link (sharing)',
    INVITE_POSTER: 'Posters invitation',
    COPY: 'Copy',
    DOWNLOAD_TO_DESK: 'Download to Computer',
    FACE_TO_FACE: 'Face-to-face invitation',
    FACE_TO_FACE_TIP: 'Scan the QR code above to accept the invitation',
    FACE_TO_FACE_GET: 'Confirm',
    DOWNLOAD_IMG: 'Save poster',
    DOWNLOAD_IMG_TIP: 'Please click here to download the poster to your computer',
    DOWNLOAD_IMG_TIP_ONE: 'Please click here to save the poster (preferred); or screenshot',
    CLOSE: 'Close',

    /* account */
    ACCOUNT_BASE: 'Basic info',
    ACCOUNT_USERNAME: "Account name",
    ACCOUNT_UID: 'UID',
    ACCOUNT_SAFETY: 'Account security',
    ACCOUNT_PWD: 'Login password',
    ACCOUNT_PWD_M: 'Modify password',
    ACCOUNT_VERIFY: 'Security verification',
    ACCOUNT_VP_OLD: 'Old phone number verification',
    ACCOUNT_VP_NEW: 'New phone number verification',
    ACCOUNT_VP: 'SMS verification',
    ACCOUNT_VPWD: 'Login password verification',
    ACCOUNT_VE: 'Email verification',
    ACCOUNT_VG: 'Google verification',
    ACCOUNT_BIND: 'Link',
    ACCOUNT_BIND_UN: 'Not yet linked',
    ACCOUNT_OPEN: 'Activated',
    ACCOUNT_OPEN_UN: 'Unactivated',
    ACCOUNT_VP_UN: 'Not linked to phone number',
    ACCOUNT_VE_UN: 'Not linked to email',
    ACCOUNT_VG_UN: 'Not linked to Google verification',
    ACCOUNT_VG_BIND: 'Linked to Google verification',
    ACCOUNT_VG_SUCCESS: 'Google verification is completed successfully',
    ACCOUNT_VERIFY_O: 'Activate verification',
    ACCOUNT_VERIFY_C: 'Close verification',
    ACCOUNT_VERIFY_SUCCESS: 'Open or close verification is successfully, please confirm!',
    ACCOUNT_LOGOUT: 'Exit',
    VGO_ABSTRACT: 'Google Authenticator is a dynamic password tool, similar to SMS dynamic verification. After linking, it generates a verification code every 30 seconds.',
    VGO_DOWN_TITLE: 'Download or search for "Google Authentication" from the App store.',
    VGO_DOWN_DES: 'For IOS users, log in to the App Store and search for "Authentication" to download. For Android users, log in to Google Play Store and search for "Google Authenticator" to download.',
    VGO_INSTALL_TITLE: 'After installation, open "Google Authentication" and scan the QR code below or manually enter the key to obtain the 6-digit verification code.',
    VGO_INSTALL_DES: 'Keep your Google authentication key properly in case you cannot re-link if you change or lose your phone.',
    VGO_VER_TITLE: 'Enter your verification code in the box below to complete verification.',
    VGO_VER_TITLE_CLOSE: 'Close Google verification',
    APP_STORE: 'App Store',
    GOOGLE_PLAY: 'Google Play',
    VGO_SEKEY: 'Key',
    VEMAIL_VER_PH: 'Enter email verification code',
    VEMAIL_BIND_TIP: 'Tip: email cannot be modified after linked',
    VPHONE_VER_PH: 'Enter phone verification code',
    VGO_VER_PH: 'Enter Google verification code',
    VGO_VER_TIP: '您已开通谷歌安全验证',
    CONFIRM: 'Confirm',
    CONFIRM_MODIFY: 'Confirm Modify',
    VER_TIP: 'For security purposes, please verify your account.',
    VER_CODE: 'Verification code',
    VER_CODE_PH: 'Enter verification code',
    VER_CODE_SEND: 'Send',
    VER_CODE_GET: 'Send',
    VER_CODE_OBTAIN: 'Obtain verification code',
    SECOND: 's',
    MODIFY: 'Modify',
    ACCOUNT_NEW_PWD: 'New Password',
    ACCOUNT_PWD_MODIFY: 'Set new password',
    ACCOUNT_PWD_MODIFY_PH: 'Enter new password',
    ACCOUNT_PWD_MODIFY_CONFIRM: 'Confirm new password',
    ACCOUNT_PWD_MODIFY_CONFIRM_PH: 'Confirm new password again',
    COUNTRY_ID_CHOOSE: 'Select area code',
    COUNTRY_ID_CHOOSE_PH: 'Searching by area code',
    COUNTRY_ID_NO_DATA: 'no data',

    /* mobile nav */
    MOBILE_NAV_SLOGAN: 'the real value coin, private placement in progress',
    MOBILE_NAV_LOGIN: 'Click to login, Get more info',

    /* mobile overview */
    FUNCTION_CENTER: 'Function center',
    FUNCTION_CENTER_CAL: 'Menu',
    CLICK_MORE: 'Click for more functions',
    INVITE_TITLE: 'Invite friends',

    /* mobile assets */
    ASSETS_TTL: 'total assets',

    /* input error msg */
    USER_NAME_ERROR: 'Create a username of 3-20 characters',
    USER_PHONE_ERROR: 'Enter your correct phone number',
    USER_EMAIL_ERROR: 'Enter your correct mail',
    USER_PWD_ERROR: 'Enter 6-20 characters, does not contain all digits',
    USER_PWD_MOD_ERROR: 'Input password is different from the previous one',
    ORDER_PAMOUNT_ERROR: 'The starting amount is:',
    ORDER_PAMOUNT_EMPTY_ERROR: 'Fill in subscription amount',
    USER_FPWD_ERROR: 'Enter your correct phone number or email',

    /* error msg */
    CONNECTION_ERROR: 'connection error, please try again later!',
    INPUT_ERROR: 'input is invalid, please check your input!',
    USER_REPEAT: 'username has been registered!',
    PHONE_REPEAT: 'phone number has been registered!',
    EMAIL_REPEAT: 'email has been registered!',
    NOT_MATCH_ERROR: 'wrong username or password, please check your input!',
    WRONG_PASS_ERROR: 'wrong password, please check your input!',
    TIME_OUT: 'your request is time out!',
    VALID_ERROR: 'your validation is time out, please do authorization first!',
    INVITER_ERROR: 'cannot found inviter, please check your input!',
    DEFAULT_ERROR: 'system error, please try again later!',
    VER_CODE_ERROR: 'wrong validation code, please check your input!',
    VER_CODE_OLD_ERROR: 'wrong old phone validation code, please check your input!',
    VER_GO_CODE_ERROR: 'wrong google validation code, please check your input!',
    VER_PHONE_CODE_ERROR: 'wrong phone validation code, please check your input!',
    VER_EMAIL_CODE_ERROR: 'wrong email validation code, please check your input!',
    VER_SEND_ERROR: 'fail to send validation code, please try again later!',
    VER_OPEN_ERROR: 'please setup your email or phone number first!',
    VER_CLOSE_ERROR: 'at least enable either email or phone validation!',
    DATA_LOAD_ERROR: 'fail to load data, please contact customer service!',
    COPY_FAIL_ERROR: 'fail to copy info, please copy it manually!',
    LOGIN_FIRST_ERROR: 'please login first!',
    VER_CODE_EMPTY: 'please enter verification code',
    OLD_USER_BY_PHONE: 'please login by phone number',

    /* success msg */
    LOGIN_SUCCESS: 'login success',
    REGISTER_SUCCESS: 'register success',
    COPY_SUCCESS: 'copied',
    PWD_MODIFY_SUCCESS: 'password has been updated, please login again',
    PE_MODIFY_SUCCESS: 'phone number or email has been updated',
    VER_SEND_SUCCESS: 'verification code has been sent, please check',
    PRIVATE_SUCCESS_TIP: 'your order has been submitted',
    DEFAULT_SUCCESS: 'this operation success',

    /* modal msg */
    LOGIN_LOADING: 'Welcome to EHZ!',
    DATA_LOADING: 'loading data...',

    /* country ip */
    COMMON_USE: 'Common',
    ALL_COUNTRY: 'All countries',
    CHINA: "China",
    FRANCE: "France",
    GERMANY: "Germany",
    INDONESIA: "Indonesia",
    KOREA: "Korea",
    NETHERLANDS: "Netherlands",
    RUSSIA: "Russia",
    SPAIN: "Spain",
    THAILAND: "Thailand",
    TURKEY: "Turkey",
    UNITED_KINGDOM: "United Kingdom",
    VIETNAM: "Vietnam",
    INDIA: "India",
    AFGHANISTAN: "Afghanistan",
    ALBANIA: "Albania",
    ALGERIA: "Algeria",
    ANDORRA: "Andorra",
    ANGOLA: "Angola",
    ANGUILLA: "Anguilla",
    ANTIGUA_AND_BARBUDA: "Antigua and Barbuda",
    ARGENTINA: "Argentina",
    ARMENIA: "Armenia",
    AUSTRALIA: "Australia",
    AUSTRIA: "Austria",
    AZERBAIJAN: "Azerbaijan",
    BAHAMAS: "Bahamas",
    BAHRAIN: "Bahrain",
    BANGLADESH: "Bangladesh",
    BARBADOS: "Barbados",
    BELARUS: "Belarus",
    BELGIUM: "Belgium",
    BELIZE: "Belize",
    BENIN: "Benin",
    BERMUDA: "Bermuda",
    BHUTAN: "Bhutan",
    BOLIVIA: "Bolivia",
    BOSNIA_AND_HERZEGOVINA: "Bosnia and Herzegovina",
    BOTSWANA: "Botswana",
    BRAZIL: "Brazil",
    VIRGIN_ISLANDS_UNITED_KINGDOM: "Virgin Islands (UK)",
    BRUNEI_DARUSSALAM: "Brunei Darussalam",
    BULGARIA: "Bulgaria",
    BURKINA_FASO: "Burkina Faso",
    BURUNDI: "Burundi",
    CAMBODIA: "Cambodia",
    CAMEROON: "Cameroon",
    CANADA: "Canada",
    CAPE_VERDE: "Cape Verde",
    CAYMAN_ISLANDS: "Cayman Islands",
    CENTRAL_AFRICAN_REPUBLIC: "Central African Republic",
    CHAD: "Chad",
    CHILE: "Chile",
    COLOMBIA: "Colombia",
    COMOROS: "Comoros",
    CONGO: "Congo",
    DEMOCRATIC_REPUBLIC_OF_CONGO: "DR of Congo",
    COSTA_RICA: "Costa Rica",
    IVORY_COAST: "Ivory Coast",
    CROATIA: "Croatia",
    CUBA: "Cuba",
    CURAçAO: "Curaçao",
    CYPRUS: "Cyprus",
    CZECH: "Czech",
    DENMARK: "Denmark",
    DJIBOUTI: "Djibouti",
    DOMINICA: "Dominica",
    DOMINICAN_REPUBLIC: "Dominican Republic",
    ECUADOR: "Ecuador",
    EGYPT: "Egypt",
    EL_SALVADOR: "El Salvador",
    EQUATORIAL_GUINEA: "Equatorial Guinea",
    ERITREA: "Eritrea",
    ESTONIA: "Estonia",
    ETHIOPIA: "Ethiopia",
    FIJI: "Fiji",
    FINLAND: "Finland",
    GABON: "Gabon",
    GAMBIA: "Gambia",
    GEORGIA: "Georgia",
    GHANA: "Ghana",
    GREECE: "Greece",
    GRENADA: "Grenada",
    GUADELOUPE: "Guadeloupe",
    GUATEMALA: "Guatemala",
    GUERNSEY: "Guernsey",
    GUINEA: "Guinea",
    GUINEA_BISSAU: "Guinea-Bissau",
    GUYANA: "Guyana",
    HAITI: "Haiti",
    HONDURAS: "Honduras",
    HONG_KONG_CHINA: "Hong Kong China",
    HUNGARY: "Hungary",
    ICELAND: "Iceland",
    IRAN: "Iran",
    IRAQ: "Iraq",
    IRELAND: "Ireland",
    ISRAEL: "Israel",
    ITALY: "Italy",
    JAMAICA: "Jamaica",
    JAPAN: "Japan",
    JORDAN: "Jordan",
    KAZAKHSTAN: "Kazakhstan",
    KENYA: "Kenya",
    KIRIBATI: "Kiribati",
    NORTH_KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA: "North Korea",
    KOSOVO: "Kosovo",
    KUWAIT: "Kuwait",
    KYRGYZSTAN: "Kyrgyzstan",
    LAO_PEOPLES_DEMOCRATIC_REPUBLIC: "Lao PDR",
    LATVIA: "Latvia",
    LEBANON: "Lebanon",
    LESOTHO: "Lesotho",
    LIBERIA: "Liberia",
    LIBYA: "Libya",
    LIECHTENSTEIN: "Liechtenstein",
    LITHUANIA: "Lithuania",
    LUXEMBOURG: "Luxembourg",
    MACAO_CHINA: "Macao China",
    REPUBLIC_OF_MACEDONIA: "Republic of Macedonia",
    MADAGASCAR: "Madagascar",
    MALAWI: "Malawi",
    MALAYSIA: "Malaysia",
    MALDIVES: "Maldives",
    MALI: "Mali",
    MALTA: "Malta",
    MARSHALL_ISLANDS: "Marshall Islands",
    MARTINIQUE: "Martinique",
    MAURITANIA: "Mauritania",
    MAURITIUS: "Mauritius",
    MEXICO: "Mexico",
    FEDERATED_STATES_OF_MICRONESIA: "Micronesia",
    REPUBLIC_OF_MOLDOVA: "Republic of Moldova",
    MONACO: "Monaco",
    MONGOLIA: "Mongolia",
    MONTENEGRO: "Montenegro",
    MOROCCO: "Morocco",
    MOZAMBIQUE: "Mozambique",
    MYANMAR: "Myanmar",
    NAMIBIA: "Namibia",
    NAURU: "Nauru",
    NEPAL: "Nepal",
    NEW_CALEDONIA: "New Caledonia",
    NEW_ZEALAND: "New Zealand",
    NICARAGUA: "Nicaragua",
    NIGER: "Niger",
    NIGERIA: "Nigeria",
    NORWAY: "Norway",
    OMAN: "Oman",
    PAKISTAN: "Pakistan",
    PALAU: "Palau",
    PALESTINE_PALESTINE: "Palestine Palestine",
    PANAMA: "Panama",
    PAPUA_NEW_GUINEA: "Papua New Guinea",
    PARAGUAY: "Paraguay",
    PERU: "Peru",
    PHILIPPINES: "Philippines",
    POLAND: "Poland",
    FRENCH_POLYNESIA: "French Polynesia",
    PORTUGAL: "Portugal",
    QATAR: "Qatar",
    REUNION_ISLAND: "Reunion Island",
    ROMANIA: "Romania",
    RWANDA: "Rwanda",
    SAINT_KITTS_AND_NEVIS: "Saint Kitts and Nevis",
    SAINT_LUCIA: "Saint Lucia",
    SAINT_VINCENT_AND_THE_GRENADINES: "St Vincent / Grenadines",
    SAMOA: "Samoa",
    SAN_MARINO: "San Marino",
    SAO_TOME_AND_PRINCIPE: "Sao Tome and Principe",
    SAUDI_ARABIA: "Saudi Arabia",
    SENEGAL: "Senegal",
    SERBIA: "Serbia",
    SEYCHELLES: "Seychelles",
    SIERRA_LEONE: "Sierra Leone",
    SINGAPORE: "Singapore",
    SLOVAKIA: "Slovakia",
    SLOVENIA: "Slovenia",
    SOLOMON_ISLANDS: "Solomon Islands",
    SOMALIA: "Somalia",
    SOUTH_AFRICA: "South Africa",
    SOUTH_SUDAN: "South Sudan",
    SRI_LANKA: "Sri Lanka",
    SUDAN: "Sudan",
    SURINAME: "Suriname",
    SWAZILAND: "Swaziland",
    SWEDEN: "Sweden",
    SWITZERLAND: "Switzerland",
    SYRIAN_ARAB_REPUBLIC: "Syrian Arab Republic",
    TAIWAN_CHINA: "Taiwan China",
    TAJIKISTAN: "Tajikistan",
    UNITED_REPUBLIC_OF_TANZANIA: "UR of Tanzania",
    GIBRALTAR: "Gibraltar",
    COOK_ISLANDS: "Cook Islands",
    EAST_TIMOR: "East Timor",
    TOGO: "Togo",
    TONGA: "Tonga",
    TRINIDAD_AND_TOBAGO: "Trinidad and Tobago",
    TUNISIA: "Tunisia",
    TURKMENISTAN: "Turkmenistan",
    TUVALU: "Tuvalu",
    VIRGIN_ISLANDS_US: "Virgin Islands (US)",
    UGANDA: "Uganda",
    UKRAINE: "Ukraine",
    UNITED_ARAB_EMIRATES: "United Arab Emirates",
    UNITED_STATES: "United States",
    URUGUAY: "Uruguay",
    UZBEKISTAN: "Uzbekistan",
    VANUATU: "Vanuatu",
    VATICAN_CITY: "Vatican City",
    VENEZUELA: "Venezuela",
    YEMEN: "Yemen",
    ZAMBIA: "Zambia",
    ZIMBABWE: "Zimbabwe",
}
