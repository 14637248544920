const userNameChecker = /^[^\u4e00-\u9fa5]{3,20}$/;

const userNameErrorMsg = (intl) => {return intl.get("USER_NAME_ERROR")};

const userPhoneChecker = /^\d{1,20}$/;

const userPhoneErrorMsg = (intl) => {return intl.get("USER_PHONE_ERROR")};

const userEmailChecker = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4}){1,50}$/;

const userEmailErrorMsg = (intl) => {return intl.get("USER_EMAIL_ERROR")};

const userPasswordChecker = /^(?!\d+$)[^\u4e00-\u9fa5]{6,20}$/;

// const userPasswordChecker = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,20}$/;

const userPasswordErrorMsg = (intl) => {return intl.get("USER_PWD_ERROR")};

const userPwdModifyErrorMsg = (intl) => {return intl.get("USER_PWD_MOD_ERROR")};

const orderPAmountChecker = /(^-?[1-9]([0-9]*)$|^-?[0-9]$)/;

const orderPAmountErrorMsg = (intl) => {return intl.get("ORDER_PAMOUNT_ERROR")};

const orderPAmountEmptyErrorMsg = (intl) => {return intl.get("ORDER_PAMOUNT_EMPTY_ERROR")};

const userFPwdErrorMsg = (intl) => {return intl.get("USER_FPWD_ERROR")};

const userFPwdNameErrorMsg = (intl) => {return intl.get("PWD_FORGET_USERNAME_ERROR")};

export {
    userNameChecker,
    userNameErrorMsg,
    userPhoneChecker,
    userPhoneErrorMsg,
    userEmailChecker,
    userEmailErrorMsg,
    userPasswordChecker,
    userPasswordErrorMsg,
    userPwdModifyErrorMsg,
    orderPAmountChecker,
    orderPAmountErrorMsg,
    orderPAmountEmptyErrorMsg,
    userFPwdErrorMsg,
    userFPwdNameErrorMsg,
}
