import React, {Component} from "react";
import './index.css'
import {Spin} from "antd";
import intl from "react-intl-universal";
import DocumentTitle from "react-document-title";

class LoadingWin extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let isMobile =this.props.isMobile;

        if (!isMobile) {
            return (
                <div className={"modal-bg-win"}>
                    <div style={{textAlign: "center", width: "100%", marginTop: '35vh'}}>
                        <Spin tip={intl.get("DATA_LOADING")} size={"large"}/>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={"mobile-modal-bg-win"}>
                    <div style={{textAlign: "center", width: "100%", marginTop: '35vh'}}>
                        <Spin tip={intl.get("DATA_LOADING")} size={"large"}/>
                    </div>
                </div>
            )
        }
    }
}

export default LoadingWin;
