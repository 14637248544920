import React, {Component, Fragment} from "react";
import "./rewards.css";
import {isMobile} from "../../utils/MobileMgr";
import Tran from "../../config/IntTranslation";
import INVITE_NUM from "../../assets/icon/icon_invite_num.svg";
import PURCHASE_NUM from "../../assets/icon/icon_purchase_num.svg";
import REWARDS from "../../assets/icon/icon_award.svg";
import DOWNLOAD from "../../assets/icon/icon_download.svg";
import {formatCurrency} from "../../utils/Math";
import {copyFun, getMedia, sortByTimeAsc, toMaskLong, toMaskLongS} from "../../utils/Request";
import OrderRecord from "../component/orderRecord";
import BANNER_M from "../../assets/img/rewards_bg_m.png";
import BANNER_M_EN from "../../assets/img/rewards_bg_m_en.png";
import QR from "../../assets/icon/icon_rewards_qr.svg";
import intl from "react-intl-universal";
import BANNER from "../../assets/img/rewards_bg.png";
import {data, findUserAwardsUrl, sendGetRequest, webHeader} from "../../utils/RequestUrlMgr";
import {connectionError, fail, findMsgByError} from "../../utils/ErrorMgr";
import LoadingWin from "../component/LoadingWin";
import QRCode from "qrcode.react";
import POSTER from "../../assets/img/rewards_poster_sample.png";
import POSTEREN from "../../assets/img/rewards_poster_sample_en.png";
import PageLayout from "../PageLayout";
import FileSaver from 'file-saver';
import CLOSE from "../../assets/icon/icon_close.svg";
import DOWN from "../../assets/icon/rewards_download.svg";
import html2canvas from "html2canvas";

class Rewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            isUserLoad: false,
            inviterData: [],
            isInviterLoad: false,
            showModal: false,
            modalContent: '',
            modalStatus: '',
            type: 'rewards',
            currentPage: 1,
            status: '',
            language: intl.get("LANG").toString() === "0" ? "zh" : "en",
            mediaResource: new Map(),
            isMediaLoad: false,
            displayStatus: true,
        }
    }

    loadingPageContent = (isInviterLoad, inviterData, userPojo, type, showModal, modalContent, currentPage, isMobile, status, isUserLoad, mediaResource, isAllLoad, modalStatus) => {
        if (!isUserLoad) {
            return (
                <div/>
            )
        } else {
            if (!isMobile) {
                return (
                    isAllLoad ?
                    <Fragment>
                        {showModal && this.loadingModal(showModal, modalContent, modalStatus)}
                        <div className={"rewards-layout-banner"}>
                            {/*<img className={"img-layout"} src={BANNER} alt={'banner'}/>*/}
                            <div style={{width: '25%'}}/>
                            <div className={"rewards-layout-banner-container"}>
                                <div className={"rewards-layout-banner-container-title"}>
                                    <Tran intlKey={"REWARDS_BANNER_TITLE"} />
                                </div>
                                <div className={"rewards-layout-banner-container-content"}>
                                    <Tran intlKey={"REWARDS_BANNER_CONTENT"} />
                                </div>
                            </div>
                            <div className={"rewards-layout-banner-button-wrap"}>
                                <div className={"rewards-layout-banner-button"}
                                     onClick={() => {this.openModal(mediaResource.get("POLICY-ONE")["itemContent"] + '\n' + '\n' + mediaResource.get("POLICY-TWO")["itemContent"] + '\n' + '\n' + mediaResource.get("POLICY-THREE")["itemContent"] + '\n' + '\n' + mediaResource.get("POLICY-FOUR")["itemContent"], "policy")}}
                                >
                                    <Tran intlKey={"REWARDS_BANNER_BUTTON"} />
                                </div>
                            </div>
                        </div>
                        <div className={"rewards-layout-my-code"}>
                            {this.loadingCode(isMobile, userPojo)}
                        </div>
                        <div className={"rewards-layout-my-assets"}>
                            {this.loadingAssets(isMobile, userPojo, inviterData)}
                        </div>
                        <div className={"rewards-layout-record"}>
                            {isInviterLoad ?
                                <OrderRecord parent={this} isMobile={isMobile} type={type} inviterData={inviterData}
                                             showModal={showModal} currentPage={currentPage}/>
                                :
                                <LoadingWin parent={this} isMobile={isMobile}/>
                            }
                        </div>
                    </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
                )
            } else {
                let visible = this.state.displayStatus;
                let lang = intl.get("LANG").toString() === "0";
                let urlReg = lang ? webHeader + "/cn/register/" : webHeader + "/en/register/";
                if (status === "qr") {
                    return (
                        <Fragment>
                            <div style={{height: '120px'}}/>
                            <div className={"mobile-qr-title"}>
                                <Tran intlKey={"FACE_TO_FACE"}/>
                            </div>
                            <div className={"mobile-qr-img"}>
                                <QRCode id={"qrCode"} value={urlReg + userPojo.uId} />
                            </div>
                            <div className={"mobile-qr-tip"}>
                                <Tran intlKey={"FACE_TO_FACE_TIP"}/>
                            </div>
                            <button className={"mobile-qr-button"}
                                    onClick={() => {this.setStatus("home")}}
                            >
                                <Tran intlKey={"FACE_TO_FACE_GET"}/>
                            </button>
                        </Fragment>
                    )
                }
                if (status === "qrImg") {
                    let imgUrl = lang ? POSTER : POSTEREN;
                    return (
                        <Fragment>
                            <div style={{height: '100px'}}/>
                            <div className={"mobile-rewards-qr-img-layout"}>
                                <div className={"mobile-rewards-qr-img"} id={"canbox"}>
                                    <img className={"img-size"} src={imgUrl} alt={"poster"}/>
                                    <div className={"mobile-img-qr-bg"} >
                                        <QRCode className={"mobile-img-qr"} value={urlReg + userPojo.uId} />
                                    </div>
                                    <canvas id="canvas" width="310" height="550" style={{opacity: '0'}} className={"canvas-style"}>
                                        <img id={"poster"} className={"img-size"} src={imgUrl} alt={"poster"}/>
                                        <QRCode id={"qrCode"} className={"img-qr"} value={urlReg + userPojo.uId} />
                                    </canvas>
                                </div>
                                <div className={"mobile-rewards-qr-img-function"}>
                                    <div className={"mobile-rewards-function"}>
                                        <div className={"mobile-rewards-download"}
                                             onClick={() => {this.downloadImg()}}
                                        >
                                            <img className={"img-size"} src={DOWN} alt={"download"}/>
                                        </div>
                                    </div>
                                    <div className={"mobile-rewards-function-ele"}
                                         onClick={() => {this.downloadImg()}}
                                    >
                                        <div className={"mobile-rewards-function-text"}>
                                            <Tran intlKey={"DOWNLOAD_IMG"}/>
                                        </div>
                                        <div className={"mobile-rewards-qr-tip"}>
                                            <Tran intlKey={"DOWNLOAD_IMG_TIP_ONE"}/>
                                        </div>
                                    </div>
                                    <div className={"mobile-rewards-function"}>
                                        <div className={"mobile-rewards-close"}
                                             onClick={() => {this.setStatus("home")}}
                                        >
                                            <img className={"img-size-close"} src={CLOSE} alt={"close"}/>
                                        </div>
                                        {/*<div className={"mobile-rewards-function-text"}>*/}
                                        {/*    <Tran intlKey={"CLOSE"}/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
                if (status === "policy") {
                    return (
                        <Fragment>
                            <div style={{height: '120px'}}/>
                            <div className={"mobile-policy-title"}>
                                <Tran intlKey={"REWARDS_POLICY_TITLE"}/>
                            </div>
                            <div className={"mobile-policy-content"}>
                                {mediaResource.get("POLICY-ONE")["itemContent"]}
                                <br/>
                                <br/>
                                {mediaResource.get("POLICY-TWO")["itemContent"]}
                                <br/>
                                <br/>
                                {mediaResource.get("POLICY-THREE")["itemContent"]}
                                <br/>
                                <br/>
                                {mediaResource.get("POLICY-FOUR")["itemContent"]}
                            </div>
                            <button className={"mobile-qr-button"} onClick={() => {window.location.href = '/rewards'}}>
                                <Tran intlKey={"FACE_TO_FACE_GET"}/>
                            </button>
                            <div style={{height: '40px'}}/>
                        </Fragment>
                    )
                } else {
                    return (
                        isAllLoad ?
                        <Fragment>
                            <div style={{height: '80px'}}/>
                            <div style={{display: visible ? '' : 'none'}}>
                                <div className={"mobile-rewards-banner"}>
                                    <img className={"mobile-img"} src={lang ? BANNER_M : BANNER_M_EN} alt={"banner"}/>
                                </div>
                                <div className={"mobile-rewards-wrap"}>
                                    <div className={"mobile-rewards-table"}>
                                        <div className={"mobile-rewards-table-header"}>
                                            <Tran intlKey={"REWARDS_INVITE_INFO_ONE"}/>
                                            <div style={{color: '#F96126'}}>
                                                <Tran intlKey={"REWARDS_INVITE_INFO_TWO"}/>
                                            </div>
                                            <Tran intlKey={"REWARDS_INVITE_INFO_THREE"}/>
                                        </div>
                                        <div className={"mobile-rewards-table-main"}>
                                            {this.loadingCode(isMobile, userPojo)}
                                        </div>
                                    </div>
                                    <div className={"mobile-rewards-assets"}>
                                        {this.loadingAssets(isMobile, userPojo, inviterData)}
                                    </div>
                                    <div className={"mobile-rewards-record"}>
                                        <OrderRecord parent={this} isMobile={isMobile} type={type} inviterData={inviterData}
                                                     showModal={showModal} currentPage={currentPage}/>
                                    </div>
                                </div>
                            </div>
                        </Fragment> : <LoadingWin parent={this} isMobile={isMobile}/>
                    )
                }
            }
        }
    }

    setStatus = (status) => {
        this.setState({
            status: status,
        })
    }

    // 我的邀请码
    loadingCode = (isMobile, userPojo) => {
        let lang = intl.get("LANG").toString() === "0";
        let posterImg = lang ? POSTER : POSTEREN;
        let shareUrl = lang ? webHeader + "/cn/pro/" : webHeader + "/en/pro/";
        let url = lang ? webHeader + "/cn/register/" : webHeader + "/en/register/";
        if (!isMobile) {
            if (userPojo) {
                return (
                    <Fragment>
                        <div className={"rewards-layout-my-code-title"}>
                            <Tran intlKey={"MY_INVITE_CODE"}/>
                        </div>
                        <div className={"rewards-layout-my-code-content"}>
                            <div className={"rewards-layout-my-code-ele"}>
                                <div className={"rewards-layout-my-code-ele-title"}>
                                    <Tran intlKey={"INVITE_CODE"}/>
                                </div>
                                <div className={"rewards-layout-my-code-ele-content"}>
                                    <div className={"rewards-layout-my-code-ele-content-text"}>
                                        {userPojo.uId}
                                    </div>
                                    <input className={"rewards-layout-my-code-ele-content-input"}
                                           id={"uid"}
                                           value={userPojo.uId}
                                           readOnly={true}
                                    />
                                    <div className={"rewards-layout-my-code-ele-content-copy"}
                                         // onClick={() => {copyFun(".rewards-layout-my-code-ele-content input")}}
                                        onClick={() => {copyFun("uid")}}
                                    >
                                        <Tran intlKey={"COPY"}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"rewards-layout-my-code-ele"}>
                                <div className={"rewards-layout-my-code-ele-title"}>
                                    <Tran intlKey={"INVITE_CODE_LINK_REG"}/>
                                </div>
                                <div className={"rewards-layout-my-code-ele-content-reg"}>
                                    <div className={"rewards-layout-my-code-ele-content-text"}>
                                        {toMaskLong(url, 1) + userPojo.uId}
                                    </div>
                                    <input className={"rewards-layout-my-code-ele-content-input"}
                                           id={"urlUid"}
                                           value={url + userPojo.uId}
                                           readOnly={true}
                                    />
                                    <div className={"rewards-layout-my-code-ele-content-copy"}
                                         // onClick={() => {copyFun(".rewards-layout-my-code-ele-content-reg input")}}
                                         onClick={() => {copyFun("urlUid")}}
                                    >
                                        <Tran intlKey={"COPY"}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"rewards-layout-my-code-ele"}>
                                <div className={"rewards-layout-my-code-ele-title"}>
                                    <Tran intlKey={"INVITE_CODE_LINK_SHARE"}/>
                                </div>
                                <div className={"rewards-layout-my-code-ele-content-share"}>
                                    <div className={"rewards-layout-my-code-ele-content-text"}>
                                        {toMaskLong(shareUrl, 1) + userPojo.uId}
                                    </div>
                                    <input className={"rewards-layout-my-code-ele-content-input"}
                                           id={"shareUid"}
                                           value={shareUrl + userPojo.uId}
                                           readOnly={true}
                                    />
                                    <div className={"rewards-layout-my-code-ele-content-copy"}
                                         // onClick={() => {copyFun(".rewards-layout-my-code-ele-content-share input")}}
                                         onClick={() => {copyFun("shareUid")}}
                                    >
                                        <Tran intlKey={"COPY"}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"rewards-layout-my-code-button-ele"}>
                                <button className={"rewards-layout-my-code-button"}
                                        onClick={() => {this.openModal(url + userPojo.uId, "poster")}}
                                >
                                    <img className={"download-img"} src={DOWNLOAD} alt={"down"}/>
                                    <Tran intlKey={"INVITE_POSTER"}/>
                                </button>
                            </div>
                        </div>
                    </Fragment>
                )
            }
        } else {
            if (userPojo) {
                return (
                    <Fragment>
                        <div className={"mobile-rewards-table-main-title"}>
                            <div style={{width: 'auto'}}>
                                <Tran intlKey={"MY_INVITE"}/>
                            </div>
                            <div style={{width: 'auto', fontSize: '14px', color: 'red'}} onClick={() => {this.setStatus("policy")}}>
                                <Tran intlKey={"REWARDS_POLICY_TITLE"}/>
                            </div>
                        </div>
                        <div className={"mobile-rewards-table-main-ele"}>
                            <div className={"mobile-rewards-table-main-ele-title"}>
                                <Tran intlKey={"INVITE_CODE"}/>
                            </div>
                            <div className={"mobile-rewards-table-main-ele-input-container"}>
                                <div className={"mobile-rewards-table-main-ele-input"}>
                                    {userPojo.uId}
                                </div>
                                <textarea className={"rewards-layout-my-code-ele-content-input"}
                                       id={"uid"}
                                       value={userPojo.uId}
                                       // readOnly={true}
                                />
                                <div className={"mobile-rewards-table-main-ele-copy"}
                                     onClick={() => {copyFun("uid")}}
                                >
                                    <Tran intlKey={"COPY"}/>
                                </div>
                            </div>
                        </div>
                        <div className={"mobile-rewards-table-main-ele"}>
                            <div className={"mobile-rewards-table-main-ele-title"}>
                                <Tran intlKey={"INVITE_CODE_LINK_REG"}/>
                            </div>
                            <div className={"mobile-rewards-table-main-ele-input-container-reg"}>
                                <div className={"mobile-rewards-table-main-ele-input"}>
                                    {toMaskLongS(url, 12, 0) + toMaskLongS(userPojo.uId, 0, 5)}
                                </div>
                                <textarea className={"rewards-layout-my-code-ele-content-input"}
                                       id={"urlUid"}
                                       value={url +userPojo.uId}
                                       // readOnly={true}
                                />
                                <div className={"mobile-rewards-table-main-ele-copy"}
                                     onClick={() => {copyFun("urlUid")}}
                                >
                                    <Tran intlKey={"COPY"}/>
                                </div>
                            </div>
                        </div>
                        <div className={"mobile-rewards-table-main-ele"}>
                            <div className={"mobile-rewards-table-main-ele-title"}>
                                <Tran intlKey={"INVITE_CODE_LINK_SHARE"}/>
                            </div>
                            <div className={"mobile-rewards-table-main-ele-input-container-share"}>
                                <div className={"mobile-rewards-table-main-ele-input"}>
                                    {toMaskLongS(shareUrl, 12, 0) + toMaskLongS(userPojo.uId, 0, 5)}
                                </div>
                                <textarea className={"rewards-layout-my-code-ele-content-input"}
                                       id={"shareUid"}
                                       value={shareUrl +userPojo.uId}
                                       // readOnly={false}
                                />
                                <div className={"mobile-rewards-table-main-ele-copy"}
                                     onClick={() => {copyFun("shareUid")}}
                                >
                                    <Tran intlKey={"COPY"}/>
                                </div>
                            </div>
                        </div>
                        <canvas id="canvas" style={{zIndex: '-1', opacity: '0', position: 'absolute'}}>
                            <img id={"poster"} className={"img-size"} src={posterImg} alt={"poster"}/>
                            <QRCode id={"qrCode"} className={"img-qr"} value={url + userPojo.uId} />
                        </canvas>
                        <div className={"mobile-rewards-table-down"}>
                            <div className={"mobile-rewards-table-down-qr"}
                                 onClick={() => {this.setStatus("qr")}}
                            >
                                <img className={"mobile-img"} src={QR} alt={"qr"}/>
                            </div>
                            <button className={"mobile-rewards-down-button"}
                                    // onClick={() => {this.downloadImg()}}
                                    onClick={() => {this.setStatus("qrImg")}}
                            >
                                <img className={"download-img"} src={DOWNLOAD} alt={"down"}/>
                                <Tran intlKey={"INVITE_POSTER"}/>
                            </button>
                        </div>
                    </Fragment>
                )
            }
        }
    }

    openModal = (data, status) => {
        if (data !== '') {
            this.setState({
                showModal: true,
                modalContent: data,
                modalStatus: status,
            })
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    loadingModal = (showModal, modalContent, modalStatus) => {
        let lang = intl.get("LANG").toString() === "0";
        let posterImg = lang ? POSTER : POSTEREN;
        if (showModal === true) {
            if (modalStatus === "policy") {
                return (
                    <div className={"private-modal"}>
                        <div className={"private-modal-win"}
                             style={{justifyContent: 'flex-start', top: 'calc(50% - 175px)', left: 'calc(50% - 300px)',  padding: '20px 0', width: '600px', height: '350px'}}
                        >
                            <div className={"private-modal-win-title"} style={{height: '15%'}}>
                                <Tran intlKey={"REWARDS_POLICY_TITLE"}/>
                            </div>
                            <div className={"private-modal-win-container"} style={{width: '90%', lineHeight: '30px', color: '#666666'}}>
                                {modalContent}
                            </div>
                        </div>
                        <button className={"private-modal-win-close"}
                                style={{top: 'calc(50% - 185px)', left: 'calc(50% + 300px)'}}
                                onClick={() => {this.closeModal()}}
                        >
                            <img className={"img-size"} src={CLOSE} alt={"clo"}/>
                        </button>
                    </div>
                )
            } else {
                return (
                    <div className={"private-modal"}>
                        <div className={"private-modal-win"} id={"canbox"}
                             style={{top: 'calc(50% - 300px)', left: 'calc(50% - 155px)',  padding: '0', width: '310px', height: '550px'}}
                        >
                            <img  id={"poster"} className={"img-size"} src={posterImg} alt={"poster"}/>
                            <QRCode id={"qrCode"} className={"img-qr"} value={modalContent}/>
                            <canvas id="canvas" width="310" height="550" style={{opacity: '0'}}>
                                <img  id={"poster"} className={"img-size"} src={posterImg} alt={"poster"}/>
                                <QRCode id={"qrCode"} className={"img-qr"} value={modalContent}/>
                            </canvas>
                        </div>
                        <button className={"private-modal-win-down"}
                                onClick={() => {this.downloadImg()}}
                        >
                            <div className={"private-modal-win-down-icon"}>
                                <img className={"img-size"} src={DOWN} alt={"download"}/>
                            </div>
                            <div className={"private-modal-win-down-text-area"}>
                                <div className={"private-modal-win-down-text"}>
                                    <Tran intlKey={"DOWNLOAD_TO_DESK"}/>
                                </div>
                                <div className={"private-modal-win-down-text-other"}>
                                    <Tran intlKey={"DOWNLOAD_IMG_TIP"}/>
                                </div>
                            </div>
                        </button>
                        <button className={"private-modal-win-close"}
                                style={{top: 'calc(50% - 310px)', left: 'calc(50% + 155px)'}}
                                onClick={() => {this.closeModal()}}
                        >
                            <img className={"img-size"} src={CLOSE} alt={"close"}/>
                        </button>
                    </div>
                )
            }
        }
    }

    // 个人资产
    loadingAssets = (isMobile, userPojo, inviterData) => {
        if (!isMobile) {
            return (
                <Fragment>
                    <div className={"rewards-layout-assets-title"}>
                        <Tran intlKey={"MY_ASSETS"}/>
                    </div>
                    <div className={"rewards-layout-assets-content"}>
                        <div className={"rewards-layout-assets-element"} style={{backgroundColor: 'rgba(232,245,241,0.5)'}}>
                            <div className={"rewards-layout-assets-element-icon"}>
                                <img className={"rewards-layout-assets-element-icon-img"} src={INVITE_NUM} alt={"icon"}/>
                            </div>
                            <div className={"rewards-layout-assets-element-content"}>
                                <div className={"rewards-layout-assets-element-title"}>
                                    <div>
                                        <Tran intlKey={"INVITE_NUM"}/>
                                    </div>
                                </div>
                                <div className={"rewards-layout-assets-element-num"}>
                                    {formatCurrency(inviterData.length)}
                                </div>
                            </div>
                        </div>
                        <div className={"rewards-layout-assets-element"} style={{backgroundColor: 'rgba(247,247,254,0.5)'}}>
                            <div className={"rewards-layout-assets-element-icon"}>
                                <img className={"rewards-layout-assets-element-icon-img"} src={PURCHASE_NUM} alt={"icon"}/>
                            </div>
                            <div className={"rewards-layout-assets-element-content"}>
                                <div className={"rewards-layout-assets-element-title"}>
                                    <div>
                                        <Tran intlKey={"PURCHASE_NUM"}/>
                                    </div>
                                </div>
                                <div className={"rewards-layout-assets-element-num"}>
                                    {formatCurrency(inviterData.filter(item => item.purchaseCZH > 0).length)}
                                </div>
                            </div>
                        </div>
                        <div className={"rewards-layout-assets-element"} style={{backgroundColor: 'rgba(248,250,255,0.5)'}}>
                            <div className={"rewards-layout-assets-element-icon"}>
                                <img className={"rewards-layout-assets-element-icon-img"} src={REWARDS} alt={"icon"}/>
                            </div>
                            <div className={"rewards-layout-assets-element-content"}>
                                <div className={"rewards-layout-assets-element-title"}>
                                    <div>
                                        <Tran intlKey={"MY_REWARDS"}/>{" (CZH)"}
                                    </div>
                                </div>
                                <div className={"rewards-layout-assets-element-num"}>
                                    {userPojo && formatCurrency(userPojo.awardCZH)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className={"mobile-rewards-assets-title"}>
                        <Tran intlKey={"MY_INVITE"}/>
                    </div>
                    <div className={"mobile-rewards-assets-container"}>
                        <div className={"mobile-rewards-assets-ele"}>
                            <div className={"mobile-rewards-assets-ele-num"}>
                                {formatCurrency(inviterData.length)}
                            </div>
                            <div className={"mobile-rewards-assets-ele-title"}>
                                <Tran intlKey={"INVITE_NUM"}/>
                            </div>
                        </div>
                        <div className={"mobile-rewards-assets-ele"}>
                            <div className={"mobile-rewards-assets-ele-num"}>
                                {formatCurrency(inviterData.filter(item => item.purchaseCZH > 0).length)}
                            </div>
                            <div className={"mobile-rewards-assets-ele-title"}>
                                <Tran intlKey={"PURCHASE_NUM"}/>
                            </div>
                        </div>
                        <div className={"mobile-rewards-assets-ele"}>
                            <div className={"mobile-rewards-assets-ele-num"} style={{color: '#DF5060'}}>
                                {userPojo && formatCurrency(userPojo.awardCZH)}
                            </div>
                            <div className={"mobile-rewards-assets-ele-title"}>
                                <Tran intlKey={"MY_REWARDS"}/>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    // 获取邀请者详情
    genUserAwards = () => {
        let userInfo = sessionStorage.getItem("userInfo");
        if (userInfo) {
            sendGetRequest(findUserAwardsUrl, {
                userName: JSON.parse(userInfo).userName,
            }).then(res => {
                if (res && res.hasOwnProperty("success") && res.success) {
                    let dataOrigin = sortByTimeAsc(res[data], "regTime")
                    let inviterData = sortByTimeAsc(dataOrigin, "lastPurchase");
                    this.setState({
                        inviterData: inviterData,
                        isInviterLoad: true,
                    })
                } else {
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                        return false;
                    } else {
                        return res;
                    }
                }
            })
        }
    }

    // 用户基础信息
    setUsrInfo = (userInfo, userPojo, isUserLoad) => {
        this.setState({
            userPojo: userPojo,
            isUserLoad: isUserLoad,
        })
    }

    // 父子类传参
    getChildrenMsg = (result, type, page) => {
        this.setState({
            type: type,
            currentPage: page,
        })
    }

    getCpMsg = (result, page) => {
        this.setState({
            currentPage: page,
        })
    }

    getModalMsg = (result, showModal, modalContent) => {
        this.setState({
            showModal: showModal,
            modalContent: modalContent,
        })
    }

    /* canvas画图 */
    downloadImg() {
        let imgBack = document.getElementById("poster");
        let img = document.getElementById("qrCode");
        let canvas = document.getElementById("canvas");

        let ratio = window.devicePixelRatio;
        let scale = 4;

        canvas.width = 310 * scale; // 将画布宽&&高放大两倍
        canvas.height = 550 * scale;

        let ctx = canvas.getContext("2d");
        ctx.scale(scale, scale);

        (window.html2canvas || html2canvas)(canvas, {
            useCORS: true,// 开启跨域配置
            width: canvas.scrollWidth * scale, //canvas宽
            height: canvas.scrollHeight * scale,//canvas高
            scale: scale, //按比例增加分辨率 (2=双倍)
            background: "#ffffff",
            dpi: 300,//设备像素比
            }
        ).then(res => {
            this.circleImgTwo(ctx, imgBack, 0, 0, 310, 550, 15);
            // ctx.drawImage(imgBack, 0, 0, 310, 550);
            ctx.drawImage(img, 227, 462, 58, 58);
            // 关闭画布锯齿
            ctx.mozImageSmoothingEnabled = false;
            ctx.webkitImageSmoothingEnabled = false;
            ctx.msImageSmoothingEnabled = false;

            // 将canvas对象转换为base64位编码
            const url = canvas.toDataURL('image/png');

            // 点击触发下载
            const a = document.createElement('a');
            const event = new MouseEvent('click');
            a.download = 'poster.png' || 'Certificate';
            a.href = url;
            a.dispatchEvent(event);

            // canvas.toBlob(function(blob) {
            //     FileSaver.saveAs(blob, 'poster.png');
            // },"image/png", 1.0
            // )
        })

        // canvas.setAttribute('width',canvas.scrollWidth * scale);
        // canvas.setAttribute('height',canvas.scrollHeight * scale);
        // // canvas.setAttribute('quality',1);
        // canvas.setAttribute('scale',4);
        // canvas.setAttribute('dpi',300);
        // this.circleImgTwo(ctx, imgBack, 0, 0, 310, 550, 15);
        // // ctx.drawImage(imgBack, 0, 0, 310, 550);
        // ctx.drawImage(img, 227, 462, 58, 58);
        // canvas.toBlob(function(blob) {
        //     FileSaver.saveAs(blob, 'poster.png');
        // })
    }

    // 生产圆角矩形
    /*
    *  参数说明
    *  ctx Canvas实例
    *  img 图片地址
    *   x  x轴坐标
    *   y  y轴坐标
    *   w  宽度
    *   h  高度
    *   r  弧度大小
    */
    circleImgTwo(ctx, img, x, y, w, h, r) {
        // 画一个图形
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        ctx.beginPath();
        ctx.moveTo(x + r, y);
        ctx.arcTo(x + w, y, x + w, y + h, r);
        ctx.arcTo(x + w, y + h, x, y + h, r);
        ctx.arcTo(x, y + h, x, y, r);
        ctx.arcTo(x, y, x + w, y, r);
        ctx.closePath();
        ctx.strokeStyle = '#4B82F3'; // 设置绘制圆形边框的颜色
        ctx.stroke();
        ctx.clip();
        ctx.drawImage(img, x, y, w, h);
    }

    componentDidMount() {
        this.genUserAwards();
        getMedia(intl, "REWARDS", this);
    }

    render() {
        let isMobile = this.state.isMobile;

        let userPojo = this.state.userPojo;
        let isUserLoad = this.state.isUserLoad;

        let status = this.state.status;
        let type = this.state.type;
        let showModal = this.state.showModal;
        let modalContent = this.state.modalContent;
        let modalStatus = this.state.modalStatus;
        let currentPage = this.state.currentPage;

        let inviterData = this.state.inviterData;
        let isInviterLoad = this.state.isInviterLoad;

        let mediaResource = this.state.mediaResource;
        let isMediaLoad = this.state.isMediaLoad;
        let isAllLoad = isMediaLoad && isInviterLoad;

        console.log("用户信息：", userPojo)
        console.log("邀请者详情：", inviterData)
        console.log("邀请者详情加载：", isInviterLoad)

        return (
            <PageLayout parent={this} pNameCN={intl.get("REWARDS")} pNameEN={intl.get("REWARDS_EN")}
                        needUser={true}
                        dom={this}
                        pContent={this.loadingPageContent(isInviterLoad, inviterData, userPojo, type, showModal, modalContent, currentPage, isMobile, status, isUserLoad, mediaResource, isAllLoad, modalStatus)}
            />
        )
    }
}

export default Rewards;
