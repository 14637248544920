import React, {Component, Fragment} from "react";
import "./page.css";
import {isMobile} from "../utils/MobileMgr";
import PageHeader from "./PageHeader";
import {DownOutlined} from "@ant-design/icons";
import {langType} from "../utils/Request";
import {data, error, findUserInfoUrl, sendGetRequest} from "../utils/RequestUrlMgr";
import {connectionError, fail, failReturn, findMsgByError} from "../utils/ErrorMgr";
import {Dropdown, Menu, Spin} from "antd";
import intl from "react-intl-universal";
import Tran from "../config/IntTranslation";
import AVATAR from "../assets/icon/icon_avatar.svg";
import DocumentTitle from "react-document-title";

class PageLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            isLogin: false,
            // userInfo: '', // 用户缓存信息
            userPojo: '', // 用户数据
            currentDisplay: true,
            otherDisplay: true,
        }
    }

    genUserPojo() {
        let userInfo = sessionStorage.getItem("userInfo");
        console.log(userInfo)
        if (userInfo) {
            let userInfoParse = JSON.parse(userInfo);
            sendGetRequest(findUserInfoUrl, {
                userName: userInfoParse.userName,
                pwd: userInfoParse.userPwd,
            }).then(res => {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        isLogin: true,
                        // userInfo: userInfoParse,
                        userPojo: res[data],
                    });
                    if (this.props.needUser) {
                        this.props.parent.setUsrInfo(userInfoParse, res[data], true);
                    }
                } else {
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
        } else {
            failReturn(findMsgByError("LOGIN_FIRST_ERROR"), "/login")
        }
    }

    componentDidMount() {
        if (!this.props.isPro) {
            this.genUserPojo();
        }
    }

    loadingUserInfo = (langMenu, pNameCN, pNameEN) => {
        let userInfo = sessionStorage.getItem("userInfo");
        let userName = userInfo && JSON.parse(userInfo).userName;
        let lang = intl.get("LANG").toString() === "0";
        return (
            <Fragment>
                <div className={"page-title"}>
                    <div className={"page-title-cn"}>
                        {pNameCN}
                    </div>
                    <div className={"page-title-en"}>
                        {lang ? pNameEN : null}
                    </div>
                </div>
                <div className={"page-language"}>
                    <Dropdown className={"page-language-box"} overlay={langMenu} placement="topCenter" trigger={['click']}>
                        <div>
                            {
                                intl.get("LANG").toString() === "0" ?
                                    <Tran intlKey="CHINESE_SIMPLE"/> :  <Tran intlKey="ENGLISH"/>
                            }
                            <DownOutlined style={{marginLeft: '10px'}}/>
                        </div>
                    </Dropdown>
                </div>
                <div className={"page-account"}>
                    <div className={"page-account-avatar"}>
                        <Dropdown
                            overlay={this.userSetting()}
                            placement={"bottomCenter"}
                            trigger={"click"}
                        >
                            <img className={"img-size"} src={AVATAR} alt={"avatar"}/>
                        </Dropdown>
                    </div>
                    <div className={"page-account-username"}>
                        {userName}
                    </div>
                </div>
            </Fragment>
        )
    }

    userSetting = () => {
        return (
            <Menu style={{paddingTop: "0px", paddingBottom: "0px", backgroundColor: "rgba(30, 67, 130, 0)"}}>
                <Menu.Item className={"page-language-item"} key={"account"}
                           onClick={() => {window.location.href = "/account"}}
                >
                    {intl.get("ACCOUNT_SETTING")}
                </Menu.Item>
                <Menu.Item className={"page-language-item"} key={"logout"}
                           onClick={() => {
                               sessionStorage.removeItem("userInfo");
                               window.location.href = '/login';
                           }}
                >
                    {intl.get("ACCOUNT_LOGOUT")}
                </Menu.Item>
            </Menu>
        )
    }

    setDisplay = (status) => {
        this.setState({
            currentDisplay: status,
        })
    }

    setClose = () => {
        this.props.dom.setCloseMenu()
    }

    render() {
        let isMobile = this.state.isMobile;
        let isLogin = this.state.isLogin;
        let pNameCN = this.props.pNameCN;
        let pNameEN = this.props.pNameEN;
        let isPro = this.props.isPro;

        const langMenu = (
            <Menu style={{paddingTop: "0px", paddingBottom: "0px", backgroundColor: "rgba(30, 67, 130, 0)"}}>
                <Menu.Item className={"page-language-item"} key={"zh"} onClick={() => langType("cn")}>
                    {intl.get("CHINESE")}
                </Menu.Item>
                <Menu.Item className={"page-language-item"} key={"en"} onClick={() => langType("en")}>
                    {intl.get("ENGLISH")}
                </Menu.Item>
            </Menu>
        );

        if (!isMobile) {
            return (
                <div className={"page-layout"}>
                    <PageHeader isMobile={isMobile} isLogin={isLogin}/>
                    <div className={"page-wrapper"}>
                        <div className={"page-container"}>
                            <div className={"page-content"}>
                                {
                                    isLogin ?
                                    <Fragment>
                                        <div className={"page-header"} id={"pageHeader"}>
                                            {this.loadingUserInfo(langMenu, pNameCN, pNameEN)}
                                        </div>
                                        {this.props.pContent}
                                    </Fragment> :
                                    <div style={{textAlign: "center", width: "100%", marginTop: '35vh'}}>
                                        <Spin tip={intl.get("DATA_LOADING")} size={"large"}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <DocumentTitle title={intl.get("DT")} key="title"/>
                </div>
            )
        } else {
            let hasOverview = this.props.dom;
            let displayStatus = '';
            if (hasOverview) {
                displayStatus = this.props.dom.state.displayStatus;
            }

            let currentDisplay = this.state.currentDisplay;
            return (
                <div className={"mobile-page-layout"}>
                    <PageHeader isMobile={isMobile} isLogin={isLogin} dom={this}
                                isPro={isPro}
                                pageName={pNameCN} displayStatus={displayStatus} hasOverview={hasOverview} inviteUId={this.props.inviteUId}/>
                    <div style={{display: (hasOverview && !this.props.dom.state.displayStatus) || this.state.otherDisplay ? '' : 'none'}}>
                        <div style={{display: currentDisplay ? '' : 'none'}}>
                            {this.props.pContent}
                        </div>
                    </div>
                    <DocumentTitle title={intl.get("DT")} key="title"/>
                </div>
            )
        }
    }
}

export default PageLayout;
