import React, {Fragment} from "react";
import {connectionError, fail, failDur, findMsgByError, findMsgBySuccess, success} from "./ErrorMgr";
import {Input, Menu} from "antd";
import Tran from "../config/IntTranslation";
import {
    data,
    error,
    findCoreCallBackInfosUrl,
    findCoreInfoSec,
    findCoreInfosUrl, genPojo,
    getMediaUrl,
    handleErrorUrl, newUserUrl,
    sendGetRequest, sendPostRequest, userLoginUrl
} from "./RequestUrlMgr";
import {
    userEmailChecker, userEmailErrorMsg, userFPwdErrorMsg, userFPwdNameErrorMsg,
    userNameChecker,
    userNameErrorMsg,
    userPasswordChecker, userPasswordErrorMsg,
    userPhoneChecker, userPhoneErrorMsg, userPwdModifyErrorMsg
} from "./UserInputChecker";
import intl from "react-intl-universal";
import {SearchOutlined} from "@ant-design/icons";
import {listCountry} from "../config/countryCode";
import SEARCH from "../assets/icon/icon_search.svg";

// 姓名脱敏
const toMaskName = (name) => {
    if (name.length === 2) {
        return name.substring(0, 1) + '*' // 截取name 字符串截取第一个字符，
    } else if (name.length === 3) {
        return name.substring(0, 1) + "*" + name.substring(2, 3) // 截取第一个和第三个字符
    } else if (name.length === 4) {
        return name.substring(0, 1) + "*" + name.substring(name.length - 2, name.length) // 截取第一个和最后两个字符
    } else if (name.length > 4) {
        return name.substring(0, 1) + "**" + name.substring(name.length - 2, name.length) // 截取第一个和最后两个字符
    }
}

// 长字符脱敏
const toMaskLong = (data, num) => {
    return data.substring(0, num) + "***" + data.substring(data.length - num, data.length) // 截取第一个和最后一个字符
}

// 长字符脱敏
const toMaskLongS = (data, num, numT) => {
    return data.substring(0, num) + "***" + data.substring(data.length - numT, data.length) // 截取第一个和最后一个字符
}

// 时间规范
const timeFormat = (time) => {
    if (time) {
        return time.toString().substring(0, 4) + "-" + time.toString().substring(4, 6) + "-" + time.toString().substring(6, 8)
    }
}

// 区号
const countryId = (commonData, data, curList, page, isMobile, label) => {
    if (!isMobile) {
        return (
            <div style={{width: label ? "382px" : "340px"}}>
                <div style={{width: "calc(73% + 2px)"}}>
                    <Menu
                        style={{
                            zIndex: '99',
                            position: 'relative',
                            margin: '10px 0 0 0',
                            height: '250px',
                            overflowY: 'scroll',
                            background: "rgb(255,255,255)",
                            borderRadius: '5px',
                            border: '1px solid #F2F4F8'
                        }}>
                        {/*<div style={{margin: '0 auto', width: '90%', height: '40px', borderRadius: '8px', background: '#F7F8FB'}}>*/}
                        {/*    <Input*/}
                        {/*        prefix={<SearchOutlined style={{color: "grey"}}/>}*/}
                        {/*        onChange={(e) => {page.setSearchValue(e)}}*/}
                        {/*        onKeyDown={(e) => {page.onKeyDownChange(e)}}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <Input
                            className={"register-search"}
                            size="middle"
                            placeholder={intl.get("COUNTRY_ID_CHOOSE_PH")}
                            maxLength={20}
                            onChange={(e) => {page.genSearch(e, listCountry)}}
                            prefix={<SearchOutlined style={{color: "grey"}}/>}
                        />
                        {
                            curList.length > 0 ?
                                curList.map((item, index) => {
                                    return (
                                        <Menu.Item key={item.key + item.value}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}
                                                 onClick={() => {
                                                     changeCountryCode(page, item.value, isMobile, label)
                                                 }}>
                                                <div><Tran intlKey={item.key}/></div>
                                                <div>{"+"}{item.value}</div>
                                            </div>
                                        </Menu.Item>
                                    )
                                }) :
                                <Fragment>
                                    <div style={{paddingLeft: '10px', fontSize: '12px', lineHeight: '25px', color: '#999999'}}>
                                        <Tran intlKey="COMMON_USE"/>
                                    </div>
                                    {
                                        commonData.length > 1 && commonData.map((item, index) => {
                                            return (
                                                <Menu.Item key={item.key + item.value + index}>
                                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
                                                         onClick={() => {
                                                             changeCountryCode(page, item.value, isMobile, label)
                                                         }}>
                                                        <div style={{width: '70%', overflow: 'hidden'}}><Tran intlKey={item.key}/>
                                                        </div>
                                                        <div style={{width: '25%', textAlign: 'right'}}>{"+"}{item.value}</div>
                                                    </div>
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                    <div style={{paddingLeft: '10px', fontSize: '12px', lineHeight: '25px', color: '#999999'}}>
                                        <Tran intlKey="ALL_COUNTRY"/>
                                    </div>
                                    {
                                        data.length > 1 && data.map((item, index) => {
                                            return (
                                                <Menu.Item key={item.key + item.value + index}>
                                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
                                                         onClick={() => {
                                                             changeCountryCode(page, item.value, isMobile, label)
                                                         }}>
                                                        <div style={{width: '70%', overflow: 'hidden'}}><Tran intlKey={item.key}/>
                                                        </div>
                                                        <div style={{width: '25%', textAlign: 'right'}}>{"+"}{item.value}</div>
                                                    </div>
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                </Fragment>
                        }
                    </Menu>
                </div>
            </div>
        )
    } else {
        return (
            <Menu style={{width: '100%', height: 'auto', border: '0'}}>
                {
                    curList.length > 0 ?
                        curList.map((item, index) => {
                            return (
                                <Menu.Item key={item.key + item.value}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}
                                         onClick={() => {
                                             changeCountryCode(page, item.value, isMobile, label)
                                         }}>
                                        <div><Tran intlKey={item.key}/></div>
                                        <div>{"+"}{item.value}</div>
                                    </div>
                                </Menu.Item>
                            )
                        }) :
                        <Fragment>
                            <div style={{paddingLeft: '10px', fontSize: '12px', lineHeight: '25px', color: '#999999'}}>
                                <Tran intlKey="COMMON_USE"/>
                            </div>
                            {
                                commonData.length > 1 && commonData.map((item, index) => {
                                    return (
                                        <Menu.Item key={item.key + item.value}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}
                                                 onClick={() => {
                                                     changeCountryCode(page, item.value, isMobile, label)
                                                 }}>
                                                <div><Tran intlKey={item.key}/></div>
                                                <div>{"+"}{item.value}</div>
                                            </div>
                                        </Menu.Item>
                                    )
                                })
                            }
                            <div style={{paddingLeft: '10px', fontSize: '12px', lineHeight: '25px', color: '#999999'}}>
                                <Tran intlKey="ALL_COUNTRY"/>
                            </div>
                            {
                                data.length > 1 && data.map((item, index) => {
                                    return (
                                        <Menu.Item key={item.key + item.value + index}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}
                                                 onClick={() => {
                                                     changeCountryCode(page, item.value, isMobile, label)
                                                 }}>
                                                <div><Tran intlKey={item.key}/></div>
                                                <div>{"+"}{item.value}</div>
                                            </div>
                                        </Menu.Item>
                                    )
                                })
                            }
                        </Fragment>
                }
            </Menu>
        )
    }
}

const changeCountryCode = (dom, data, isMobile, label) => {
    let countryStatus = dom.state.countryStatus;
    if (!isMobile) {
        if (label) {
            let setterPojo = dom.setterInfo.setterPojo;
            setterPojo["country"] = data;
            dom.setState({
                type: label,
                countryStatus: !countryStatus,
                setterPojo
            })
        } else {
            // let registerPojo = dom.registerInfo.registerPojo;
            // registerPojo["country"] = data;
            dom.setState({
                countryStatus: !countryStatus,
                countryCode: data,
                // registerPojo
            })
        }
    } else {
        if (label) {
            let setterPojo = dom.setterInfo.setterPojo;
            setterPojo["country"] = data;
            dom.setState({
                type: label,
                countryStatus: !countryStatus,
                countryCode: data,
                setterPojo
            })
        } else {
            // let registerPojo = dom.registerInfo.registerPojo;
            // registerPojo["country"] = data;
            dom.setState({
                type: "home",
                status: "register",
                countryCode: data,
                // registerPojo,
            })
        }
    }
}

//60s倒计时
const handleCountDown = (seconds, dom, label) => {
    let second = seconds;
    const countDown = () => {
        if (second > 0) {
            second--;
            if (label) {
                if (label === "mPhone") {
                    dom.setState({
                        secondOld: second
                    })
                } else {
                    dom.setState({
                        secondL: second
                    })
                }
            } else {
                dom.setState({
                    second: second
                })
            }
        }
        if (second === 0) {
            second = seconds;
            if (label) {
                if (label === "mPhone") {
                    dom.setState({
                        secondOld: second
                    })
                } else {
                    dom.setState({
                        secondL: second
                    })
                }
            } else {
                dom.setState({
                    second: second
                })
            }
            return;
        }
        setTimeout(countDown, 1000);
    };
    setTimeout(countDown, 1000);
};

// 缓存
const saveSession = (title, data) => {
    sessionStorage.setItem(title, JSON.stringify(data));
}

// imgUrl
const convertToUrl = (imgUrl) => {
    return (
        "data:image/png;base64," + imgUrl
    );
}

// 时间排序——升序
const sortByTimeAsc = (arr, key) => {
    if (arr) {
        arr.sort(function (a, b) {
            let x = a[key] === null ? "0000-00-00 00:00:00" : a[key];
            let y = b[key] === null ? "0000-00-00 00:00:00" : b[key];
            console.log("x:", x);
            console.log("y:", y);
            return x > y ? -1 : x < y ? 1 : 0;
        });
        console.log("arrAsc:", arr)
        return arr;
    }
}

// 时间排序——降序
const sortByTimeDes = (arr, key) => {
    if (arr) {
        arr.sort(function (a, b) {
            let x = a[key];
            let y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
        });
        console.log("arrDes:", arr)
        return arr;
    }
}

// 交换合并字符串字母
const toChar = (a, b) => {
    let lenTotal = a.length + b.length;
    let p1s = a.split("");
    let p2s = b.split("");
    let result = "";
    let id1 = 0;
    let id2 = 0;
    for (let i = 0; i < lenTotal; i++) {
        if (i % 2 === 0) {
            result += p1s[id1];
            id1++;
        } else {
            result += p2s[id2];
            id2++;
        }
    }
    return result;
}

// 处理json转换为map
const handleJson = (data) => {
    if (data) {
        let items = new Map();
        for (let key in data) {
            items.set(data[key].itemKey, data[key]);
        }
        return items;
    }
}

const langType = (type) => {
    localStorage.setItem('lang_type', type);
    let url = window.location.href;
    let params = url.split("://");
    let urlHeader = params[0];
    let ct = params[1];
    let ctOne = ct.split("/")[1];
    let pageName = ct.split("/")[2];
    let param = ct.split("/")[3];
    if (ctOne === "") {
        let s = url + type;
        window.location.href = s;
        return;
    }
    let ctLength = ct.split("/").length;
    if (!pageName) {
        window.location.href = urlHeader + "://" + ct.split("/")[0] + "/" + type;
    }
    if (ctLength === 3 && pageName) {
        window.location.href = urlHeader + "://" + ct.split("/")[0] + "/" + pageName;
    }
    if (ctLength === 4 && pageName && param) {
        window.location.href = urlHeader + "://" + ct.split("/")[0] + "/" + pageName + "/"  + param;
    }
}

// 获取静态资源
const getMedia = (intl, page, dom) => {
    let lang = intl.get("LANG").toString() === "0" ? "CN" : "EN";
    console.log(lang)
    console.log(page)
    sendGetRequest(getMediaUrl, {
        lang: lang,
        page: page,
    }).then(res => {
        console.log("静态资源res：", res);
        if (res && res.hasOwnProperty("success") && res.success) {
            let mediaResource = dom.state.mediaResource;
            let dataList = res[data];
            if (dataList.length > 0) {
                dataList.forEach((item) => {
                    mediaResource.set(item["itemKey"], item);
                })
                console.log(mediaResource)
                dom.setState({
                    mediaResource: mediaResource,
                    isMediaLoad: true,
                })
                return true;
            } else {
                fail(findMsgByError("DATA_LOAD_ERROR"))
            }
        } else {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                fail(findMsgByError(res[error]))
            }
        }
    })
}

// 获取服务静态资源
const getServiceMedia = (intl, page, dom) => {
    let lang = intl.get("LANG").toString() === "0" ? "CN" : "EN";
    sendGetRequest(getMediaUrl, {
        lang: lang,
        page: page,
    }).then(res => {
        console.log("静态资源res：", res);
        if (res && res.hasOwnProperty("success") && res.success) {
            let mediaServiceResource = dom.state.mediaServiceResource;
            let dataList = res[data];
            if (dataList.length > 0) {
                dataList.forEach((item) => {
                    mediaServiceResource.set(item["itemKey"], item);
                })
                console.log(mediaServiceResource)
                dom.setState({
                    mediaServiceResource: mediaServiceResource,
                    isMediaServiceLoad: true,
                })
                return true;
            } else {
                fail(findMsgByError("DATA_LOAD_ERROR"))
            }
        } else {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                fail(findMsgByError(res[error]))
            }
        }
    })
}

// 资产核心数据 api
const genCoreInfos = (page) => {
    sendGetRequest(findCoreInfosUrl, {
        sec: findCoreInfoSec,
    }).then(res => {
        console.log("获取CZH相关基本信息：", res)
        if (res && res.hasOwnProperty("success") && res.success) {
            let coreInfo = page.state.coreInfo;
            let dataL = res[data];
            if (dataL.length > 0) {
                dataL.forEach((item) => {
                    coreInfo.set(item["itemKey"], item);
                })
                page.setState({
                        coreInfo: coreInfo,
                        isCoreLoad: true,
                    },
                    () => {
                        genCoreCallBack(page);
                    })
                return true;
            } else {
                fail(findMsgByError("DATA_LOAD_ERROR"))
                return false;
            }
        } else {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
                return false;
            } else {
                fail(findMsgByError(res[error]));
                return false;
            }
        }
    })
}

const genCoreCallBack = (page) => {
    let coreInfo = page.state.coreInfo;
    let isCoreLoad = page.state.isCoreLoad;
    let userInfo = page.state.userInfo;
    if (isCoreLoad) {
        let imgUrl = coreInfo.get("USDT_QR")["itemValue"];
        let link = toChar(coreInfo.get("USDT_LINK")["itemValue"], coreInfo.get("USDT_LINK")["itemSec"]);
        if (imgUrl && link) {
            console.log(imgUrl, link)
            sendGetRequest(findCoreCallBackInfosUrl, {
                img: imgUrl,
                link: link,
                type: "e",
            }).then(res => {
                console.log("获取后回传校验：", res)
                if (res && res.hasOwnProperty("success") && res.success) {
                    let imgTUrl = coreInfo.get("USDT_QR_T")["itemValue"];
                    let linkT = toChar(coreInfo.get("USDT_LINK_T")["itemValue"], coreInfo.get("USDT_LINK_T")["itemSec"]);
                    if (imgTUrl && linkT) {
                        console.log(imgTUrl, linkT)
                        sendGetRequest(findCoreCallBackInfosUrl, {
                            img: imgTUrl,
                            link: linkT,
                            type: "t",
                        }).then(resT => {
                            console.log("获取后回传校验T：", resT)
                            if (resT && resT.hasOwnProperty("success") && resT.success) {
                                //
                            } else {
                                if (resT[error] === "CORE_ERROR") {
                                    if (userInfo) {
                                        sendGetRequest(handleErrorUrl, {
                                            userName: userInfo.userName,
                                            pwd: userInfo.userPwd,
                                        }).then(resTError => {
                                            console.log("CORE_ERROR时调用resT：", resTError);
                                        })
                                    } else {
                                        fail(findMsgByError("DATA_LOAD_ERROR"))
                                    }
                                } else {
                                    fail(findMsgByError(res[error]));
                                }
                            }
                        })
                    }
                } else {
                    if (res[error] === "CORE_ERROR") {
                        if (userInfo) {
                            sendGetRequest(handleErrorUrl, {
                                userName: userInfo.userName,
                                pwd: userInfo.userPwd,
                            }).then(resError => {
                                console.log("CORE_ERROR时调用res：", resError);
                            })
                        } else {
                            fail(findMsgByError("DATA_LOAD_ERROR"))
                        }
                    } else {
                        fail(findMsgByError(res[error]));
                    }
                }
            })
        }
    }
}

const copyFun = (id) => {
    // let copyDOM = document.querySelector(id);  //需要复制文字的节点
    // // let range = document.createRange(); //创建一个range
    // // window.getSelection().removeAllRanges();   //清楚页面中已有的selection
    // // range.selectNode(copyDOM);    // 选中需要复制的节点
    // // window.getSelection().addRange(range);   // 执行选中元素
    // // console.log(copyDOM.value)
    // if (copyDOM) {
    //     document.body.appendChild(copyDOM);
    //     copyDOM.select();
    // }
    window.getSelection().removeAllRanges();//这段代码必须放在前面否则无效
    let input = document.getElementById(id);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    let successful = document.execCommand('copy');    // 执行 copy 操作
    if (successful) {
        success(findMsgBySuccess("COPY_SUCCESS"))
    } else {
        fail(findMsgByError("COPY_FAIL_ERROR"))
    }
    // 移除选中的元素
    window.getSelection().removeAllRanges();
}

/* loginInfo */
const genLoginInfo = (e, props, dom) => {
    let LoginPojo = dom.loginInfo.loginPojo;
    let inputValue = e.target.value;
    switch (props) {
        case "userNameL": {
            if (inputValue && (userPhoneChecker.test(inputValue) || userEmailChecker.test(inputValue))) {
                LoginPojo["userName"] = inputValue;
                dom.setState({
                    LoginPojo
                })
            } else {
                LoginPojo["userName"] = "";
                dom.setState({
                    LoginPojo
                })
            }
            return;
        }
        case "userName": {
            if (inputValue && userNameChecker.test(inputValue)) {
                LoginPojo["userName"] = inputValue;
                dom.setState({
                    LoginPojo
                })
            } else {
                LoginPojo["userName"] = "";
                dom.setState({
                    LoginPojo
                })
            }
            return;
        }
        case "userPwd": {
            if (inputValue && userPasswordChecker.test(inputValue)) {
                LoginPojo["userPwd"] = inputValue;
                dom.setState({
                    LoginPojo
                })
            } else {
                LoginPojo["userPwd"] = "";
                dom.setState({
                    LoginPojo
                })
            }
            return;
        }
        default: {
            return;
        }
    }
}

/* registerInfo */
const genRegisterInfo = (e, props, dom) => {
    let registerPojo = dom.registerInfo.registerPojo;
    let inputValue = e.target.value;
    console.log("input：", inputValue)
    switch (props) {
        case "userName": {
            if (inputValue && userNameChecker.test(inputValue)) {
                registerPojo["userName"] = inputValue;
                dom.setState({
                    registerPojo
                })
            } else {
                registerPojo["userName"] = "";
                dom.setState({
                    registerPojo
                })
            }
            return;
        }
        case "userPhone": {
            if (inputValue && userPhoneChecker.test(inputValue)) {
                registerPojo["userPhone"] = inputValue;
                registerPojo["regCode"] = 1;
                dom.setState({
                    registerPojo
                })
            } else {
                registerPojo["userPhone"] = "";
                dom.setState({
                    registerPojo
                })
            }
            return;
        }
        case "userEmail": {
            if (inputValue && userEmailChecker.test(inputValue)) {
                registerPojo["userEmail"] = inputValue;
                registerPojo["regCode"] = 2;
                dom.setState({
                    registerPojo
                })
            } else {
                registerPojo["userEmail"] = "";
                dom.setState({
                    registerPojo
                })
            }
            return;
        }
        case "userPwd": {
            if (inputValue && userPasswordChecker.test(inputValue)) {
                registerPojo["userPwd"] = inputValue;
                dom.setState({
                    registerPojo
                })
            } else {
                registerPojo["userPwd"] = "";
                dom.setState({
                    registerPojo
                })
            }
            return;
        }
        case "inviteUId": {
            if (inputValue) {
                registerPojo["inviteUId"] = inputValue.replace(/\s+/g, "");
                dom.setState({
                    registerPojo
                })
            } else {
                registerPojo["inviteUId"] = "";
                dom.setState({
                    registerPojo
                })
            }
            return;
        }
        default: {
            return;
        }
    }
}

const register = (dom, pojo, loginPojo, userInfo) => {
    let registerPojo = genPojo(newUserUrl, pojo);
    console.log(pojo)
    console.log("传参：", registerPojo)
    if (registerPojo) {
        sendPostRequest(registerPojo).then(res => {
            console.log("传参data：", registerPojo.data);
            console.log(res)
            dom.setState({
                registerResponse: res,
            })
            if (res && res.hasOwnProperty("success") && res.success) {
                success(findMsgBySuccess("REGISTER_SUCCESS"));
                console.log(loginPojo)
                loginPojo["userName"] = registerPojo.data.userName;
                loginPojo["userPwd"] = registerPojo.data.userPwd;
                loginPojo["useUserName"] = true;
                loginPojo["step"] = 1;
                dom.setState({
                        loginPojo,
                        submitStatus: 2,
                    },
                    () => {
                        let loginPojoOne = genPojo(userLoginUrl, loginPojo);
                        console.log("用户登录传参：", loginPojoOne)
                        if (loginPojoOne) {
                            sendPostRequest(loginPojoOne).then(resOne => {
                                console.log(resOne)
                                if (resOne && resOne.hasOwnProperty("success") && resOne.success) {
                                    loginPojo["step"] = 2;
                                    let loginPojoTwo = genPojo(userLoginUrl, loginPojo);
                                    console.log("用户登录传参：", loginPojoTwo)
                                    if (loginPojoTwo) {
                                        sendPostRequest(loginPojoTwo).then(resTwo => {
                                            console.log(resTwo);
                                            userInfo["userName"] = resTwo[data].userName;
                                            userInfo["userPwd"] = loginPojo["userPwd"];
                                            userInfo["uId"] = resTwo[data].uId;
                                            userInfo["inviterName"] = resTwo[data].inviterName;
                                            console.log(userInfo);
                                            saveSession("userInfo", userInfo)
                                            dom.setState({
                                                userPojo: res[data],
                                            })
                                            // success(findMsgBySuccess("LOGIN_SUCCESS"), "/overview");
                                            window.location.href = '/overview';
                                        })
                                    }
                                }
                            })
                        }
                    })
            } else {
                failDur(findMsgByError(res ? res[error] : "UNDEFINED"), 5);
                closeModal(dom);
                dom.setState({
                    submitStatus: 0,
                })
            }
        })
        return;
    }
    dom.setState({
        submitStatus: 1,
    })
}

const closeModal = (dom) => {
    dom.setState({
        showModal: false,
    });
}

const getErrorMsg = (props, dom) => {
    if (dom.state.submitStatus !== 1) {
        return;
    }
    switch (props) {
        case "userName": {
            return dom.registerInfo.registerPojo["userName"] === "" ? userNameErrorMsg(intl) : "";
        }
        case "userPhone": {
            return dom.registerInfo.registerPojo["userPhone"] === 0 || dom.registerInfo.registerPojo["userPhone"] === "" ? userPhoneErrorMsg(intl) : "";
        }
        case "userEmail": {
            return dom.registerInfo.registerPojo["userEmail"] === "" ? userEmailErrorMsg(intl) : "";
        }
        case "userPwd": {
            return dom.registerInfo.registerPojo["userPwd"] === "" ? userPasswordErrorMsg(intl) : "";
        }
        case "mPwd": {
            return dom.state.mPwd === "" ? userPasswordErrorMsg(intl) : "";
        }
        case "mPwdV": {
            return dom.state.mPwd !== dom.state.mPwdV ? userPwdModifyErrorMsg(intl) : "";
        }
        case "userNameL": {
            return dom.loginInfo.loginPojo["userName"] === "" ? userNameErrorMsg(intl) : "";
        }
        case "userNameLP": {
            return dom.loginInfo.loginPojo["userName"] === "" ? userFPwdErrorMsg(intl) : "";
        }
        case "userNameF": {
            return dom.loginInfo.loginPojo["userName"] === "" ? userFPwdNameErrorMsg(intl) : "";
        }
        case "userPwdL": {
            return dom.loginInfo.loginPojo["userPwd"] === "" ? userPasswordErrorMsg(intl) : "";
        }
        case "param": {
            return dom.state.param === "" ? userFPwdErrorMsg(intl) : "";
        }
        default: {
            return "";
        }
    }
}

const changeCountryStatus = (dom, countryStatus) => {
    let status = !countryStatus;
    dom.setState({
        countryStatus: status,
    })
}

const changeType = (type, dom) => {
    dom.setState({
            type: type,
        },
        () => scrollTop())
}

const scrollTop = () => {
    let navTop = document.getElementById("pageHeader");
    navTop.scrollIntoView();
    window.scrollTo(0, 0);
}

export {
    toMaskName,
    toMaskLong,
    toMaskLongS,
    timeFormat,
    countryId,
    changeCountryCode,
    handleCountDown,
    saveSession,
    convertToUrl,
    sortByTimeDes,
    sortByTimeAsc,
    toChar,
    handleJson,
    langType,
    getMedia,
    getServiceMedia,
    genCoreInfos,
    genCoreCallBack,
    copyFun,
    genLoginInfo,
    genRegisterInfo,
    register,
    closeModal,
    getErrorMsg,
    changeCountryStatus,
    changeType,
    scrollTop
}
